import { Radio } from '@/shared/components/fields'
import { Button } from '@/shared/components/commons'
import { FunnelIcon } from '@heroicons/react/24/outline'
import { AsideModal } from '@/shared/components/modals'
import { useFilterReport } from '../hooks'
import CheckList from '@/shared/components/checkList'

export const FilterStockAlmacenTemplate = () => {
	const {
		isOpen,
		setOpen,
		places,
		onSubmit,
		onTypeChange,
		loading,
		initialLoad,
		defaultCheck,
		onChangePlaceCheck
	} = useFilterReport()

	return (
		<>
			<div className='main-layout-toolbar toolbar'>
				<AsideModal
					title='Filtros'
					className='w-full md:w-[30%] xl:w-[20%]'
					closeButtonElement={<FunnelIcon width={28} className='text-white' />}
					classNameButton='toolbar-button'
					isOpen={isOpen}
					handleClose={setOpen}
				>
					<form onSubmit={onSubmit} className='h-full flex flex-col'>
						<div className={`absolute top-14 bottom-14 left-2 right-2 overflow-auto`}>
							<fieldset className='border border-slate-300  rounded-md p-2 overflow-auto'>
								<legend className='font-semibold'>Plazas</legend>
								<CheckList
									onCheckChange={onChangePlaceCheck}
									onDefaultValuesChange={onChangePlaceCheck}
									initialItems={places?.map((item) => ({
										id: item.plaza,
										label: item.plaza,
										defaultChecked: defaultCheck(item.codigoLocalidad),
										itemList: item.almacenes.map((list) => ({
											id: list.codigo,
											value: list.codigo,
											label: list.nombre,
											sup: list.tipo,
											defaultChecked: list.tipo.includes('Tienda') || list.tipo.includes('Bodega')
										}))
									}))}
								/>
							</fieldset>
							<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col'>
								<legend className='font-semibold'>Tipo de Articulo</legend>
								<Radio id='Prenda' name='Tipo' value='0' onChange={onTypeChange} label='Prenda' />
								<Radio
									id='Accesorio'
									name='Tipo'
									value='1'
									onChange={onTypeChange}
									label='Accesorios'
								/>
								<Radio
									id='Ambos'
									name='Tipo'
									value='2'
									onChange={onTypeChange}
									label='Ambos'
									defaultChecked
								/>
							</fieldset>
						</div>
						<div className='flex-1 mt-4 absolute bottom-2 left-2 right-2'>
							<Button
								type='submit'
								className='btn primary w-full'
								isLoading={loading || initialLoad}
							>
								Consultar
							</Button>
						</div>
					</form>
				</AsideModal>
			</div>
		</>
	)
}
