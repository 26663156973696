import { configureStore, combineReducers } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import warehouseSlice from '@/features/warehouse/warehouse.slice'
import authSlice from '@/features/auth/auth.slice'
import notificationSlice from '@/features/notifications/notifications.slice'
import systemMessageSlice from '@/features/systemMessage/systemMessage.slice'
import stockAlmacenSlice from '@/features/reports/inventario/stockAlmacen/stockAlmacen.slice'
import { shippingReceiptSlice, packingReceiptSlice } from '@/features/goodsReceipt/slices'
import { SAPShippingListSlice, packingListDocumentsSlice } from '@/features/operationStatus/slices'
import topVentasSlice from '@/features/reports/ventas/topVentas/topVentas.slice'
import { serviceLayerBaseApi } from '../api'
import partidasAbiertasSlice from '@/features/reports/informes/partidasAbiertas/partidasAbiertas.slice'
import { surtidoTiendasSlice } from '@/features/inventoryAssortment/slices'
import traspasosTiendasSlice from '@/features/inventoryAssortment/slices/TraspasosTiendas.slice'

const InventarioReportReducer = combineReducers({
	[stockAlmacenSlice.name]: stockAlmacenSlice.reducer
})

const VentasReportReducer = combineReducers({
	[topVentasSlice.name]: topVentasSlice.reducer
})

const InformesReportReducer = combineReducers({
	[partidasAbiertasSlice.name]: partidasAbiertasSlice.reducer
})

const reportReducer = combineReducers({
	inventario: InventarioReportReducer,
	ventas: VentasReportReducer,
	informes: InformesReportReducer
})

const repoInventoryReducer = combineReducers({
	[surtidoTiendasSlice.name]: surtidoTiendasSlice.reducer,
	[traspasosTiendasSlice.name]: traspasosTiendasSlice.reducer
})

const rootReducer = combineReducers({
	[warehouseSlice.name]: warehouseSlice.reducer,
	[authSlice.name]: authSlice.reducer,
	[notificationSlice.name]: notificationSlice.reducer,
	[systemMessageSlice.name]: systemMessageSlice.reducer,
	reports: reportReducer,
	[shippingReceiptSlice.name]: shippingReceiptSlice.reducer,
	[SAPShippingListSlice.name]: SAPShippingListSlice.reducer,
	[packingReceiptSlice.name]: packingReceiptSlice.reducer,
	[packingListDocumentsSlice.name]: packingListDocumentsSlice.reducer,
	[serviceLayerBaseApi.reducerPath]: serviceLayerBaseApi.reducer,
	repoInventory: repoInventoryReducer
})

export const createStore = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => {
		if (process.env.NODE_ENV === 'development') {
			return getDefaultMiddleware({ serializableCheck: false })
				.concat(logger)
				.concat(serviceLayerBaseApi.middleware)
		} else {
			return getDefaultMiddleware().concat(serviceLayerBaseApi.middleware)
		}
	}
})

export type RootState = ReturnType<typeof createStore.getState>
export type AppDispatch = typeof createStore.dispatch
export default createStore
