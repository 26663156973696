import { DefaultTemplate } from '@/shared/templates'
import { Title, DashLink } from '@/shared/components/commons'
import { sapIcon, containerIcon, unpackingIcon } from '@/assets'

const SAPDocsPage = () => {
	return (
		<DefaultTemplate>
			<Title
				backPage='Dashboard'
				backTo='/Dashboard'
				title='Generación de documentos SAP'
				pageName='Generación de documentos'
				icon={sapIcon}
			/>

			<section className='mt-8 grid place-items-center w-s'>
				<ul className='space-y-4'>
					<DashLink
						title='LISTA DE PACKING'
						description='Creacion de documentos'
						icon={unpackingIcon}
						primaryColor='bg-[#4493DC]'
						secondaryColor='bg-[#4493DC]'
						to='/documentos/packing'
					/>

					<DashLink
						title='LISTA DE EMBARQUE'
						description='Creacion de traslados'
						icon={containerIcon}
						primaryColor='bg-blue-gray'
						secondaryColor='bg-blue-gray'
						to='/documentos/embarques'
					/>
				</ul>
			</section>
		</DefaultTemplate>
	)
}

export default SAPDocsPage
