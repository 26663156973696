import { ChangeEvent } from 'react'
import { noImage } from '@/assets'

export const useOnErrorImage = (image: string = noImage) => {
  const replaceImage = (evt: ChangeEvent<HTMLImageElement>) => {
    evt.target.onerror = null
    evt.target.src = image
  }

  return [replaceImage]
}

export default useOnErrorImage
