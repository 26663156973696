import { createContext } from 'react'
import { AccordionItemProps, AccordionItemContext } from './interface'
import { useAccordionItem } from './useAccordionItem'

export const accordionItemContext = createContext({} as AccordionItemContext)
const { Provider } = accordionItemContext

export const AccordionItem = ({ children, itemVisible = true }: AccordionItemProps) => {
	const { visible, handleVisible } = useAccordionItem()
	return (
		<>
			{itemVisible && (
				<Provider value={{ visible, handleVisible }}>
					<div className=' cursor-pointer'>{children}</div>
				</Provider>
			)}
		</>
	)
}
