import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'
import {
	IDataReposicion,
	IGrupoPrenda,
	IGrupoFamilia,
	IGrupoModelReferencia
} from '../types/Reposicion.types'
import { useState } from 'react'
import { useNotification } from '@/shared/hooks'
import { getError } from '@/shared/helpers'

const usePDFReposicion = () => {
	const notification = useNotification()
	const useAxios = makeUseAxios({
		axios: axiosInstance(process.env.REACT_APP_API_WMS ?? (env.REACT_APP_API_WMS as string))
	})

	const [data, setData] = useState<IDataReposicion[] | undefined>(undefined)

	const [{ loading }, executeDataReposicion] = useAxios<IDataReposicion[]>(
		{ url: '/asistente-reposicion/pdf', method: 'post' },
		{ manual: true }
	)

	const [GrupoTipoArticulo, setGrupoTipoArticulo] = useState<string[]>([])
	const [GrupoFamilia, setGrupoFamilia] = useState<IGrupoFamilia[]>([])
	const [GrupoPrenda, setGrupoPrenda] = useState<IGrupoPrenda[]>([])
	const [GrupoModelReferencia, setGrupoModelReferencia] = useState<IGrupoModelReferencia[]>([])

	const getDataReposicion = async (DocNum: string, Version: string) => {
		await executeDataReposicion({
			data: {
				empresa: process.env.REACT_APP_SAP_COMPANY_TEST ?? env.REACT_APP_SAP_COMPANY_TEST,
				docNum: Number(DocNum)
			}
		})
			.then(({ data }) => {
				for (let i = 0; i < data.length; i++) {
					if (Version === 'A' || (Version === 'B' && data[i].TipoArticulo === 'PRENDA')) {
						data[i].Familia = ''
						data[i].FamiliaDesc = ''
					}
				}
				setData(data)

				let _tipoArticulo = data.map((item) => item.TipoArticulo)
				_tipoArticulo = _tipoArticulo.filter((item, index) => {
					return _tipoArticulo.indexOf(item) === index
				})
				//console.log(_tipoArticulo)
				setGrupoTipoArticulo(_tipoArticulo)

				const _grupoFamilias: IGrupoFamilia[] = []
				const _familias = data.map((item) => ({
					TipoArticulo: item.TipoArticulo,
					Code: item.Familia,
					Desc: item.FamiliaDesc
				}))
				_familias.forEach((a) => {
					if (
						_grupoFamilias.filter((b) => b.TipoArticulo === a.TipoArticulo && b.Code === a.Code)
							.length === 0
					) {
						_grupoFamilias.push(a)
					}
				})
				//console.log(_grupoFamilias)
				setGrupoFamilia(_grupoFamilias)

				const _grupoPrendas: IGrupoPrenda[] = []
				const _prendas = data.map((item) => ({
					TipoArticulo: item.TipoArticulo,
					FamiliaCode: item.Familia,
					Code: item.UGSPCode,
					Desc: item.UGSPName
				}))
				_prendas.forEach((a) => {
					if (
						_grupoPrendas.filter(
							(b) =>
								b.TipoArticulo === a.TipoArticulo &&
								b.FamiliaCode === a.FamiliaCode &&
								b.Code === a.Code
						).length === 0
					) {
						_grupoPrendas.push(a)
					}
				})
				//console.log(_grupoPrendas)
				setGrupoPrenda(_grupoPrendas)

				const _grupoReferencias: IGrupoModelReferencia[] = []
				const _referencias = data.map((item) => ({
					TipoArticulo: item.TipoArticulo,
					FamiliaCode: item.Familia,
					PrendaCode: item.UGSPCode,
					Referencia: item.UGSPReference
				}))
				_referencias.forEach((a) => {
					if (
						_grupoReferencias.filter(
							(b) =>
								b.TipoArticulo === a.TipoArticulo &&
								b.FamiliaCode === a.FamiliaCode &&
								b.PrendaCode === a.PrendaCode &&
								b.Referencia === a.Referencia
						).length === 0
					) {
						_grupoReferencias.push(a)
					}
				})
				//console.log(_grupoReferencias)
				setGrupoModelReferencia(_grupoReferencias)
			})
			.catch((error) => {
				console.log('No se obtuvo información del documento')
				notification.error('No se obtuvo información del documento. Error:' + getError(error))
				setGrupoTipoArticulo([])
				setGrupoFamilia([])
				setGrupoPrenda([])
				setGrupoModelReferencia([])
				setData(undefined)
			})
	}

	return {
		loading,
		data,
		getDataReposicion,
		GrupoTipoArticulo,
		GrupoFamilia,
		GrupoPrenda,
		GrupoModelReferencia
	}
}
export default usePDFReposicion
