import { useContext } from 'react'
import { AccordionHeaderProps } from './interface'
import { accordionItemContext } from './AccordionItem'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

export const AccordionHeader = ({ title, icon, mode = 'light' }: AccordionHeaderProps) => {
	const { visible, handleVisible } = useContext(accordionItemContext)
	return (
		<div
			className={`h-12 w-full border-t ${mode === 'light' ? 'bg-gray-100' : 'bg-gray-600'}`}
			onClick={handleVisible}
		>
			<div className='w-hull h-full flex items-center justify-between p-2'>
				<section className='flex items-center'>
					{icon && (
						<figure className='w-8 h-8'>
							<img src={icon} alt={title} className='w-full h-full object-cover object-center' />
						</figure>
					)}
					<h2 className={`ml-3 font-semibold ${mode === 'light' ? 'text-black' : 'text-white'}`}>
						{title}
					</h2>
				</section>

				<ChevronDownIcon
					width={24}
					className={`transition ease-in-out duration-300 ${visible && 'rotate-180'} ${
						mode === 'light' ? 'text-black' : 'text-white'
					}`}
				/>
			</div>
		</div>
	)
}
