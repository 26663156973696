import { DataGrid } from 'devextreme-react'
import { ContentReadyEvent } from 'devextreme/ui/data_grid'
import TextBox from 'devextreme/ui/text_box'
import { useRef } from 'react'

const useDevexpressActions = () => {
  const dataGridRef = useRef<DataGrid>(null)

  /**
   * Esta funcion se usa cuando se quiere poner el focus del SearchPanel cuando el contenido este listo
   */
  const focusSearchPanelOnContentReady = (e: ContentReadyEvent) => {
    const searchPanelElement = e.element.querySelector('.dx-datagrid-search-panel')
    if (searchPanelElement) {
      const enbedElement = TextBox.getInstance(searchPanelElement)
      setTimeout(() => {
        // @ts-ignore
        enbedElement.focus()
      })
    }
  }

  const filterDataGrid = (column: string, operation: string, value: string | number | boolean) => {
    if (dataGridRef.current) {
      const dataGrid = dataGridRef.current.instance
      dataGrid.filter([column, operation, value])
    }
  }

  const reloadGrid = () => {
    if (dataGridRef.current) {
      const dataGrid = dataGridRef.current.instance
      dataGrid.refresh()
    }
  }

  const clearfilter = () => {
    if (dataGridRef.current) {
      const dataGrid = dataGridRef.current.instance
      dataGrid.clearFilter()
    }
  }

  const autoClearAndFocus = (column: string) => {
    if (dataGridRef.current) {
      //clearfilter();
      const element = document.querySelector(`input[name="${column}"]`)
      if (element) {
        const ancestor = element.closest('div')
        const input = ancestor?.querySelectorAll('input')[1]
        if (input?.value) {
          input.value = ''
        }
        // @ts-ignore
        input.focus()
      }
    }
  }

  return {
    focusSearchPanelOnContentReady,
    filterDataGrid,
    clearfilter,
    dataGridRef,
    autoClearAndFocus,
    reloadGrid
  }
}

export default useDevexpressActions
