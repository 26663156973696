import { useState, forwardRef, useImperativeHandle, useRef, KeyboardEvent } from 'react'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { searchTextTypes, searchTextRef } from './types'

// Eslint no reconoce el render prop pattern
// eslint-disable-next-line react/display-name
const SearchText = forwardRef<searchTextRef, searchTextTypes>((props, ref) => {
	const { onChange, id, name, placeholder, toUpperCase, onPressEnter, value } = props
	const [showClearText, setShowClearText] = useState<boolean>(false)
	const [textValue, setTextValue] = useState(value || '')
	const inputRef = useRef<HTMLInputElement>(null)

	useImperativeHandle(ref, () => ({
		focus() {
			inputRef.current?.focus()
		},
		clear() {
			handleClear()
		}
	}))

	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const textValue = toUpperCase ? value.toUpperCase() : value
		setTextValue(textValue)
		handleShowClearText(value)
		if (onChange) {
			await onChange(textValue)
		}
	}

	const handleShowClearText = (value: string) => {
		if (value.length > 0) {
			setShowClearText(true)
		} else {
			setShowClearText(false)
		}
	}

	const handleClear = () => {
		if (inputRef.current) {
			inputRef.current!.value = ''
			handleShowClearText('')
			setTextValue('')
		}
	}

	const onKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
		const { code } = evt
		if (code === 'Enter' && onPressEnter) {
			onPressEnter(textValue)
		}
	}

	return (
		<>
			<div className='field-input flex items-center space-x-2'>
				<label htmlFor='search-text'>
					<MagnifyingGlassIcon width={22} />
				</label>
				<input
					id={id}
					name={name}
					placeholder={placeholder}
					type='text'
					className={`outline-none w-full h-full ${toUpperCase ? 'uppercase' : null}`}
					onChange={handleChange}
					ref={inputRef}
					onKeyDown={onKeyDown}
				/>
				{showClearText ? (
					<button onClick={handleClear}>
						<XMarkIcon width={24} />
					</button>
				) : null}
			</div>
		</>
	)
})

export default SearchText
