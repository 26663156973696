import { DrawerTypes } from './types'
import { Overlay } from '../commons'
import Portal from '../portal'
import { XMarkIcon } from '@heroicons/react/24/solid'

const Drawer = ({
	wrapperId,
	isOpen,
	handleClose,
	children,
	title,
	position = 'right',
	size = 'small'
}: DrawerTypes) => {
	return (
		<Portal wrapperId={wrapperId}>
			<aside className={`portal__drawer ${position} ${!isOpen ? 'hide' : ''} ${size}`}>
				<section className='portal__drawer__head'>
					<h4>{title}</h4>
					<button onClick={handleClose} className='flex items-center'>
						<XMarkIcon width={28} />
					</button>
				</section>
				<section className='portal__drawer__body'>{children}</section>
			</aside>
			{isOpen ? <Overlay /> : null}
		</Portal>
	)
}

export default Drawer
