import { spotlightHocTypes } from './interfaces'

import { Spotlight as SpotlightHoc } from './Spotlight'
import SpotlightBody from './SpotlightBody'
import SpotlightHeader from './SpotlightHeader'

export { default as SpotlightBody } from './SpotlightBody'
export { default as SpotlightHeader } from './SpotlightHeader'

export const Spotlight: spotlightHocTypes = Object.assign(SpotlightHoc, {
  Header: SpotlightHeader,
  Body: SpotlightBody
})

export default Spotlight
