const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
const meses = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre'
]

export const formatStrToDateComplete = (fecha: string) => {
	console.log(fecha)
	const i = fecha.split('-')
	const f = new Date(Number(i[0]), Number(i[1]) - 1, Number(i[2]))
	return (
		diasSemana[f.getDay()] +
		', ' +
		f.getDate() +
		' de ' +
		meses[f.getMonth()] +
		' de ' +
		f.getFullYear()
	)
}

export default formatStrToDateComplete
