import DataGrid, { Column, FilterRow, Selection, MasterDetail } from 'devextreme-react/data-grid'

import {
	MasterDetailTemplateData,
	DataRowTemplateData,
	SelectionChangedEvent
} from 'devextreme/ui/data_grid'

import {
	ShippingListDoc,
	ShippingListDocLine,
	HasOriginLine,
	ShipmentsDocs,
	PrimaryDocument,
	PrimaryDocumentLine,
	SecondaryDocument,
	SecondaryDocumentLine,
	RenderArticleStatus
} from '../types/shippingList.types'
import { EyeIcon } from '@heroicons/react/24/outline'

import { useOnErrorImage } from '@/shared/hooks'
import { useDispatch } from 'react-redux'
import { setDocsToGenerate, setDocumentsQuantity } from '../slices/SAPShippingList.slices'

interface Props {
	data?: ShippingListDoc[] | null
	bulkHasIncident: (idbulk: number) => boolean
	getbulkIncidents: (idBulk: number) => void
}

const ShippingListTable = ({ data, bulkHasIncident, getbulkIncidents }: Props) => {
	const [replaceImg] = useOnErrorImage()
	const dispatch = useDispatch()

	const onSelect = ({ selectedRowsData }: SelectionChangedEvent<ShippingListDoc, any>) => {
		//Se guarda la cantidad total de documentos para los embarques
		let docsQuantity = 0

		const shipments: ShipmentsDocs[] = selectedRowsData.map((row) => {
			//Obtiene el numero de embarque y el cliente del la liena seleccionada
			const Shipment = row.Embarque
			const CardCode = row.NombreCliente

			const documentsWithLines = [
				...new Set(
					row.Data.filter((line) => line.ConLinea === HasOriginLine.Y).flatMap(
						(flat) => flat.DocBase
					)
				)
			]
			const documentsWithoutLines = [
				...new Set(
					row.Data.filter((line) => line.ConLinea === HasOriginLine.N).flatMap(
						(flat) => flat.DocBase
					)
				)
			]
			docsQuantity += documentsWithLines.length + documentsWithoutLines.length

			const primaryLinesDocs: PrimaryDocument[] = documentsWithLines.map((docNum) => {
				const getAllItemsWithDocNum = [
					...new Set(
						row.Data.filter(
							(line) => line.DocBase === docNum && line.ConLinea === HasOriginLine.Y
						).flatMap((flat) => flat.Articulo)
					)
				]
				const firstElement = row.Data.find(
					(line) => line.DocBase === docNum && line.ConLinea === HasOriginLine.Y
				)
				const Lines: PrimaryDocumentLine[] = getAllItemsWithDocNum.map((item) => {
					const getAllItemsWithSameCode = row.Data.filter(
						(line) =>
							line.Articulo === item && line.ConLinea === HasOriginLine.Y && line.DocBase === docNum
					)
					const reducedItems = getAllItemsWithSameCode.reduce((acc, curr) => ({
						...acc,
						CantidadRecibidaBulto: (acc.CantidadRecibidaBulto || 0) + curr.CantidadRecibidaBulto
					}))
					return {
						ItemCode: reducedItems.Articulo,
						WarehouseCode: reducedItems.AlmacenDestino,
						FromWarehouse: reducedItems.AlmacenOrigen,
						Quantity: reducedItems.CantidadRecibidaBulto,
						BaseEntry: reducedItems.SolTrasldoDocEntry,
						BaseLine: reducedItems.LineNum,
						BaseType: 'InventoryTransferRequest',
						U_U_BYS_BaseCode: reducedItems.IdentificadorArticulos,
						Stock: reducedItems.Stock,
						RequiredQuantity: reducedItems.CantidadRequerida
					}
				})
				return {
					Document: firstElement?.Documento || 0,
					DocNum: docNum,
					CardCode: firstElement?.Cliente || '',
					Comments: firstElement?.Comments || '',
					JournalMemo: firstElement?.JrnlMemo || '',
					FromWarehouse: firstElement?.AlmacenOrigen || '',
					ToWarehouse: firstElement?.AlmacenDestino || '',
					PiceList: firstElement?.GroupNum || 5,
					U_GSP_CodeDocBaseStr: firstElement?.BaseCode || '',
					Lines
				}
			})

			const secondaryLinesDocs: SecondaryDocument[] = documentsWithoutLines.map((docNum) => {
				const getAllItemsWithDocNum = [
					...new Set(
						row.Data.filter(
							(line) => line.DocBase === docNum && line.ConLinea === HasOriginLine.N
						).flatMap((flat) => flat.Articulo)
					)
				]
				const firstElement = row.Data.find(
					(line) => line.DocBase === docNum && line.ConLinea === HasOriginLine.N
				)
				const Lines: SecondaryDocumentLine[] = getAllItemsWithDocNum.map((item) => {
					const getAllItemsWithSameCode = row.Data.filter(
						(line) =>
							line.Articulo === item && line.ConLinea === HasOriginLine.N && line.DocBase === docNum
					)
					const reducedItems = getAllItemsWithSameCode.reduce((acc, curr) => ({
						...acc,
						CantidadRecibidaBulto: (acc.CantidadRecibidaBulto || 0) + curr.CantidadRecibidaBulto
					}))
					return {
						ItemCode: reducedItems.Articulo,
						WarehouseCode: reducedItems.AlmacenDestino,
						FromWarehouse: reducedItems.AlmacenOrigen,
						Quantity: reducedItems.CantidadRecibidaBulto,
						U_U_BYS_BaseCode: reducedItems.IdentificadorArticulos,
						Stock: reducedItems.Stock,
						RequiredQuantity: reducedItems.CantidadRequerida
					}
				})

				return {
					Document: firstElement?.Documento || 0,
					DocNum: docNum,
					CardCode: firstElement?.Cliente || '',
					Comments: firstElement?.Comments || '',
					JournalMemo: firstElement?.JrnlMemo || '',
					FromWarehouse: firstElement?.AlmacenOrigen || '',
					ToWarehouse: firstElement?.AlmacenDestino || '',
					PiceList: firstElement?.GroupNum || 5,
					U_GSP_CodeDocBaseStr: firstElement?.BaseCode || '',
					Lines
				}
			})

			return {
				Shipment,
				CardCode,
				PrimaryDocuments: primaryLinesDocs,
				SecondaryDocuments: secondaryLinesDocs
			}
		})

		dispatch(setDocsToGenerate(shipments))
		dispatch(setDocumentsQuantity(docsQuantity))
	}

	const RenderArticleStatus = ({
		productStock,
		amountProcessed,
		outstandingAmount
	}: RenderArticleStatus) => {
		if (amountProcessed > 0 && outstandingAmount === 0) {
			return (
				<h5 className='bg-blue-500 px-3 py-1 rounded-md w-24 font-bold text-white mb-1 self-end'>
					Procesado
				</h5>
			)
		} else if (productStock > 0 && productStock >= outstandingAmount) {
			return (
				<h5 className='bg-green-500 px-3 py-1 rounded-md w-24 font-bold text-white mb-1 self-end'>
					Disponible
				</h5>
			)
		} else {
			return (
				<h5 className='bg-gray-500 px-3 py-1 rounded-md w-30 font-bold text-white mb-1 self-end'>
					No Disponible
				</h5>
			)
		}
	}

	const RowRender = ({ data }: DataRowTemplateData<ShippingListDocLine>) => {
		return (
			<>
				<tr>
					<td colSpan={4}>
						<div className='w-full flex  flex-col'>
							<RenderArticleStatus
								productStock={data.Stock}
								amountProcessed={data.CantidadProcesada}
								outstandingAmount={data.CantidadPendienteBultos}
							/>
							<ul className={'text-gray-500 font-semibold flex flex-col md:flex-row md:space-x-2'}>
								<li>
									Caja: <span className={'font-bold text-gray-700'}>{data.Caja}</span>
								</li>
								<li>
									Bulto: <span className={'font-bold text-gray-700'}>{data.Bulto}</span>
								</li>
								<li>
									Almacen Origen:{' '}
									<span className={'font-bold text-gray-700'}>{data.AlmacenOrigen}</span>
								</li>
								<li>
									Almacen Destino:{' '}
									<span className={'font-bold text-gray-700'}>{data.AlmacenDestino}</span>
								</li>
								<li>
									Doc. Base: <span className={'font-bold text-gray-700'}>{data.DocBase}</span>
								</li>
							</ul>
						</div>
					</td>
				</tr>

				<tr className={'border-b-2'}>
					<td className={''} colSpan={4}>
						<section
							className={
								'flex flex-col  justify-start  xl:flex-row xl:items-stretch xl:justify-between xl:space-x-4'
							}
						>
							<div
								className={'flex flex-1 flex-col md:flex-row md:items-center lg:justify-between'}
							>
								<div className=' w-full md:w-3/4 flex items-center truncate'>
									<img
										src={data.Imagen1}
										alt='Product'
										className='w-32 h-32 object-contain object-center'
										onError={replaceImg}
									/>
									<ul className={'text-gray-500 font-semibold space-y-1'}>
										<li>
											ItemCode: <span className={'font-bold text-gray-700'}>{data.Articulo}</span>{' '}
										</li>
										<li>
											Articulo:{' '}
											<span className={'font-bold text-gray-700'}>{data.Descripcion}</span>{' '}
										</li>
										<li>
											Precio público:{' '}
											<span className={'font-semibold text-gray-700'}>${data?.PrecioPublico}</span>{' '}
										</li>
										<li
											className={` text-white font-bold w-36 py-1 px-2 rounded-md ${
												data.Calidad === 'PRIMERA' ? 'bg-green-500' : 'bg-red-500'
											}`}
										>
											Calidad: <span className={'font-bold text-gray-700'}>{data.Calidad}</span>
										</li>
									</ul>
								</div>

								<div className='flex justify-start w-full mt-4 md:mt-0 md:w-1/4 md:justify-end lg:justify-center'>
									<ul className=''>
										<li className={' font-semibold text-gray-400 uppercase'}>
											Recibido:{' '}
											<span className={'text-sky-800 font-bold'}>{data.CantidadRecibidaBulto}</span>
										</li>
										<li className={' font-semibold text-gray-400 uppercase'}>
											Enviado:{' '}
											<span className={'text-sky-800 font-bold'}>{data.CantidadEmpacadaBulto}</span>
										</li>
										<li className={' font-semibold text-gray-400 uppercase'}>
											Cant. Requerida:{' '}
											<span className={'text-sky-800 font-bold'}>{data.CantidadRequerida}</span>
										</li>
										<li className={' font-semibold text-gray-400 uppercase'}>
											Cant.Procesada:{' '}
											<span className={'text-sky-800 font-bold'}>{data.CantidadProcesada}</span>
										</li>
										<li className={' font-semibold text-gray-400 uppercase'}>
											Cant.Pendiente:{' '}
											<span className={'text-sky-800 font-bold'}>
												{data.CantidadPendienteBultos}
											</span>
										</li>
										<li className={' font-semibold text-gray-400 uppercase'}>
											Stock: <span className={'text-sky-800 font-bold'}>{data.Stock}</span>
										</li>
									</ul>
								</div>
							</div>
							<div className='flex items-center space-x-2 mt-4 xl:mt-0'>
								<button
									className='btn flex-button danger'
									onClick={() => getbulkIncidents(data.Bulto)}
									disabled={bulkHasIncident(data.Bulto) ? false : true}
								>
									<EyeIcon width={32} className='mr-2' />
									Ver incidencia
								</button>
							</div>
						</section>
					</td>
				</tr>
			</>
		)
	}

	const Detail = (props: MasterDetailTemplateData<ShippingListDoc>) => {
		return (
			<DataGrid
				dataSource={props.data.Data}
				showBorders={true}
				hoverStateEnabled={true}
				columnAutoWidth={false}
				dataRowRender={RowRender}
			>
				<FilterRow visible={true} />
				<Column dataField={'Caja'} caption={'Caja'} dataType={'number'} width={180} />
				<Column dataField={'Bulto'} caption={'Bulto'} dataType={'number'} width={180} />
				<Column dataField={'DocBase'} caption={'Documento'} dataType={'string'} width={180} />
				<Column dataField={'Articulo'} caption={'Articulo'} dataType={'string'} />
			</DataGrid>
		)
	}

	return (
		<DataGrid
			dataSource={data}
			showBorders={true}
			hoverStateEnabled={true}
			columnAutoWidth={false}
			onSelectionChanged={onSelect}
		>
			<Selection mode='multiple' showCheckBoxesMode={'onClick'} />
			<FilterRow visible={true} />
			<Column dataField={'Embarque'} caption={'No.Embarque'} width={100} type={'number'} />
			<Column dataField={'CantidadCajas'} caption={'No.Cajas'} width={80} type={'number'} />
			<Column dataField={'Estatus'} caption={'Estatus'} width={100} type={'string'} />
			<Column dataField={'TotalPiezas'} caption={'Total de piezas'} type={'number'} />
			<MasterDetail enabled={true} render={Detail} />
		</DataGrid>
	)
}

export default ShippingListTable
