import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { ControlSelect, ControlText, ControlFile } from '@/shared/components/fields'
import { Button } from '@/shared/components/commons'
import { IncidentValues, IncidentFormProps } from './types'

const IncidentForm = ({
	receivedAmount,
	hideForm,
	onSubmit,
	isPostIncident
}: IncidentFormProps) => {
	const initialValues: IncidentValues = {
		type: 1,
		packedQuantity: receivedAmount ? receivedAmount : 0,
		receivedAmount: receivedAmount ? receivedAmount : 0,
		reason: '',
		images: null
	}

	const validationSchema: Yup.SchemaOf<IncidentValues> = Yup.object()
		.shape({
			type: Yup.string().required('Por favor seleccione un tipo de incidencia'),
			packedQuantity: Yup.number().required('La cantidad es requerida'),
			receivedAmount: Yup.number()
				.required('La cantidad confirmada es requerida')
				.min(0, 'La cantidad confirmada no puede ser negativa'),
			reason: Yup.string().required('El campo razon es requerido'),
			images: Yup.mixed().nullable()
		})
		.required()

	const handleSubmit = async (
		{ type, packedQuantity, receivedAmount, reason, images }: IncidentValues,
		{ resetForm }: FormikHelpers<IncidentValues>
	) => {
		await onSubmit({ type, packedQuantity, receivedAmount, reason, images })
		resetForm()
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			enableReinitialize
		>
			<Form>
				<fieldset className='space-y-2'>
					<ControlSelect
						id='type'
						name='type'
						label='Tipo de incidencia'
						defaultText='Seleccione una incidencia'
						optList={[
							{ value: '1', text: 'Mercancia dañada' },
							{ value: '2', text: 'Mercancia abierta' },
							{ value: '3', text: 'Faltante de Mercancia' },
							{ value: '4', text: 'Sobrante de Mercancia' },
							{ value: '5', text: 'Error en informacion de articulo' }
						]}
					/>
					<ControlText
						type='number'
						id='packedQuantity'
						name='packedQuantity'
						label='Cantidad'
						disabled
					/>
					<ControlText type='number' id='receivedAmount' name='receivedAmount' label='Confirmada' />
					<ControlText
						type='textarea'
						id='reason'
						name='reason'
						label='Comentarios'
						rows={14}
						cols={14}
					/>
					<ControlFile id='images' name='images' label='Imagen' accept='image' useCompress />
				</fieldset>
				<div className='flex items-center space-x-2 mt-4'>
					<Button type='reset' className='btn secondary w-full' onClick={hideForm}>
						Cancelar
					</Button>
					<Button type='submit' className='btn primary w-full' isLoading={isPostIncident}>
						Guardar
					</Button>
				</div>
			</Form>
		</Formik>
	)
}

export default IncidentForm
