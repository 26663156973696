import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/app/store/createStore'
import { EllipsisLoader } from '@/shared/components/loaders'

/**
 * Este outlet se encarga de la  autentificacion de los usuarios
 * este outlet verifica que el usuario tenga sus claves para ingresar
 * a la pagina
 *
 * NOTA:
 * Este outlet no protege las ruta con autorizacion para eso esta el outlet de
 * autorizacion
 *
 * NOTA:
 * Este outlet puede retornar 4 componentes
 * 1: Retorna un spinner si se esta consultado los datos iniciales del usuario
 * 2: Si el usuario no esta autentificado y la pagina solicidada es diferente a la principal
 * redirige el usuario al login
 * 3: Si el usuario esta autentificado y la pagina solicitada es la pagina principal redirige al
 * usuario al dashboard
 */

const ProtectionOutlet = () => {
	const { isAuth, isLoading } = useSelector((state: RootState) => state.auth)
	const { pathname } = useLocation()

	if (isLoading) {
		return (
			<div className='w-screen h-screen grid place-items-center'>
				<EllipsisLoader />
			</div>
		)
	}

	if (!isAuth && pathname !== '/') {
		return <Navigate to='/' replace />
	}

	if (isAuth && pathname === '/') {
		return <Navigate to='/dashboard' replace />
	}

	return <Outlet />
}

export default ProtectionOutlet
