import { ErrorMessage, useField } from 'formik'
import { ICheckboxControl } from '@/shared/components/fields/types'

export const ControlCheckbox = ({ label, ...props }: ICheckboxControl) => {
	const [field, meta] = useField(props)

	return (
		<label className='block'>
			<label htmlFor={props.id || props.name} className='block'>
				<input
					type='checkbox'
					{...field}
					{...props}
					className={`mr-1 ${meta.error && meta.touched ? 'error' : ''}`}
				/>
				{label}
			</label>
			<ErrorMessage
				name={props.name}
				component='span'
				className='text-red-500 font-semibold text-right text-sm'
			/>
		</label>
	)
}
