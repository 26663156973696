import { useRef } from 'react'
import DataGrid, {
	Column,
	FilterRow,
	Summary,
	TotalItem,
	Button as TableButton,
	Toolbar,
	Item
} from 'devextreme-react/data-grid'
import { Popup, Position } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import { useSelector, useDispatch } from 'react-redux'
import { ColumnCellTemplateData, ColumnButtonClickEvent } from 'devextreme/ui/data_grid'
import { TextBox } from 'devextreme-react/text-box'
import { CheckBox } from 'devextreme-react/check-box'
import Button from 'devextreme-react/button'
import { useOnErrorImage, useToggle } from '@/shared/hooks'
import { HandRaisedIcon, HandThumbUpIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

import type { IPackingListDetail, IPackingListTable } from '../types/packingList.types'
import { clearFilterIcon } from '@/assets'
import { RootState } from '@/app/store/createStore'
import { setCheckLocation } from '@/features/auth/auth.slice'
import { changeOmitProv } from '@/features/goodsReceipt/slices/packingReceiptSlice'

const PackingListTable = ({
	data,
	onReportLine,
	onConfirmLine,
	dataGridRef,
	inputRef,
	onValueChange,
	onCheckOmitPrimary,
	isOmitCheckedPrimary,
	isOmitCheckedSecondary,
	onCheckOmitSecondary,
	onPressClearFilter,
	packingID,
	incidents
}: IPackingListTable) => {
	const {
		auth: { user },
		packingSlice: { includProv }
	} = useSelector((state: RootState) => state)
	const [isKeyboardDisabled, setIsKeyboardDisabled] = useToggle(true)
	const [isPopupVisible, setIsPopupVisible] = useToggle()
	const dispatch = useDispatch()
	const isScannerUsed = useRef<boolean>(false)

	const isLineConfirmed = (line: IPackingListDetail) => {
		if (line.Recibe === 'Y') {
			return line.Confirmado1 === 'C'
		} else {
			return line.Confirmado1 === 'C' && line.Confirmado2 === 'C'
		}
	}

	const hasIncidents = (line?: IPackingListDetail) => {
		if (incidents && line) {
			const attachedIncidents = incidents.filter((item) => item.Dispersion === line.IdDispersion)
			if (attachedIncidents.length > 0) {
				return (
					<>
						<p className='flex items-center font-bold'>
							incidencias:
							<span className='ml-1 w-5 h-5 block flex items-center justify-center bg-amber-300 rounded-full'>
								{attachedIncidents.length}
							</span>
						</p>
						{attachedIncidents[0].UnidadesAclarar ? (
							<p className='flex items-center font-bold'>
								Por aclarar:
								<span className='ml-1 block text-amber-600'>
									{attachedIncidents[0].UnidadesAclarar}
								</span>
							</p>
						) : null}
					</>
				)
			}
		}
		return null
	}

	const CellItem = ({ data }: ColumnCellTemplateData<IPackingListDetail>) => {
		const [replaceImg] = useOnErrorImage()
		return (
			<div className={'flex truncate'}>
				<img
					src={data?.Imagen}
					className='w-28 h-28 object-contain object-center my-auto mr-2'
					onError={replaceImg}
					alt={data?.Descripcion}
				/>
				<div>
					{data?.DocEntryEntradaMercancia && data.DocEntryEntradaMercancia > 0 ? (
						<p
							className={`
                                w-fit px-2 py-1 font-bold
                                ${
																	data.DocumentosGenerados === 1
																		? 'bg-green-600'
																		: data.DocumentosGenerados === 2
																		? 'bg-red-600'
																		: 'bg-yellow-600'
																}
                                rounded-md mb-1
                                `}
						>
							Documento Generado{data?.DocEntryEntradaMercancia}
						</p>
					) : null}
					<p className='w-fit px-2 py-1 font-bold bg-blue-300 rounded-md mb-1'>{data?.ItemCode}</p>
					<p className='font-semibold text-gray-600 mb-2 truncate'>EAN: {data?.EAN}</p>
					<p className='font-semibold text-gray-600 mb-2 truncate'>{data?.Descripcion}</p>
					<p className='font-bold text-gray-600 mb-1'>
						Piezas: <span className='text-blue-600'>{data?.Piezas ?? 0}</span>
					</p>
					<p className='font-bold text-gray-600'>
						Recibidas: <span className='text-blue-600'>{data?.UnidadesRecibidas ?? 0}</span>
					</p>
					<div className='mt-2'>
						{data?.Confirmado1 === 'C' ? (
							<span className='flex items-center font-bold'>
								{data?.Recibe === 'Y' ? 'Confirmado' : 'Primera confirmacion'}
								<CheckCircleIcon className='w-5 h-5 text-green-500 ml-1' />
							</span>
						) : null}
						{data?.Confirmado2 === 'C' ? (
							<span className='flex items-center font-bold'>
								Segunda confirmacion
								<CheckCircleIcon className='w-5 h-5 text-green-500 ml-1' />
							</span>
						) : null}
					</div>
					{hasIncidents(data)}
				</div>
			</div>
		)
	}

	return (
		<section className='packing-table-container'>
			<DataGrid
				dataSource={data}
				showBorders={true}
				hoverStateEnabled={false}
				columnAutoWidth={true}
				showRowLines={true}
				showColumnLines={false}
				rowAlternationEnabled={false}
				ref={dataGridRef}
				onContentReady={(e) => {
					if (isScannerUsed.current) {
						const rows = e.component.getVisibleRows()
						const dataRows = rows.filter((item) => item.rowType === 'data')
						if (dataRows.length === 0 && user?.confirmGlobalPackingList === 'CL') {
							dataRows.forEach((row) => {
								onConfirmLine(row.data)
							})
						}
						isScannerUsed.current = false
					}
				}}
			>
				<FilterRow visible={true} />
				<Column dataField={'EAN'} visible={false} />
				<Column
					dataField={'ItemCode'}
					caption={'Articulo'}
					dataType={'text'}
					cellRender={CellItem}
				/>
				<Column dataField={'Agrupador'} caption={'Agrupador'} dataType={'text'} groupIndex={1} />
				<Column
					dataField={'NombreLocalidad'}
					caption={'Localidad'}
					dataType={'text'}
					groupIndex={2}
				/>
				<Column dataField={'NombreCliente'} caption={'Cliente'} groupIndex={3} />
				<Column caption={'Acciones'} type={'buttons'}>
					<TableButton
						cssClass={
							'relative flex items-center w-32 border border-yellow-600 px-4 py-2 text-yellow-600 rounded-md'
						}
						onClick={({ row }: ColumnButtonClickEvent<IPackingListDetail>) =>
							onReportLine(row?.data)
						}
					>
						<HandRaisedIcon width={18} className='mr-2' />
						Incidencia
					</TableButton>

					<TableButton
						cssClass={
							'relative flex items-center w-32 border border-green-600 px-4 py-2 text-green-600 rounded-md'
						}
						disabled={({ row }: ColumnCellTemplateData<IPackingListDetail>) =>
							isLineConfirmed(row.data)
						}
						onClick={({ row }: ColumnButtonClickEvent<IPackingListDetail>) =>
							onConfirmLine(row?.data, packingID)
						}
					>
						<HandThumbUpIcon width={18} className='mr-2' />
						Confirmar
					</TableButton>
				</Column>
				<Summary>
					<TotalItem column={'Cajas'} summaryType={'sum'} />
					<TotalItem column={'UnidadesXCaja'} summaryType={'sum'} />
				</Summary>
				<Toolbar>
					<Item location='before'>
						<span className='flex flex-col mb-1'>
							<CheckBox
								text='Omitir primera confirmacion'
								className='mb-1'
								onValueChange={onCheckOmitPrimary}
								value={isOmitCheckedPrimary}
							/>
							<CheckBox
								text='Omitir segunda confirmacion'
								className='mb-1'
								onValueChange={onCheckOmitSecondary}
								value={isOmitCheckedSecondary}
							/>
							<CheckBox
								text='No usar teclado'
								value={isKeyboardDisabled}
								onValueChange={setIsKeyboardDisabled}
							/>
						</span>
						<span className='flex items-center'>
							<TextBox
								placeholder='Buscar EAN'
								ref={inputRef}
								inputAttr={{ inputMode: isKeyboardDisabled ? 'none' : 'text' }}
								onValueChange={(e) => {
									onValueChange(e)
									isScannerUsed.current = true
								}}
							/>
							<Button icon={clearFilterIcon} className='ml-1' onClick={onPressClearFilter} />
						</span>
					</Item>
					<Item location='after'>
						<Button icon='columnchooser' onClick={setIsPopupVisible} id='button-location' />
						<Popup
							width={300}
							height={320}
							visible={isPopupVisible}
							onHiding={setIsPopupVisible}
							hideOnOutsideClick={true}
							title='Filtrar agrupador'
						>
							<Position my='right top' at='bottom' of='#button-location' collision='flip' />
							<ScrollView width='100%' height='100%'>
								<div className='content'>
									<div className='grid grid-cols-2 gap-y-1'>
										{user?.Localidades.slice()
											.sort((a, b) => (a.Nombre.toUpperCase() < b.Nombre.toUpperCase() ? -1 : 1))
											.map((item) => (
												<CheckBox
													key={item.Codigo}
													text={item.Nombre}
													value={item.Ver}
													onValueChange={(value: boolean) =>
														dispatch(
															setCheckLocation({
																code: item.Codigo,
																value
															})
														)
													}
												/>
											))}
										<CheckBox
											text={'Clientes de proveduria'}
											value={includProv}
											onValueChange={(value: boolean) => dispatch(changeOmitProv(value))}
										/>
									</div>
								</div>
							</ScrollView>
						</Popup>
					</Item>
				</Toolbar>
			</DataGrid>
		</section>
	)
}

export default PackingListTable
