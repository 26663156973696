import { DefaultTemplate } from '@/shared/templates'
import { NavLink } from 'react-router-dom'
import {
	deliveryguyIcon,
	reportboxIconIcon,
	statuscloseIcon,
	tshirtIcon,
	ConfigurationIcon,
	inventoryFlowIcon
} from '@/assets'
import { osName } from 'react-device-detect'
const DashboardPage = () => {
	return (
		<DefaultTemplate>
			<section className='grid place-items-center h-full w-full'>
				<ul className='grid grid-cols-2 gap-1 '>
					<li>
						<NavLink
							to={'/warehouse'}
							className='block bg-gray-dark w-full h-full p-4 rounded-tl-2xl shadow-md'
						>
							<div className='flex flex-col justify-center items-center w-full h-full'>
								<img src={tshirtIcon} alt='Products' className=' w-16 mb-4' />
								<span className=' text-white font-semibold text-center'>Productos</span>
							</div>
						</NavLink>
					</li>

					<li>
						<NavLink
							to={'/entrada-de-mercancias'}
							className='block bg-gray-dark w-full h-full p-4 rounded-tr-2xl shadow-md'
						>
							<div className='flex flex-col justify-center items-center w-full h-full'>
								<img src={deliveryguyIcon} alt='Products' className=' w-16 mb-4' />
								<span className=' text-white font-semibold text-center'>Entrada de mercancias</span>
							</div>
						</NavLink>
					</li>

					<li>
						<NavLink to={'/reports'} className='block bg-gray-dark w-full h-full p-4  shadow-md'>
							<div className='flex flex-col justify-center items-center w-full h-full'>
								<img src={reportboxIconIcon} alt='Products' className=' w-16 mb-4' />
								<span className=' text-white font-semibold text-center'>Reportes</span>
							</div>
						</NavLink>
					</li>

					<li>
						<NavLink
							to={'/reposicion-de-inventario'}
							className='block bg-gray-dark w-full h-full p-4  shadow-md'
						>
							<div className='flex flex-col justify-center items-center w-full h-full'>
								<img src={inventoryFlowIcon} alt='Inventory assortment' className=' w-16 mb-4' />
								<span className=' text-white font-semibold text-center'>
									Reposición de inventario
								</span>
							</div>
						</NavLink>
					</li>

					<li>
						<NavLink
							to={'/configuraciones'}
							className='block bg-gray-dark w-full h-full p-4 rounded-bl-2xl shadow-md'
						>
							<div className='flex flex-col justify-center items-center w-full h-full'>
								<img src={ConfigurationIcon} alt='Products' className=' w-16 mb-4' />
								<span className=' text-white font-semibold text-center'>Configuraciones</span>
							</div>
						</NavLink>
					</li>

					<li>
						<NavLink
							to={'/documentos'}
							className='block bg-gray-dark w-full h-full p-4 rounded-br-2xl shadow-md'
						>
							<div className='flex flex-col justify-center items-center w-full h-full'>
								<img src={statuscloseIcon} alt='Products' className=' w-16 mb-4' />
								<span className=' text-white font-semibold text-center'>Estatus de operacion</span>
							</div>
						</NavLink>
					</li>
				</ul>
			</section>

			<div className='fixed bottom-1 right-1 p-1'>
				<div>
					<ol>
						<li>
							<small>Version: PRE-0.23</small>
						</li>
						<li>
							<small>Sistema: {osName}</small>
						</li>
					</ol>
				</div>
			</div>
		</DefaultTemplate>
	)
}

export default DashboardPage
