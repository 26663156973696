import { deleteMessage, newMessage } from '@/features/systemMessage/systemMessage.slice'
import { useDispatch } from 'react-redux'

const useSystemMessage = () => {
	const dispatch = useDispatch()

	const add = (message: string) => {
		dispatch(newMessage(message))
	}

	const remove = () => {
		dispatch(deleteMessage())
	}

	return { add, remove }
}
export default useSystemMessage
