import { useState } from 'react'
import { IDataTransferencia } from '@/features/inventoryAssortment/types/Reposicion.types'
import { useNotification } from '@/shared/hooks'
import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'
import { getError } from '@/shared/helpers'

const usePDFTraslado = () => {
	const notification = useNotification()
	const useAxiosOneBeat = makeUseAxios({
		axios: axiosInstance(
			process.env.REACT_APP_API_WMS_ONE_BEAT ?? (env.REACT_APP_API_WMS_ONE_BEAT as string)
		)
	})

	const [data, setData] = useState<IDataTransferencia[] | undefined>(undefined)

	const [{ loading }, executeDataTraslado] = useAxiosOneBeat<IDataTransferencia[]>(
		{ url: '/traspasos/pdf', method: 'post' },
		{ manual: true }
	)

	const getDataTraslado = async (DocNum: string) => {
		await executeDataTraslado({
			data: {
				empresa: process.env.REACT_APP_SAP_COMPANY_TEST ?? env.REACT_APP_SAP_COMPANY_TEST,
				docNum: Number(DocNum)
			}
		})
			.then(({ data }) => {
				setData(data)
			})
			.catch((error) => {
				notification.error('No se obtuvo información del documento. Error:' + getError(error))
				setData(undefined)
			})
	}

	return {
		loading,
		data,
		getDataTraslado
	}
}

export default usePDFTraslado
