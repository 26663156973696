import { Routes, Route } from 'react-router-dom'
import { SAPDocsPage, ShippingListPage, PackingListDocumentsPage } from '../pages'

const SAPDocsRoute = () => {
	return (
		<Routes>
			<Route path='/' element={<SAPDocsPage />} />
			<Route path='/embarques' element={<ShippingListPage />} />
			<Route path='/packing' element={<PackingListDocumentsPage />} />
		</Routes>
	)
}

export default SAPDocsRoute
