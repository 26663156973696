import { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchTextRef } from '@/shared/components/fields/types'
import { useToggle, useNotification } from '@/shared/hooks'

import axiosInstance from '@/app/services/axiosInstance.service'
import { makeUseAxios } from 'axios-hooks'
import {
  modelListTypes,
  productMarketingResponseTypes
} from '@/shared/interfaces/shared.interfaces'
import { getError } from '@/shared/helpers'

import { setProduct } from '@/features/warehouse/warehouse.slice'
import { RootState } from '@/app/store/createStore'
import { env } from '@/env'

const useSearchProduct = () => {
  const [models, setModels] = useState<modelListTypes[]>([])
  const searchTextRef = useRef<searchTextRef>(null)
  const [spotlightOpen, setSpotLightOpen] = useToggle(false)
  const useAxios = makeUseAxios({
    axios: axiosInstance(process.env.REACT_APP_API_WMS ?? env.REACT_APP_API_WMS)
  })
  const notification = useNotification()
  const [findByScanner, setfindByScanner] = useToggle(false)
  const dispatch = useDispatch()
  const { whsTypes } = useSelector((state: RootState) => state.warehouse)

  const urlModelReference = (UgspReference: string) => `/modelos/${UgspReference}`
  const [{ loading: isFetchingModels }, executeFetchModels] = useAxios<modelListTypes[]>(
    { method: 'get' },
    { manual: true }
  )
  const [{ loading: isFetchigDocuments }, executeFetchDocuments] =
    useAxios<productMarketingResponseTypes>(
      {
        url: '/articulo-documentos',
        method: 'post'
      },
      { manual: true }
    )

  const closeSpotLight = () => {
    if (searchTextRef.current) {
      searchTextRef.current.clear()
      searchTextRef.current.focus()
      setSpotLightOpen()
      setModels([])
    }
  }

  const onSearchTextChange = async (value: string) => {
    try {
      if (value.length > 6 && value.includes('-') && !isFetchingModels) {
        const { data } = await executeFetchModels({ url: urlModelReference(value) })
        setModels(data)
      }
    } catch (error) {
      notification.error(getError(error))
    }
  }

  const onPressEnter = async (value: string) => {
    try {
      if (!value.includes('-')) {
        const {
          data: { product }
        } = await executeFetchDocuments({
          data: { itemQuery: 0, barcode: value, whsType: whsTypes }
        })
        dispatch(setProduct(product))
        if (spotlightOpen) {
          closeSpotLight()
        }
        searchTextRef.current?.clear()
      } else if (value.length > 6 && !isFetchingModels) {
        const {
          data: { product }
        } = await executeFetchDocuments({ data: { itemQuery: 1, model: value, whsType: whsTypes } })
        dispatch(setProduct(product))
        if (spotlightOpen) {
          closeSpotLight()
        }
      }
    } catch (error) {
      notification.error(getError(error))
    }
  }

  const onClickOnModel = async (model: string) => {
    try {
      const {
        data: { product }
      } = await executeFetchDocuments({ data: { itemQuery: 1, model, whsType: whsTypes } })
      dispatch(setProduct(product))
    } catch (error) {
      notification.error(getError(error))
    } finally {
      if (spotlightOpen) {
        closeSpotLight()
      }
    }
  }

  const onScannerDecode = async (barcode: string) => {
    try {
      const {
        data: { product }
      } = await executeFetchDocuments({ data: { itemQuery: 0, barcode, whsType: whsTypes } })
      dispatch(setProduct(product))
      if (spotlightOpen) {
        closeSpotLight()
      }
    } catch (error) {
      notification.error(getError(error))
    }
  }

  return {
    spotlightOpen,
    closeSpotLight,
    searchTextRef,
    onSearchTextChange,
    models,
    isFetchingModels,
    onClickOnModel,
    onScannerDecode,
    findByScanner,
    setfindByScanner,
    isFetchigDocuments,
    onPressEnter
  }
}

export default useSearchProduct
