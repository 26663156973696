import { v4 } from 'uuid'
import { useDispatch } from 'react-redux'
import {
  addNotification,
  removeNotification,
  clearNotifications
} from '@/features/notifications/notifications.slice'

export const useNotification = () => {
  const dispatch = useDispatch()

  const error = (message: string = '', duration: number = 3000) => {
    dispatch(addNotification({ id: v4(), type: 'ERROR', message, duration }))
  }

	const warning = (message: string = '', duration: number = 3000) => {
    dispatch(addNotification({ id: v4(), type: 'WARNING', message, duration }))
  }

  const success = (message: string = '', duration: number = 3000) => {
    dispatch(addNotification({ id: v4(), type: 'SUCCESS', message, duration }))
  }

  const remove = (id: string) => {
    dispatch(removeNotification(id))
  }

  const clear = () => {
    dispatch(clearNotifications())
  }

  return { error, warning, success, remove, clear }
}

export default useNotification
