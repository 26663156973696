import { useRef, useState } from 'react'
import DataGrid from 'devextreme-react/data-grid'
import { TextBox } from 'devextreme-react/text-box'
import { useNotification, useToggle } from '@/shared/hooks'
import { getError } from '@/shared/helpers'
import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'
import { IPackingList } from '../types/packingList.types'

import { useSelector, useDispatch } from 'react-redux'
import { setPacking, setConfirmLine, clearPacking } from '../slices/packingReceiptSlice'
import type { RootState } from '@/app/store/createStore'
import type { IPackingListDetail, IPackingListIncidentValues } from '../types/packingList.types'

const usePackingList = () => {
	const dataGridRef = useRef<DataGrid>(null)
	const inputRef = useRef<TextBox>(null)
	const notification = useNotification()
	const useAxios = makeUseAxios({
		axios: axiosInstance(process.env.REACT_APP_API_WMS ?? env.REACT_APP_API_WMS)
	})
	const dispatch = useDispatch()

	const { data, incidents, ...packing } = useSelector((state: RootState) => state.packingSlice)
	const [isDrawerOpen, setIsDrawerOpen] = useToggle()
	const [currentLine, setCurrentLine] = useState<IPackingListDetail | undefined>()
	const [packingListNumber, setPackingListNumber] = useState<string>('')
	const [isScannerModalOpen, setIsScannerModalOpen] = useToggle()
	const [isOmitCheckedPrimary, setIsOmitCheckedPrimary] = useToggle(false)
	const [isOmitCheckedSecondary, setIsOmitCheckedSecondary] = useToggle(false)

	const [{ loading: isLoadingPackingList }, executeGetPackingList] = useAxios<IPackingList>(
		{ method: 'GET' },
		{ manual: true }
	)
	const [{ loading: isPostingIncident }, executePostIncident] = useAxios(
		{ url: '/packing-list/incidencias', method: 'POST' },
		{ manual: true }
	)
	const [, executeConfirmLine] = useAxios({ method: 'PUT' }, { manual: true, autoCancel: false })
	const [{ loading: isUpdatingPacking }, executeConfirmPacking] = useAxios(
		{ method: 'PUT' },
		{ manual: true }
	)
	const [, executeConfirmClients] = useAxios(
		{ url: '/packing-list/clientes/confirmacion', method: 'PUT' },
		{ manual: true }
	)

	const getPackingListByNumber = async (packingListNumber: number) => {
		try {
			const { data } = await executeGetPackingList({
				url: `/packing-list/${packingListNumber}`,
				params: {
					company: env.REACT_APP_SAP_COMPANY_TEST ?? process.env.REACT_APP_SAP_COMPANY_TEST
				}
			})
			dispatch(clearPacking())
			dispatch(setPacking(data))
			if (dataGridRef.current && inputRef.current) {
				dataGridRef.current.instance.clearFilter()
				if (isOmitCheckedPrimary) {
					dataGridRef.current.instance.filter(['Confirmado1', '<>', 'C'])
				}
				if (isOmitCheckedSecondary) {
					dataGridRef.current.instance.filter(['Confirmado2', '<>', 'C'])
				}
				inputRef.current.instance.reset()
				inputRef.current.instance.focus()
			}
			if (isScannerModalOpen) {
				setIsScannerModalOpen()
			}
		} catch (error) {
			notification.error(getError(error))
		}
	}

	const onDecodePackingListBarcode = async (packingListCodebar: string) => {
		const packingListNumber = Number(packingListCodebar)
		await getPackingListByNumber(packingListNumber)
	}

	const postIncident = async ({
		packingList,
		dispercion,
		receivedAmount,
		type,
		images,
		motive
	}: IPackingListIncidentValues) => {
		try {
			const formData = new FormData()
			formData.append('packingList', packingList.toString())
			formData.append('dispersion', dispercion.toString())
			formData.append('receivedAmount', receivedAmount.toString())
			formData.append('type', type.toString())
			formData.append('motive', motive?.toString())
			if (images) {
				formData.append('images', images, images.name)
			}
			await executePostIncident({ data: formData })
			await getPackingListByNumber(packingList)
		} catch (error) {
			notification.error(getError(error))
		}
	}

	const onReportLine = (line?: IPackingListDetail) => {
		if (line) {
			setCurrentLine(line)
			setIsDrawerOpen()
		}
	}

	const onConfirmLine = async (line?: IPackingListDetail) => {
		if (data && line) {
			if (!packing.confirmLines.includes(line.IdDispersion)) {
				try {
					await executeConfirmLine({
						url: `/packing-list/estatus/linea/${line.IdDispersion}`,
						data: { status: 'C' }
					})
					await executeConfirmClients({
						data: { packingList: +packingListNumber, clients: [line.CodigoCliente] }
					})
					dispatch(setConfirmLine({ id: line.IdDispersion, onlyReceived: line.Recibe }))
				} catch (error) {
					notification.error(getError(error))
				}
			}
		}
	}

	const onConfirmPacking = async (packingId: number) => {
		try {
			if (data) {
				/*
        const cardCodes = [
          ...new Set(
            data
              .filter((item) => packing.confirmLines.includes(item.IdDispersion))
              .flatMap((item) => item.CodigoCliente)
          )
        ]
        await executeConfirmClients({ data: { packingList: packingId, clients: cardCodes } })
        */
				await executeConfirmPacking({
					url: `/packing-list/estatus/${packingId}`,
					data: { status: 7 },
					params: {
						company: env.REACT_APP_SAP_COMPANY_TEST ?? process.env.REACT_APP_SAP_COMPANY_TEST
					}
				})
				await getPackingListByNumber(packingId)
				notification.success('Packing list confirmado')
			} else {
				notification.error('No hay lineas confirmadas')
			}
		} catch (error) {
			notification.error(getError(error))
		}
	}

	const onfilterEanchange = (value: string) => {
		if (dataGridRef.current && inputRef.current) {
			if (value) {
				dataGridRef.current.instance.filter(['EAN', '=', value])
				inputRef.current.instance.focus()
				inputRef.current.instance.resetOption('value')
			}
		}
	}

	const onCheckOmitPrimary = (value: boolean) => {
		setIsOmitCheckedPrimary()
		if (dataGridRef.current) {
			if (value) {
				dataGridRef.current.instance.filter(['Confirmado1', '<>', 'C'])
			} else {
				dataGridRef.current.instance.clearFilter()
				if (isOmitCheckedSecondary) {
					dataGridRef.current.instance.filter(['Confirmado2', '<>', 'C'])
				}
			}
		}
	}

	const onCheckOmitSecondary = (value: boolean) => {
		setIsOmitCheckedSecondary()
		if (dataGridRef.current) {
			if (value) {
				dataGridRef.current.instance.filter(['Confirmado2', '<>', 'C'])
			} else {
				dataGridRef.current.instance.clearFilter()
				if (isOmitCheckedPrimary) {
					dataGridRef.current.instance.filter(['Confirmado1', '<>', 'C'])
				}
			}
		}
	}

	const onPressClearfilter = () => {
		if (dataGridRef.current) {
			dataGridRef.current.instance.clearFilter()
			if (isOmitCheckedPrimary) {
				dataGridRef.current.instance.filter(['Estatus', '<>', 'C'])
			}
			if (isOmitCheckedSecondary) {
				dataGridRef.current.instance.filter(['Confirmado2', '<>', 'C'])
			}
		}
	}

	return {
		getPackingListByNumber,
		onDecodePackingListBarcode,
		isLoadingPackingList,
		data,
		packing,
		incidents,
		isDrawerOpen,
		setIsDrawerOpen,
		onReportLine,
		onConfirmLine,
		postIncident,
		isPostingIncident,
		currentLine,
		setCurrentLine,
		packingListNumber,
		setPackingListNumber,
		isScannerModalOpen,
		setIsScannerModalOpen,
		dataGridRef,
		inputRef,
		onfilterEanchange,
		onCheckOmitPrimary,
		isOmitCheckedPrimary,
		onCheckOmitSecondary,
		isOmitCheckedSecondary,
		onPressClearFilter: onPressClearfilter,
		isUpdatingPacking,
		onConfirmPacking
	}
}

export default usePackingList
