import { useSelector } from 'react-redux'
import { RootState } from '@/app/store/createStore'
import { useSystemMessage } from '@/shared/hooks'
import Message from '../components/Message'

const SystemMessageContainer = () => {
	const { systemMessage } = useSelector((state: RootState) => state)
	const { remove } = useSystemMessage()

	if (systemMessage.length > 0) {
		return (
			<div
				id='loader'
				className='fixed top-0 right-0 bottom-0 left-0 bg-black/5 z-50 flex flex-row justify-center items-center'
			>
				<Message text={systemMessage[0]} remove={remove} />
			</div>
		)
	} else return <></>
}

export default SystemMessageContainer
