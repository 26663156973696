import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getCookie, removeCookie, setCookie } from 'typescript-cookie'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { refreshTokenResponseTypes } from './types'
import { env } from '@/env'

const configuratorURL =
  process.env.REACT_APP_API_CONFIGURATOR ?? (env.REACT_APP_API_CONFIGURATOR as string)
const refreshURL = `${configuratorURL}/auth/refresh`

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (!config?.headers) throw new Error('Config Headers are not defined')
  config.headers.Authorization = `Bearer ${getCookie('accessToken')}`
  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

const refreshToken = (req: AxiosError): Promise<any> => {
  return axios
    .get<refreshTokenResponseTypes>(refreshURL, { withCredentials: true })
    .then(({ data }) => {
      setCookie('accessToken', data.accessToken, {
        domain: process.env.REACT_APP_DOMAIN_APP ?? env.REACT_APP_DOMAIN_APP
      })
      if (!req.response?.headers) return Promise.reject('Config Headers are not defined')
      req.response.headers.Authorization = `Bearer ${data.accessToken}`
      return Promise.resolve()
    })
    .catch((error) => {
      removeCookie('accessToken', {
        domain: process.env.REACT_APP_DOMAIN_APP ?? env.REACT_APP_DOMAIN_APP
      })
      removeCookie('jwt')
      window.location.href = '/'
      return Promise.reject(error)
    })
}

const axiosInstance = (baseURL: string): AxiosInstance => {
  const provider: AxiosInstance = axios.create({
    baseURL,
    withCredentials: true
  })

  createAuthRefreshInterceptor(provider, refreshToken)
  provider.interceptors.request.use(onRequest, onRequestError)
  provider.interceptors.response.use(onResponse, onResponseError)
  return provider
}

export default axiosInstance
