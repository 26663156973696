import { ErrorMessage, useField } from 'formik'
import { IRadioControl } from '@/shared/components/fields/types'

export const ControlRadio = ({ label, onChangeCheck, ...props }: IRadioControl) => {
	const [field, meta, { setValue }] = useField(props)

	const onchangeCheackedValue = (value: string) => {
		setValue(value)
		onChangeCheck && onChangeCheck(value)
	}

	return (
		<label className='block'>
			<label htmlFor={props.id || props.name} className='block'>
				<input
					type='radio'
					{...field}
					{...props}
					className={`mr-1 ${meta.error && meta.touched ? 'error' : ''}`}
					onChange={(event) => {
						onchangeCheackedValue(event.target.value)
					}}
				/>
				{label}
			</label>
			<ErrorMessage
				name={props.name}
				component='span'
				className='text-red-500 font-semibold text-right text-sm'
			/>
		</label>
	)
}
