import { useEffect } from 'react'
import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { userResponseTypes, IGlobalParameters } from '@/shared/interfaces/user.interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading, setUser } from '@/features/auth/auth.slice'
import { getCookie } from 'typescript-cookie'
import { RootState } from '@/app/store/createStore'
import { env } from '@/env'
import { AxiosError } from 'axios'

/**
 * Este hook se encarga de la autentificación del usuario
 * Revisa si en las cookies se encuentra la cookie del usuario si la encuentra
 * trata de recuperar los datos
 *
 * NOTA:
 * Si no encuentra la cookie o le es imposible recuperar los datos del usuario no cambiara su estatus a autorizad
 * y el usuario tendrá que hacer logging
 *
 * NOTA:
 * Es el primer hook en ejecutarse de la aplicación
 */

const useInitalAuth = () => {
	const useAxios = makeUseAxios({
		axios: axiosInstance(
			process.env.REACT_APP_API_CONFIGURATOR ?? (env.REACT_APP_API_CONFIGURATOR as string)
		)
	})
	const [{ error }, execute] = useAxios<userResponseTypes>(
		{ url: '/user/perfil', method: 'get' },
		{ manual: true }
	)
	const dispatch = useDispatch()
	const { isLoading, isAuth } = useSelector((state: RootState) => state.auth)
	const [, executeParameters] = useAxios<IGlobalParameters>(
		{ url: '/user/dashboards/parameters/globals/1' },
		{ manual: true }
	)

	/**
	 * Obtiene los datos del usuario
	 * se omite el erro de petición cancelada porque no es relevante para el usuario
	 */
	const getInitial = async () => {
		try {
			const {
				data: { user }
			} = await execute()
			console.log(executeParameters)
			const {
				data: { confirmGlobalShippingList, confirmGlobalPackingList, tipoTraspasos }
			} = await executeParameters()
			dispatch(
				setUser({
					...user,
					confirmGlobalShippingList,
					confirmGlobalPackingList,
					tipoTraspasos,
					Localidades: user.Localidades.map((item) => ({ ...item, Ver: true }))
				})
			)
			dispatch(setIsLoading(false))
		} catch (error) {
			if (error instanceof AxiosError) {
				if (error.code !== 'ERR_CANCELED') {
					dispatch(setIsLoading(false))
				}
			}
		}
	}

	useEffect(() => {
		if (getCookie('accessToken')) {
			getInitial()
		} else {
			dispatch(setIsLoading(false))
		}
	}, [])

	return {
		isLoading,
		error,
		isAuth
	}
}
export default useInitalAuth
