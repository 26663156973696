import { spinnerLoaderTypes } from './types'

const SpinnerLoader = ({ width = 200, height = 200 }: spinnerLoaderTypes) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			style={{ shapeRendering: 'auto' }}
			width={width}
			height={height}
			viewBox='0 0 100 100'
			preserveAspectRatio='xMidYMid'
		>
			<g transform='rotate(0 50 50)'>
				<rect x='48' y='24' rx='1.2' ry='1.2' width='4' height='12' fill='#0051a2'>
					<animate
						attributeName='opacity'
						values='1;0'
						keyTimes='0;1'
						dur='1.3157894736842106s'
						begin='-1.2061403508771928s'
						repeatCount='indefinite'
					/>
				</rect>
			</g>
			<g transform='rotate(30 50 50)'>
				<rect x='48' y='24' rx='1.2' ry='1.2' width='4' height='12' fill='#0051a2'>
					<animate
						attributeName='opacity'
						values='1;0'
						keyTimes='0;1'
						dur='1.3157894736842106s'
						begin='-1.0964912280701753s'
						repeatCount='indefinite'
					/>
				</rect>
			</g>
			<g transform='rotate(60 50 50)'>
				<rect x='48' y='24' rx='1.2' ry='1.2' width='4' height='12' fill='#0051a2'>
					<animate
						attributeName='opacity'
						values='1;0'
						keyTimes='0;1'
						dur='1.3157894736842106s'
						begin='-0.9868421052631577s'
						repeatCount='indefinite'
					/>
				</rect>
			</g>
			<g transform='rotate(90 50 50)'>
				<rect x='48' y='24' rx='1.2' ry='1.2' width='4' height='12' fill='#0051a2'>
					<animate
						attributeName='opacity'
						values='1;0'
						keyTimes='0;1'
						dur='1.3157894736842106s'
						begin='-0.8771929824561403s'
						repeatCount='indefinite'
					/>
				</rect>
			</g>
			<g transform='rotate(120 50 50)'>
				<rect x='48' y='24' rx='1.2' ry='1.2' width='4' height='12' fill='#0051a2'>
					<animate
						attributeName='opacity'
						values='1;0'
						keyTimes='0;1'
						dur='1.3157894736842106s'
						begin='-0.7675438596491228s'
						repeatCount='indefinite'
					/>
				</rect>
			</g>
			<g transform='rotate(150 50 50)'>
				<rect x='48' y='24' rx='1.2' ry='1.2' width='4' height='12' fill='#0051a2'>
					<animate
						attributeName='opacity'
						values='1;0'
						keyTimes='0;1'
						dur='1.3157894736842106s'
						begin='-0.6578947368421052s'
						repeatCount='indefinite'
					/>
				</rect>
			</g>
			<g transform='rotate(180 50 50)'>
				<rect x='48' y='24' rx='1.2' ry='1.2' width='4' height='12' fill='#0051a2'>
					<animate
						attributeName='opacity'
						values='1;0'
						keyTimes='0;1'
						dur='1.3157894736842106s'
						begin='-0.5482456140350876s'
						repeatCount='indefinite'
					/>
				</rect>
			</g>
			<g transform='rotate(210 50 50)'>
				<rect x='48' y='24' rx='1.2' ry='1.2' width='4' height='12' fill='#0051a2'>
					<animate
						attributeName='opacity'
						values='1;0'
						keyTimes='0;1'
						dur='1.3157894736842106s'
						begin='-0.43859649122807015s'
						repeatCount='indefinite'
					/>
				</rect>
			</g>
			<g transform='rotate(240 50 50)'>
				<rect x='48' y='24' rx='1.2' ry='1.2' width='4' height='12' fill='#0051a2'>
					<animate
						attributeName='opacity'
						values='1;0'
						keyTimes='0;1'
						dur='1.3157894736842106s'
						begin='-0.3289473684210526s'
						repeatCount='indefinite'
					/>
				</rect>
			</g>
			<g transform='rotate(270 50 50)'>
				<rect x='48' y='24' rx='1.2' ry='1.2' width='4' height='12' fill='#0051a2'>
					<animate
						attributeName='opacity'
						values='1;0'
						keyTimes='0;1'
						dur='1.3157894736842106s'
						begin='-0.21929824561403508s'
						repeatCount='indefinite'
					/>
				</rect>
			</g>
			<g transform='rotate(300 50 50)'>
				<rect x='48' y='24' rx='1.2' ry='1.2' width='4' height='12' fill='#0051a2'>
					<animate
						attributeName='opacity'
						values='1;0'
						keyTimes='0;1'
						dur='1.3157894736842106s'
						begin='-0.10964912280701754s'
						repeatCount='indefinite'
					/>
				</rect>
			</g>
			<g transform='rotate(330 50 50)'>
				<rect x='48' y='24' rx='1.2' ry='1.2' width='4' height='12' fill='#0051a2'>
					<animate
						attributeName='opacity'
						values='1;0'
						keyTimes='0;1'
						dur='1.3157894736842106s'
						begin='0s'
						repeatCount='indefinite'
					/>
				</rect>
			</g>
		</svg>
	)
}

export default SpinnerLoader
