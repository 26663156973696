import { useField, ErrorMessage } from 'formik'
import DatePicker from 'react-datepicker'
import type { IDateControl } from './types'
import type { FocusEvent } from 'react'
import 'react-datepicker/dist/react-datepicker.css'

export const DateControl = ({
	label,
	disabled,
	dateFormat = 'MM/dd/yyyy',
	onChangeValue,
	...props
}: IDateControl) => {
	const [field, meta, { setValue }] = useField(props)

	const onDatepickerFocus = (evt: FocusEvent<HTMLInputElement>) => {
		evt.target.blur()
		evt.target.readOnly = true
	}

	const onchangeDate = (date: Date | null) => {
		if (date) {
			setValue(date)
			onChangeValue && onChangeValue(date)
		}
	}

	return (
		<label id={props.id} className={'block'}>
			{label}
			<DatePicker
				{...field}
				{...props}
				className={`field-input ${meta.error && meta.touched ? 'error' : ''}`}
				selected={(field.value && new Date(field.value)) || new Date()}
				onChange={(date, event) => {
					onchangeDate(date)
					event?.preventDefault()
				}}
				disabledKeyboardNavigation
				onFocus={onDatepickerFocus}
				disabled={disabled}
				dateFormat={dateFormat}
			/>
			<ErrorMessage
				name={props.name}
				component='span'
				className='text-red-500 font-semibold text-right text-sm'
			/>
		</label>
	)
}
