import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { stockAlmacenSliceTypes } from './stockAlmacen.types'

const initialState: stockAlmacenSliceTypes = {
  data: [],
  type: '',
  warehouesIn: {
    places: 'Ninguna',
    warehouses: ''
  }
}

export const stockAlmacenSlice = createSlice({
  name: 'stockAlmacen',
  initialState,
  reducers: {
    setStockAlmacenReportData: (state, action: PayloadAction<stockAlmacenSliceTypes>) => ({
      ...state,
      data: [...action.payload.data],
      type: action.payload.type,
      warehouesIn: action.payload.warehouesIn
    }),
    resetStockAlmacenReportData: () => initialState
  }
})

export const { setStockAlmacenReportData, resetStockAlmacenReportData } = stockAlmacenSlice.actions
export default stockAlmacenSlice
