import useCheckList from './useCheckList'
import { CheckListProps } from './types'
import CheckListItems from './CheckListItems'

export const CheckList = ({
	initialItems,
	height,
	onCheckChange,
	enableRendered = false,
	onDefaultValuesChange
}: CheckListProps) => {
	const { items, onListItemCheck, onAllListCheck, isChecked, isListChecked, onselectAll } =
		useCheckList({ initialItems, onCheckChange, enableRendered, onDefaultValuesChange })

	return (
		<div className='flex flex-col space-y-4'>
			<div className='flex justify-end'>
				<label htmlFor={'Selected-all'} className='block text-xs font-bold '>
					<input id='Selected-all' type={'checkbox'} className='mr-1' onChange={onselectAll} />
					{'Seleccionar todo'}
				</label>
			</div>

			<div className={`overflow-auto flex flex-col space-y-2`} style={{ height: height }}>
				{items?.map((item) => (
					<CheckListItems
						key={item.id}
						item={item}
						isChecked={isChecked}
						isListChecked={isListChecked}
						onAllListCheck={onAllListCheck}
						onListItemCheck={onListItemCheck}
					/>
				))}
			</div>
		</div>
	)
}
