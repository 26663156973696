import {
	FilterStockAlmacenTemplate,
	DataStockAlmacenTemplate
} from '@/features/reports/inventario/stockAlmacen/templates'

const StockAlmacenPage = () => {
	return (
		<>
			<FilterStockAlmacenTemplate />
			<DataStockAlmacenTemplate />
		</>
	)
}

export default StockAlmacenPage
