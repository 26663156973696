import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { IPackingListSlice, IPackingData, SelectedDocument } from '../types/packingList'

export const packingListDocumentsSlice = createSlice({
  initialState: {} as IPackingListSlice,
  name: 'packingListDocuments',
  reducers: {
    setData: (state, action: PayloadAction<IPackingData>) => ({
      ...state,
      data: action.payload
    }),
    setSelectedDocuments: (state, action: PayloadAction<SelectedDocument[]>) => ({
      ...state,
      selectedDocuments: action.payload
    })
  }
})

export const { setData, setSelectedDocuments } = packingListDocumentsSlice.actions
