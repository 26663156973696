import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { loginFormProps, userValuesTypes } from '../auth.types'
import { ControlText } from '@/shared/components/fields'
import { Button } from '@/shared/components/commons'

const LoginForm = ({ onSubmit, isLoading }: loginFormProps) => {
	const initialValues: userValuesTypes = {
		UserCode: '',
		password: ''
	}

	const validationSchema = Yup.object().shape({
		UserCode: Yup.string().required('El nombre de usuario es requerido'),
		password: Yup.string().required('La contraseña es requerida')
	})

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			<Form>
				<div className=' space-y-3 mb-4'>
					<ControlText type='login' id='UserCode' name='UserCode' placeholder='Usuario' />
					<ControlText type='password' id='password' name='password' placeholder='Contraseña' />
				</div>
				<Button className='btn primary w-full' isLoading={isLoading}>
					Entrar
				</Button>
			</Form>
		</Formik>
	)
}

export default LoginForm
