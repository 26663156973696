import { useField, ErrorMessage } from 'formik'
import type { ISelectControl } from './types'

export const SelectControl = ({
	label,
	children,
	selectText = 'Selecciona',
	onChangeSelect,
	...props
}: ISelectControl) => {
	const [field, meta, { setValue }] = useField(props)

	const handleOnChange = (value: string) => {
		setValue(value)
		onChangeSelect && onChangeSelect(value)
	}

	return (
		<label className='block'>
			<label htmlFor={props.id || props.name} className='block'>
				{label}
				<select
					{...props}
					{...field}
					onChange={(evt) => handleOnChange(evt.target.value)}
					className={`field-input ${meta.error && meta.touched ? 'error' : ''}`}
				>
					<option value={''}> {selectText} </option>
					{children}
				</select>
				<ErrorMessage
					name={props.name}
					component='span'
					className='text-red-500 font-semibold text-right text-sm'
				/>
			</label>
		</label>
	)
}
