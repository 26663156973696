import { useState } from 'react'
import { useSurtidoTiendas, useFiltersCatalogos } from '@/features/inventoryAssortment/hooks'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import {
	ControlCheckbox,
	ControlRadio,
	ControlText,
	DateControl,
	SelectControl
} from '@/shared/components/fields'
import { Button } from '@/shared/components/commons'
import { CheckListSimple } from '@/shared/components/checkList/CheckListSimple'
import { format } from 'date-fns'

const FilterSurtidoTiendasTemplate = () => {
	const {
		user,
		filters,
		onSubmitFilters,
		loading,
		loadingTiendas,
		lstTiendasPlaza,
		onChangePlaza,
		loadingBuffer,
		loadingDocsPendientes,
		generatingDocsPends,
		loadingDetalleNormal,
		loadingDetalleOneBeat
	} = useSurtidoTiendas()

	const { loadingCatListsPrecios, catListsPrecios } = useFiltersCatalogos(['ListasPrecios'])
	const [AuxTiendasSel, setAuxTiendasSel] = useState<string[]>([])
	const [disabledFechaVta, setDisabledFechaVta] = useState(false)

	const validationSchema = Yup.object().shape({
		FechaVenta: Yup.string().required(),
		Plaza: Yup.number().required('Seleccione una plaza'),
		FechaConta: Yup.string().required(),
		FechaDoc: Yup.string().required(),
		ListaPrecios: Yup.number().required('Seleccione una lista de precios'),
		UserSAP: Yup.string().required('Usuario SAP requerido'),
		PwdSAP: Yup.string().required('Contraseña SAP requerida'),
		penetracionBuffer: Yup.boolean()
	})

	const onChangeAuxTiendasSel = (values: string[]) => {
		setAuxTiendasSel(values)
	}

	const onChangeComment = (Base: string, FechaVenta: Date) => {
		if (Base === 'OneBeat') return `Reposición de Mercancia por OneBeat`
		if (Base === 'Ambos')
			return `Reposición de Mercancia por OneBeat y Normal Corr. venta del dia ${format(
				FechaVenta.setDate(FechaVenta.getDate() - 1),
				'dd/MM/yyyy'
			)}`
		else return `Reposición de Mercancia Corr. venta del dia ${format(FechaVenta, 'dd/MM/yyyy')}`
	}

	return (
		<section
			className={`w-full overflow-auto`}
			style={{
				height: 'calc(100% - 5rem)'
			}}
		>
			<Formik
				enableReinitialize={false}
				initialValues={{
					...filters,
					UserSAP: user ? user.UserSAP : '',
					PwdSAP: user ? (user.PasswordSAP ? user.PasswordSAP : '') : '',
					penetracionBuffer: filters.penetracionBuffer || false
				}}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					//console.log(JSON.stringify({ ...values, Tiendas: AuxVisualSel }, null, 2))
					onSubmitFilters({
						...values,
						Tiendas: AuxTiendasSel
					})
				}}
			>
				{(props) => (
					<Form onSubmit={props.handleSubmit} className='flex flex-col w-full h-full'>
						<div className={`flex flex-row flex-wrap`}>
							<div className={`w-full md:w-1/3 p-5 space-y-1.5 `}>
								<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-row flex-wrap space-x-3'>
									<legend>Base para reposición</legend>
									<ControlRadio
										id='base1'
										name='Base'
										value='Normal'
										label='Normal'
										checked={props.values.Base === 'Normal'}
										onChangeCheck={(value) => {
											setDisabledFechaVta(false)
											const fechaVenta = new Date()
											fechaVenta.setDate(fechaVenta.getDate() - 1)
											props.setFieldValue('FechaVenta', fechaVenta)
											props.setFieldValue('Comentario', onChangeComment(value, fechaVenta))
											props.setFieldValue('Segmentos', ['ACCESORIOS'])
										}}
									/>
									<ControlRadio
										id='base2'
										name='Base'
										value='OneBeat'
										label='OneBeat'
										checked={props.values.Base === 'OneBeat'}
										onChangeCheck={(value) => {
											setDisabledFechaVta(true)
											props.setFieldValue('FechaVenta', new Date())
											props.setFieldValue('Comentario', onChangeComment(value, new Date()))
											props.setFieldValue('Segmentos', ['LINEA', 'MODA'])
										}}
									/>
									<ControlRadio
										id='base3'
										name='Base'
										value='Ambos'
										label='Ambos'
										checked={props.values.Base === 'Ambos'}
										onChangeCheck={(value) => {
											setDisabledFechaVta(true)
											props.setFieldValue('FechaVenta', new Date())
											props.setFieldValue('Comentario', onChangeComment(value, new Date()))
											props.setFieldValue('Segmentos', ['LINEA', 'MODA', 'ACCESORIOS', 'OTROS'])
										}}
									/>
								</fieldset>

								<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-row flex-wrap space-x-3'>
									<legend>Segmento</legend>
									<ControlCheckbox
										id='ckLinea'
										name='Segmentos'
										value='LINEA'
										label='Linea'
										checked={props.values.Segmentos.includes('LINEA')}
										disabled={props.values.Base == 'Ambos' ? true : false}
									/>
									<ControlCheckbox
										id='ckModa'
										name='Segmentos'
										value='MODA'
										label='Moda'
										checked={props.values.Segmentos.includes('MODA')}
										disabled={props.values.Base == 'Ambos' ? true : false}
									/>
									<ControlCheckbox
										id='ckAccesorios'
										name='Segmentos'
										value='ACCESORIOS'
										label='Accesorios'
										checked={props.values.Segmentos.includes('ACCESORIOS')}
										disabled={props.values.Base == 'Normal' ? false : true}
									/>
									<ControlCheckbox
										id='ckOtros'
										name='Segmentos'
										value='OTROS'
										label='Otros'
										checked={props.values.Segmentos.includes('OTROS')}
										disabled={props.values.Base == 'Ambos' ? true : false}
									/>
								</fieldset>

								<DateControl
									id='FechaVenta'
									name='FechaVenta'
									label='Fecha de Venta'
									disabled={disabledFechaVta}
									dateFormat='yyyy-MM-dd'
									onChangeValue={(value) =>
										props.setFieldValue('Comentario', onChangeComment(props.values.Base, value))
									}
								/>
								<SelectControl id='Plaza' name='Plaza' label='Plaza' onChangeSelect={onChangePlaza}>
									{user?.Localidades.map((list) => (
										<option key={list.Codigo} value={list.Codigo}>
											{list.Nombre}
										</option>
									))}
								</SelectControl>
								<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col'>
									<legend className='font-semibold'>Tiendas</legend>
									{lstTiendasPlaza.length > 0 && (
										<CheckListSimple
											initialItems={lstTiendasPlaza.map((tienda) => ({
												code: tienda.almacen,
												description: tienda.descripcion,
												sup: 'Tienda',
												defaultChecked: tienda.surtir
											}))}
											height={150}
											onChangeCheck={onChangeAuxTiendasSel}
										/>
									)}
								</fieldset>

								<span className='block'>
									<ControlCheckbox
										id='ckPenetracion'
										name='penetracionBuffer'
										value='penetracionBuffer'
										label='Buffer con penetracion'
										checked={props.values.penetracionBuffer}
									/>
								</span>
							</div>

							<div className={`w-full md:w-1/3 p-5 `}>
								<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col p-3 space-y-1.5 '>
									<legend className='font-semibold'>Valores iniciales para Traslados</legend>
									<DateControl
										id='FechaConta'
										name='FechaConta'
										label='Fecha Contabilización'
										dateFormat='yyyy-MM-dd'
									/>
									<DateControl
										id='FechaDoc'
										name='FechaDoc'
										label='Fecha Documento'
										dateFormat='yyyy-MM-dd'
									/>
									<SelectControl id='ListaPrecios' name='ListaPrecios' label='Lista de Precios'>
										{catListsPrecios?.map((list) => (
											<option key={list.ListNum} value={list.ListNum}>
												{list.ListName}
											</option>
										))}
									</SelectControl>
									<ControlText
										id='Comentario'
										name='Comentario'
										label='Comentario'
										type='textarea'
									/>
								</fieldset>
							</div>
							<div className={`w-full md:w-1/3 p-5 `}>
								<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col p-3 space-y-1.5 '>
									<legend className='font-semibold'>Sesión SAP</legend>
									<ControlText
										id='UserSAP'
										name='UserSAP'
										label='Usuario'
										type='login'
										placeholder='Usuario'
									/>
									<ControlText
										id='PwdSAP'
										name='PwdSAP'
										label='Contraseña'
										type='password'
										placeholder='Contraseña'
									/>
								</fieldset>
							</div>
						</div>
						<div className='p-5 flex justify-end absolute bottom-0 right-0 w-full items-center'>
							{loadingBuffer && <span className='mr-5 font-semibold'>Actualizando buffer</span>}
							{loadingDocsPendientes && (
								<span className='mr-5 font-semibold'>Consultando reposiciones pendientes</span>
							)}
							{generatingDocsPends && (
								<span className='mr-5 font-semibold'>Generando documentos pendientes</span>
							)}
							{(loadingDetalleNormal || loadingDetalleOneBeat) && (
								<span className='mr-5 font-semibold'>Consultando surtido</span>
							)}
							<Button
								type='submit'
								className='btn primary w-48'
								isLoading={
									loading ||
									loadingTiendas ||
									loadingCatListsPrecios ||
									loadingBuffer ||
									loadingDocsPendientes ||
									generatingDocsPends ||
									loadingDetalleNormal ||
									loadingDetalleOneBeat
								}
							>
								Siguiente
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</section>
	)
}
export default FilterSurtidoTiendasTemplate
