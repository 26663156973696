import { ellipsisLoaderTyps } from './types'

const EllipsisLoader = ({ width = 200, height = 200 }: ellipsisLoaderTyps) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			style={{ shapeRendering: 'auto' }}
			width={width}
			height={height}
			viewBox='0 0 100 100'
			preserveAspectRatio='xMidYMid'
		>
			<circle cx='84' cy='50' r='10' fill='#0051a2'>
				<animate
					attributeName='r'
					repeatCount='indefinite'
					dur='0.641025641025641s'
					calcMode='spline'
					keyTimes='0;1'
					values='10;0'
					keySplines='0 0.5 0.5 1'
					begin='0s'
				/>
				<animate
					attributeName='fill'
					repeatCount='indefinite'
					dur='2.564102564102564s'
					calcMode='discrete'
					keyTimes='0;0.25;0.5;0.75;1'
					values='#0051a2;#89bff8;#408ee0;#1b75be;#0051a2'
					begin='0s'
				/>
			</circle>
			<circle cx='16' cy='50' r='10' fill='#0051a2'>
				<animate
					attributeName='r'
					repeatCount='indefinite'
					dur='2.564102564102564s'
					calcMode='spline'
					keyTimes='0;0.25;0.5;0.75;1'
					values='0;0;10;10;10'
					keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
					begin='0s'
				/>
				<animate
					attributeName='cx'
					repeatCount='indefinite'
					dur='2.564102564102564s'
					calcMode='spline'
					keyTimes='0;0.25;0.5;0.75;1'
					values='16;16;16;50;84'
					keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
					begin='0s'
				/>
			</circle>
			<circle cx='50' cy='50' r='10' fill='#1b75be'>
				<animate
					attributeName='r'
					repeatCount='indefinite'
					dur='2.564102564102564s'
					calcMode='spline'
					keyTimes='0;0.25;0.5;0.75;1'
					values='0;0;10;10;10'
					keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
					begin='-0.641025641025641s'
				/>
				<animate
					attributeName='cx'
					repeatCount='indefinite'
					dur='2.564102564102564s'
					calcMode='spline'
					keyTimes='0;0.25;0.5;0.75;1'
					values='16;16;16;50;84'
					keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
					begin='-0.641025641025641s'
				/>
			</circle>
			<circle cx='84' cy='50' r='10' fill='#408ee0'>
				<animate
					attributeName='r'
					repeatCount='indefinite'
					dur='2.564102564102564s'
					calcMode='spline'
					keyTimes='0;0.25;0.5;0.75;1'
					values='0;0;10;10;10'
					keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
					begin='-1.282051282051282s'
				/>
				<animate
					attributeName='cx'
					repeatCount='indefinite'
					dur='2.564102564102564s'
					calcMode='spline'
					keyTimes='0;0.25;0.5;0.75;1'
					values='16;16;16;50;84'
					keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
					begin='-1.282051282051282s'
				/>
			</circle>
			<circle cx='16' cy='50' r='10' fill='#89bff8'>
				<animate
					attributeName='r'
					repeatCount='indefinite'
					dur='2.564102564102564s'
					calcMode='spline'
					keyTimes='0;0.25;0.5;0.75;1'
					values='0;0;10;10;10'
					keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
					begin='-1.923076923076923s'
				/>
				<animate
					attributeName='cx'
					repeatCount='indefinite'
					dur='2.564102564102564s'
					calcMode='spline'
					keyTimes='0;0.25;0.5;0.75;1'
					values='16;16;16;50;84'
					keySplines='0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1'
					begin='-1.923076923076923s'
				/>
			</circle>
		</svg>
	)
}

export default EllipsisLoader
