import { useParams } from 'react-router-dom'
import { usePDFReposicion } from '../hooks'
import { Document, Font, Image, Page, PDFViewer, View, Text, StyleSheet } from '@react-pdf/renderer'
import { LoaderTemplate } from '@/shared/templates'
import { RobotoBold, RobotoRegular } from '@/fonts'
import { useEffect } from 'react'
import { logoTheGreenCompanyImg } from '@/assets'
import { format } from 'date-fns'
import { formatDateComplete, formatStrToDateComplete } from '@/shared/helpers'

const PreviewPDFReposicionPage = () => {
	const { Version, DocNumTransfer } = useParams()
	const {
		loading,
		data,
		GrupoTipoArticulo,
		GrupoFamilia,
		GrupoPrenda,
		GrupoModelReferencia,
		getDataReposicion
	} = usePDFReposicion()

	useEffect(() => {
		getDataReposicion(DocNumTransfer ? DocNumTransfer : '0', Version ? Version : '')
	}, [])

	Font.register({
		family: 'Roboto',
		fonts: [
			{ src: RobotoRegular }, // font-style: normal, font-weight: normal
			{
				src: RobotoBold,
				fontWeight: 700
			}
		]
	})

	const styles = StyleSheet.create({
		viewer: { width: '100%', height: '100vh', position: 'absolute' },
		page: { padding: '30px 30px 40px 30px' },
		encabezadoPagina: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'flex-start'
		},
		lines: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between'
		},
		tItemCode: { width: '14%', fontSize: '7px' },
		tEAN: { width: '12%', fontSize: '7px' },
		tDescripcion: { width: '35%', fontSize: '7px' },
		tEstCanasto: { width: '6%', fontSize: '7px' },
		tPPrimera: { width: '6%', fontSize: '7px' },
		tEXI: { width: '3%', fontSize: '7px' },
		tVTA: { width: '3%', fontSize: '7px' },
		tBUF: { width: '3%', fontSize: '7px' },
		tSURTIDO: { width: '6%', fontSize: '7px' },
		tFirma: {
			fontSize: '10px',
			borderTop: 1,
			display: 'flex',
			justifyContent: 'center',
			width: '100px',
			marginLeft: '20px',
			marginRight: '20px',
			textAlign: 'center',
			padding: '1px'
		},
		tNegritas: { fontFamily: 'Roboto', fontWeight: 'bold' },
		piePagina: {
			position: 'absolute',
			fontSize: '7px',
			bottom: '20px',
			left: '30px',
			right: '30px',
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between'
		}
	})

	return (
		<>
			{loading && <LoaderTemplate />}
			{data != undefined && (
				<PDFViewer style={styles.viewer}>
					<Document title={`Reposicion-${DocNumTransfer}`} author='WMS'>
						<Page size='LETTER' style={styles.page}>
							{/*ENCABEZADO*/}
							<View style={styles.encabezadoPagina} fixed>
								<View>
									<Image src={logoTheGreenCompanyImg} style={{ width: '85px' }} />
								</View>

								<View style={{ width: '45%' }}>
									<Text style={[styles.tNegritas, { fontSize: '14px', textAlign: 'center' }]}>
										REPOSICIÓN DE MERCANCIA
									</Text>
									<Text style={{ fontSize: '10px' }}>
										Surtido del {data[0].Filler} al {data[0].WhsCode}
									</Text>
									<Text style={{ fontSize: '10px' }}>
										Fecha Pedido: {formatStrToDateComplete(data[0].FechaRemision)}
									</Text>
								</View>
								<View
									style={{
										border: 1,
										width: '80px',
										borderRadius: '5px',
										display: 'flex',
										justifyContent: 'flex-end',
										alignItems: 'center',
										flexDirection: 'column',
										padding: '5px'
									}}
								>
									<Text style={[styles.tNegritas, { fontSize: '14px', color: 'red' }]}>
										{data[0].DocNum}
									</Text>
									<Text style={{ fontSize: '8px' }}>Reposición</Text>
								</View>
							</View>
							<View
								style={[
									styles.lines,
									{ margin: '15px 0 10px 0', padding: '5px 0', borderTop: 1, borderBottom: 1 }
								]}
								fixed
							>
								<Text style={[styles.tItemCode, styles.tNegritas]}>ARTICULO</Text>
								<Text style={[styles.tEAN, styles.tNegritas]}>EAN</Text>
								<Text style={[styles.tDescripcion, styles.tNegritas]}>DESCRIPCIÓN</Text>
								<Text style={[styles.tEstCanasto, styles.tNegritas]}>EST. CANASTO</Text>
								<Text style={[styles.tPPrimera, styles.tNegritas]}>PRECIO PRIMERA</Text>
								<Text style={[styles.tEXI, styles.tNegritas]}>EXI</Text>
								<Text style={[styles.tVTA, styles.tNegritas]}>VTA</Text>
								<Text style={[styles.tBUF, styles.tNegritas]}>BUF</Text>
								<Text style={[styles.tSURTIDO, styles.tNegritas]}>SURTIDO</Text>
							</View>
							{/*LINEAS*/}
							<View style={{ width: '100%' }}>
								{/*GRUPO 1: TIPO DE ARTICULO*/}
								{GrupoTipoArticulo.map((tipoArticulo, i1) => (
									<View key={`TipoArticulo-${i1}`}>
										<Text style={{ fontSize: '10px', textAlign: 'center', margin: '5px' }}>
											{tipoArticulo}
										</Text>
										{/*GRUPO 2: FAMILIA*/}
										{GrupoFamilia.filter((f) => f.TipoArticulo === tipoArticulo)
											.sort((a, b) => a.Desc.localeCompare(b.Desc))
											.map((familia, i2) => (
												<View key={`Familia-${i2}`} style={{ marginBottom: '5px' }}>
													<Text style={{ fontSize: '9px' }}>{familia.Desc}</Text>
													{/*GRUPO 3: PRENDA*/}
													{GrupoPrenda.filter(
														(p) => p.TipoArticulo === tipoArticulo && p.FamiliaCode === familia.Code
													)
														.sort((a, b) => a.Code.localeCompare(b.Code))
														.map((prenda, i3) => (
															<View key={`Prenda-${i3}`} style={{ marginBottom: '5px' }}>
																<View
																	style={{
																		width: '60%',
																		borderBottom: 1.5,
																		padding: '2px',
																		paddingLeft: '10%',
																		marginBottom: '1px'
																	}}
																>
																	<Text
																		style={[styles.tNegritas, { fontSize: '8px' }]}
																	>{`${prenda.Code} - ${prenda.Desc}`}</Text>
																</View>
																{/*GRUPO 3: MODELO*/}
																{GrupoModelReferencia.filter(
																	(m) =>
																		m.TipoArticulo === tipoArticulo &&
																		m.FamiliaCode === familia.Code &&
																		m.PrendaCode === prenda.Code
																)
																	.sort((a, b) => a.Referencia.localeCompare(b.Referencia))
																	.map((model, i4) => (
																		<View key={`ModelReferencia-${i4}`}>
																			{data
																				.filter(
																					(d) =>
																						d.TipoArticulo === tipoArticulo &&
																						d.Familia === familia.Code &&
																						d.UGSPCode === prenda.Code &&
																						d.UGSPReference === model.Referencia
																				)
																				.map((item) => (
																					<View
																						key={item.ItemCode}
																						style={[styles.lines, { marginTop: '3px' }]}
																					>
																						<Text style={styles.tItemCode}>{item.ItemCode}</Text>
																						<View
																							style={[
																								styles.tEAN,
																								{ display: 'flex', flexDirection: 'row' }
																							]}
																						>
																							<Text style={{ fontSize: '9px' }}>
																								{item.EAN.slice(0, -4)}
																							</Text>
																							<Text style={[{ fontSize: '9px' }, styles.tNegritas]}>
																								{item.EAN.slice(-4)}
																							</Text>
																						</View>
																						<Text style={styles.tDescripcion}>
																							{item.Dscription}
																						</Text>
																						<Text style={styles.tEstCanasto}>
																							{item.Descripcion}
																						</Text>
																						<Text
																							style={[styles.tPPrimera, { textAlign: 'right' }]}
																						>
																							$ {item.PPrimera.toFixed(2)}
																						</Text>
																						<Text style={[styles.tEXI, { textAlign: 'right' }]}>
																							{item.Existencia}
																						</Text>
																						<Text style={[styles.tVTA, { textAlign: 'right' }]}>
																							{item.Venta}
																						</Text>
																						<Text style={[styles.tBUF, { textAlign: 'right' }]}>
																							{item.Buffer}
																						</Text>
																						<Text
																							style={[
																								styles.tSURTIDO,
																								{ fontSize: '8px', textAlign: 'right' }
																							]}
																						>
																							{item.Quantity}
																						</Text>
																					</View>
																				))}
																			<View style={styles.lines}>
																				<View
																					style={{
																						borderTop: 1.5,
																						width: '60%',
																						marginTop: '5px'
																					}}
																				></View>
																				<View
																					style={{
																						borderTop: 1.5,
																						width: '20%',
																						marginTop: '5px',
																						paddingTop: '1px'
																					}}
																				></View>
																			</View>
																		</View>
																	))}
																<Text
																	style={[
																		styles.tNegritas,
																		{ fontSize: '8px', textAlign: 'right', width: '100%' }
																	]}
																>
																	{data
																		.filter(
																			(d) =>
																				d.TipoArticulo === tipoArticulo &&
																				d.Familia === familia.Code &&
																				d.UGSPCode === prenda.Code
																		)
																		.reduce((a, b) => a + b.Quantity, 0)}
																</Text>
															</View>
														))}
												</View>
											))}
									</View>
								))}
								<View style={{ marginTop: '25px', display: 'flex', flexDirection: 'row' }}>
									<Text style={{ width: '65px', fontSize: '8px' }}>PRENDAS:</Text>
									<Text style={{ width: '60px', fontSize: '8px' }}>
										{data
											.filter((d) => d.TipoArticulo === 'PRENDA')
											.reduce((a, b) => a + b.Quantity, 0)}
									</Text>
									<Text style={{ width: '85px', fontSize: '8px' }}>ACCESORIOS:</Text>
									<Text style={{ width: '60px', fontSize: '8px' }}>
										{data
											.filter((d) => d.TipoArticulo === 'ACCESORIOS')
											.reduce((a, b) => a + b.Quantity, 0)}
									</Text>
								</View>
								<View style={{ marginTop: '5px', display: 'flex', flexDirection: 'row' }}>
									<Text style={[styles.tNegritas, { width: '50px', fontSize: '8px' }]}>TOTAL:</Text>
									<Text style={[styles.tNegritas, { width: '60px', fontSize: '8px' }]}>
										{data.reduce((a, b) => a + b.Quantity, 0)}
									</Text>
								</View>
								<View
									style={{
										width: '100%',
										marginTop: '80px',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center'
									}}
								>
									<Text style={[styles.tFirma, { width: '150px' }]}>FECHA</Text>
									<Text style={[styles.tFirma, { width: '100px' }]}>HORA</Text>
									<Text style={[styles.tFirma, { width: '300px' }]}>RECIBIO</Text>
								</View>
							</View>
							{/*PIE DE PAGINA*/}
							<View style={styles.piePagina} fixed>
								<Text
									render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
								/>
								<Text>
									{formatDateComplete(new Date())} {format(new Date(), 'HH:mm')}
								</Text>
							</View>
						</Page>
					</Document>
				</PDFViewer>
			)}
		</>
	)
}

export default PreviewPDFReposicionPage
