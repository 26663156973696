import { useState, useEffect } from 'react'
import {
	FaceSmileIcon,
	XMarkIcon,
	FaceFrownIcon,
	ExclamationTriangleIcon
} from '@heroicons/react/24/outline'
import { notificationsTypes } from './interfaces'

const Notifications = ({ id, close, message, type, duration }: notificationsTypes) => {
	const [hidden, setHidden] = useState<boolean>(true)

	const autoClose = () => {
		setTimeout(() => {
			close(id)
		}, 300)
	}

	const hideNotification = () => {
		setTimeout(() => {
			setHidden(true)
			autoClose()
		}, duration + 300)
	}

	useEffect(() => {
		setTimeout(() => {
			setHidden(false)
			hideNotification()
		}, 300)
	}, [])

	return (
		<div className={`notification__message ${hidden ? 'hide' : ''}`}>
			<div className='notification__message__header'>
				{type === 'SUCCESS' ? (
					<FaceSmileIcon width={28} />
				) : type === 'WARNING' ? (
					<ExclamationTriangleIcon width={28} />
				) : (
					<FaceFrownIcon width={28} />
				)}
				<button onClick={() => close(id)}>
					<XMarkIcon width={28} />
				</button>
			</div>
			<div className='notification__message__body'>
				<p>{message}</p>
			</div>
			<div className={`notification__message__footer ${type}`} />
		</div>
	)
}

export default Notifications
