import { Modal } from '@/shared/components/modals'
import { IResultModal } from '@/features/reports/informes/partidasAbiertas/partidasAbiertas.types'
import DataGrid, {
	Column,
	ColumnChooser,
	FilterRow,
	Paging,
	Export
} from 'devextreme-react/data-grid'
import { useRef } from 'react'
import { useDevexpressActions } from '@/shared/hooks'
import { ColumnCellTemplateData } from 'devextreme/ui/data_grid'
import {
	CheckCircleIcon,
	ExclamationTriangleIcon,
	InformationCircleIcon,
	MinusIcon
} from '@heroicons/react/20/solid'
import { Status } from '@/features/reports/informes/partidasAbiertas/partidasAbiertas.types'

export const DataResultCloseLines = ({ isOpen, handleClose, dataResult }: IResultModal) => {
	const dataGridRef = useRef<DataGrid>(null)
	const { focusSearchPanelOnContentReady } = useDevexpressActions()

	const StatusCell = ({ value }: ColumnCellTemplateData<Status>) => {
		switch (value as Status) {
			case 'P' /*Pendiente*/:
				return (
					<span className='flex'>
						<InformationCircleIcon width={18} className='mr-1 text-yellow-400' />
						{value}
					</span>
				)

			case 'E' /*Error*/:
				return (
					<span className='flex'>
						<ExclamationTriangleIcon width={18} className='mr-1 text-red-600' />
						{value}
					</span>
				)

			case 'T' /*Terminado*/:
				return (
					<span className='flex'>
						<CheckCircleIcon width={18} className='mr-1 text-green-600' />
						{value}
					</span>
				)

			case 'A' /*No Aplica*/:
				return (
					<span className='flex'>
						<MinusIcon width={18} className='mr-1 text-gray-400' />
						{value}
					</span>
				)

			default:
				return <span className='flex'>{value}</span>
		}
	}

	return (
		<Modal
			wrapperId='ResultCloseLines'
			isOpen={isOpen}
			handleClose={handleClose}
			title='Resultado de lineas cerradas'
			width={800}
			height={500}
		>
			<div className='flex flex-col w-full h-full overflow-auto'>
				<DataGrid
					ref={dataGridRef}
					dataSource={dataResult}
					allowColumnResizing={true}
					columnResizingMode={'nextColumn'}
					showBorders={true}
					columnAutoWidth
					onContentReady={(e) => focusSearchPanelOnContentReady(e)}
				>
					<Paging enabled={false} />
					<ColumnChooser enabled={false} />
					<FilterRow visible={true} />
					<Export enabled={false} />
					<Column
						dataField='Estatus'
						caption='Estatus'
						dataType='string'
						cellRender={StatusCell}
						width={50}
					/>
					<Column dataField='TipoDoc' caption='Tipo Doc.' dataType='string' width={100} />
					<Column dataField='DocNum' caption='DocNum' dataType='number' width={100} />
					<Column dataField='DocEntry' caption='DocEntry' dataType='number' width={100} />
					<Column dataField='LineNum' caption='LineNum' dataType='number' width={100} />
					<Column dataField='OpenQty' caption='OpenQty' dataType='number' width={100} />
					<Column dataField='Mensaje' caption='Mensaje' dataType='string' width={600} />
				</DataGrid>
			</div>
		</Modal>
	)
}
