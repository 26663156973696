import { useDispatch } from 'react-redux'
import { setCookie, removeCookie } from 'typescript-cookie'
import { useNavigate } from 'react-router-dom'
import { clearUser, setUser } from '../auth.slice'
import { useNotification } from '@/shared/hooks'
import { getError } from '@/shared/helpers'
import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { userValuesTypes, accessTokenTypes, userEmailTypes } from '../auth.types'
import {
	userResponseTypes,
	userTypes,
	IGlobalParameters
} from '@/shared/interfaces/user.interfaces'

import { env } from '@/env'

export const useAuth = () => {
	const dispatch = useDispatch()
	const notification = useNotification()
	const useAxios = makeUseAxios({
		axios: axiosInstance(process.env.REACT_APP_API_CONFIGURATOR ?? env.REACT_APP_API_CONFIGURATOR)
	})
	const navigate = useNavigate()

	const [{ loading: isFetchLogin }, executeLogin] = useAxios<accessTokenTypes>(
		{
			url: '/auth/login',
			method: 'post'
		},
		{ manual: true }
	)

	const [{ loading: isFetchUser }, executeGetUser] = useAxios<userResponseTypes>(
		{
			url: '/user/perfil',
			method: 'get'
		},
		{ manual: true }
	)

	const [{ loading: isFetchLogout }, executeLogout] = useAxios(
		{
			url: '/auth/logout',
			method: 'get'
		},
		{ manual: true }
	)

	const [{ loading: isSendingEmail }, executeRequestNewPassword] = useAxios(
		{
			url: '/user/reset-password',
			method: 'POST'
		},
		{ manual: true }
	)

	const [, executeParameters] = useAxios<IGlobalParameters>(
		{ url: '/user/dashboards/parameters/globals/1' },
		{ manual: true }
	)

	const userLogin = async (body: userValuesTypes) => {
		try {
			const {
				data: { accessToken }
			} = await executeLogin({ data: body })
			setCookie('accessToken', accessToken, {
				domain: process.env.REACT_APP_DOMAIN_APP ?? env.REACT_APP_DOMAIN_APP
			})
			const user = await getUserData()
			const {
				data: { confirmGlobalShippingList, confirmGlobalPackingList, tipoTraspasos }
			} = await executeParameters()
			dispatch(
				setUser({
					...user,
					confirmGlobalShippingList,
					confirmGlobalPackingList,
					tipoTraspasos,
					Localidades: user.Localidades.map((item) => ({ ...item, Ver: true }))
				})
			)
			navigate('/dashboard')
		} catch (error) {
			notification.error(getError(error))
		}
	}

	const getUserData = async (): Promise<userTypes> => {
		try {
			const {
				data: { user }
			} = await executeGetUser()
			return user
		} catch (error) {
			throw error
		}
	}

	const userLogout = async () => {
		try {
			await executeLogout()
			removeCookie('accessToken', {
				domain: process.env.REACT_APP_DOMAIN_APP ?? env.REACT_APP_DOMAIN_APP
			})
			dispatch(clearUser())
		} catch (error) {
			notification.error(getError(error))
		}
	}

	const requestNewPassword = async (body: userEmailTypes) => {
		try {
			await executeRequestNewPassword({ data: body })
			notification.success('Se ha enviado un link a su correo')
		} catch (error) {
			notification.error(getError(error))
		}
	}

	return {
		userLogin,
		isFetchLogin,
		userLogout,
		isFetchLogout,
		isFetchUser,
		getUserData,
		requestNewPassword,
		isSendingEmail
	}
}

export default useAuth
