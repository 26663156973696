import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/app/store/createStore'
import { ChangeEvent, useEffect, useState } from 'react'
import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'
import { useNotification, useToggle } from '@/shared/hooks'
import { getError } from '@/shared/helpers'
import { Movements, ShippingListDoc, UpdateDocTypes } from '../types/shippingList.types'
import { ShipmentIncidentsTypes } from '@/features/goodsReceipt/types/shippingList.types'
import dayjs from 'dayjs'
import {
	setIncidents,
	setDocumentsCreation,
	setClearDocumentsCreation,
	setDocumentsQuintityMinus,
	setClearDocs,
	setDocumentsQuantity
} from '../slices/SAPShippingList.slices'
import { useInventoryTransfer } from '@/app/service_layer/hooks'
import {
	StockTransferTypes,
	StockTranferLinesTypes,
	StockTransferFromRequestTypes,
	StockTranferFromRequestLinesTypes
} from '@/app/service_layer/types/StockTransfer.types'
import { getServiceLayerError } from '@/app/service_layer/helpers/getServiceLayerError'

export const useShippingListPage = () => {
	const SapCompany = process.env.REACT_APP_SAP_COMPANY ?? env.REACT_APP_SAP_COMPANY
	const useAxios = makeUseAxios({
		axios: axiosInstance(process.env.REACT_APP_API_WMS ?? env.REACT_APP_API_WMS)
	})
	const notification = useNotification()
	const dispatch = useDispatch()
	const [dates, setDates] = useState<{ beginDate: Date; endDate: Date }>({
		beginDate: new Date(),
		endDate: new Date()
	})
	const { createStockTransfer, createStockTransferFromRequest } = useInventoryTransfer()
	const getUpdateShipmentEndpoint = (shippingListId: number) =>
		`listas-embarque/status/${shippingListId}`

	const [{ loading: isLoadingShipmets, data: shipmets }, executeGetShipments] = useAxios<
		ShippingListDoc[]
	>({ url: 'listas-embarque/proceso', method: 'POST' }, { manual: true })
	const [, executeGetIncidents] = useAxios<ShipmentIncidentsTypes[]>(
		{ url: '/listas-embarque/incidencias/', method: 'GET' },
		{ manual: true }
	)
	const [{ data: movements }] = useAxios<Movements[]>({ url: '/listas-embarque/movimientos' })
	const [, executeUpdateShipment] = useAxios(
		{ url: '/listas-embarque/documento', method: 'POST' },
		{ manual: true }
	)
	const [, executeUpdateStatusShipment] = useAxios({ method: 'PUT' }, { manual: true })

	const { DocsToGenerate, documentsCreation, DocsQuantity, icidents } = useSelector(
		(state: RootState) => state.SAPShippingList
	)
	const [razonMov, SetRazonMov] = useState<string>('')
	const [showToolbarFilter, setShowToolbarFilter] = useToggle()
	const [showResults, setShowResults] = useToggle()
	const [isDrawerOpen, setIsDrawerOpen] = useToggle()
	const [bulkIncidents, setBulkIncidents] = useState<ShipmentIncidentsTypes[]>([])
	const [includSeconds, setIncludSeconds] = useState<boolean>(false)
	const [isCreatingDocs, setIsCreatingDocs] = useToggle()

	const getShipments = async (beginDate: string, endDate: string) => {
		try {
			if (dayjs(beginDate).isValid() && dayjs(endDate).isValid()) {
				const { data } = await executeGetShipments({ data: { beginDate, endDate } })
				if (showToolbarFilter && data.length > 0) setShowToolbarFilter()
				const incidents: ShipmentIncidentsTypes[] = []
				for await (const item of data) {
					const result = await executeGetIncidents({ params: { shippingId: item.Embarque } })
					incidents.push(...result.data)
				}
				dispatch(setIncidents(incidents))
			} else {
				throw Error('El formato de fechas no es valido')
			}
		} catch (error) {
			notification.error(getError(error))
		}
	}

	const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()
		await getShipments(
			dayjs(dates.beginDate).format('YYYY-MM-DD').toString(),
			dayjs(dates.endDate).format('YYYY-MM-DD').toString()
		)
	}

	const onChangeDates = (newDate: Date | null, dateType: 'beginDate' | 'endDate') => {
		if (newDate) {
			setDates((prev) => ({ ...prev, [dateType]: newDate }))
		}
	}

	const onRazonChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.target
		SetRazonMov(value)
	}

	const updateShipment = async (args: UpdateDocTypes) => {
		try {
			await executeUpdateShipment({ data: args })
		} catch (error) {
			notification.error(getError(error))
		}
	}

	const onCheckSecondsChange = (evt: ChangeEvent<HTMLInputElement>) => {
		const { checked } = evt.target
		setIncludSeconds(checked)
	}

	const createDocuments = async (userSap: string) => {
		if (razonMov === '') {
			notification.error('No has elegido una razon de movimiento')
		} else if (DocsToGenerate.length === 0) {
			notification.error('No hay embarques elegidos')
		} else {
			dispatch(setClearDocumentsCreation(''))
			setIsCreatingDocs()
			setShowResults()

			for await (const item of DocsToGenerate) {
				for await (const line of item.PrimaryDocuments) {
					const Lines: StockTranferFromRequestLinesTypes[] = line.Lines.filter(
						(item) => item.Stock > 0 && item.Stock >= item.Quantity
					).map((item) => ({
						BaseEntry: item.BaseEntry,
						BaseLine: item.BaseLine,
						BaseType: 'InventoryTransferRequest',
						ItemCode: item.ItemCode,
						Quantity: item.Quantity,
						WarehouseCode: item.WarehouseCode,
						U_U_BYS_BaseCode: item.U_U_BYS_BaseCode
					}))

					const Transfer: StockTransferFromRequestTypes = {
						CardCode: line.CardCode,
						DocDate: new Date(),
						TaxDate: new Date(),
						PriceList: line.PiceList,
						FromWarehouse: line.FromWarehouse,
						ToWarehouse: line.ToWarehouse,
						Comments: line.Comments,
						JournalMemo: line.JournalMemo,
						U_BYS_CodeDocBaseStr: line.U_GSP_CodeDocBaseStr,
						U_GSP_MOVESTOCK: razonMov,
						StockTransferLines: Lines
					}

					try {
						if (Transfer.StockTransferLines.length > 0) {
							const data = await createStockTransferFromRequest(Transfer)
							const updateObject: UpdateDocTypes = {
								TipoDoc: 'Nota de Fabricacion',
								NumDoc: line.DocNum,
								TipoDocBase: 'SolTraslado',
								NumDocBase: line.Document,
								TipoDocGenerado: 'Traslado',
								Mensaje: 'Ok',
								Empresa: SapCompany,
								Fecha: dayjs().format('YYYY-MM-DD'),
								Hora: dayjs().format('HH:mm:ss'),
								Embarque: item.Shipment,
								Lugar: 'RE',
								EntryDocGenerado: data.DocEntry,
								NumDocGenerado: data.DocNum,
								Usuario: userSap
							}
							await updateShipment(updateObject)
							dispatch(
								setDocumentsCreation({
									document: line.DocNum,
									shipment: item.Shipment,
									type: 'success',
									message: `Documento generado ${data.DocNum}`
								})
							)
						} else {
							throw new Error('No se puede relizar la transeferencia sin lineas seleccioandas')
						}
					} catch (error) {
						dispatch(
							setDocumentsCreation({
								document: line.DocNum,
								shipment: item.Shipment,
								type: 'error',
								message: getServiceLayerError(error)
							})
						)
					} finally {
						dispatch(setDocumentsQuintityMinus(1))
					}
				}

				for await (const line of item.SecondaryDocuments) {
					const Lines: StockTranferLinesTypes[] = line.Lines.filter(
						(item) => item.Stock > 0 && item.Stock >= item.Quantity && includSeconds
					).map((item) => ({
						ItemCode: item.ItemCode,
						Quantity: item.Quantity,
						WarehouseCode: item.WarehouseCode,
						U_U_BYS_BaseCode: item.U_U_BYS_BaseCode
					}))

					const Transfer: StockTransferTypes = {
						CardCode: line.CardCode,
						DocDate: new Date(),
						TaxDate: new Date(),
						PriceList: line.PiceList,
						FromWarehouse: line.FromWarehouse,
						ToWarehouse: line.ToWarehouse,
						Comments: line.Comments,
						JournalMemo: line.JournalMemo,
						U_BYS_CodeDocBaseStr: line.U_GSP_CodeDocBaseStr,
						U_GSP_MOVESTOCK: razonMov,
						StockTransferLines: Lines
					}

					try {
						if (Transfer.StockTransferLines.length > 0) {
							const data = await createStockTransfer(Transfer)
							const updateObject: UpdateDocTypes = {
								TipoDoc: 'Nota de Fabricacion',
								NumDoc: line.DocNum,
								TipoDocBase: 'SolTraslado',
								NumDocBase: line.Document,
								TipoDocGenerado: 'Traslado',
								Mensaje: 'Ok',
								Empresa: SapCompany,
								Fecha: dayjs().format('YYYY-MM-DD'),
								Hora: dayjs().format('HH:mm:ss'),
								Embarque: item.Shipment,
								Lugar: 'RE',
								EntryDocGenerado: data.DocEntry,
								NumDocGenerado: data.DocNum,
								Usuario: userSap
							}
							await updateShipment(updateObject)
							dispatch(
								setDocumentsCreation({
									document: line.DocNum,
									shipment: item.Shipment,
									type: 'success',
									message: `Documento generado ${data.DocNum}`
								})
							)
						} else {
							throw new Error('No se puede relizar la transfeferencia sin lineas seleccioandas')
						}
					} catch (error) {
						dispatch(
							setDocumentsCreation({
								document: line.DocNum,
								shipment: item.Shipment,
								type: 'error',
								message: getServiceLayerError(error)
							})
						)
					} finally {
						dispatch(setDocumentsQuintityMinus(1))
					}
				}

				try {
					await executeUpdateStatusShipment({
						url: getUpdateShipmentEndpoint(item.Shipment),
						data: { status: 'R' }
					})
					await executeUpdateStatusShipment({
						url: getUpdateShipmentEndpoint(item.Shipment),
						params: { verify: true }
					})
				} catch (error) {
					notification.error(getError(error))
				}
			}

			try {
				dispatch(setClearDocs(''))
				getShipments(
					dayjs(dates.beginDate).format('YYYY-MM-DD').toString(),
					dayjs(dates.endDate).format('YYYY-MM-DD').toString()
				)
				setIsCreatingDocs()
			} catch (error) {
				notification.error(getError(error))
			}

			/*
            DocsToGenerate.map(async item => {

                item.PrimaryDocuments.map(async line => {
                    const Lines: StockTranferFromRequestLinesTypes[] = line.Lines.filter(item => item.Stock > 0 && item.Stock >= item.Quantity )
                        .map(item => ({
                            BaseEntry: item.BaseEntry,
                            BaseLine: item.BaseLine,
                            BaseType: 'InventoryTransferRequest',
                            ItemCode: item.ItemCode,
                            Quantity: item.Quantity,
                            WarehouseCode: item.WarehouseCode,
                            U_U_BYS_BaseCode: item.U_U_BYS_BaseCode
                        }))

                    const Transfer: StockTransferFromRequestTypes = {
                        CardCode: line.CardCode,
                        DocDate: new Date(),
                        TaxDate: new Date(),
                        PriceList: line.PiceList,
                        FromWarehouse: line.FromWarehouse,
                        ToWarehouse: line.ToWarehouse,
                        Comments: line.Comments,
                        JournalMemo: line.JournalMemo,
                        U_BYS_CodeDocBaseStr: line.U_GSP_CodeDocBaseStr,
                        U_GSP_MOVESTOCK: razonMov,
                        StockTransferLines: Lines
                    }

                    try {
                        if (Transfer.StockTransferLines.length > 0) {
                            const data = await createStockTransferFromRequest(Transfer)
                            const updateObject: UpdateDocTypes = {
                                TipoDoc: 'Nota de Fabricacion',
                                NumDoc: line.DocNum,
                                TipoDocBase: 'SolTraslado',
                                NumDocBase: line.Document,
                                TipoDocGenerado: 'Traslado',
                                Mensaje: 'Ok',
                                Empresa: SapCompany,
                                Fecha: dayjs().format("YYYY-MM-DD"),
                                Hora: dayjs().format("HH:mm:ss"),
                                Embarque: item.Shipment,
                                Lugar: 'RE',
                                EntryDocGenerado: data.DocEntry,
                                NumDocGenerado: data.DocNum,
                                Usuario: userSap
                            }
                            await updateShipment(updateObject)
                            //await executeUpdateStatusShipment({ url: getUpdateShipmentEndpoint(item.Shipment), params: { verify: true } })
                            dispatch(setDocumentsCreation({
                                document: line.DocNum,
                                shipment: item.Shipment,
                                type: 'success',
                                message: `Documento generado ${data.DocNum}`
                            }))

                        }else {
                            throw new Error('No se puede relizar la transefencia sin lineas seleccioandas')
                        }

                    } catch (error) {
                        dispatch(setDocumentsCreation({
                            document: line.DocNum,
                            shipment: item.Shipment,
                            type: 'error',
                            message: getServiceLayerError(error)
                        }))
                    } finally {
                        dispatch(setDocumentsQuintityMinus(1));
                    }
                })


                item.SecondaryDocuments.map(async line => {

                    const Lines: StockTranferLinesTypes[] = line.Lines.filter(item => item.Stock > 0 && item.Stock >= item.Quantity && includSeconds)
                        .map(item => ({
                            ItemCode: item.ItemCode,
                            Quantity: item.Quantity,
                            WarehouseCode: item.WarehouseCode,
                            U_U_BYS_BaseCode: item.U_U_BYS_BaseCode
                        }))

                    const Transfer: StockTransferTypes = {
                        CardCode: line.CardCode,
                        DocDate: new Date(),
                        TaxDate: new Date(),
                        PriceList: line.PiceList,
                        FromWarehouse: line.FromWarehouse,
                        ToWarehouse: line.ToWarehouse,
                        Comments: line.Comments,
                        JournalMemo: line.JournalMemo,
                        U_BYS_CodeDocBaseStr: line.U_GSP_CodeDocBaseStr,
                        U_GSP_MOVESTOCK: razonMov,
                        StockTransferLines: Lines
                    }

                    try {
                        if (Transfer.StockTransferLines.length > 0) {
                            const data = await createStockTransfer(Transfer);
                            const updateObject: UpdateDocTypes = {
                                TipoDoc: 'Nota de Fabricacion',
                                NumDoc: line.DocNum,
                                TipoDocBase: 'SolTraslado',
                                NumDocBase: line.Document,
                                TipoDocGenerado: 'Traslado',
                                Mensaje: 'Ok',
                                Empresa: SapCompany,
                                Fecha: dayjs().format("YYYY-MM-DD"),
                                Hora: dayjs().format("HH:mm:ss"),
                                Embarque: item.Shipment,
                                Lugar: 'RE',
                                EntryDocGenerado: data.DocEntry,
                                NumDocGenerado: data.DocNum,
                                Usuario: userSap
                            }
                            await updateShipment(updateObject)
                            //await executeUpdateStatusShipment({ url: getUpdateShipmentEndpoint(item.Shipment), params: { verify: true } })
                            dispatch(setDocumentsCreation({
                                document: line.DocNum,
                                shipment: item.Shipment,
                                type: 'success',
                                message: `Documento generado ${data.DocNum}`
                            }))
                        } else {
                            throw new Error('No se puede relizar la transefencia sin lineas seleccioandas')
                        }
                    } catch (error) {
                        dispatch(setDocumentsCreation({
                            document: line.DocNum,
                            shipment: item.Shipment,
                            type: 'error',
                            message: getServiceLayerError(error)
                        }))
                    } finally {
                        dispatch(setDocumentsQuintityMinus(1));
                    }
                })

            });
            */
		}
	}

	const bulkHasIncident = (idBulk: number): boolean => {
		if (icidents.length > 0) {
			return icidents.some((item) => item.Bulto === idBulk)
		} else {
			return false
		}
	}

	const getbulkIncidents = (idbulk: number) => {
		const incidents = icidents.filter((item) => item.Bulto === idbulk)
		setBulkIncidents(incidents)
		setIsDrawerOpen()
	}

	/*
    useEffect(() => {
        if (DocsQuantity === 0 && DocsToGenerate.length > 0) {
            dispatch(setClearDocs(''));
            getShipments(dayjs(dates.beginDate).format('YYYY-MM-DD').toString(), dayjs(dates.endDate).format('YYYY-MM-DD').toString());
            setIsCreatingDocs();
        }
    }, [DocsQuantity, DocsToGenerate])
*/
	useEffect(() => {
		return () => {
			dispatch(setClearDocs(''))
			dispatch(setClearDocumentsCreation(''))
			dispatch(setDocumentsQuantity(0))
		}
	}, [])

	return {
		shipmets,
		isLoadingShipmets,
		getShipments,
		dates,
		setDates,
		onSubmit,
		onChangeDates,
		movements,
		createDocuments,
		razonMov,
		onRazonChange,
		showToolbarFilter,
		setShowToolbarFilter,
		showResults,
		setShowResults,
		documentsCreation,
		DocsQuantity,
		isDrawerOpen,
		setIsDrawerOpen,
		bulkHasIncident,
		bulkIncidents,
		getbulkIncidents,
		includSeconds,
		onCheckSecondsChange,
		isCreatingDocs
	}
}
