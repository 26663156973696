import { ConfigurationIconBlack } from '@/assets'
import { Button, Title } from '@/shared/components/commons'
import { useSurtidoTiendasConfig } from '@/features/inventoryAssortment/hooks'
import { ConfigDiasTienda } from '@/features/inventoryAssortment/components'
import { useEffect } from 'react'

const SurtidoTiendasConfigDiasPage = () => {
	const { dataConfig, loading, lstPlaza, getConfiguration, onChangeConfig, saving, onSaveConfig } =
		useSurtidoTiendasConfig()

	useEffect(() => {
		getConfiguration()
	}, [])

	return (
		<>
			<div className='main-layout-toolbar toolbar'></div>
			<main className='main-layout-main p-4'>
				<Title
					title='Configuración de días para surtido de tiendas'
					pageName='Configuración'
					backTo='/reposicion-de-inventario/tiendas'
					backPage='Surtido de Tiendas'
					icon={ConfigurationIconBlack}
				/>
				<section
					className='overflow-auto '
					style={{
						height: 'calc(100% - 10rem)'
					}}
				>
					<div className='flex flex-row flex-nowrap items-end border-b-2 h-12'>
						<div className='diaSurtido tienda'>Tienda</div>
						<div className='diaSurtido'>Lunes</div>
						<div className='diaSurtido'>Martes</div>
						<div className='diaSurtido'>Miércoles</div>
						<div className='diaSurtido'>Jueves</div>
						<div className='diaSurtido'>Viernes</div>
						<div className='diaSurtido'>Sábado</div>
						<div className='diaSurtido'>Domingo</div>
						<div className='diaSurtido porcentajes'>
							<h6 className='text-center font-semibold'>Penetración</h6>
							<div className='w-full flex'>
								<span className='block flex-1 text-center'>Linea</span>
								<span className='block flex-1 text-center'>Moda</span>
								<span className='block flex-1 text-center'>Accesorios</span>
								<span className='block flex-1 text-center'>Otros</span>
							</div>
						</div>
					</div>
					{lstPlaza
						.sort((a, b) => a.name.localeCompare(b.name))
						.map((p) => (
							<div key={p.code}>
								<p className='py-2 font-bold'>{p.name}</p>
								{dataConfig
									.filter((t) => t.Location == p.code)
									.sort(
										(a, b) =>
											a.Maximizador - b.Maximizador || a.Descripcion.localeCompare(b.Descripcion)
									)
									.map((i) => (
										<ConfigDiasTienda key={i.Almacen} data={i} onChangeConfig={onChangeConfig} />
									))}
							</div>
						))}
				</section>

				<div className='p-5 flex justify-end absolute bottom-0 right-0 w-full items-center'>
					<Button
						type='button'
						className='btn primary w-40'
						isLoading={loading || saving}
						onClick={onSaveConfig}
					>
						Guardar
					</Button>
				</div>
			</main>
		</>
	)
}

export default SurtidoTiendasConfigDiasPage
