import { useState, useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'
import { portalProps } from './interface'
import createWrapper from './createWrapper'

const Portal = ({ children, wrapperId }: portalProps) => {
	const [wrapperElement, setWrapperElement] = useState<HTMLElement>()
	useLayoutEffect(() => {
		let element: HTMLElement | null = document.getElementById(wrapperId)
		let systemCreated = false
		if (!element) {
			element = createWrapper(wrapperId)
			systemCreated = true
		}
		setWrapperElement(element)

		return () => {
			if (systemCreated && element?.parentNode) {
				element.parentNode?.removeChild(element)
			}
		}
	}, [wrapperId])

	if (wrapperElement === undefined || wrapperElement === null) return null

	return createPortal(children, wrapperElement)
}

export default Portal
