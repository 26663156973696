import { CheckListSimpleProps } from '@/shared/components/checkList/types'
import { CheckListSimpleItem } from '@/shared/components/checkList/CheckListSimpleItem'
import useCheckListSimple from '@/shared/components/checkList/useCheckListSimple'

export const CheckListSimple = ({
	initialItems,
	onChangeCheck,
	height = 50,
	showSearchBox = false
}: CheckListSimpleProps) => {
	const { items, isChecked, search, onSearch, onListItemCheck, onselectAll } = useCheckListSimple({
		initialItems,
		onChangeCheck
	})

	return (
		<div className='flex flex-col space-y-4'>
			<div className={`flex ${showSearchBox ? 'justify-between' : 'justify-end'} `}>
				{showSearchBox && (
					<input
						type='text'
						className='w-50 text-sm rounded border border-gray-400 p-1 '
						placeholder='Buscar'
						value={search}
						onChange={onSearch}
					/>
				)}
				<label htmlFor={'Selected-all'} className='block text-xs font-bold '>
					<input id='Selected-all' type={'checkbox'} className='mr-1' onChange={onselectAll} />
					{'Seleccionar todo'}
				</label>
			</div>

			<div className={`overflow-auto flex flex-col`} style={{ height: height }}>
				{items
					?.filter((item) => item.description.toLowerCase().includes(search.toLowerCase()))
					.map((item) => (
						<CheckListSimpleItem
							key={`${item.code}-${item.description}`}
							{...item}
							isChecked={isChecked(item.code)}
							onChangeCheck={onListItemCheck}
						/>
					))}
			</div>
		</div>
	)
}
