import {
	ChevronDownIcon,
	XMarkIcon,
	ArrowRightOnRectangleIcon,
	UserIcon
} from '@heroicons/react/24/solid'
import { useToggle } from '../../hooks'
import { useAuth } from '@/features/auth/hooks'
import Portal from '../portal'

const Menu = () => {
	const [hidden, setHidden] = useToggle(true)
	const { userLogout } = useAuth()
	return (
		<>
			<button onClick={setHidden}>
				<ChevronDownIcon width={32} className='text-white' />
			</button>

			<Portal wrapperId='MENU_USER_CONTAINER'>
				<aside className={`header-menu ${hidden && 'hide'}`}>
					<section className='flex justify-between items-center'>
						<h5 className='text-xl font-semibold'>Opciones</h5>
						<button onClick={setHidden}>
							<XMarkIcon width={26} />
						</button>
					</section>

					<section className='mt-4 p-2'>
						<ul>
							<li>
								<a
									href='https://corpo.officialstore.net/usuario/perfil'
									className='flex w-full items-center h-10 rounded-md hover:bg-blue-500'
								>
									<UserIcon width={26} className='mr-2' />
									Perfil
								</a>
							</li>
							<li>
								<button
									className='flex w-full items-center h-10 rounded-md hover:bg-blue-500'
									onClick={userLogout}
								>
									<ArrowRightOnRectangleIcon width={26} className='mr-2' />
									<span>Cerrar sesion</span>
								</button>
							</li>
						</ul>
					</section>
				</aside>
			</Portal>
		</>
	)
}

export default Menu
