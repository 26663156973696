import { NavLink } from 'react-router-dom'
import { TitlePros } from './types'

const Title = ({ title, backTo, icon, backPage, pageName }: TitlePros) => {
	return (
		<div className='w-full h-16 shadow-md rounded-md p-2 mb-8 bg-slate-100'>
			<div className='flex items-center  w-full h-full'>
				{icon && (
					<figure className='w-10 h-10'>
						<img src={icon} alt={title} className='w-full h-full object-cover object-center' />
					</figure>
				)}
				<div className='ml-4'>
					<h2 className='text-xl text-black font-bold'>{title}</h2>
					<span className='text-sm'>
						<NavLink to={backTo} className='text-blue-700'>
							{backPage}
						</NavLink>{' '}
						/ {pageName}
					</span>
				</div>
			</div>
		</div>
	)
}

export default Title
