import { Routes, Route } from 'react-router-dom'
import {
	InventoryAssortmentPage,
	SurtidoTiendasConfigDiasPage,
	SurtidoTiendasPage,
	TraspasosTiendasPage,
	UploadFileTraspasosTiendasPage,
	UploadFileMinPage
} from '@/features/inventoryAssortment/pages'

const InventoryAssortmentRoutes = () => {
	return (
		<Routes>
			<Route path='/' element={<InventoryAssortmentPage />} />
			<Route path='/tiendas' element={<SurtidoTiendasPage />} />
			<Route path='/tiendas/configuracion' element={<SurtidoTiendasConfigDiasPage />} />
			<Route path='/traspasos-entre-tiendas' element={<TraspasosTiendasPage />} />
			<Route
				path='/traspasos-entre-tiendas/carga-archivo'
				element={<UploadFileTraspasosTiendasPage />}
			/>
			<Route path='/tiendas/carga-archivo-minimos' element={<UploadFileMinPage />} />
		</Routes>
	)
}

export default InventoryAssortmentRoutes
