import { useState } from 'react'
import axiosInstance from '@/app/services/axiosInstance.service'
import { useSelector, useDispatch } from 'react-redux'
import { useNotification, useToggle } from '@/shared/hooks'
import { getError } from '@/shared/helpers'
import { makeUseAxios } from 'axios-hooks'
import { env } from '@/env'
import { setData, setSelectedDocuments } from '../slices/PackingListDocumentsSlice'
import {
	useLoginMutation,
	useCreatePurchaseDeliveryNoteMutation,
	useCreateLandedCostMutation,
	useCreateStockTransferMutation,
	useCreateRevaluationMutation,
	useUpdateLandedCostMutation,
	useCreateStockTransferRequestMutation
} from '../service/serviceLayerService'
import { format } from 'date-fns'
import { getServiceLayerError } from '../helpers/getServiceLayerError'
import type {
	PriceList,
	IDocumentValues,
	Aduanas,
	IDeliveryNoteGroup,
	ILandedCostGroup,
	IMaterialRevaluationGroup,
	IAvgItemsPrice,
	ICostsItems,
	IStockTransferGroup,
	StockTransferType,
	IPackingData
} from '../types/packingList'
import type { RootState } from '@/app/store/createStore'
import type { PurchaseDeliveryLine } from '../types/purchaseDeliveryNotes'
import type { LandedCostItemLine, LandedCostLine } from '../types/landedCosts'
import type { StockTransferLines } from '../types/stockTransfer'
import type { Movements } from '../types/shippingList.types'
import type { MaterialRevaluationLine } from '../types/materialRevaluation'
import { DocumentBoTypes } from '../types/objectTypes'
import { error } from 'console'

export const usePackingList = () => {
	//Configure redux
	const dispatch = useDispatch()
	const { data, selectedDocuments } = useSelector((state: RootState) => state.packingListDocuments)

	//Configure axiosHooks
	const useAxios = makeUseAxios({
		axios: axiosInstance(process.env.REACT_APP_API_WMS ?? env.REACT_APP_API_WMS)
	})

	const notification = useNotification()
	const [packingId, setPackingId] = useState<string>('')
	const [isCreatingDocuments, setIsCreatingDocuments] = useToggle()

	const [{ data: priceList }] = useAxios<PriceList[]>({
		url: '/packing-list/documentos/lista-precios',
		method: 'GET'
	})
	const [{ loading: isFetchingPacking }, executeGetPacking] = useAxios<IPackingData>(
		{ method: 'GET' },
		{ manual: true }
	)
	const [, executeUpdateLog] = useAxios({ method: 'PUT' }, { manual: true })
	const [{ data: stockMovements }] = useAxios<Movements[]>({ url: '/listas-embarque/movimientos' })
	const [, executeGetAvgItemsPrice] = useAxios<IAvgItemsPrice[]>(
		{ url: '/packing-list/documentos/revalorizacion/precio-promedio', method: 'POST' },
		{ manual: true }
	)
	const [{ data: aduanas }] = useAxios<Aduanas[]>({ url: '/packing-list/aduanas', method: 'GET' })
	const [, executePostPedimento] = useAxios(
		{ url: '/packing-list/pedimento', method: 'POST' },
		{ manual: true }
	)
	const [, executeGetCostsItems] = useAxios<ICostsItems[]>({ method: 'GET' }, { manual: true })
	const [, executePostCostsItems] = useAxios(
		{ url: '/packing-list/documentos/costo-pt', method: 'POST' },
		{ manual: true }
	)

	//Endpoints de agrupacion de documentos
	const [, executeGetDeliveryNoteGroup] = useAxios<IDeliveryNoteGroup[]>(
		{ method: 'POST' },
		{ manual: true }
	)
	const [, executeGetLandedCostGroup] = useAxios<ILandedCostGroup[]>(
		{ method: 'POST' },
		{ manual: true }
	)
	const [, executeGetRevaluationGroup] = useAxios<IMaterialRevaluationGroup[]>(
		{ method: 'POST' },
		{ manual: true }
	)
	const [, executeGetTransferRequestGroup] = useAxios<IStockTransferGroup[]>(
		{ method: 'POST' },
		{ manual: true }
	)
	const [, executeGetTransferGroup] = useAxios<IStockTransferGroup[]>(
		{ method: 'POST' },
		{ manual: true }
	)

	//Endpoints de creacion de documentos
	const [executeLogin] = useLoginMutation()
	const [executeCreateDeliveryNote] = useCreatePurchaseDeliveryNoteMutation()
	const [executeCreateLandedCost] = useCreateLandedCostMutation()
	const [executeCreateStockTransfer] = useCreateStockTransferMutation()
	const [executeCreateRevaluation] = useCreateRevaluationMutation()
	const [executeUpdateLandedCost] = useUpdateLandedCostMutation()
	const [executeCreateStockTransferRequest] = useCreateStockTransferRequestMutation()

	//Obtiene la informacion del packing list
	const getPackingList = async (idPackingList: string) => {
		try {
			const { data } = await executeGetPacking({
				url: `/packing-list/documentos/${idPackingList}`,
				params: {
					company: env.REACT_APP_SAP_COMPANY_TEST ?? process.env.REACT_APP_SAP_COMPANY_TEST
				}
			})
			dispatch(setSelectedDocuments([]))
			dispatch(setData(data))
		} catch (error) {
			notification.error(getError(error))
		}
	}

	//Actauliza los logs de los documentos
	const updateLogs = async (ids: number[], message: string, docNumber: number, status: number) => {
		for await (const id of ids) {
			await executeUpdateLog({
				url: `/packing-list/documentos/doc-generado/${id}`,
				data: {
					DocEntry: docNumber,
					Estatus: status,
					ErrorMessage: message,
					Empresa: env.REACT_APP_SAP_COMPANY_TEST ?? process.env.REACT_APP_SAP_COMPANY_TEST
				}
			})
		}
	}

	//Obtiene los id de los documentos seleccionados segun el tipo
	const getDocumentTypeFromSelectedDocument = (type: DocumentBoTypes) => {
		return selectedDocuments.filter((doc) => doc.type === type).flatMap((flat) => flat.id)
	}

	//Crea la entrada de mercancias
	const createDeliveryNote = async (
		doc: IDeliveryNoteGroup,
		docDate: string,
		taxDate: string,
		comments: string
	) => {
		const lines: PurchaseDeliveryLine[] = doc.Lineas.map((line) => ({
			ItemCode: line.ItemCode,
			Quantity: line.Quantity,
			WarehouseCode: doc.Almacen,
			UnitPrice: line.Price,
			TaxCode: line.TaxCode,
			BaseEntry: line.BaseEntry,
			BaseLine: line.BaseLine,
			BaseType: line.BaseType
		}))
		return executeCreateDeliveryNote({
			DocDate: format(new Date(docDate), 'yyyy-MM-dd'),
			TaxDate: format(new Date(taxDate), 'yyyy-MM-dd'),
			U_BYS_CodeDocBaseStr: doc.BaseCode,
			JournalMemo: `Creado desde WMS PL ${packingId}`,
			Comments: `Creado desde WMS PL ${comments}`,
			CardCode: doc.VendorCode,
			U_U_BYS_EnvioD: doc.Localidad.toUpperCase().includes('MATRIZ') ? 'N' : 'Y',
			U_BYS_Destino: doc.Localidad.toUpperCase().includes('MATRIZ') ? doc.Localidad : undefined,
			DocumentLines: lines
		})
			.unwrap()
			.then(async (data) => {
				await updateLogs(
					doc.IdDocumentos,
					`No.Documento generado ${data.DocEntry}`,
					data.DocEntry,
					2
				)
				return true
			})
			.catch(async (error) => {
				const errorMessage = getServiceLayerError(error)
				await updateLogs(doc.IdDocumentos, errorMessage, 0, 3)
				notification.error(errorMessage)
				return false
			})
	}

	//Crea los precios de entrega
	const createLandedCost = async (
		doc: ILandedCostGroup,
		pedimentCode: string,
		pedimentDate: string,
		aduana: string,
		comments: string,
		vendorName: string
	) => {
		const lines: LandedCostItemLine[] = doc.Lineas.map((line) => ({
			Number: line.ItemCode,
			BaseEntry: line.BaseEntry,
			BaseDocumentType: line.BaseDocumentType,
			Warehouse: line.Warehouse,
			BaseLine: line.BaseLine
		}))
		const allocationCost = doc.Lineas.flatMap((line) => line.Quantity).reduce(
			(acc, cur) => acc + cur,
			0
		)
		const journalMemo = `${vendorName} ${pedimentCode}`
		const costLines: LandedCostLine[] = doc.Costos.map((cost) => ({
			LandedCostCode: cost.Codigo,
			Amount: allocationCost * cost.Costo,
			CostType: 'asFixedCosts',
			AllocationBy: 'asQuantity'
		}))
		return executeCreateLandedCost({
			VendorCode: doc.VendorCode,
			U_BYS_CodeDocBaseStr: doc.BaseCode,
			LandedCost_CostLines: costLines,
			LandedCost_ItemLines: lines,
			Remarks: `Creado desde WMS PL ${packingId}. ${comments}`,
			Reference: `PED ${pedimentCode.slice(-7)}`,
			JournalRemarks: journalMemo.slice(-50)
		})
			.unwrap()
			.then(async (data) => {
				if (pedimentCode) {
					await executePostPedimento({
						data: {
							DocOrigen: data.LandedCostNumber.toString(),
							DocEntry: data.DocEntry.toString(),
							Fecha: format(new Date(pedimentDate), 'dd/MM/YYY'),
							Aduana: aduana,
							Pedimento: pedimentCode,
							Sociedad: env.REACT_APP_SAP_COMPANY_TEST ?? process.env.REACT_APP_SAP_COMPANY_TEST
						}
					}).catch((error) => {
						const errorMessage = getError(error)
						notification.error(errorMessage)
					})
				}
				await updateLogs(
					doc.IdDocumentos,
					`No.Documento generado ${data.DocEntry}`,
					data.DocEntry,
					2
				)
				return true
			})
			.catch(async (error) => {
				const errorMessage = getServiceLayerError(error)
				await updateLogs(doc.IdDocumentos, errorMessage, 0, 3)
				notification.error(errorMessage)
				return false
			})
	}

	/*
	* Obtiene los precios promedios  de los articulos
	* Esta operacion ya no es necesaria por que el precio ya tiene el costro agregado
	const searchAvgPriceForItem = (itemCode: string, avgPriceItems: IAvgItemsPrice[]) => {
		const itemFromAvgList = avgPriceItems.find((item) => item.itemCode === itemCode)
		if (!itemFromAvgList) {
			return 0
		}
		return itemFromAvgList.precio
	}
	*/

	//Crea una revalorizacion de material
	const createMaterialRevaluation = async (
		doc: IMaterialRevaluationGroup,
		docDate: string,
		taxDate: string,
		user: string
	) => {
		if (!doc.Valido) {
			notification.error('El documento no es valido para reavalorizacion')
			return false
		}

		const itemCodeList = doc.Lineas.flatMap((item) => item.ItemCode)
		const whsCode = doc.Lineas[0].WarehouseCode

		const { data: avgItemsPrice } = await executeGetAvgItemsPrice({
			data: { itemCodeList, whsCode }
		})

		if (!avgItemsPrice) {
			notification.error('Los articulos no tienen precio promedio')
			return false
		}

		const itemCodesInAvgPrices = avgItemsPrice.flatMap((item) => item.itemCode)
		const isSomeItemCodeMissing = doc.Lineas.find(
			(line) => !itemCodesInAvgPrices.includes(line.ItemCode)
		)

		if (isSomeItemCodeMissing) {
			notification.error(`El articulo ${isSomeItemCodeMissing.ItemCode} no tiene precio promedio`)
			return false
		}

		const lines: MaterialRevaluationLine[] = doc.Lineas.map((line) => ({
			ItemCode: line.ItemCode,
			WarehouseCode: line.WarehouseCode,
			RevaluationIncrementAccount: line.RevaluationIncrementAccount,
			RevaluationDecrementAccount: line.RevaluationDecrementAccount,
			Price: line.Price
		}))

		return executeCreateRevaluation({
			DocDate: format(new Date(docDate), 'yyyy-MM-dd'),
			TaxDate: format(new Date(taxDate), 'yyyy-MM-dd'),
			RevalType: 'P',
			RevaluationExpenseAccount: doc.CostRvlAct ?? undefined,
			RevaluationIncomeAccount: doc.CostRvlAct ?? undefined,
			U_BYS_CodeDocBaseStr: doc.BaseCode,
			MaterialRevaluationLines: lines
		})
			.unwrap()
			.then(async (data) => {
				const { data: costsItems } = await executeGetCostsItems({
					url: `/packing-list/documentos/costo-pt/${data.U_BYS_CodeDocBaseStr}`
				})
				if (costsItems) {
					for await (const cost of costsItems) {
						await executePostCostsItems({
							data: {
								NumLinea: cost.NumLinea,
								Articulo: cost.Articulo,
								Almacen: cost.Almacen,
								Stock: cost.Stock,
								Cuenta: cost.Cuenta,
								ItemCodeFase: cost.ItemCodeFase,
								Modelo: cost.Modelo,
								IDGrupoFase: cost.IDGrupoFase.toString(),
								Grupofase: cost.GrupoFase,
								FaseCode: cost.FaseCode.toString(),
								NombreFase: cost.NombreFase,
								Factor: cost.Factor,
								Importe: cost.Importe,
								Total: cost.Total,
								NumRevInv: cost.NumRevInv,
								NumPoliza: cost.NumPoliza,
								Fecha: cost.Fecha,
								Hora: cost.Hora,
								Usuario: user,
								Empresa: env.REACT_APP_SAP_COMPANY_TEST ?? process.env.REACT_APP_SAP_COMPANY_TEST
							}
						}).catch((error) => {
							const errorMessage = getError(error)
							notification.error(errorMessage)
						})
					}
				}
				await updateLogs(
					doc.IdDocumentos,
					`No.Documento Generado ${data.DocEntry}`,
					data.DocEntry,
					2
				)
			})
			.catch(async (error) => {
				const errorMessage = getServiceLayerError(error)
				await updateLogs(doc.IdDocumentos, errorMessage, 0, 3)
				notification.error(errorMessage)
			})
	}

	//Crea una transferencia de stock o una solicitud de traslado
	const createStockTransfer = async (
		doc: IStockTransferGroup,
		type: StockTransferType,
		docDate: string,
		taxDate: string,
		priceList: number,
		moveStock: string,
		comments?: string
	) => {
		const lines: StockTransferLines[] = doc.Linea.map((line) => ({
			ItemCode: line.ItemCode,
			Quantity: line.Quantity,
			WarehouseCode: doc.AlmacenDestino,
			BaseType: type === 'stockTransferFromRequest' ? 'InventoryTransferRequest' : undefined,
			BaseLine: type === 'stockTransferFromRequest' ? line.BaseLine : undefined,
			BaseEntry: type === 'stockTransferFromRequest' ? line.BaseEntry : undefined
		}))

		if (type === 'stockTransferRequest') {
			return executeCreateStockTransferRequest({
				DocDate: format(new Date(docDate), 'yyyy-MM-dd'),
				TaxDate: format(new Date(taxDate), 'yyyy-MM-dd'),
				PriceList: priceList,
				U_GSP_MOVESTOCK: moveStock,
				U_GSP_BYSMOVESTOCK: moveStock,
				U_U_GSP_MOVESTOCK: moveStock,
				FromWarehouse: doc.AlmacenOrigen,
				ToWarehouse: doc.AlmacenDestino,
				U_BYS_CodeDocBaseStr: doc.BaseCode,
				CardCode: doc.CardCode,
				StockTransferLines: lines,
				Comments: `Creado desde WMS PL ${packingId}. ${comments}`
			})
				.unwrap()
				.then(async (data) => {
					await updateLogs(
						doc.IdDocumentos,
						`No.Documento Generado ${data.DocEntry}`,
						data.DocEntry,
						2
					)
				})
				.catch(async (error) => {
					const errorMessage = getServiceLayerError(error)
					await updateLogs(doc.IdDocumentos, errorMessage, 0, 3)
					notification.error(errorMessage)
				})
		} else {
			return executeCreateStockTransfer({
				DocDate: format(new Date(docDate), 'yyyy-MM-dd'),
				TaxDate: format(new Date(taxDate), 'yyyy-MM-dd'),
				PriceList: priceList,
				U_GSP_MOVESTOCK: moveStock,
				U_GSP_BYSMOVESTOCK: moveStock,
				U_U_GSP_MOVESTOCK: moveStock,
				FromWarehouse: doc.AlmacenOrigen,
				ToWarehouse: doc.AlmacenDestino,
				U_BYS_CodeDocBaseStr: doc.BaseCode,
				CardCode: doc.CardCode,
				StockTransferLines: lines,
				Comments: `Creado desde WMS PL ${packingId}. ${comments}`
			})
				.unwrap()
				.then(async (data) => {
					await updateLogs(
						doc.IdDocumentos,
						`No.Documento Generado ${data.DocEntry}`,
						data.DocEntry,
						2
					)
				})
				.catch(async (error) => {
					const errorMessage = getServiceLayerError(error)
					await updateLogs(doc.IdDocumentos, errorMessage, 0, 3)
					notification.error(errorMessage)
				})
		}
	}

	const createDocuments = async (values: IDocumentValues) => {
		//Si no hay lienas seleccionadas se salta el proceso
		if (!selectedDocuments || selectedDocuments.length === 0) {
			notification.error('No hay lineas seleccionadas para procesar')
			return
		}
		//Checa si no hay otro proceso creado documentos
		if (isCreatingDocuments) {
			notification.error('Ya hay un proceso creando documentos')
			return
		}

		//Pone el sistema en modo generacion
		setIsCreatingDocuments()

		//Incia sesion en el service layer
		executeLogin({
			UserName: values.UserName,
			Password: values.Password,
			CompanyDB: values.CompanyDB
		})
			.unwrap()
			.then(async () => {
				//Obtiene y clasifica todos los docuemntos para generar
				const deliveryNotes = getDocumentTypeFromSelectedDocument(DocumentBoTypes.DeliveryNote)
				const landedCosts = getDocumentTypeFromSelectedDocument(DocumentBoTypes.LandadCost)
				const revaluationMaterial = getDocumentTypeFromSelectedDocument(
					DocumentBoTypes.MaterialRevaluation
				)
				const transferToAssortment = getDocumentTypeFromSelectedDocument(
					DocumentBoTypes.AssortmentTransfer
				)
				const firstRequestTransfer = getDocumentTypeFromSelectedDocument(
					DocumentBoTypes.FirstRequestTransfer
				)
				const secondRequestTransfer = getDocumentTypeFromSelectedDocument(
					DocumentBoTypes.SecondRequestTransfer
				)
				const firstTransferFromRequest = getDocumentTypeFromSelectedDocument(
					DocumentBoTypes.FirstTransferFromRequest
				)
				const secondTransferFromRequest = getDocumentTypeFromSelectedDocument(
					DocumentBoTypes.SecondTransferFromRequest
				)
				const vendorName = data?.Proveedor ?? ''

				//Obtiene los datos y genenera las notas de entrega
				if (deliveryNotes.length > 0) {
					await executeGetDeliveryNoteGroup({
						url: `/agrupacion/entrada-mercancia/${packingId}/`,
						data: {
							documentos: deliveryNotes
						}
					})
						.then(async ({ data }) => {
							for await (const doc of data) {
								await createDeliveryNote(
									doc,
									values.deliveryNoteDocDate,
									values.deliveryNoteTaxDate,
									values.deliveryNoteRemarks
								)
							}
						})
						.catch((error) => {
							const errorMessage = getError(error)
							notification.error(errorMessage)
						})
				}

				//Obtiene los datos y genera los precios de entrega
				if (landedCosts.length > 0) {
					await executeGetLandedCostGroup({
						url: `/agrupacion/precios-entrega/${packingId}`,
						data: { documentos: landedCosts }
					})
						.then(async ({ data }) => {
							for await (const doc of data) {
								await createLandedCost(
									doc,
									values.pedimentCode,
									values.pedimentDate,
									values.aduana,
									values.landedCostRemarks,
									vendorName
								)
							}
						})
						.catch((error) => {
							const errorMessage = getError(error)
							notification.error(errorMessage)
						})
				}

				//Obtiene los datos y genera la revalorizacion de documentos
				if (revaluationMaterial.length > 0) {
					await executeGetRevaluationGroup({
						url: `/agrupacion/revalorizacion/${packingId}`,
						data: {
							documentos: revaluationMaterial
						}
					})
						.then(async ({ data }) => {
							for await (const doc of data) {
								await createMaterialRevaluation(
									doc,
									values.revaluationDocDate,
									values.revaluationTaxDate,
									values.UserName
								)
							}
						})
						.catch((error) => {
							const errorMessage = getError(error)
							notification.error(errorMessage)
						})
				}

				//Obtiene los datos y genera la transferencia a surtido
				if (transferToAssortment.length > 0) {
					await executeGetTransferGroup({
						url: `/agrupacion/transferencias/${packingId}`,
						data: {
							documentos: transferToAssortment,
							tipo: 'surtido'
						}
					})
						.then(async ({ data }) => {
							for await (const doc of data) {
								await createStockTransfer(
									doc,
									'stockTransfer',
									values.transferAssortmentDocDate,
									values.transferAssortmentTaxDate,
									values.priceList,
									values.transferAssortmentMovement
								)
							}
						})
						.catch((error) => {
							const errorMessage = getError(error)
							notification.error(errorMessage)
						})
				}

				//Obtiene los datos y genera la solicitud de traslado 1
				if (firstRequestTransfer.length > 0) {
					await executeGetTransferRequestGroup({
						url: `/agrupacion/solicitud-traslado/${packingId}`,
						data: {
							documentos: firstRequestTransfer,
							tipo: 'puente'
						}
					})
						.then(async ({ data }) => {
							for await (const doc of data) {
								await createStockTransfer(
									doc,
									'stockTransferRequest',
									values.transferAssortmentDocDate,
									values.transferAssortmentTaxDate,
									values.priceList,
									values.transferAssortmentMovement
								)
							}
						})
						.catch((error) => {
							const errorMessage = getError(error)
							notification.error(errorMessage)
						})
				}

				//Obtiene los datos y genera ele traslado para la solicitud 1
				if (firstTransferFromRequest.length > 0) {
					await executeGetTransferGroup({
						url: `/agrupacion/transferencias/${packingId}`,
						data: {
							documentos: firstTransferFromRequest,
							tipo: 'puente'
						}
					})
						.then(async ({ data }) => {
							for await (const doc of data) {
								await createStockTransfer(
									doc,
									'stockTransferFromRequest',
									values.transferAssortmentDocDate,
									values.transferAssortmentTaxDate,
									values.priceList,
									values.transferAssortmentMovement
								)
							}
						})
						.catch((error) => {
							const errorMessage = getError(error)
							notification.error(errorMessage)
						})
				}

				//Obtiene los datos y genera la solicitud de traslado 2
				if (secondRequestTransfer.length > 0) {
					await executeGetTransferRequestGroup({
						url: `/agrupacion/solicitud-traslado/${packingId}`,
						data: {
							documentos: secondRequestTransfer,
							tipo: 'plaza'
						}
					})
						.then(async ({ data }) => {
							for await (const doc of data) {
								await createStockTransfer(
									doc,
									'stockTransferRequest',
									values.transferAssortmentDocDate,
									values.transferAssortmentTaxDate,
									values.priceList,
									values.transferAssortmentMovement
								)
							}
						})
						.catch((error) => {
							const errorMessage = getError(error)
							notification.error(errorMessage)
						})
				}

				//Obtiene los datos y genera ele traslado para la solicitud 2
				if (secondTransferFromRequest.length > 0) {
					await executeGetTransferGroup({
						url: `/agrupacion/transferencias/${packingId}`,
						data: {
							documentos: secondTransferFromRequest,
							tipo: 'plaza'
						}
					})
						.then(async ({ data }) => {
							for await (const doc of data) {
								await createStockTransfer(
									doc,
									'stockTransferFromRequest',
									values.transferAssortmentDocDate,
									values.transferAssortmentTaxDate,
									values.priceList,
									values.transferAssortmentMovement
								)
							}
						})
						.catch((error) => {
							const errorMessage = getError(error)
							notification.error(errorMessage)
						})
				}
			})
			.catch((error) => {
				const errorMessage = getServiceLayerError(error)
				notification.error(errorMessage)
			})
			.finally(async () => {
				await getPackingList(packingId)
				setIsCreatingDocuments()
			})
	}

	return {
		priceList,
		data,
		getPackingList,
		setPackingId,
		packingId,
		isFetchingPacking,
		createDocuments,
		stockMovements,
		isCreatingDocuments,
		aduanas
	}
}
