import { ChangeEvent, useEffect, useState, useRef } from 'react'
import { Items, UseCheckListProps } from './types'

const useCheckList = ({
  initialItems,
  enableRendered,
  onCheckChange,
  onDefaultValuesChange
}: UseCheckListProps) => {
  const [items, setItems] = useState<Items[]>([])
  const [values, setValues] = useState<string[]>([])
  const allowRender = useRef(enableRendered)
  const isMount = useRef(false)
  const isfinallyMount = useRef(false)

  useEffect(() => {
    if (initialItems) {
      if (items.length > 0 && !allowRender.current) return
      setItems(initialItems)
      listDefaultChecked(initialItems)
      isMount.current = true
    }
  }, [initialItems])

  useEffect(() => {
    if (items && isMount.current) {
      onCheckChange(values)
    }
    if (items && values.length > 0 && !isfinallyMount.current) {
      if (onDefaultValuesChange) onDefaultValuesChange!(values)
      isfinallyMount.current = true
    }
  }, [values])

  const onListItemCheck = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = evt.target
    if (checked) {
      setValues((prev) => [...prev, value])
    } else {
      setValues((prev) => prev.filter((val) => val !== value))
    }
  }

  const onAllListCheck = (evt: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = evt.target
    const dataOnList = items.find((list) => list.id === id)

    if (dataOnList && dataOnList.itemList) {
      const listValues = dataOnList.itemList?.flatMap((item) => item.value)
      if (checked) {
        setValues((prev) => [...prev, ...listValues])
      } else {
        setValues((prev) => prev.filter((val) => !listValues.includes(val)))
      }
    }
  }

  const itemDefaultChecked = (initial: Items[]) => {
    const defaultValues = initial.flatMap(
      (items) => items.itemList?.filter((list) => list?.defaultChecked === false)
    )
    if (!defaultValues) return []
    const listValues = defaultValues.flatMap((list) => list?.value)
    if (listValues) return listValues
    return []
  }

  const listDefaultChecked = (initial: Items[]) => {
    const itemsDefault = itemDefaultChecked(initial)
    const dataOnList: Items[] = initial.filter((item) => item.defaultChecked)
    if (dataOnList) {
      const defaultValues = dataOnList.flatMap(
        (item) => item.itemList?.flatMap((list) => list.value)
      )
      const filterData = defaultValues?.filter((list) => list && !itemsDefault.includes(list))
      if (filterData) {
        setValues(filterData as string[])
      }
    } else if (itemsDefault) {
      setValues(itemsDefault as string[])
    }
  }

  const isChecked = (val: string) => {
    if (values) {
      return values.includes(val)
    }
    return false
  }

  const isListChecked = (id: string) => {
    if (values) {
      const dataOnList = items.find((item) => item.id === id)
      const listHasChecked = dataOnList?.itemList?.some((item) => values.includes(item.value))
      if (listHasChecked) return true
      return false
    }
    return false
  }

  const onselectAll = (evt: ChangeEvent<HTMLInputElement>) => {
    const { checked } = evt.target
    if (items) {
      if (checked) {
        const allData = items.flatMap((item) => item.itemList?.flatMap((list) => list.value))
        if (allData) {
          setValues(allData as string[])
        }
      } else {
        setValues([])
      }
    }
  }

  return { items, onListItemCheck, onAllListCheck, isChecked, isListChecked, onselectAll }
}

export default useCheckList
