import { DefaultTemplate } from '@/shared/templates'
import { Title, DashLink } from '@/shared/components/commons'
import { deliveryguyAltIcon, unpackingIcon, containerIcon, documentBoxIcon } from '@/assets'

const GoodsReceiptPage = () => {
	return (
		<DefaultTemplate>
			<Title
				backPage='Dashboard'
				backTo='/Dashboard'
				title='Generación de entradas de mercancias'
				pageName='Entradas'
				icon={deliveryguyAltIcon}
			/>

			<section className='mt-8 grid place-items-center'>
				<ul className='space-y-4'>
					<DashLink
						title='PACKING LIST'
						description='Envíos a CEDIS y directos a plaza'
						icon={unpackingIcon}
						primaryColor='bg-[#4493DC]'
						secondaryColor='bg-[#4493DC]'
						to='/entrada-de-mercancias/packing'
					/>

					<DashLink
						title='LISTA DE EMBARQUE'
						description='Envíos a CEDIS a plazas'
						icon={containerIcon}
						primaryColor='bg-blue-gray'
						secondaryColor='bg-blue-gray'
						to='/entrada-de-mercancias/embarques'
					/>

					<DashLink
						title='INFORMES'
						description='Reportes disponibles'
						icon={documentBoxIcon}
						primaryColor='bg-blue-gray'
						secondaryColor='bg-blue-gray'
						to=''
					/>
				</ul>
			</section>
		</DefaultTemplate>
	)
}

export default GoodsReceiptPage
