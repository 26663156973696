import { useEffect, useState, useRef, ChangeEvent } from 'react'
import { ListItemProp, UseCheckListSimpleProps } from './types'

const useCheckListSimple = ({
	initialItems,
	onChangeCheck,
	enableRendered,
	onDefaultValuesChange
}: UseCheckListSimpleProps) => {
	const [items, setItems] = useState<ListItemProp[]>([])
	const [values, setValues] = useState<string[]>([])
	const allowRender = useRef(enableRendered)
	const isMount = useRef(false)
	const isfinallyMount = useRef(false)
	const [search, setSearch] = useState('')

	const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value)
	}

	useEffect(() => {
		if (initialItems) {
			if (items.length > 0 && !allowRender.current) return
			setItems(initialItems)
			listDefaultChecked(initialItems)
			isMount.current = true
		}
	}, [initialItems, items.length])

	useEffect(() => {
		if (items && isMount.current) {
			onChangeCheck(values)
		}
		if (items && values.length > 0 && !isfinallyMount.current) {
			if (onDefaultValuesChange) onDefaultValuesChange!(values)
			isfinallyMount.current = true
		}
	}, [values.length])

	const listDefaultChecked = (initial: ListItemProp[]) => {
		const dataOnList: ListItemProp[] = initial.filter((item) => item.defaultChecked)
		if (dataOnList) {
			const defaultValues = dataOnList.flatMap((item) => item.code)
			if (defaultValues) {
				setValues(defaultValues)
			}
		} else {
			setValues([])
		}
	}

	const onListItemCheck = (evt: ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = evt.target
		if (checked) {
			setValues((prev) => [...prev, value])
		} else {
			setValues((prev) => prev.filter((val) => val !== value))
		}
	}

	const onselectAll = (evt: ChangeEvent<HTMLInputElement>) => {
		const { checked } = evt.target
		if (items) {
			if (checked) {
				const allData = items
					.filter((item) => item.description.toLowerCase().includes(search.toLowerCase()))
					.flatMap((item) => item.code)
				if (allData) setValues(allData)
			} else {
				setValues([])
			}
		}
	}

	const isChecked = (val: string) => {
		if (values) {
			return values.includes(val)
		}
		return false
	}

	return { items, values, isChecked, search, onSearch, onListItemCheck, onselectAll }
}

export default useCheckListSimple
