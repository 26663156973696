import { useToggle } from '@/shared/hooks'
import { subMenuTypes } from '@/shared/components/commons/types'
import { triangleIcon } from '@/assets'
import SubLinks from '@/shared/components/commons/SubLinks'

const SubMenu = ({ title, close, items }: subMenuTypes) => {
	const [active, setActive] = useToggle()

	return (
		<li>
			<button className='flex items-center' onClick={setActive}>
				<img
					src={triangleIcon}
					className={`w-4 h-4 object-center mr-2 transform  transition-transform duration-100 ease-in-out ${
						active ? ' rotate-0' : '-rotate-90'
					}`}
					alt='icon'
				/>
				{title}
			</button>
			<ul className={`submenuItem ${active ? 'block' : 'hidden'}`}>
				{items.map((item) => (
					<SubLinks key={title + '_' + item.title} close={close} {...item} />
				))}
			</ul>
		</li>
	)
}
export default SubMenu
