import { XMarkIcon } from '@heroicons/react/24/outline'
import { asideModalTypes } from './types'
import Portal from '../portal'

const AsideModal = ({
	isOpen,
	title = '',
	children,
	className,
	closeButtonElement,
	classNameButton,
	handleClose
}: asideModalTypes) => {
	return (
		<>
			<button onClick={handleClose} className={classNameButton}>
				{closeButtonElement}
			</button>

			<Portal wrapperId='FILTER_STOCK'>
				<aside className={`aside-modal ${!isOpen ? 'hide' : ''} ${className}`}>
					<div className='flex justify-between items-center h-12 '>
						<span className='font-bold text-xl'>{title}</span>
						<button onClick={handleClose}>
							<XMarkIcon width={28} />
						</button>
					</div>
					<div className=' overflow-auto flex-1'>{children}</div>
				</aside>
			</Portal>
		</>
	)
}

export default AsideModal
