import { SAPUser } from '../types/SAPUser.types'
import axios from 'axios'
import { env } from '@/env'

export const useAuthSAP = () => {
  const baseUrl = process.env.REACT_APP_SERVICE_LAYER ?? env.REACT_APP_SERVICE_LAYER

  /**
   * Incia sesion en SAP.
   * La sesion se verifica por una cookie de HTTP
   * @param args
   * @returns
   */
  const login = async (args: SAPUser) => {
    try {
      const { data } = await axios.post(baseUrl + '/Login', args, { withCredentials: true })
      return data
    } catch (error) {
      throw error
    }
  }

  /**
   * Cerrar sesion de SAP.
   * Por defecto SAP mantiene la sesion por 30 minutos, este parametro es customizable en las propiedades de service layer
   * @returns
   */
  const logout = async () => {
    try {
      const { data } = await axios.post(baseUrl + '/Logout', '', { withCredentials: true })
      return data
    } catch (error) {
      throw error
    }
  }

  return {
    login,
    logout
  }
}
