import { useToggle } from '@/shared/hooks'
import { logoBrand } from '@/assets'
import { LoginForm, RecoveryPasswordForm } from '../components'
import { useAuth } from '../hooks'

const LoginPage = () => {
	const [showLoginForm, setShowLoginForm] = useToggle(true)
	const { userLogin, isFetchLogin, requestNewPassword, isSendingEmail } = useAuth()
	return (
		<div className={'w-full h-screen grid place-items-center bg-cyan-600 relative'}>
			<section
				className={
					'w-5/6  sm:w-4/12   xl:w-2/12  p-4 shadow-sm shadow-slate-800 bg bg-gray-50 rounded-md'
				}
			>
				<figure className={'w-full h-36 flex justify-center'}>
					<img
						src={logoBrand}
						alt={'Company logo'}
						className={'object-cover object-center w-36 h-36'}
					/>
				</figure>
				<div className={'mb-6'}>
					{showLoginForm ? (
						<LoginForm onSubmit={userLogin} isLoading={isFetchLogin} />
					) : (
						<RecoveryPasswordForm onSubmit={requestNewPassword} isLoading={isSendingEmail} />
					)}
				</div>
				<button className='btn cuartiary w-full' onClick={setShowLoginForm}>
					{showLoginForm ? 'Olvide mi contraseña' : 'Regresar'}
				</button>
			</section>
			<button
				className='xl:hidden btn primary w-20 absolute top-9 right-9'
				onClick={() => window.location.reload()}
			>
				Refrescar
			</button>
		</div>
	)
}

export default LoginPage
