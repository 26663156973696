import { ChangeEvent, useState, useRef, useCallback } from 'react'
import { read, utils } from 'xlsx'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useNotification, useToggle } from '@/shared/hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { makeUseAxios } from 'axios-hooks'
import { env } from '@/env'
import type { IXlsxMinFile, MaximumRequest, MinimumRequest } from '../types/SurtidoTiendas.types'
import { getError } from '@/shared/helpers'

const useUploadFileMin = () => {
	const [data, setData] = useState<Array<IXlsxMinFile>>([])
	const [isMaximumsCheck, setIsMaximumsCheck] = useState(false)
	const notification = useNotification()
	const intputRef = useRef<HTMLInputElement>(null)
	dayjs.extend(customParseFormat)

	const useAxios = makeUseAxios({
		axios: axiosInstance(process.env.REACT_APP_API_WMS ?? env.REACT_APP_API_WMS)
	})

	const [{ loading: loadingUpdateDate }, executeUploadMinus] = useAxios(
		{ url: '/asistente-reposicion/minimos', method: 'post' },
		{ manual: true }
	)

	const [{ loading: loadingUpdateMaximums }, executeUploadsMaximums] = useAxios(
		{ url: '/asistente-reposicion/maximos', method: 'POST' },
		{ manual: true }
	)

	/**
	 * Obtiene el archivo  xlxs y lee cada una de las columnas
	 * @param e
	 */
	const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target
		if (files) {
			const buffer = await files[0].arrayBuffer()
			const wb = read(buffer)
			const ws = wb.Sheets[wb.SheetNames[0]]
			const rows = utils.sheet_to_json<IXlsxMinFile>(ws, { raw: false })
			if (rows.length) {
				for await (const row of rows) {
					const result = await validateRowData(row)
						.then((data) => {
							setData((prev) => [...prev, data])
							return true
						})
						.catch((error) => {
							notification.error(error)
							setData([])
							e.target.value = ''
							return false
						})
					if (!result) break
				}
			}
		}
	}
	/**
	 * Valida el contenido de cada columna del excel
	 * @param row
	 * @returns
	 */
	const validateRowData = (row: IXlsxMinFile): Promise<IXlsxMinFile> => {
		return new Promise((resolve, reject) => {
			if (!row.whscode) {
				reject('No se encuentra el codigo de la tienda')
			}

			if (!row.itemcode) {
				reject('No se encuentra el codigo de articulo')
			}
			if (!row.valor && isNaN(Number(row.valor))) {
				reject('La cantidad valor no es numérica')
			}

			if (!row.finicio || !dayjs(row.finicio).isValid()) {
				reject('El formato de fecha inicial no cumple con el formato DD/MM/YYYY')
			}

			if (!row.ffin || !dayjs(row.ffin).isValid()) {
				reject('El formato de fecha final no cumple con el formato DD/MM/YYYY')
			}

			const xlxsRow: IXlsxMinFile = {
				whscode: row.whscode,
				itemcode: row.itemcode,
				valor: row.valor,
				finicio: dayjs(row.finicio).format('YYYY-MM-DD'),
				ffin: dayjs(row.ffin).format('YYYY-MM-DD')
			}
			resolve(xlxsRow)
		})
	}

	/**
	 * Sube la informacion
	 * @returns
	 */
	const uploadFileData = useCallback(async () => {
		if (!data.length) {
			notification.warning('No se puede')
			return
		}

		if (isMaximumsCheck) {
			const maximumData: Array<MaximumRequest> = data.map((item) => ({
				itemcode: item.itemcode,
				whscode: item.whscode,
				ffin: item.ffin,
				finicio: item.finicio,
				maximo: item.valor
			}))
			await executeUploadsMaximums({ data: { maximos: maximumData } })
				.then(() => {
					notification.success('Carga de maximos realizado correctamente')
					if (intputRef.current) intputRef.current.value = ''
					setData([])
				})
				.catch((error) => {
					notification.error(`No fue posible realizar la carga. Error: ${getError(error)}`)
				})
		} else {
			const minimumData: Array<MinimumRequest> = data.map((item) => ({
				itemcode: item.itemcode,
				whscode: item.whscode,
				ffin: item.ffin,
				finicio: item.finicio,
				minimo: item.valor
			}))
			await executeUploadMinus({ data: { minimos: minimumData } })
				.then(() => {
					notification.success('Carga de minimos realizado correctamente')
					if (intputRef.current) intputRef.current.value = ''
					setData([])
				})
				.catch((error) => {
					notification.error(`No fue posible realizar la carga. Error: ${getError(error)}`)
				})
		}
	}, [data, isMaximumsCheck])

	const onChangeChecked = ({ target }: ChangeEvent<HTMLInputElement>) => {
		setIsMaximumsCheck(target.checked)
	}

	return {
		data,
		loadingUpdateDate,
		loadingUpdateMaximums,
		intputRef,
		isMaximumsCheck,
		onChange,
		uploadFileData,
		onChangeChecked
	}
}

export default useUploadFileMin
