import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {
  IShippingReceiptSlice,
  ShipmentDocs,
  ShipmentIncidentsTypes,
  ShipmentLines,
  ShipmentStatus
} from '../types/shippingList.types'

type SetShipment = {
  shipmentId: number
  shipmentDocuments: ShipmentDocs[]
  data: ShipmentLines[]
  incidents: ShipmentIncidentsTypes[]
  shipmentStatus: ShipmentStatus
}
export const shippingReceiptSlice = createSlice({
  name: 'shippingReceipt',
  initialState: {} as IShippingReceiptSlice,
  reducers: {
    setShipment: (state, action: PayloadAction<SetShipment>) => ({
      ...state,
      shipmentId: action.payload.shipmentId,
      data: action.payload.data,
      incidents: action.payload.incidents,
      shipmentStatus: action.payload.shipmentStatus,
      shipmentDocuments: action.payload.shipmentDocuments
    }),

    clearShipment: (state) => ({
      ...state,
      data: [],
      incidents: [],
      confirmedBulks: [],
      shipmentStatus: 'Ninguno'
    }),

    setConfirmBulk: (state, action: PayloadAction<number>) => ({
      ...state,
      confirmedBulks: [...state.confirmedBulks, action.payload],
      data: state.data
        ? state.data.map((item) =>
            item.Bulto !== action.payload ? item : { ...item, EstatusBulto: 'C' }
          )
        : undefined
    }),

    refreshShipment: (state, action: PayloadAction<ShipmentLines[]>) => ({
      ...state,
      data: action.payload
    }),

    refreshShipmentIncidents: (state, action: PayloadAction<ShipmentIncidentsTypes[]>) => ({
      ...state,
      incidents: action.payload
    })
  }
})

export const {
  setShipment,
  clearShipment,
  setConfirmBulk,
  refreshShipment,
  refreshShipmentIncidents
} = shippingReceiptSlice.actions
