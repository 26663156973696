import { DefaultTemplate } from '@/shared/templates'
import { reportboxBlackIcon } from '@/assets'
import { Title } from '@/shared/components/commons'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem
} from '@/shared/components/accordion'
import { reportList } from '@/features/reports/routes'
import { Link } from 'react-router-dom'

const ReportsPage = () => {
	return (
		<DefaultTemplate>
			<Title
				backPage='Dashboard'
				backTo='/Dashboard'
				title='Reportes'
				pageName='Reportes'
				icon={reportboxBlackIcon}
			/>
			<></>
			<section className='flex-1 h-full overflow-auto'>
				{reportList?.map((item) => {
					return (
						<section key={item.path} className='mb-3'>
							<Accordion>
								<AccordionItem>
									<AccordionHeader title={item.title} icon={item.icons?.dark} mode='dark' />
									<AccordionBody>
										<section>
											{item.items.map((list) => (
												<Link
													key={list.path}
													to={`${item.path}${list.path}`}
													className='hover:font-bold block text-blue-500 font-semibold border-b-4 border-b-blue-50'
												>
													{list.name}
												</Link>
											))}
										</section>
									</AccordionBody>
								</AccordionItem>
							</Accordion>
						</section>
					)
				})}
			</section>
		</DefaultTemplate>
	)
}
export default ReportsPage
