import { ChangeEvent, KeyboardEvent, useRef } from 'react'
import { MagnifyingGlassIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import { Title, Button } from '@/shared/components/commons'
import { cameraIcon, basketICon } from '@/assets'

import { PackingListTable, IncidentFormPackingList, PackingListIncident } from '../components'
import { usePackingList } from '../hooks'
import { Modal, Drawer } from '@/shared/components/modals'
import Scanner from '@/shared/components/scanner'
import { formatDistance } from 'date-fns'
import { es } from 'date-fns/locale'
import { v4 } from 'uuid'

import { usePackingListToReceive } from '@/features/goodsReceipt/hooks/usePackingListToReceive'

const PackingListPage = () => {
	const {
		getPackingListByNumber,
		onDecodePackingListBarcode,
		isLoadingPackingList,
		data,
		packing,
		isDrawerOpen,
		setIsDrawerOpen,
		onConfirmLine,
		onReportLine,
		postIncident,
		isPostingIncident,
		currentLine,
		setCurrentLine,
		isScannerModalOpen,
		packingListNumber,
		setIsScannerModalOpen,
		setPackingListNumber,
		dataGridRef,
		inputRef,
		onfilterEanchange,
		onCheckOmitPrimary,
		isOmitCheckedPrimary,
		onCheckOmitSecondary,
		isOmitCheckedSecondary,
		onPressClearFilter,
		incidents,
		onConfirmPacking,
		isUpdatingPacking
	} = usePackingList()

	const {
		isPackingListDrawerOpen,
		packingListToReceive,
		setIsPackingListDrawerOpen,
		isSearchingPackingToReceive,
		getPackingToReceive
	} = usePackingListToReceive()

	const buttonClickRef = useRef<HTMLButtonElement>(null)
	const idPackingListRef = useRef(0)

	const onInputChangeValue = (evt: ChangeEvent<HTMLInputElement>) => {
		setPackingListNumber(evt.target.value)
	}
	const onInputKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
		if (evt.code.includes('Enter' || 'NumpadEnter')) {
			getPackingListByNumber(Number(packingListNumber))
		}
	}

	return (
		<>
			<div className='main-layout-toolbar toolbar'>
				<button
					onClick={() => {
						setIsDrawerOpen()
						setCurrentLine(undefined)
					}}
					className=' text-white'
				>
					<ClipboardDocumentListIcon width={28} />
				</button>
			</div>

			<main className='main-layout-main p-4'>
				<Title
					title='Entrada de mercancias (Packing List)'
					pageName='Packing List'
					backTo='/entrada-de-mercancias'
					backPage='Menu de entradas'
					icon={basketICon}
				/>
				<section className='mb-8'>
					<div className='w-full flex items-center space-x-2  md:w-2/4 xl:w-1/4'>
						<Button className='btn flex-button border w-16' onClick={setIsScannerModalOpen}>
							<img src={cameraIcon} alt='use Camera' />
						</Button>
						<input
							type='search'
							className='field-input'
							placeholder='No.Packing List'
							value={packingListNumber}
							onChange={onInputChangeValue}
							onKeyDown={onInputKeyDown}
						/>
						<Button
							className='btn primary flex-button'
							onClick={() => {
								console.log(idPackingListRef.current)
								if (idPackingListRef.current !== 0) getPackingListByNumber(idPackingListRef.current)
								else getPackingListByNumber(parseInt(packingListNumber))
							}}
							isLoading={isLoadingPackingList}
							innerRef={buttonClickRef}
						>
							<MagnifyingGlassIcon width={22} className='mr-1' />
							Buscar
						</Button>
						<Button
							className='btn primary flex-button'
							onClick={() => {
								getPackingToReceive().then(() => setIsPackingListDrawerOpen())
							}}
							isLoading={isSearchingPackingToReceive}
						>
							<MagnifyingGlassIcon width={22} className='mr-1' />
							PackingList a Recibir
						</Button>
					</div>
				</section>

				<section className='bg-gray-100 px-1 py-2 rounded-md mb-4'>
					<h4 className='font-semibold text-lg mb-1'>{packing.NombreProveedor}</h4>
					<ul className='font-semibold text-gray-600 text-sm space-y-1'>
						<li>
							Fecha de llegada: {packing.FechaLlegada}
							{data ? (
								<small className='font-normal text-sm ml-1'>
									(
									{formatDistance(new Date(), new Date(packing.FechaLlegada || Date.now()), {
										locale: es
									})}
									)
								</small>
							) : null}
						</li>
						<li>
							Estatus:{' '}
							<span className={` bg-yellow-400 px-2 rounded-md ${data ? 'inline' : 'hidden'}`}>
								{packing.Estatus}
							</span>
						</li>
					</ul>
				</section>

				<PackingListTable
					data={data}
					packingID={packing.Id}
					onConfirmLine={onConfirmLine}
					onReportLine={onReportLine}
					dataGridRef={dataGridRef}
					inputRef={inputRef}
					onValueChange={onfilterEanchange}
					isOmitCheckedPrimary={isOmitCheckedPrimary}
					onCheckOmitPrimary={onCheckOmitPrimary}
					isOmitCheckedSecondary={isOmitCheckedSecondary}
					onCheckOmitSecondary={onCheckOmitSecondary}
					onPressClearFilter={onPressClearFilter}
					incidents={incidents}
				/>

				<div className='mt-2 flex justify-end'>
					<Button
						className='btn primary'
						onClick={() => onConfirmPacking(+packingListNumber)}
						isLoading={isUpdatingPacking}
						disabled={!data || packing.Estatus === 'Confirmado'}
					>
						Confirmar packing
					</Button>
				</div>
			</main>

			<Modal
				wrapperId='GOODRECEIPT_PACKINGLIST_SCANNER_MODAL'
				isOpen={isScannerModalOpen}
				handleClose={setIsScannerModalOpen}
				width={350}
				height={500}
				title='Escanear codigo'
			>
				{isScannerModalOpen ? (
					<Scanner onDecode={onDecodePackingListBarcode} initialWidth={400} />
				) : null}
			</Modal>

			<Drawer
				wrapperId='PACKING_LIST_DRAWER'
				isOpen={isDrawerOpen}
				handleClose={setIsDrawerOpen}
				title={currentLine ? `Incidencia de linea ${currentLine.Id}` : 'Incidencias del packing'}
			>
				{currentLine ? (
					<div className='shadow-lg p-4 rounded-md bg-white'>
						<IncidentFormPackingList
							hide={setIsDrawerOpen}
							isLoading={isPostingIncident || isLoadingPackingList}
							packingId={packing.Id}
							postIncident={postIncident}
							item={currentLine}
						/>
					</div>
				) : null}

				<div className='space-y-4 mt-8'>
					{incidents && currentLine ? (
						incidents
							.filter((item) => item.Dispersion === currentLine.IdDispersion)
							.map((item) => <PackingListIncident key={item.Id} {...item} />)
					) : incidents ? (
						incidents.map((item) => <PackingListIncident key={item.Id} {...item} />)
					) : (
						<div className=' bg-white rounded-md shadow-lg h-12 flex items-center justify-center '>
							<h3 className=''>Sin incidencias</h3>
						</div>
					)}
				</div>
			</Drawer>

			<Drawer
				wrapperId={'SHIPPINGLIST_TO_RECEIVE'}
				isOpen={isPackingListDrawerOpen}
				handleClose={setIsPackingListDrawerOpen}
				title={'Packing List a Recibir'}
			>
				{packingListToReceive.length > 0 ? (
					packingListToReceive.map((item) => (
						<ul
							key={v4()}
							onClick={() => {
								idPackingListRef.current = item.PackingList
								console.log(idPackingListRef.current)
								// Para que se muestre el input
								setPackingListNumber(item.PackingList.toString())
								setIsPackingListDrawerOpen()
								buttonClickRef?.current?.click()
							}}
							className='px-4 py-2 mt-3 bg-gray-200 rounded-md text-md text-gray-600 cursor-pointer'
						>
							<li>
								Packing List: <span className='font-semibold text-black'>{item.PackingList}</span>
							</li>
							<li>
								Proveedor: <span className='font-semibold text-black'>{item.Proveedor}</span>
							</li>
							<li>
								Estatus: <span className='font-semibold text-black'>{item.Estatus}</span>
							</li>
						</ul>
					))
				) : (
					<div className='bg-white rounded-md shadow-lg h-12 flex items-center justify-center'>
						<h3>No hay Packing List a recibir</h3>
					</div>
				)}
			</Drawer>
		</>
	)
}

export default PackingListPage
