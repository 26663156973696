import { DefaultTemplate } from '@/shared/templates'
import { Title, DashLink } from '@/shared/components/commons'
import { InventoryFlowFillWhite, inventoryFlowAltIcon, moveInStockIcon } from '@/assets'

const InventoryAssortmentPage = () => {
	return (
		<DefaultTemplate>
			<Title
				backPage='Dashboard'
				backTo='/Dashboard'
				title='Reposición de inventario'
				pageName='Reposición'
				icon={inventoryFlowAltIcon}
			/>

			<section className='mt-8 grid place-items-center'>
				<ul className='space-y-4'>
					<DashLink
						title='Surtido de tiendas'
						description='Realiza las reposiciones a tiendas'
						icon={moveInStockIcon}
						primaryColor='bg-[#4493DC]'
						secondaryColor='bg-[#4493DC]'
						to='/reposicion-de-inventario/tiendas'
					/>
				</ul>
			</section>

			<section className='mt-8 grid place-items-center'>
				<ul className='space-y-4'>
					<DashLink
						title='Traspasos entre tiendas'
						description='Realiza transferencia de artículos a otra tienda'
						icon={InventoryFlowFillWhite}
						primaryColor='bg-[#30475e]'
						secondaryColor='bg-[#30475e]'
						to='/reposicion-de-inventario/traspasos-entre-tiendas'
					/>
				</ul>
			</section>
		</DefaultTemplate>
	)
}

export default InventoryAssortmentPage
