import { defaultTemplateTypes } from './types'

const DefaultTemplate = ({ children }: defaultTemplateTypes) => {
	return (
		<>
			<div className='main-layout-toolbar toolbar' />
			<main className='main-layout-main p-4'>{children}</main>
		</>
	)
}

export default DefaultTemplate
