import { useNotification } from '@/shared/hooks'
import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { IDataSolTraslado } from '@/features/inventoryAssortment/types/Reposicion.types'
import { env } from '@/env'
import { useState } from 'react'
import { getError } from '@/shared/helpers'

const usePDFSolTraslado = () => {
	const notification = useNotification()
	const useAxios = makeUseAxios({
		axios: axiosInstance(process.env.REACT_APP_API_WMS ?? (env.REACT_APP_API_WMS as string))
	})

	const [data, setData] = useState<IDataSolTraslado[] | undefined>(undefined)

	const [{ loading }, executeDataSolTraslado] = useAxios<IDataSolTraslado[]>(
		{ url: '/asistente-reposicion/pdf/solicitudes-traslado', method: 'post' },
		{ manual: true }
	)

	const getDataSolTraslado = async (DocNum: string) => {
		await executeDataSolTraslado({
			data: {
				empresa: process.env.REACT_APP_SAP_COMPANY_TEST ?? env.REACT_APP_SAP_COMPANY_TEST,
				docNum: Number(DocNum)
			}
		})
			.then(({ data }) => setData(data))
			.catch((error) => {
				console.log('No se obtuvo información del documento')
				notification.error('No se obtuvo información del documento. Error:' + getError(error))
				setData(undefined)
			})
	}

	return {
		loading,
		data,
		getDataSolTraslado
	}
}

export default usePDFSolTraslado
