import { useState, useEffect } from 'react'
import { ViewfinderCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { CarouselTypes, ImagesTypes } from './types'
import { useToggle, useOnErrorImage } from '@/shared/hooks'
import Portal from '../portal'

const Carousel = ({ images }: CarouselTypes) => {
	const [selectedImage, setSelectedImage] = useState<ImagesTypes>()
	const [showViewer, setShowViewer] = useToggle()
	const [replaceImage] = useOnErrorImage()

	const onPressImage = (item: ImagesTypes) => {
		setSelectedImage(item)
	}

	useEffect(() => {
		if (images && images.length > 0) {
			const firstSelected = images[0]
			setSelectedImage(firstSelected)
		}
	}, [images])

	return (
		<>
			<div className='relative w-full h-full flex flex-col'>
				<figure className='relative w-full h-[calc(_100%_-_6rem)] border p-2 rounded-md overflow-hidden'>
					<img
						src={selectedImage?.url}
						alt={selectedImage?.alt}
						className='w-full h-full object-contain object-center'
						onError={replaceImage}
					/>

					<button className=' absolute bottom-1 right-1 text-gray-500 z-10' onClick={setShowViewer}>
						<ViewfinderCircleIcon width={32} />
					</button>
				</figure>

				<div className='w-full mt-2 h-20 overflow-auto relative'>
					<div className=' flex absolute overflow-x-auto space-x-2'>
						{images?.map((item, i) => (
							<figure
								key={item.url + i}
								className={`w-20 h-20 cursor-pointer overflow-hidden ${
									selectedImage === item ? 'border-b-2 border-blue-600' : ''
								}`}
								onClick={() => onPressImage(item)}
							>
								<img
									src={item.url}
									alt={item?.alt}
									className='w-full h-full object-contain object-center'
									onError={replaceImage}
								/>
							</figure>
						))}
					</div>
				</div>
			</div>

			<Portal wrapperId='CAROUSEL_VIEWER_CONTAINER'>
				<div className={`portal_carousel_viewer overflow-auto p-4 ${showViewer ? 'active' : ''}`}>
					<h3 className=' text-white text-xl mb-4 mt-4'>{selectedImage?.alt}</h3>
					<figure className='flex-1 overflow-auto'>
						<img
							src={selectedImage?.url}
							alt={selectedImage?.alt}
							className='w-full h-full object-contain object-center'
							onError={replaceImage}
						/>
					</figure>
					<button className='absolute top-1 right-1 text-white' onClick={setShowViewer}>
						<XMarkIcon width={32} />
					</button>
				</div>
			</Portal>
		</>
	)
}

export default Carousel
