import { useParams } from 'react-router-dom'
import { usePDFTraslado } from '@/features/inventoryAssortment/hooks'
import { Document, Font, Image, Page, PDFViewer, View, Text, StyleSheet } from '@react-pdf/renderer'
import { logoTheGreenCompanyImg } from '@/assets'
import { RobotoRegular, RobotoBold } from '@/fonts'
import { formatDateComplete, formatStrToDateComplete } from '@/shared/helpers'
import { format } from 'date-fns'
import { useEffect } from 'react'

const PreviewPDFTrasladoPage = () => {
	const { DocNumTransfer } = useParams()
	const { data, getDataTraslado } = usePDFTraslado()

	useEffect(() => {
		getDataTraslado(DocNumTransfer ? DocNumTransfer : '0')
	}, [])

	Font.register({
		family: 'Roboto',
		fonts: [
			{ src: RobotoRegular }, // font-style: normal, font-weight: normal
			{
				src: RobotoBold,
				fontWeight: 700
			}
		]
	})

	const styles = StyleSheet.create({
		viewer: { width: '100%', height: '100vh', position: 'absolute' },
		page: { padding: '30px 30px 40px 30px' },
		encabezadoPagina: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'flex-start'
		},
		lines: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between'
		},
		tItemCode: { width: '15%', fontSize: '8px' },
		tEAN: { width: '14%', fontSize: '8px' },
		tDescripcion: { width: '57%', fontSize: '8px' },
		tCANTIDAD: { width: '8%', fontSize: '8px' },
		tFirma: {
			fontSize: '8px',
			borderTop: 1,
			display: 'flex',
			justifyContent: 'center',
			width: '100px',
			marginLeft: '20px',
			marginRight: '20px',
			textAlign: 'center',
			padding: '1px'
		},
		tNegritas: { fontFamily: 'Roboto', fontWeight: 'bold' },
		piePagina: {
			position: 'absolute',
			fontSize: '7px',
			bottom: '20px',
			left: '30px',
			right: '30px',
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between'
		}
	})

	return (
		<>
			{data != undefined && (
				<PDFViewer style={styles.viewer}>
					<Document title={`Traslado-${DocNumTransfer}`} author='WMS'>
						<Page size='LETTER' style={styles.page}>
							{/*ENCABEZADO*/}
							<View style={styles.encabezadoPagina} fixed>
								<View>
									<Image src={logoTheGreenCompanyImg} style={{ width: '85px' }} />
								</View>

								<View style={{ width: '45%' }}>
									<Text style={[styles.tNegritas, { fontSize: '14px', textAlign: 'center' }]}>
										TRASLADO DE MERCANCIA
									</Text>
									<Text style={{ fontSize: '10px', textAlign: 'center' }}>
										{data[0].WhsFrom} al {data[0].WhsTo}
									</Text>
									<Text style={{ fontSize: '10px', textAlign: 'center' }}>
										{formatStrToDateComplete(data[0].FechaRemision)}
									</Text>
								</View>
								<View
									style={{
										border: 1,
										width: '80px',
										borderRadius: '5px',
										display: 'flex',
										justifyContent: 'flex-end',
										alignItems: 'center',
										flexDirection: 'column',
										padding: '5px'
									}}
								>
									<Text style={[styles.tNegritas, { fontSize: '14px', color: 'red' }]}>
										{data[0].DocNum}
									</Text>
									<Text style={{ fontSize: '8px' }}>Traslado</Text>
								</View>
							</View>
							<View
								style={[
									styles.lines,
									{ margin: '15px 0 10px 0', padding: '5px 0', borderTop: 1, borderBottom: 1 }
								]}
								fixed
							>
								<Text style={[styles.tItemCode, styles.tNegritas]}>ARTICULO</Text>
								<Text style={[styles.tEAN, styles.tNegritas]}>EAN</Text>
								<Text style={[styles.tDescripcion, styles.tNegritas]}>DESCRIPCIÓN</Text>
								<Text style={[styles.tCANTIDAD, styles.tNegritas]}>CANTIDAD</Text>
							</View>
							{/*LINEAS*/}
							<View style={{ width: '100%' }}>
								<View>
									{data
										.sort((a, b) => a.ItemCode.localeCompare(b.ItemCode))
										.map((item) => (
											<View key={item.ItemCode} style={[styles.lines, { marginTop: '5px' }]}>
												<Text style={styles.tItemCode}>{item.ItemCode}</Text>
												<View style={[styles.tEAN, { display: 'flex', flexDirection: 'row' }]}>
													<Text style={{ fontSize: '10px' }}>{item.EAN.slice(0, -4)}</Text>
													<Text style={[{ fontSize: '10px' }, styles.tNegritas]}>
														{item.EAN.slice(-4)}
													</Text>
												</View>
												<Text style={styles.tDescripcion}>{item.Descripcion}</Text>
												<Text style={[styles.tCANTIDAD, { fontSize: '9px', textAlign: 'right' }]}>
													{item.Cantidad}
												</Text>
											</View>
										))}
									<View style={styles.lines}>
										<View style={{ borderTop: 1, width: '60%', marginTop: '5px' }}></View>
										<View
											style={{ borderTop: 1, width: '20%', marginTop: '5px', paddingTop: '1px' }}
										></View>
									</View>
									<Text
										style={[
											styles.tNegritas,
											{ fontSize: '9px', textAlign: 'right', width: '100%', paddingTop: '3px' }
										]}
									>
										{data.reduce((a, b) => a + b.Cantidad, 0)}
									</Text>
								</View>
								<View
									style={{
										width: '100%',
										marginTop: '80px',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center'
									}}
								>
									<Text style={[styles.tFirma, { width: '150px' }]}>FECHA</Text>
									<Text style={[styles.tFirma, { width: '100px' }]}>HORA</Text>
									<Text style={[styles.tFirma, { width: '300px' }]}>RECIBIO</Text>
								</View>
							</View>
							{/*PIE DE PAGINA*/}
							<View style={styles.piePagina} fixed>
								<Text
									render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages}`}
								/>
								<Text>
									{formatDateComplete(new Date())} {format(new Date(), 'HH:mm')}
								</Text>
							</View>
						</Page>
					</Document>
				</PDFViewer>
			)}
		</>
	)
}

export default PreviewPDFTrasladoPage
