import { serviceLayerBaseApi } from '@/app/api'
import type {
	InventoryGenEntries,
	IInventoryGenEntriesDocument
} from '../types/inventoryGenEntries'
import type {
	IMaterialRevaluationDocument,
	MaterialRevaluation
} from '../types/materialRevaluation'
import type { ILandedCostDocument, LandedCost, LandedCostOptional } from '../types/landedCosts'
import type { StockTransfer, IStockTransferDocument } from '../types/stockTransfer'
import type { IPurchaseDeliveryNotes, PurchaseDeliveryNote } from '../types/purchaseDeliveryNotes'
import type { IAuthSAP } from '../types/serviceLayer'
import { IOrderDocument, IOrdenDocumentOptional } from '@/features/operationStatus/types/order'
import { IWarehouse, IWarehouses } from '@/features/operationStatus/types/warehouse'

type Params = {
	DocNum: string
	params?: { [k: string]: string }
}

type Query = {
	params?: { [k: string]: string }
}

const extendedApi = serviceLayerBaseApi.injectEndpoints({
	endpoints: (build) => ({
		login: build.mutation<void, IAuthSAP>({
			query: ({ UserName, Password, CompanyDB }) => ({
				url: '/Login',
				method: 'POST',
				body: { UserName, Password, CompanyDB }
			})
		}),

		createInventoryGenEntries: build.mutation<IInventoryGenEntriesDocument, InventoryGenEntries>({
			query: (data) => ({
				url: '/InventoryGenEntries',
				method: 'POST',
				body: data
			})
		}),

		getInventoryGenEntries: build.query<IInventoryGenEntriesDocument, Params>({
			query: ({ DocNum, params }) => ({
				url: `/InventoryGenEntries(${DocNum})`,
				method: 'GET',
				params
			})
		}),

		createRevaluation: build.mutation<IMaterialRevaluationDocument, MaterialRevaluation>({
			query: (data) => ({
				url: '/MaterialRevaluation',
				method: 'POST',
				body: data
			})
		}),

		createLandedCost: build.mutation<ILandedCostDocument, LandedCost>({
			query: (data) => ({
				url: '/LandedCosts',
				method: 'POST',
				body: data
			})
		}),

		updateLandedCost: build.mutation<void, { data: LandedCostOptional; id: number }>({
			query: ({ data, id }) => ({
				url: `/LandedCosts(${id})`,
				method: 'PATCH',
				body: data
			})
		}),

		createStockTransfer: build.mutation<IStockTransferDocument, StockTransfer>({
			query: (data) => ({
				url: '/StockTransfers',
				method: 'POST',
				body: data
			})
		}),

		getStockTransfer: build.query<IStockTransferDocument, Params>({
			query: ({ DocNum, params }) => ({
				url: `/StockTransfers(${DocNum})`,
				method: 'GET',
				params
			})
		}),

		createPurchaseDeliveryNote: build.mutation<IPurchaseDeliveryNotes, PurchaseDeliveryNote>({
			query: (data) => ({
				url: '/PurchaseDeliveryNotes',
				method: 'POST',
				body: data
			})
		}),

		getPurchaseDeliveryNote: build.query<IPurchaseDeliveryNotes, Params>({
			query: ({ DocNum, params }) => ({
				url: `/PurchaseDeliveryNotes(${DocNum})`,
				method: 'GET',
				params
			})
		}),

		createStockTransferRequest: build.mutation<IStockTransferDocument, StockTransfer>({
			query: (data) => ({
				url: '/InventoryTransferRequests',
				method: 'POST',
				body: data
			})
		}),

		getStockTransferRequest: build.query<IStockTransferDocument, Params>({
			query: ({ DocNum, params }) => ({
				url: `/InventoryTransferRequests(${DocNum})`,
				method: 'GET',
				params
			})
		}),

		closeStockTransferRequest: build.mutation<void, Params>({
			query: ({ DocNum, params }) => ({
				url: `/InventoryTransferRequests(${DocNum})/Close`,
				method: 'POST',
				params
			})
		}),

		getOrder: build.query<IOrderDocument, Params>({
			query: ({ DocNum, params }) => ({
				url: `/Orders(${DocNum})`,
				method: 'GET',
				params
			})
		}),

		updateOrder: build.mutation<void, { data: IOrdenDocumentOptional; id: number }>({
			query: ({ data, id }) => ({
				url: `/Orders(${id})`,
				method: 'PATCH',
				body: data
			})
		}),

		getAllWarehouses: build.query<IWarehouses, Query>({
			query: ({ params }) => ({
				url: `Warehouses`,
				method: 'GET',
				params
			})
		}),

		getWarehouse: build.query<IWarehouse, { Code: string }>({
			query: ({ Code }) => ({
				url: `Warehouses('${Code}')`,
				method: 'GET'
			})
		})
	}),
	overrideExisting: false
})

export const {
	useLoginMutation,
	useCreateInventoryGenEntriesMutation,
	useCreateRevaluationMutation,
	useCreateLandedCostMutation,
	useCreateStockTransferMutation,
	useLazyGetStockTransferQuery,
	useLazyGetInventoryGenEntriesQuery,
	useCreatePurchaseDeliveryNoteMutation,
	useLazyGetPurchaseDeliveryNoteQuery,
	useCreateStockTransferRequestMutation,
	useCloseStockTransferRequestMutation,
	useLazyGetStockTransferRequestQuery,
	useUpdateLandedCostMutation,
	useLazyGetOrderQuery,
	useUpdateOrderMutation,
	useLazyGetWarehouseQuery,
	useLazyGetAllWarehousesQuery
} = extendedApi
