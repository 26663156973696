import { ExportingEvent } from 'devextreme/ui/data_grid'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'

const useOnExportToExcel = () => {
  const devExpressExport = (
    evt: ExportingEvent<any>,
    filename: string = 'devExpressExcel',
    sheetName: string = 'sheet'
  ) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet(sheetName)

    exportDataGrid({
      component: evt.component,
      worksheet,
      autoFilterEnabled: true
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${filename}.xlsx`)
      })
    })
    evt.cancel = true
  }

  return { devExpressExport }
}

export default useOnExportToExcel
