import { Modal } from '@/shared/components/modals'
import { Button } from 'devextreme-react'

interface PropsModal {
	message: string
	isOpen: boolean
	handleClose: () => void
	onConfirm: () => void
}

export const ConfirmModalTemplate = ({ message, isOpen, handleClose, onConfirm }: PropsModal) => {
	return (
		<Modal
			wrapperId='InicioSesionSAP'
			isOpen={isOpen}
			handleClose={handleClose}
			width={350}
			title='Confirmación'
		>
			<p className='mb-4'>{message}</p>
			<div className={`flex flex-row flex-wrap justify-end`}>
				<Button className='btn primary w-1/3 mx-1' onClick={onConfirm}>
					Aceptar
				</Button>
				<Button className='btn secondary w-1/3 mx-1' onClick={handleClose}>
					Cancelar
				</Button>
			</div>
		</Modal>
	)
}
