import { subLinksTypes } from './types'
import { NavLink } from 'react-router-dom'
import { triangleIcon } from '@/assets'
import { useToggle } from '@/shared/hooks'

const SubLinks = ({ title, path = '', items, close }: subLinksTypes) => {
	const [active, setActive] = useToggle()
	return (
		<li>
			<button className='flex items-center' onClick={setActive}>
				<img
					src={triangleIcon}
					className={`w-4 h-4 object-center mr-2 transform  transition-transform duration-100 ease-in-out ${
						active ? ' rotate-0' : '-rotate-90'
					}`}
					alt='icon'
				/>
				{title}
			</button>

			<ul className={`submenuItem ${active ? 'block' : 'hidden'}`}>
				{items.map((item) => (
					<li key={item.path}>
						<NavLink
							to={`${path}${item.path}`}
							onClick={close}
							className={({ isActive }) => (isActive ? 'active' : '')}
						>
							{item.name}
						</NavLink>
					</li>
				))}
			</ul>
		</li>
	)
}

export default SubLinks
