import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'
import { useSelector } from 'react-redux'
import { RootState } from '@/app/store/createStore'
import { useNotification, useToggle, useSystemMessage } from '@/shared/hooks'
import { ChangeEvent, useState } from 'react'
import { getError } from '@/shared/helpers'
import {
	IDataUploadTraspaso,
	IExecUploadFile,
	ILineTraspasoFileUpload,
	ILineUploadTraspaso,
	ITraspasosOneBeat,
	IUbicacionesTraspasoUpload
} from '@/features/inventoryAssortment/types/TraspasosTiendas.types'
import { format } from 'date-fns'
import axios from 'axios'

const useTraspasosTiendas = () => {
	const { user } = useSelector((state: RootState) => state.auth)
	const CompanyDB = process.env.REACT_APP_SAP_COMPANY_TEST ?? env.REACT_APP_SAP_COMPANY_TEST
	const notification = useNotification()
	const systemMessage = useSystemMessage()

	const [typeUpload, setTypeUpload] = useState('archivo')
	const allowedExtensions = ['csv']
	const [file, setFile] = useState<File>()
	const [loading, setLoading] = useState(false)
	const [uploading, setUploading] = useState(false)
	const [uploadOverwrite, setUploadOverwrite] = useState(false)
	const [traspasosUpload, setTraspasosUpload] = useState<ILineTraspasoFileUpload[]>([])

	const useAxiosOneBeat = makeUseAxios({
		axios: axiosInstance(
			process.env.REACT_APP_API_WMS_ONE_BEAT ?? (env.REACT_APP_API_WMS_ONE_BEAT as string)
		)
	})

	const [{}, excuteUploadFileTraspasos] = useAxiosOneBeat<IExecUploadFile>(
		{ url: '/traspasos/archivo', method: 'post' },
		{ manual: true }
	)

	const [{}, excuteOverwriteFileTraspasos] = useAxiosOneBeat<IExecUploadFile>(
		{ url: '/traspasos/archivo/nuevo', method: 'post' },
		{ manual: true }
	)

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target
		if (files) {
			const inputFile = files[0]
			const fileExtension = inputFile?.type.split('/')[1]
			if (!allowedExtensions.includes(fileExtension)) {
				return notification.error('Archivo no valido')
			}
			setFile(inputFile)
		}
	}

	const handleParse = async () => {
		setLoading(true)
		setTraspasosUpload([])
		switch (typeUpload) {
			case 'archivo':
				if (!file) {
					setLoading(false)
					return notification.error('Archivo no valido')
				}
				const reader = new FileReader()
				// Event listener on reader when the file
				// loads, we parse it and set the data.
				reader.onload = async ({ target }) => {
					if (!target?.result) {
						setLoading(false)
						return systemMessage.add('No se encontro información para cargar')
					}

					const text = target.result.toString()
					const lines_str = text.replace(/\r/g, '').split('\n')
					const lines_array = lines_str
						.filter((line, i) => {
							// Por cada linea obtenemos los valores
							const values = line.split(',')
							if (i > 0 && values[0] != '') return values
						})
						.map((line) => {
							const values = line.split(',')
							return {
								Fecha: values[0],
								Plaza: values[1],
								AlmOrigen: values[3],
								AlmDestino: values[5],
								ItemCode: values[9],
								Descripcion: values[8],
								Color: values[11].replaceAll('"', '').replace('[', '').replace(']', ''),
								Talla: values[12],
								Cantidad: Number(values[6])
							}
						})
					setTraspasosUpload(lines_array)
				}
				reader.readAsText(file)
				break
			case 'api':
				await getStoreTransfersOneBeat()
					.then(({ data }) => {
						if (data.length < 1) {
							setLoading(false)
							return systemMessage.add('No se encontro información para cargar')
						}
						const lines_array = data.map((line) => {
							return {
								Fecha: format(new Date(), 'yyyy-MM-dd'),
								Plaza: line[0],
								AlmOrigen: line[1],
								AlmDestino: line[3],
								ItemCode: line[5],
								Descripcion: line[6],
								Color: line[8],
								Talla: line[9],
								Cantidad: Number(line[10])
							}
						})
						setTraspasosUpload(lines_array)
					})
					.catch((error) => {
						notification.error('No se obtuvo información. Error: ' + getError(error), 5000)
					})
				break
		}
		setLoading(false)
	}

	const getStoreTransfersOneBeat = async (): Promise<ITraspasosOneBeat> => {
		const { data } = await axios({
			url: 'https://api.onebeat.co/v1/exporters/store_transfer',
			method: 'get',
			params: { account_id: '4756a8d4-ce6c-47b5-b4e3-fa924fe71d88' },
			headers: {
				Authorization:
					'Bearer qSKYhLOYXXHpPBjBC8w2FoFkgbNlDau4Sx4AgdHqRmc3vshyW9jHJqhnXZBrPkgiQF1W5wgvzf8qSz4fw5zHHfo6w0BEZ1iw0mmRfOiKqN6c5Z97858Bv4eYcwaf9wjk'
			}
		})
		return data
	}

	const getDataUploadFileTraspasos = () => {
		const _grupoUbicaciones: IUbicacionesTraspasoUpload[] = []
		const _ubicaciones = traspasosUpload.map((item) => ({
			Fecha: item.Fecha,
			AlmOrigen: item.AlmOrigen,
			AlmDestino: item.AlmDestino
		}))
		_ubicaciones.forEach((a) => {
			if (
				_grupoUbicaciones.filter(
					(b) => b.AlmOrigen === a.AlmOrigen && b.AlmDestino === a.AlmDestino
				).length === 0
			) {
				_grupoUbicaciones.push(a)
			}
		})

		const data: IDataUploadTraspaso[] = []
		_grupoUbicaciones.map((e) => {
			const details: ILineUploadTraspaso[] = traspasosUpload
				.filter((line) => line.AlmOrigen == e.AlmOrigen && line.AlmDestino == e.AlmDestino)
				.map((line) => ({ ItemCode: line.ItemCode, Cantidad: line.Cantidad }))
			const enc: IDataUploadTraspaso = {
				AlmOrigen: e.AlmOrigen,
				AlmDestino: e.AlmDestino,
				Items: details
			}
			data.push(enc)
		})

		return {
			FileName: typeUpload == 'archivo' ? file?.name : 'Carga desde api onebeat',
			FileType: typeUpload == 'archivo' ? file?.type : 'Api',
			FileCountLines: traspasosUpload.length,
			Fecha: traspasosUpload[0].Fecha,
			Data: data,
			Usr: user?.Id,
			Empresa: CompanyDB
		}
	}

	const onUploadDataTraspados = async () => {
		setUploading(true)
		try {
			const dataSend = getDataUploadFileTraspasos()

			await excuteUploadFileTraspasos({ data: dataSend }).then(({ data }) => {
				if (data.sobreescribir) setUploadOverwrite(true)
				else {
					setTraspasosUpload([])
					notification.success('Carga de archivo realizada correctamente')
				}
			})
		} catch (error) {
			notification.error('No fue posible realizar la carga. Error: ' + getError(error), 6000)
		}
		setUploading(false)
	}

	const onOverwriteDataTraspados = async () => {
		setUploading(true)
		try {
			const dataSend = getDataUploadFileTraspasos()

			await excuteOverwriteFileTraspasos({ data: dataSend }).then(({ data }) => {
				if (data.sobreescribir) setUploadOverwrite(true)
				else {
					setTraspasosUpload([])
					notification.success('Carga de archivo realizada correctamente')
				}
			})
		} catch (error) {
			notification.error('No fue posible realizar la carga. Error: ' + getError(error), 6000)
		}
		setUploading(false)
	}

	return {
		loading,
		handleFileChange,
		handleParse,
		typeUpload,
		setTypeUpload,
		traspasosUpload,
		uploading,
		onUploadDataTraspados,
		uploadOverwrite,
		setUploadOverwrite,
		onOverwriteDataTraspados
	}
}

export default useTraspasosTiendas
