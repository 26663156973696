import { AccordionHocProps } from './interface'

import { Accordion as AccordionHoc } from './Accordion'
import { AccordionItem } from './AccordionItem'
import { AccordionHeader } from './AccordionHeader'
import { AccordionBody } from './AccordionBody'

export { AccordionItem } from './AccordionItem'
export { AccordionHeader } from './AccordionHeader'
export { AccordionBody } from './AccordionBody'

export const Accordion: AccordionHocProps = Object.assign(AccordionHoc, {
  Item: AccordionItem,
  Header: AccordionHeader,
  Body: AccordionBody
})

export default Accordion
