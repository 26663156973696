import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { topVentasSliceTypes } from '@/features/reports/ventas/topVentas/topVentas.types'

const date1 = new Date()
date1.setMonth(date1.getMonth() - 1)
const date2 = new Date()

const initialState: topVentasSliceTypes = {
  data: [],
  ReportType: '',
  ShowTop: '',
  DateStart: date1,
  DateEnd: date2,
  Segmentos: [],
  Vision: '',
  AuxVision: [],
  InventoryType: [],
  OmitDesc: false,
  DescNoValid: 20,
  OrdenBy: '',
  GroupByLocation: '',
  GroupByItem: '',
  Familias: [],
  Generos: [],
  GruposEdad: [],
  DiasValidoMov: 15
}

export const topVentasSlice = createSlice({
  name: 'topVentasReport',
  initialState,
  reducers: {
    setTopVentasReportData: (state, action: PayloadAction<topVentasSliceTypes>) => ({
      ...state,
      ...action.payload
    }),
    resetTopVentasReportData: () => initialState
  }
})

export const { setTopVentasReportData, resetTopVentasReportData } = topVentasSlice.actions
export default topVentasSlice
