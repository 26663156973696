import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { authSliceTypes } from './auth.types'
import { userTypes } from '@/shared/interfaces/user.interfaces'

const initialState: authSliceTypes = {
  isAuth: false,
  user: null,
  isLoading: true,
  isLoadingPageAuth: true
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<userTypes>) => ({
      ...state,
      isAuth: true,
      user: action.payload
    }),
    clearUser: (state) => ({ ...state, user: null, isAuth: false }),
    setIsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload
    }),
    setIsLoadingPageAuth: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoadingPageAuth: action.payload
    }),
    setCheckLocation: (state, action: PayloadAction<{ code: number; value: boolean }>) => {
      if (state.user && state.user.Localidades) {
        const editItem = state.user.Localidades.find((item) => item.Codigo === action.payload.code)
        if (editItem) {
          const restOfData = state.user.Localidades.filter(
            (item) => item.Codigo !== action.payload.code
          )
          return {
            ...state,
            user: {
              ...state.user,
              Localidades: [...restOfData, { ...editItem, Ver: action.payload.value }]
            }
          }
        }
      }
      return state
    }
  }
})

export const { setUser, clearUser, setIsLoading, setCheckLocation } = authSlice.actions

export default authSlice
