import { UploadBlackIcon } from '@/assets'
import { Button, Title } from '@/shared/components/commons'
import { useRef } from 'react'
import { DataGrid } from 'devextreme-react'
import {
	Column,
	ColumnChooser,
	Export,
	FilterRow,
	GroupPanel,
	Scrolling,
	SearchPanel
} from 'devextreme-react/data-grid'
import { useUploadTraspasosTiendas } from '../hooks'
import { ConfirmModalTemplate } from '@/shared/templates'

const UploadFileTraspasosTiendasPage = () => {
	const dataGridRef = useRef<DataGrid>(null)
	const inputFile = useRef<HTMLInputElement>(null)

	const {
		loading,
		typeUpload,
		setTypeUpload,
		handleFileChange,
		handleParse,
		traspasosUpload,
		onUploadDataTraspados,
		uploading,
		uploadOverwrite,
		setUploadOverwrite,
		onOverwriteDataTraspados
	} = useUploadTraspasosTiendas()

	return (
		<>
			<div className='main-layout-toolbar toolbar'></div>

			<main className='main-layout-main p-4'>
				<Title
					title='Carga de Traspasos de tiendas'
					pageName='Carga'
					backTo='/reposicion-de-inventario/traspasos-entre-tiendas'
					backPage='Traspasos entre tiendas'
					icon={UploadBlackIcon}
				/>

				<div className='w-full'>
					<section>
						Tipo de carga:
						<label className='ml-3'>
							<input
								type='radio'
								value='archivo'
								checked={typeUpload == 'archivo'}
								onChange={(e) => setTypeUpload(e.target.value)}
							/>{' '}
							Archivo
						</label>
						<label className='ml-3'>
							<input
								type='radio'
								value='api'
								checked={typeUpload == 'api'}
								onChange={(e) => {
									setTypeUpload(e.target.value)
									if (inputFile.current) inputFile.current.value = ''
								}}
							/>{' '}
							Api
						</label>
					</section>
				</div>
				<div className='w-full mt-3'>
					<label htmlFor='csvInput'>Archivo de carga (.csv)</label>
					<br></br>
					<input
						type='file'
						ref={inputFile}
						className='field-input'
						disabled={typeUpload != 'archivo'}
						onChange={(e) => handleFileChange(e)}
					/>
				</div>

				<div className='mt-2 flex justify-end w-full items-center'>
					<Button className='btn primary w-20' isLoading={loading} onClick={handleParse}>
						Cargar
					</Button>
				</div>

				<div className='overflow-auto mt-5' style={{ height: 'calc(100% - 20rem)' }}>
					<DataGrid
						ref={dataGridRef}
						dataSource={traspasosUpload}
						allowColumnResizing={true}
						columnResizingMode={'nextColumn'}
						showBorders={true}
						showRowLines={true}
						columnAutoWidth
					>
						<ColumnChooser enabled={false} />
						<GroupPanel visible={true} />
						<FilterRow visible={true} />
						<SearchPanel visible={true} highlightCaseSensitive={true} placeholder='Buscar' />
						<Scrolling mode='virtual' />
						<Export enabled={false} />
						<Column
							dataField='Plaza'
							caption='Plaza'
							dataType='string'
							width={120}
							groupIndex={0}
							sortOrder='asc'
						/>
						<Column dataField='Fecha' caption='Fecha' dataType='string' width={100} />
						<Column dataField='AlmOrigen' caption='Alm. Origen' dataType='string' width={100} />
						<Column dataField='AlmDestino' caption='Alm. Destino' dataType='string' width={100} />
						<Column dataField='ItemCode' caption='Articulo' dataType='string' width={180} />
						<Column dataField='Descripcion' caption='Descripcion' dataType='string' />
						<Column dataField='Color' caption='Collor' dataType='string' width={100} />
						<Column dataField='Talla' caption='Talla' dataType='string' width={100} />
						<Column dataField='Cantidad' caption='Cantidad' dataType='string' width={100} />
					</DataGrid>
				</div>

				<div className='flex flex-row justify-end py-5'>
					{uploading && <span className='mr-5 font-semibold'>Subiendo</span>}
					<Button
						className='btn primary'
						style={{ width: '110px' }}
						isLoading={uploading}
						disabled={traspasosUpload.length == 0 ? true : false}
						onClick={() => onUploadDataTraspados()}
					>
						Procesar
					</Button>
				</div>
				<ConfirmModalTemplate
					message='Se han encontrado traspasos previamente cargados, ¿Desea sobreescribir?'
					isOpen={uploadOverwrite}
					handleClose={() => setUploadOverwrite(false)}
					onConfirm={() => {
						setUploadOverwrite(false)
						onOverwriteDataTraspados()
					}}
				/>
			</main>
		</>
	)
}

export default UploadFileTraspasosTiendasPage
