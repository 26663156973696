import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { SAPUser } from '@/app/service_layer/types/SAPUser.types'
import { ControlText } from '@/shared/components/fields'
import { Button } from '@/shared/components/commons'
import { useAuthSAP } from '@/app/service_layer/hooks'
import { useToggle, useNotification } from '@/shared/hooks'
import { getServiceLayerError } from '@/app/service_layer/helpers/getServiceLayerError'

import { env } from '@/env'

interface Props {
	action: (sapUser: string) => Promise<void> | void
}

export const LoginSAP = ({ action }: Props) => {
	const { login } = useAuthSAP()
	const [fetching, setFetching] = useToggle()
	const notification = useNotification()

	const initialValues: SAPUser = {
		UserName: '',
		Password: '',
		CompanyDB: process.env.REACT_APP_SAP_COMPANY ?? env.REACT_APP_SAP_COMPANY
	}

	const onSubmit = async (values: SAPUser, { resetForm }: FormikHelpers<SAPUser>) => {
		try {
			setFetching()
			await login(values)
			await action(values.UserName)
		} catch (error) {
			notification.error(getServiceLayerError(error))
		} finally {
			setFetching()
			resetForm()
		}
	}

	const validationSchema = Yup.object().shape({
		UserName: Yup.string().required('Por favor ingrese su usuario de sap'),
		Password: Yup.string().required('Por favor ingrese su contraseña de sap')
	})

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			enableReinitialize
			validationSchema={validationSchema}
		>
			<Form>
				<fieldset className='space-y-2'>
					<ControlText id='UserName' name='UserName' type='login' placeholder='Usuario SAP' />
					<ControlText id='Password' name='Password' type='password' placeholder='Contraseña' />
				</fieldset>
				<div className='mt-4'>
					<Button type='submit' className='btn primary w-full' isLoading={fetching}>
						Procesar
					</Button>
				</div>
			</Form>
		</Formik>
	)
}
