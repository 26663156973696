import { Route, Routes } from 'react-router-dom'
import { StockAlmacenPage } from '@/features/reports/inventario'
import { TopVentasPage } from '@/features/reports/ventas'
import { PartidasAbiertasPage } from '@/features/reports/informes'
import ReportsPage from '@/pages/ReportsPage'

export const ReportsRoutes = () => {
	return (
		<Routes>
			{/* eslint-disable-next-line react/jsx-no-undef*/}
			<Route path='/' element={<ReportsPage />} />

			{/*inventario*/}
			<Route path='/inventario' element={<ReportsPage />} />
			<Route path='/inventario/StockAlmacen' element={<StockAlmacenPage />} />

			{/*ventas*/}
			<Route path='/ventas' element={<ReportsPage />} />
			<Route path='/ventas/TopVentas' element={<TopVentasPage />} />

			{/*informes*/}
			<Route path='/informes' element={<ReportsPage />} />
			<Route path='/informes/PartidasAbiertas' element={<PartidasAbiertasPage />} />
		</Routes>
	)
}
