import DataGrid, {
	Column,
	Paging,
	Pager,
	Selection,
	SearchPanel,
	GroupPanel,
	FilterRow,
	Export
} from 'devextreme-react/data-grid'
import { documentsTableTypes } from './interfaces'

const DocumentsTable = ({ title, rows, onExport }: documentsTableTypes) => {
	return (
		<div className='shadow-lg rounded-b-md'>
			<span className='block w-full px-2 py-1 mb-2 bg-blue-300 rounded-t-md'>
				<h4 className='text-xl text-gray-700 font-semibold'>{title}</h4>
			</span>
			<div className='p-2'>
				<DataGrid
					dataSource={rows}
					columnHidingEnabled={true}
					showBorders={true}
					onExporting={onExport}
				>
					<GroupPanel visible={true} />
					<FilterRow visible={true} />
					<SearchPanel visible={true} highlightCaseSensitive={true} />
					<Paging defaultPageSize={10} />
					<Pager allowedPageSizes={[5, 10, 30, 50]} showPageSizeSelector={true} />
					<Selection mode='multiple' />
					<Export enabled={true} allowExportSelectedData={true} />
					<Column
						dataField='NoDocumento'
						caption='No.Documento'
						format=',##0.###'
						dataType='number'
						width={110}
					/>
					<Column dataField='Proveedor' caption='Proveedor' dataType='string' />
					<Column
						dataField='Cantidad'
						caption='Cantidad'
						format=',##0.###'
						dataType='number'
						width={80}
					/>
					<Column
						dataField='CantidadAbierta'
						caption='Cantidad abierta'
						format=',##0.###'
						dataType='number'
						width={120}
					/>
					<Column dataField='FechaLlegada' caption='Fecha llegada' dataType='date' width={100} />
					<Column dataField='Almacen' caption='Almacen' dataType='string' width={300} />
				</DataGrid>
			</div>
		</div>
	)
}

export default DocumentsTable
