import { useDispatch, useSelector } from 'react-redux'
import { useNotification, useToggle } from '@/shared/hooks'
import { getError } from '@/shared/helpers'
import {
  IFiltersTopVentas,
  topVentasReportTypes
} from '@/features/reports/ventas/topVentas/topVentas.types'
import { RootState } from '@/app/store/createStore'
import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import {
  catalogueTypes,
  placesWarehouseTypes,
  IUserShops
} from '@/features/reports/types/reports.types'
import {
  resetTopVentasReportData,
  setTopVentasReportData
} from '@/features/reports/ventas/topVentas/topVentas.slice'
import { env } from '@/env'
import { format } from 'date-fns'

const useFilterTopVentas = () => {
  const { user } = useSelector((state: RootState) => state.auth)
  const { topVentasReport } = useSelector((state: RootState) => state.reports.ventas)
  const useAxios = makeUseAxios({
    axios: axiosInstance(process.env.REACT_APP_API_WMS ?? (env.REACT_APP_API_WMS as string))
  })
  const useConfigurator = makeUseAxios({
    axios: axiosInstance(
      process.env.REACT_APP_API_CONFIGURATOR ?? (env.REACT_APP_API_CONFIGURATOR as string)
    )
  })
  const [{ loading: initialLoad, data: placesCatalogue }] = useAxios<placesWarehouseTypes[]>({
    url: '/reportes/stock/almacenes',
    method: 'get'
  })
  const [{ loading: initialLoadCat1, data: catFamilias }] = useAxios<catalogueTypes[]>({
    url: '/reportes/catalogo/familias',
    method: 'get'
  })
  const [{ loading: initialLoadCat2, data: catGeneros }] = useAxios<catalogueTypes[]>({
    url: '/reportes/catalogo/generos',
    method: 'get'
  })
  const [{ loading: initialLoadCat3, data: catGruposEdad }] = useAxios<catalogueTypes[]>({
    url: '/reportes/catalogo/grupos-edad',
    method: 'get'
  })
  const [{ data: userShops }] = useConfigurator<IUserShops>({ url: `/user/tiendas/${user?.Id}` })

  //console.log(JSON.stringify(placesCatalogue, null, 2))
  const [{ loading }, excuteReport] = useAxios<topVentasReportTypes[]>(
    { url: '/reportes/top-ventas', method: 'post' },
    { manual: true }
  )
  const [isOpen, setOpen] = useToggle(true)
  const dispatch = useDispatch()
  const notification = useNotification()

  const ReportType = [
    { code: '1', name: 'Más Venta' },
    { code: '2', name: 'Menos Venta' },
    { code: '3', name: 'Sin Venta' }
  ]

  const defaulRegionCheck = (code: string) => {
    if (userShops?.tiendas) {
      const find = userShops.tiendas
        ?.map((item) => ({
          Codigo: item.region
        }))
        .filter(
          (a, i) =>
            userShops.tiendas.findIndex((s) => a.Codigo === s.region && a.Codigo !== null) === i
        )
        .find((a) => a.Codigo === code)
      return find ? true : false
    }
    return false
  }

  const defaultLocationCheck = (code: number) => {
    if (user?.Localidades) {
      const find = user.Localidades.find((item) => item.Codigo === code)
      return find ? true : false
    }
    return false
  }

  const getReportData = async (values: IFiltersTopVentas) => {
    try {
      const dataSend = {
        TipoRpt: parseInt(values.ReportType),
        Top: parseInt(values.ShowTop),
        FechaInicio: format(values.DateStart, 'yyyy-MM-dd'),
        FechaFin: format(values.DateEnd, 'yyyy-MM-dd'),
        Vision: values.Vision,
        AuxVision: values.AuxVision.toString(),
        TipoVenta: values.InventoryType.includes('Primera')
          ? values.InventoryType.includes('Segunda')
            ? 0
            : 1
          : values.InventoryType.includes('Segunda')
          ? 2
          : 1,
        Linea: values.Segmento.includes('Linea') ? 1 : 0,
        Moda: values.Segmento.includes('Moda') ? 1 : 0,
        Acc: values.Segmento.includes('Accesorios') ? 1 : 0,
        Familia: values.Familias.toString(),
        Genero: values.Generos.toString(),
        Edad: values.GruposEdad.toString(),
        OmitDesc: values.OmitDesc ? 1 : 0,
        DescNoValido: values.DescNoValid,
        Orden: parseInt(values.OrdenBy),
        GroupByLocation: parseInt(values.GroupByLocation),
        GroupByItem: parseInt(values.GroupByItem),
        DiasValidoMov: values.DiasValidoMov
      }

      const { data } = await excuteReport({ data: { ...dataSend } })

      dispatch(
        setTopVentasReportData({
          data,
          ReportType: ReportType?.find((list) => list.code === values.ReportType)?.name || '',
          ShowTop: values.ShowTop,
          DateStart: values.DateStart,
          DateEnd: values.DateEnd,
          Segmentos: values.Segmento,
          Vision:
            values.Vision === '1'
              ? 'Grupo'
              : values.Vision === '2'
              ? 'Region'
              : values.Vision === '3'
              ? 'Plaza'
              : 'Tienda',
          AuxVision:
            values.Vision === '1'
              ? ['']
              : values.Vision === '2'
              ? placesCatalogue
                  ?.filter((item) => values.AuxVision.includes(item.region))
                  .map((data) => data.nombreRegion || '')
                  .filter(
                    (a, i) =>
                      placesCatalogue.findIndex((s) => a === s.nombreRegion && a !== null) === i
                  )
                  .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0)) || []
              : values.Vision === '3'
              ? placesCatalogue
                  ?.filter((item) => values.AuxVision.includes(item.codigoLocalidad.toString()))
                  .map((data) => data.plaza || '')
                  .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0)) || []
              : values.AuxVision,
          InventoryType: values.InventoryType,
          OmitDesc: values.OmitDesc,
          DescNoValid: values.DescNoValid,
          OrdenBy:
            values.Vision === '3'
              ? values.OrdenBy === '1'
                ? 'Tiempo'
                : values.OrdenBy === '2'
                ? 'Stock'
                : 'Margen'
              : values.OrdenBy === '1'
              ? 'Piezas'
              : values.OrdenBy === '2'
              ? 'Importe'
              : 'Margen',
          GroupByLocation:
            values.GroupByLocation === '1'
              ? 'Grupo'
              : values.GroupByLocation === '2'
              ? 'Region'
              : values.GroupByLocation === 'Plaza'
              ? ''
              : 'Tienda',
          GroupByItem: values.GroupByItem === '1' ? 'Modelo' : 'SKU',
          Familias:
            catFamilias
              ?.filter((item) => values.Familias.includes(item.Code))
              .map((data) => data.Name || '') || [],
          Generos:
            catGeneros
              ?.filter((item) => values.Generos.includes(item.Code))
              .map((data) => data.Name || '') || [],
          GruposEdad:
            catGruposEdad
              ?.filter((item) => values.GruposEdad.includes(item.Code))
              .map((data) => data.Name || '') || [],
          DiasValidoMov: values.DiasValidoMov
        })
      )
      if (isOpen) setOpen()
    } catch (error) {
      notification.error(getError(error))
    }
  }
  const onSubmit = async (values: IFiltersTopVentas) => {
    try {
      dispatch(resetTopVentasReportData())
      //console.log(JSON.stringify(values, null, 2))
      if (values.DateStart > values.DateEnd)
        throw new Error('La Fecha de inicio debe ser menos o igual a la fecha final')
      if (values.Segmento.length === 0)
        throw new Error('Necesita seleccionar por lo menos un segmento')
      if (values.InventoryType.length === 0)
        throw new Error('Necesita seleccionar por lo menos un tipo de venta')
      if (values.Vision === '1') values.AuxVision = []
      if (values.Vision === '2' && values.AuxVision.length === 0)
        throw new Error('Necesita seleccionar por lo menos una región')
      if (values.Vision === '3' && values.AuxVision.length === 0)
        throw new Error('Necesita seleccionar por lo menos una plaza')
      if (values.Vision === '4' && values.AuxVision.length === 0)
        throw new Error('Necesita seleccionar por lo menos una tienda')
      if (values.Familias.length === 0)
        throw new Error('Necesita seleccionar por lo menos una familia')
      if (values.Generos.length === 0)
        throw new Error('Necesita seleccionar por lo menos un genero')
      if (values.GruposEdad.length === 0)
        throw new Error('Necesita seleccionar por lo menos un grupo de edad')

      await getReportData(values)
    } catch (error) {
      notification.error(getError(error))
    }
  }

  const getUserDefaultShops = (warehouseCode: string) => {
    const warehouse = userShops?.tiendas
      .flatMap((item) => item.almacenes.filter((flat) => flat.selected))
      .map((flat) => flat.codigo)
    if (warehouse) {
      return warehouse.includes(warehouseCode)
    }
    return false
  }

  const userHasAShop = () => {
    const warehouse = userShops?.tiendas
      .flatMap((item) => item.almacenes.filter((flat) => flat.selected))
      .map((flat) => flat.codigo)
    return !!warehouse && warehouse.length > 0
  }

  return {
    isOpen,
    setOpen,
    ReportType,
    topVentasReport,
    initialLoad,
    initialLoadCat1,
    initialLoadCat2,
    initialLoadCat3,
    loading,
    placesCatalogue,
    defaulRegionCheck,
    defaultLocationCheck,
    catFamilias,
    catGeneros,
    catGruposEdad,
    onSubmit,
    getUserDefaultShops,
    userHasAShop
  }
}

export default useFilterTopVentas
