import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/app/store/createStore'
import { useNotification, useToggle, useSystemMessage } from '@/shared/hooks'
import { getError } from '@/shared/helpers'
import { ExportingEvent } from 'devextreme/ui/data_grid'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import {
	IConfigDiasSurtido,
	IDoscPendientes,
	IFiltersSurtidoTiendas,
	IItemStock,
	IParameterSGI,
	ISurtidoTiendaLog,
	ITienda,
	dataDetalleSurtidoTienda,
	logResultDocument,
	resultDocument,
	resultExec,
	WhsBufferPercentConfiguration
} from '@/features/inventoryAssortment/types/SurtidoTiendas.types'
import {
	resetSurtidoTiendasData,
	changeStep,
	setSurtidoTiendasData,
	addDocLog,
	setPenetracionBuffer
} from '@/features/inventoryAssortment/slices/SurtidoTiendas.slices'
import { format } from 'date-fns'
import { getServiceLayerError } from '@/app/service_layer/helpers/getServiceLayerError'
import { IWarehouse } from '@/features/operationStatus/types/warehouse'
import {
	useCreateStockTransferMutation,
	useCreateStockTransferRequestMutation,
	useLazyGetAllWarehousesQuery,
	useLazyGetStockTransferQuery,
	useLoginMutation
} from '@/features/operationStatus/service/serviceLayerService'
import { StockTransfer, StockTransferLines } from '@/features/operationStatus/types/stockTransfer'
import { StockTransferType } from '@/features/operationStatus/types/packingList'
import { useState } from 'react'

const useSurtidoTiendas = () => {
	const { user } = useSelector((state: RootState) => state.auth)
	const CompanyDB = process.env.REACT_APP_SAP_COMPANY_TEST ?? env.REACT_APP_SAP_COMPANY_TEST
	const [loading, setLoading] = useToggle(false)
	const [openConfirm, setOpenConfirm] = useToggle(false)
	const [generatingDocs, setGeneratingDocs] = useState(false)
	const [lstTiendasPlaza, setLstTiendasPlaza] = useState<ITienda[]>([])

	const [executeLogin] = useLoginMutation()
	const [executeGetAllWarehousesRequest] = useLazyGetAllWarehousesQuery()
	const [executeGetStockTransfer] = useLazyGetStockTransferQuery()

	const {
		surtidoTiendas: { data: dataSurtido, step: selectedTab, logDocs, whsBufferPercents, ...filters }
	} = useSelector((state: RootState) => state.repoInventory)

	const useAxios = makeUseAxios({
		axios: axiosInstance(process.env.REACT_APP_API_WMS ?? (env.REACT_APP_API_WMS as string))
	})

	const dispatch = useDispatch()
	const notification = useNotification()
	const systemMessage = useSystemMessage()

	const [{ loading: loadingTiendas }, executeGetTiendas] = useAxios<ITienda[]>(
		{ url: '/asistente-reposicion/tiendas', method: 'get' },
		{ manual: true }
	)

	const [{ loading: loadingBuffer }, excuteActualizarBuffer] = useAxios<resultExec>(
		{ url: '/asistente-reposicion/buffer', method: 'put' },
		{ manual: true }
	)

	const [{ loading: loadingDocsPendientes }, excuteGetDocsPendientes] = useAxios<IDoscPendientes[]>(
		{ url: '/asistente-reposicion/documentos-pendientes/tiendas/logs', method: 'post' },
		{ manual: true }
	)
	const [generatingDocsPends, setGeneratingDocsPends] = useToggle(false)

	const [{ loading: loadingDetalleNormal }, excuteDetalleNormal] = useAxios<
		dataDetalleSurtidoTienda[]
	>({ url: '/asistente-reposicion/tiendas', method: 'post' }, { manual: true })

	const [{ loading: loadingDetalleOneBeat }, excuteDetalleOneBeat] = useAxios<
		dataDetalleSurtidoTienda[]
	>({ url: '/asistente-reposicion-one-beat', method: 'post' }, { manual: true })

	const [{}, excuteGetParametro] = useAxios<IParameterSGI>(
		{ url: '/asistente-reposicion/parametros', method: 'post' },
		{ manual: true }
	)

	const [{}, excuteAddDocPendiente] = useAxios<resultExec>(
		{ url: '/asistente-reposicion/documentos-pendientes/tiendas', method: 'post' },
		{ manual: true }
	)

	const [executeCreateStockTransfer] = useCreateStockTransferMutation()
	const [executeCreateStockTransferRequest] = useCreateStockTransferRequestMutation()

	const [dataSurtidosByUser, setDataSurtidosByUser] = useState<ISurtidoTiendaLog[]>()
	const [{ loading: searching }, executeGetSurtidosByUser] = useAxios<ISurtidoTiendaLog[]>(
		{ url: '/asistente-reposicion/logs/lista', method: 'post' },
		{ manual: true }
	)
	const [{}, executeAddLog] = useAxios<ISurtidoTiendaLog[]>(
		{ url: '/asistente-reposicion/logs', method: 'post' },
		{ manual: true }
	)

	/**
	 * Este hook trae toda la configuracion de las tiendas
	 */
	const [{}, executeGetWhsAssormentConfiguration] = useAxios<IConfigDiasSurtido[]>(
		{
			url: '/asistente-reposicion/dias',
			method: 'get'
		},
		{ manual: true }
	)

	const onChangePreviousTab = async () => {
		dispatch(changeStep(selectedTab - 1))
	}

	const onChangeNextTab = async () => {
		dispatch(changeStep(selectedTab + 1))
	}

	const onReset = async () => {
		dispatch(resetSurtidoTiendasData())
		systemMessage.remove()
	}

	const onChangePlaza = async (code: string) => {
		setLstTiendasPlaza([])
		await executeGetTiendas({ params: { plaza: code } })
			.then(({ data }) => {
				setLstTiendasPlaza(data)
			})
			.catch((error) => {
				notification.error('No se obtuvo información de la tiendas.\nError: ' + getError(error))
			})
	}

	const CalculateValuesRepo = (
		Buffer: number,
		IsAccesory: boolean,
		RotacionCliente: number,
		MinimoAccesorio: number,
		RotacionCalculada: number,
		PromedioVentas: number,
		ExistenciasBodega: number,
		UltimaVenta: number,
		ExistenciaTienda: number,
		RotaAlmacen: number,
		RotaReal: number,
		TipoRep: string,
		Day7Qty: number
	) => {
		let reponer: number = 0
		let entregado: number = 0
		let negado: number = 0
		let DiferenciaRotacion: number

		switch (TipoRep) {
			case 'OneBeat':
				/*REPONER*/
				if (ExistenciaTienda >= Buffer) reponer = 0
				else reponer = Buffer - ExistenciaTienda

				/*ENTREGADO*/
				if (reponer > 0 && reponer < ExistenciasBodega) entregado = reponer
				else if (reponer > 0 && ExistenciasBodega > 0) entregado = ExistenciasBodega

				/*NEGADO*/
				if (reponer > 0) negado = reponer - entregado

				break
			case 'R':
				Buffer = MinimoAccesorio
				/*REPONER*/
				if (RotacionCliente != 99.99) DiferenciaRotacion = RotacionCliente - RotacionCalculada
				else DiferenciaRotacion = 1

				if (IsAccesory && RotacionCliente != 99.99) {
					if (ExistenciaTienda < MinimoAccesorio) {
						reponer = Math.round(RotacionCliente * MinimoAccesorio)
						if (reponer === 0) reponer = 1
					}
				} else {
					/*Validacion para Articulos con rotacion 99.99*/
					if (RotacionCliente != 99.99) {
						reponer = Math.round(DiferenciaRotacion * PromedioVentas)
						if (reponer === 0) reponer = 1
					} else {
						if (RotaAlmacen == 99.99 && RotaReal != 99.99) {
							/*EL ALMACEN MANEJA LA ROTACION 99.99 POR LO TANTO SURTE LA VENTA y se repone en base a la venta*/
							reponer = UltimaVenta
							if (reponer === 0) reponer = 1
						} else if (Buffer > ExistenciaTienda) {
							/*REPOSICION SEGUN BUFFER*/
							reponer = Buffer - ExistenciaTienda
						}
					}
				}

				/*ENTREGADO*/
				if (reponer > 0 && reponer < ExistenciasBodega) entregado = reponer
				else if (reponer > 0 && ExistenciasBodega > 0) entregado = ExistenciasBodega

				/*NEGADO*/
				if (reponer > 0) negado = reponer - entregado
				break
			default:
				/*REPONER*/
				reponer = Day7Qty

				/*ENTREGADO*/
				if (reponer > 0 && reponer < ExistenciasBodega) entregado = reponer
				else if (reponer > 0 && ExistenciasBodega > 0) entregado = ExistenciasBodega

				/*NEGADO*/
				if (reponer > 0) negado = reponer - entregado

				break
		}

		return [reponer, entregado, negado]
	}

	const generateReposicionesPendientes = async (docsPendientes: IDoscPendientes[]) => {
		setGeneratingDocsPends()
		try {
			for await (const doc of docsPendientes) {
				const dataStockTransfer = await executeGetStockTransfer({
					DocNum: doc.DocNumTransfer.toString()
				})
					.unwrap()
					.then((data) => {
						return data
					})
					.catch((error) => {
						notification.error(
							'No se obtivo informacion de la transferencia ' +
								doc.DocNumTransfer.toString() +
								'.\nError: ' +
								getServiceLayerError(error)
						)
						return undefined
					})

				if (dataStockTransfer === undefined) {
					return false
				}

				const dataSolicitud: StockTransfer = {
					DocDate: dataStockTransfer.DocDate,
					TaxDate: dataStockTransfer.TaxDate,
					PriceList: dataStockTransfer.PriceList,
					Comments: dataStockTransfer.Comments,
					FromWarehouse: doc.WhsPuente,
					ToWarehouse: doc.WhsCode,
					U_GSP_MOVESTOCK: dataStockTransfer.U_GSP_MOVESTOCK,
					StockTransferLines: dataStockTransfer.StockTransferLines.map((item) => ({
						ItemCode: item.ItemCode,
						Quantity: item.Quantity
					})),
					U_BYS_CodeDocBaseStr: dataStockTransfer.U_BYS_CodeDocBaseStr
				}
				const resultSolicitud: resultDocument = await createStockTransfer(
					dataSolicitud,
					'stockTransferRequest'
				)
				if (resultSolicitud.DocNum === 0) {
					notification.error(
						'No fue posible generar la solicitud de traslado de ' +
							doc.WhsFrom +
							' al ' +
							doc.WhsCode +
							'.\nError: ' +
							(resultSolicitud.Error ? resultSolicitud.Error : 'Sin Contexto')
					)
					setGeneratingDocsPends()
					return false
				}
			}
			setGeneratingDocsPends()
			return true
		} catch (error) {
			setGeneratingDocsPends()
			return false
		}
	}

	const getDetalleSurtido = async (values: IFiltersSurtidoTiendas) => {
		try {
			const dataSurtido: dataDetalleSurtidoTienda[] = []
			const dataSend = {
				fventa: values.FechaVenta,
				almacenes: "('" + values.Tiendas.toString().replaceAll(',', "','") + "')",
				incluirModa: values.Segmentos.includes('MODA') ? 'Y' : 'N',
				incluirAcc: values.Segmentos.includes('ACCESORIOS') ? 'Y' : 'N',
				incluirLinea: values.Segmentos.includes('LINEA') ? 'Y' : 'N',
				incluirOtros: values.Segmentos.includes('OTROS') ? 'Y' : 'N'
			}

			if (values.Base == 'OneBeat' || values.Base == 'Ambos') {
				/*CALCULO OBTENIDO CON ONE BEAT */
				const dataOneBeat = await excuteDetalleOneBeat({
					data: {
						...dataSend,
						fventa: format(dataSend.fventa, 'yyyy-MM-dd'),
						incluirAcc: values.Base === 'Ambos' ? 'N' : dataSend.incluirAcc,
						incluirOtros: values.Base === 'Ambos' ? 'N' : dataSend.incluirOtros
					}
				})
					.then(({ data }) => data)
					.catch(({ status, response }) => {
						if (status === 409) systemMessage.add(response.data.message)
						else notification.error(response.data.message)
						return []
					})
				dataSurtido.push(...dataOneBeat)
			}

			if (values.Base == 'Normal' || values.Base == 'Ambos') {
				const fechaVenta: Date = new Date(dataSend.fventa)
				if (values.Base == 'Ambos') fechaVenta.setDate(fechaVenta.getDate() - 1)
				const dataNormal = await excuteDetalleNormal({
					data: {
						...dataSend,
						fventa: format(fechaVenta, 'yyyy-MM-dd'),
						incluirLinea: values.Base === 'Ambos' ? 'N' : dataSend.incluirLinea,
						incluirModa: values.Base === 'Ambos' ? 'N' : dataSend.incluirModa
					}
				})
					.then(({ data }) => data)
					.catch((error) => {
						notification.error(getError(error))
						return []
					})
				dataSurtido.push(...dataNormal)
			}

			if (dataSurtido.length > 0) {
				for (let i = 0; i < dataSurtido.length; i++) {
					const calculateData: number[] = CalculateValuesRepo(
						dataSurtido[i].Buffer,
						dataSurtido[i].TipoArticulo === 'Accesorio' ? true : false,
						dataSurtido[i].MinStock,
						dataSurtido[i].MinOrder,
						dataSurtido[i].RotaCalc,
						dataSurtido[i].SalesAvg,
						dataSurtido[i].WhsFromOnHand,
						dataSurtido[i].Day7Qty,
						dataSurtido[i].WhsOnHand + dataSurtido[i].WhsOnHandTransito,
						dataSurtido[i].UUBysRotacion,
						dataSurtido[i].RotaReal,
						dataSurtido[i].TipoRep,
						dataSurtido[i].Day7Qty
					)
					dataSurtido[i].Reponer = calculateData[0]
					dataSurtido[i].Entregado = calculateData[1]
					dataSurtido[i].Negado = calculateData[2]

					/*GENERA UNICO ID CONCATENANDO (WhsFrom,WhsCode,ItemCode) */
					const ID: string =
						dataSurtido[i].WhsFrom + dataSurtido[i].WhsCode + dataSurtido[i].ItemCode
					dataSurtido[i].ID = ID.replaceAll('-', '')
				}

				/**Se destructura la configuracion del buffer */
				const whsBufferPercents: Array<WhsBufferPercentConfiguration> = []
				const { data } = await executeGetWhsAssormentConfiguration()
				if (data) {
					const whsBufferPercentsLines: Array<WhsBufferPercentConfiguration> = data.map((item) => ({
						almacen: item.Almacen,
						linea: item.PorcLinea,
						moda: item.PorcModa,
						accesorios: item.PorcAccesorios,
						otros: item.PorcOtros
					}))
					whsBufferPercents.push(...whsBufferPercentsLines)
				}

				dispatch(
					setSurtidoTiendasData({
						data: dataSurtido,
						logDocs: [],
						step: selectedTab,
						whsBufferPercents,
						...values
					})
				)
				onChangeNextTab()
			} else systemMessage.add('No se encontraron artículos para surtir')
		} catch (error) {
			notification.error(getError(error))
		}
	}

	const onSubmitFilters = async (values: IFiltersSurtidoTiendas) => {
		setLoading()
		try {
			dispatch(resetSurtidoTiendasData())
			dispatch(setPenetracionBuffer(values.penetracionBuffer))
			if (values.Segmentos.length === 0) throw new Error('Seleccione por lo menos un segmento')
			if (values.Plaza === 0) throw new Error('Seleccione una plaza')
			if (values.Tiendas.length === 0) throw new Error('Seleccione por lo menos una tienda')
			if (values.ListaPrecios === 0) throw new Error('Seleccione una lista de precios')

			/*INICIO DE SESION SAP*/
			await executeLogin({
				UserName: values.UserSAP,
				Password: values.PwdSAP,
				CompanyDB: CompanyDB
			})
				.unwrap()
				.catch((error) => {
					throw new Error('Inicio de sesión SAP no válida.\nError: ' + getServiceLayerError(error))
				})

			/*ACTUALIZAR BUFFER*/
			const { data: actualizarBuffer } = await excuteActualizarBuffer({
				data: { almacenes: values.Tiendas }
			})

			if (actualizarBuffer.status != 200) {
				throw new Error(
					actualizarBuffer.mensaje ? actualizarBuffer.mensaje : 'Error al actualizar buffer'
				)
			}

			/*CONSULTAR REPOSICIONES PENDIENTES*/
			const { data: docsPendientes } = await excuteGetDocsPendientes({
				data: {
					empresa: CompanyDB,
					almacenes: values.Tiendas
				}
			})

			if (docsPendientes.length > 0) {
				const generatePendientes: boolean = await generateReposicionesPendientes(docsPendientes)
				if (!generatePendientes) {
					throw new Error('Error al ejecutar las reposiciones pendiente\nIntenta de nuevo')
				} else {
					notification.success('Reposiciones pendientes generadas correctamente')
				}
			}

			/*OBTENER DETALLE DE SURTIDO*/
			await getDetalleSurtido(values)
			setLoading()
		} catch (error) {
			notification.error(getError(error))
			setLoading()
		}
	}

	const isSelectableRowDataSurtido = (item: dataDetalleSurtidoTienda) => {
		if (Number(item.Entregado) > 0) return true
		else {
			notification.warning('La linea no puede ser seleccionada porque la cantidad de Entrega es 0')
			return false
		}
	}

	const onGenerateSurtido = async (selectedRowKeys: any) => {
		setOpenConfirm()
		setLoading()
		try {
			/*OBTENER DATA LINEAS SELECCIONADAS*/
			const surtir: dataDetalleSurtidoTienda[] = dataSurtido.filter((item) =>
				selectedRowKeys.includes(item.ID)
			)
			if (surtir.length > 0) {
				setGeneratingDocs(true)
				/*realizar un datatable con el stock actual en los almacenes centrales de los artículos que se van a tratar*/
				let itemsStock: IItemStock[] = surtir.map((item) => ({
					ItemCode: item.ItemCode,
					WhsFrom: item.WhsFrom,
					WhsFromOnHand: item.WhsFromOnHand
				}))

				itemsStock = itemsStock.filter((item, index) => {
					return itemsStock.indexOf(item) === index
				})

				/*OBTENER ORDEN DE PREFERENCIA DE SURTIDO DE LAS TIENDAS*/
				const dataWarehouses: IWarehouse[] = await executeGetAllWarehousesRequest({
					params: {
						$filter:
							"WarehouseCode eq '" +
							filters.Tiendas.toString().replaceAll(',', "' or WarehouseCode eq '") +
							"'",
						$orderby: 'U_GSP_ITORDER'
					}
				})
					.unwrap()
					.then((data) => {
						return data.value
					})
					.catch((error) => {
						const errorMessage = getServiceLayerError(error)
						notification.error('No se obtuvo la preferencia de surtido.\nError: ' + errorMessage)
						return []
					})

				/*recorrer los almacenes/tiendas seleccionados y ordenados por prioridad*/
				for await (const warehouse of dataWarehouses) {
					/*filtrar solo las líneas del almacén actual y que se tienen que reponer*/
					const surtirTemp: dataDetalleSurtidoTienda[] = surtir
						.filter((item) => item.WhsCode === warehouse.WarehouseCode && Number(item.Reponer) > 0)
						.sort(
							(a, b) =>
								a.WhsFrom.localeCompare(b.WhsFrom) ||
								a.AlmPuenteVal.localeCompare(b.AlmPuenteVal) ||
								a.ItemCode.localeCompare(b.ItemCode)
						)
					if (surtirTemp.length > 0) await tratarLineas(itemsStock, surtirTemp)
				}
				setGeneratingDocs(false)
			} else {
				systemMessage.add('No se encontraron reposiciones por efectuar.')
			}
			setLoading()
			onChangeNextTab()
		} catch (error) {
			setLoading()
			setGeneratingDocs(false)
			notification.error(getServiceLayerError(error))
		}
	}

	const tratarLineas = async (itemsStock: IItemStock[], data: dataDetalleSurtidoTienda[]) => {
		let reponer_array: number[]
		let dataTraslado: StockTransfer | undefined
		let linesTraslado: StockTransferLines[] = []
		let whsFrom: string = ''
		let whsPuente: string = ''
		let whsTo: string = ''

		for await (const item of data) {
			if (whsFrom != item.WhsFrom) {
				if (whsFrom != '' && dataTraslado != undefined) {
					dataTraslado.StockTransferLines = linesTraslado
					/*GENERA TRANSFERENCIA DE STOCK*/
					await guardarStockTransfer(whsFrom, whsPuente, whsTo, dataTraslado)
				}
				const d = new Date()
				const CodeDocBaseStr: string = 'RT-WMS-' + format(d, 'yyyyMMddHmmssSSS')

				/*obtener razones de movimiento desde parametros GPS */
				const { data: MOVRAZON } = await excuteGetParametro({
					data: { nombre: 'MOVRAZON', aplicacion: 'GS_BYES_REP', empresa: CompanyDB }
				})

				/*iniciar nuevo data traslado*/
				linesTraslado = []
				dataTraslado = {
					DocDate: format(filters.FechaConta, 'yyyy-MM-dd'),
					TaxDate: format(filters.FechaDoc, 'yyyy-MM-dd'),
					PriceList: filters.ListaPrecios,
					FromWarehouse: item.WhsFrom,
					U_GSP_MOVESTOCK: MOVRAZON.Value.toString(),
					Comments: filters.Comentario,
					StockTransferLines: linesTraslado,
					U_BYS_CodeDocBaseStr: CodeDocBaseStr
				}
				whsFrom = item.WhsFrom
				whsPuente = item.AlmPuenteVal
				whsTo = item.WhsCode
			}

			/*obtener el stock actual del almacén central*/
			const stock = itemsStock.find(
				(i) => i.ItemCode === item.ItemCode && i.WhsFrom === item.WhsFrom
			)

			if (stock === undefined) {
				notification.error(
					'No se ha encontrado stock en' + item.WhsFrom + ' para el artículo ' + item.ItemCode
				)
				return false
			}

			/*calcular la reposición de esta línea con el stock actual del almacén central (Porque el stock del almacén central cambia continuamente)*/
			reponer_array = CalculateValuesRepo(
				item.Buffer,
				item.TipoArticulo === 'Accesorio' ? true : false,
				item.MinStock,
				item.MinOrder,
				item.RotaCalc,
				item.SalesAvg,
				stock.WhsFromOnHand,
				item.Day7Qty,
				item.WhsOnHand + item.WhsOnHandTransito,
				item.UUBysRotacion,
				item.RotaReal,
				item.TipoRep,
				item.Day7Qty
			)
			//const Reponer: number = reponer_array[0]
			const Entregado: number = reponer_array[1]
			const Negado: number = reponer_array[2]

			/*añadir la línea en el traslado si se tiene que reponer
        si la línea tiene ctd. entregado > 0 , validar la ctd. en stock del almacén central*/
			if (Entregado > 0) {
				linesTraslado.push({
					ItemCode: item.ItemCode,
					Quantity: Entregado,
					WarehouseCode: item.AlmPuenteVal,
					DistributionRule: item.Centro,
					U_U_BYS_EXISTENCIA: item.WhsOnHand.toString(),
					U_U_BYS_UVENTA: item.Day7Qty.toString(),
					U_U_BYS_NEGADO: Negado.toString(),
					U_U_BYS_ROTACION: Math.round(item.RotaCalc).toString()
				})
				/*MUY IMPORTANTE RESTAR DEL STOCK DEL ALMACÉN CENTRAL*/
				stock.WhsFromOnHand -= Entregado
			}
		}

		if (whsFrom != '' && dataTraslado != undefined && linesTraslado.length > 0) {
			dataTraslado.StockTransferLines = linesTraslado
			/*GENERA TRANSFERENCIA DE STOCK*/
			await guardarStockTransfer(whsFrom, whsPuente, whsTo, dataTraslado)
		}
		return true
	}

	const guardarStockTransfer = async (
		whsFrom: string,
		whsPuente: string,
		whsTo: string,
		dataTraslado: StockTransfer
	) => {
		const logResultDoc: logResultDocument = {
			WhsCodeFrom: whsFrom,
			AlmPuenteVal: whsPuente != whsTo ? whsPuente : '',
			WhsCode: whsTo
		}
		const resultTransfer: resultDocument = await createStockTransfer(dataTraslado, 'stockTransfer')

		if (resultTransfer.DocNum != 0) {
			/*GENERO CORRECTAMENTE LA TRANSFERENCIA*/
			if (whsPuente != whsTo) {
				/*CREAR SOLICITUD DE TRASLADO DEL PUENTE A LA TIENDA*/
				const dataSolicitud: StockTransfer = {
					DocDate: dataTraslado.DocDate,
					TaxDate: dataTraslado.TaxDate,
					PriceList: dataTraslado.PriceList,
					Comments: dataTraslado.Comments,
					FromWarehouse: whsPuente,
					ToWarehouse: whsTo,
					U_GSP_MOVESTOCK: dataTraslado.U_GSP_MOVESTOCK,
					StockTransferLines: dataTraslado.StockTransferLines.map((item) => ({
						ItemCode: item.ItemCode,
						Quantity: item.Quantity
					})),
					U_BYS_CodeDocBaseStr: dataTraslado.U_BYS_CodeDocBaseStr
				}
				const resultSolicitud: resultDocument = await createStockTransfer(
					dataSolicitud,
					'stockTransferRequest'
				)

				if (resultSolicitud.DocNum != 0) {
					/*SOLICITUD GENERADA CORRECTAMENTE*/
					logResultDoc.DocNum = resultTransfer.DocNum
					logResultDoc.SolDocNum = resultSolicitud.DocNum
				} else {
					await excuteAddDocPendiente({
						data: {
							documentos: 'SolTrasl',
							baseCode: dataTraslado.U_BYS_CodeDocBaseStr,
							empresa: CompanyDB,
							usuario: filters.UserSAP,
							ubicacion: 'Reposicion',
							whsCode: whsTo,
							whsPuente: whsPuente,
							whsFrom: whsFrom
						}
					})
					logResultDoc.DocNum = resultTransfer.DocNum
					logResultDoc.Error = resultSolicitud.Error
				}
			} else {
				logResultDoc.DocNum = resultTransfer.DocNum
			}
			/*AGREGAR LOG DE DOCUMENTOS DE SURTIDO DE TIENDAS*/
			await executeAddLog({
				data: {
					AlmOrigen: logResultDoc.WhsCodeFrom,
					AlmDestino: logResultDoc.WhsCode,
					Puente: logResultDoc.AlmPuenteVal != '' ? logResultDoc.AlmPuenteVal : null,
					FechaContabilidad: dataTraslado.DocDate,
					FechaDocumento: dataTraslado.TaxDate,
					BaseCodeStr: dataTraslado.U_BYS_CodeDocBaseStr,
					TransferDocNum: logResultDoc.DocNum,
					SolTrasladoDocNum: logResultDoc.SolDocNum ? logResultDoc.SolDocNum : null,
					Usr: user?.Id,
					Fecha: format(new Date(), 'yyyy-MM-dd'),
					Hora: format(new Date(), 'HH:mm:ss'),
					Empresa: CompanyDB
				}
			})
		} else {
			logResultDoc.Error = resultTransfer.Error
		}

		dispatch(addDocLog(logResultDoc))
	}

	const createStockTransfer = async (dataDoc: StockTransfer, type: StockTransferType) => {
		if (type === 'stockTransferRequest') {
			return await executeCreateStockTransferRequest({ ...dataDoc })
				.unwrap()
				.then((data) => {
					return { DocNum: data.DocNum, Error: '' }
				})
				.catch((error) => {
					return { DocNum: 0, Error: error.data.error.message }
				})
		} else {
			return await executeCreateStockTransfer({ ...dataDoc })
				.unwrap()
				.then((data) => {
					return { DocNum: data.DocNum, Error: '' }
				})
				.catch((error) => {
					return { DocNum: 0, Error: error.data.error.message }
				})
		}
	}

	const onExport = (evt: ExportingEvent<logResultDocument, any>) => {
		const workbook = new Workbook()
		const worksheet = workbook.addWorksheet('SURTIDO')
		exportDataGrid({
			component: evt.component,
			worksheet,
			autoFilterEnabled: true
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(
					new Blob([buffer], { type: 'application/octet-stream' }),
					'Docs Surtido Tiendas ' + format(new Date(), 'yyyy-MM-dd') + '.xlsx'
				)
			})
		})
		evt.cancel = true
	}

	const getSurtidosByUser = async (finicial: Date, ffinal: Date) => {
		try {
			if (finicial > ffinal)
				throw new Error('La Fecha inicio debe ser menor o igual a la fecha final')

			await executeGetSurtidosByUser({
				data: {
					Empresa: CompanyDB,
					Usr: user?.Id,
					FechaIni: format(finicial, 'yyyy-MM-dd'),
					FechaFin: format(ffinal, 'yyyy-MM-dd')
				}
			})
				.then(({ data }) => setDataSurtidosByUser(data))
				.catch(({ error }) => {
					setDataSurtidosByUser([])
					notification.error('No fue posible obtener informacion. Error: ' + getError(error))
				})
		} catch (error) {
			notification.error(getError(error))
		}
	}

	const validateWhsBufferPercent = (
		line: dataDetalleSurtidoTienda,
		warehousesConfig?: Array<WhsBufferPercentConfiguration>
	) => {
		if (!warehousesConfig) return false
		const whsConfig = warehousesConfig.find((whs) => whs.almacen === line.WhsCode)
		console.log(whsConfig)
		if (!whsConfig) return false

		switch (line.TipoArticulo) {
			case 'Linea':
				return line.PenetracionBuffer >= whsConfig.linea
			case 'Moda':
				return line.PenetracionBuffer >= whsConfig.moda
			case 'Accesorio':
				return line.PenetracionBuffer >= whsConfig.accesorios
			case 'Otros':
				return line.PenetracionBuffer >= whsConfig.otros
			default:
				return false
		}
	}

	return {
		loading,
		loadingTiendas,
		lstTiendasPlaza,
		onChangePlaza,
		loadingBuffer,
		loadingDetalleNormal,
		loadingDetalleOneBeat,
		user,
		filters,
		logDocs,
		dataSurtido,
		selectedTab,
		onChangePreviousTab,
		onReset,
		onSubmitFilters,
		generatingDocs,
		onGenerateSurtido,
		openConfirm,
		isSelectableRowDataSurtido,
		setOpenConfirm,
		onExport,
		loadingDocsPendientes,
		generatingDocsPends,
		dataSurtidosByUser,
		searching,
		getSurtidosByUser,
		whsBufferPercents,
		validateWhsBufferPercent
	}
}
export default useSurtidoTiendas
