import { IReportsList } from '@/features/reports/types/reports.types'
import {
  InventoryListDarkIcon,
  InventoryListLightIcon, ReportsDarkIcon, ReportsLightIcon,
  SalesDarkIcon,
  SalesLightIcon
} from '@/assets'

export const reports: IReportsList[] = [
  {
    title: 'Inventario',
    icons: {
      dark: InventoryListLightIcon,
      light: InventoryListDarkIcon
    },
    path: '/reports/inventario',
    items: [
      {
        name: 'Stock por almacén',
        path: '/StockAlmacen'
      }
    ]
  },
  {
    title: 'Ventas',
    path: '/reports/ventas',
    icons: {
      dark: SalesLightIcon,
      light: SalesDarkIcon
    },
    items: [
      {
        name: 'Top de ventas',
        path: '/TopVentas'
      }
    ]
  },
  {
    title: 'Informes',
    path: '/reports/informes',
    icons: {
      dark: ReportsLightIcon,
      light: ReportsDarkIcon
    },
    items: [
      {
        name: 'Lista de Partidas abiertas',
        path: '/PartidasAbiertas'
      }
    ]
  }
]
