import { Outlet, Navigate } from 'react-router-dom'
import { useAuthorization } from '@/features/auth/hooks'
import { DefaultTemplate } from '@/shared/templates'

/**
 * Este outlet maneja las autorizaciones de la pagina
 * cualquier pagina que este dentro de este outlet revisa
 * la autorizacion de usuario
 *
 * NOTA:
 * Puede regresar 3 componentes
 * 1: Retorna el loader de la pagina
 * 2: Retorna el outlet por consiguiente la pagina solicitada
 * 3: Retorna un Navigate, redirige el usuario a la pantalla de unauthorized
 */

const AuthorizationOutlet = () => {
	const { data, loading } = useAuthorization()

	if (loading) {
		return (
			<DefaultTemplate>
				<div className=' bg-sky-100 h-12 rounded-md shadow-sm flex items-center p-2'>
					<h1 className=' text-lg'>Cargando autorización de usuario....</h1>
				</div>
			</DefaultTemplate>
		)
	}

	if (data?.access) {
		return <Outlet />
	}

	return <Navigate to={'/unauthorized'} replace />
}

export default AuthorizationOutlet
