import { InventoryFlowFillBlack, UploadWhiteIcon } from '@/assets'
import { Title } from '@/shared/components/commons'
import { TabPanel } from 'devextreme-react'
import { Item } from 'devextreme-react/tab-panel'
import {
	DataTraspasosTiendasTemplate,
	FilterTraspasosTiendasTemplate
} from '@/features/inventoryAssortment/templates'
import { useTraspasosTiendas } from '@/features/inventoryAssortment/hooks'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

const TraspasosTiendasPage = () => {
	const { selectedTab, onReset } = useTraspasosTiendas()

	useEffect(() => {
		onReset()
	}, [])

	return (
		<>
			<div className='main-layout-toolbar toolbar'>
				<NavLink
					to={`/reposicion-de-inventario/traspasos-entre-tiendas/carga-archivo`}
					title='Carga de Traspasos de tiendas'
					className='m-0'
				>
					<img src={UploadWhiteIcon} className='w-7 h-7 cursor-pointer' />
				</NavLink>
			</div>

			<main className='main-layout-main p-4'>
				<Title
					title='Traspasos entre tiendas'
					pageName='Traspasos'
					backTo='/reposicion-de-inventario'
					backPage='Reposición de inventario'
					icon={InventoryFlowFillBlack}
				/>
				<TabPanel
					className='h-[calc(_100%_-_6rem)]'
					swipeEnabled={false}
					selectedIndex={selectedTab}
					onTitleClick={() => {
						return false
					}}
					onOptionChanged={() => {
						return false
					}}
					onSelectionChanged={() => {
						return false
					}}
					onItemClick={() => {
						return false
					}}
				>
					<Item title='Selección de filtro'>
						<FilterTraspasosTiendasTemplate />
					</Item>
					<Item title='Detalle'>
						<DataTraspasosTiendasTemplate />
					</Item>
				</TabPanel>
			</main>
		</>
	)
}

export default TraspasosTiendasPage
