import DataGrid, {
	Column,
	Paging,
	Pager,
	Selection,
	SearchPanel,
	GroupPanel,
	FilterRow,
	Export
} from 'devextreme-react/data-grid'
import { inventoryTableTypes } from './interfaces'

const InventoriesTable = ({ rows, onExport }: inventoryTableTypes) => {
	return (
		<div className='shadow-lg rounded-b-md'>
			<span className='block w-full px-2 py-1 mb-2 bg-blue-300 -z-10 rounded-t-md'>
				<h4 className='text-xl text-gray-700 font-semibold'>Informe de stock por almacen</h4>
			</span>
			<div className='p-2'>
				<DataGrid
					dataSource={rows}
					columnHidingEnabled={true}
					showBorders={true}
					onExporting={onExport}
				>
					<GroupPanel visible={true} />
					<FilterRow visible={true} />
					<SearchPanel visible={true} highlightCaseSensitive={true} />
					<Paging defaultPageSize={10} />
					<Pager allowedPageSizes={[5, 10, 30, 50]} showPageSizeSelector={true} />
					<Selection mode='multiple' />
					<Export enabled={true} allowExportSelectedData={true} />
					<Column dataField='Almacen' caption='Almacen' dataType='string' />
					<Column
						dataField='Stock'
						caption='Stock'
						format=',##0.###'
						dataType='number'
						width={60}
					/>
					<Column
						dataField='Disponible'
						caption='Disponible'
						format=',##0.###'
						dataType='number'
						width={90}
					/>
					<Column
						dataField='Solicitado'
						caption='Solicitado'
						format=',##0.###'
						dataType='number'
						width={80}
					/>
					<Column
						dataField='Comprometido'
						caption='Comprometido'
						format=',##0.###'
						dataType='number'
						width={110}
					/>
					<Column dataField='Localidad' caption='Localidad' dataType='string' width={130} />
					<Column dataField='Calidad' caption='Calidad' dataType='string' width={100} />
					<Column dataField='Tipo' caption='Tipo' dataType='string' width={80} />
				</DataGrid>
			</div>
		</div>
	)
}

export default InventoriesTable
