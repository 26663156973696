import { PlusSmallIcon, MinusSmallIcon } from '@heroicons/react/24/outline'
import { CheckListItem } from './types'
import CheckItem from './CheckItem'
import { useToggle } from '@/shared/hooks'

const CheckListItems = ({
	item,
	isChecked,
	isListChecked,
	onAllListCheck,
	onListItemCheck
}: CheckListItem) => {
	const [visible, setVisible] = useToggle()
	return (
		<div>
			<span className='flex items-center justify-between border-b border-slate-300'>
				<label htmlFor={item.id} className='block  text-xs font-bold'>
					<input
						id={item.id}
						type='checkbox'
						className='mr-1'
						onChange={onAllListCheck}
						checked={isListChecked(item.id)}
					/>
					{item.label}
				</label>
				<button type='button' onClick={setVisible}>
					{visible ? <MinusSmallIcon width={22} /> : <PlusSmallIcon width={22} />}
				</button>
			</span>

			<div
				className={`h-fit overflow-auto pl-4 mt-2 rounded-md bg-white   ${
					visible ? 'block' : 'hidden'
				}`}
			>
				{item.itemList?.map((list) => (
					<CheckItem
						key={list.id}
						value={list.value}
						id={list.id}
						isChecked={isChecked(list.value)}
						sup={list.sup}
						sub={list.sub}
						onListItemCheck={onListItemCheck}
						label={list.label}
					/>
				))}
			</div>
		</div>
	)
}

export default CheckListItems
