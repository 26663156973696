import { Formik, Form } from 'formik'
import { format } from 'date-fns'
import * as Yup from 'yup'
import { DateControl, SelectControl, ControlText } from '@/shared/components/fields'
import { Button } from '@/shared/components/commons'
import type { IDocumentValues, PriceList, Aduanas } from '../types/packingList'
import { env } from '@/env'
import { Movements } from '../types/shippingList.types'

type Props = {
	priceList?: PriceList[]
	onSubmit: (values: IDocumentValues) => Promise<void>
	movements?: Movements[]
	closeDrawer: () => void
	aduanas?: Aduanas[]
	Pedimento?: string | null
	FechaPedimento?: string | null
	AduanaPedimento?: string | null
}
export const PackingListDocumentsForm = ({
	priceList,
	movements,
	onSubmit,
	closeDrawer,
	aduanas,
	Pedimento,
	FechaPedimento,
	AduanaPedimento
}: Props) => {
	const initialValues: IDocumentValues = {
		deliveryNoteDocDate: format(new Date(), 'MM/dd/YYY'),
		deliveryNoteTaxDate: format(new Date(), 'MM/dd/YYY'),
		transferAssortmentDocDate: format(new Date(), 'MM/dd/YYY'),
		transferAssortmentTaxDate: format(new Date(), 'MM/dd/YYY'),
		transferAssortmentMovement: 'T215',
		revaluationDocDate: format(new Date(), 'MM/dd/YYY'),
		revaluationTaxDate: format(new Date(), 'MM/dd/YYY'),
		transferDocDate: format(new Date(), 'MM/dd/YYY'),
		transferTaxDate: format(new Date(), 'MM/dd/YYY'),
		transferMovement: 'T217',
		priceList: 1,
		CompanyDB: process.env.REACT_APP_SAP_COMPANY_TEST ?? env.REACT_APP_SAP_COMPANY_TEST,
		Password: '',
		UserName: '',
		pedimentCode: Pedimento ? Pedimento : '',
		landedCostRemarks: 'Documento generado desde WMS',
		deliveryNoteRemarks: 'Documento generado desde WMS',
		pedimentDate: format(new Date(FechaPedimento ? FechaPedimento : Date.now()), 'MM/dd/YYY'),
		aduana: AduanaPedimento ? AduanaPedimento : ''
	}

	const validationSchema = Yup.object().shape({
		deliveryNoteDocDate: Yup.string().required('Por favor, ingrese una fecha valida'),
		deliveryNoteTaxDate: Yup.string().required('Por favor, ingrese una fecha valida'),
		transferAssortmentDocDate: Yup.string().required('Por favor, ingrese una fecha valida'),
		transferAssortmentTaxDate: Yup.string().required('Por favor, ingrese una fecha valida'),
		transferAssortmentMovement: Yup.string().required(
			'Por favor, ingrese una razon de movimiento valida'
		),
		revaluationDocDate: Yup.string().required('Por favor, ingrese una fecha valida'),
		revaluationTaxDate: Yup.string().required('Por favor, ingrese una fecha valida'),
		transferDocDate: Yup.string().required('Por favor, ingrese una fecha valida'),
		transferTaxDate: Yup.string().required('Por favor, ingrese una fecha valida'),
		transferMovement: Yup.string().required('Por favor, ingrese una razon de movimiento valida'),
		priceList: Yup.number().required('Por favor, seleccione una lista de precios'),
		CompanyDB: Yup.string().required('Por favor, selecciona una empresa valida'),
		Password: Yup.string().required('Por favor, ingrese su contraseña de SAP'),
		UserName: Yup.string().required('Por favor, ingrese su usuaio de SAP '),
		aduana: Yup.string().when('pedimentCode', {
			is: (value: string) => !!value,
			then: Yup.string().required('Por favor, seleccione una lista de aduanas')
		})
	})

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values: IDocumentValues) => {
				onSubmit(values)
				closeDrawer()
			}}
			validationSchema={validationSchema}
			enableReinitialize
		>
			<Form>
				<fieldset className='space-y-2 border px-4 py-2 rounded-md border-gray-400 mb-2'>
					<legend className='text-xs font-bold'>Datos de entrega</legend>
					<DateControl
						id='deliveryNoteDocDate'
						name='deliveryNoteDocDate'
						label='Fecha de documento'
					/>
					<DateControl
						id='deliveryNoteTaxDate'
						name='deliveryNoteTaxDate'
						label='Fecha de contabilización'
					/>
					<ControlText
						id='deliveryNoteRemarks'
						name='deliveryNoteRemarks'
						type='textarea'
						label='Comentario de entrada de mercancia'
					/>
				</fieldset>
				<fieldset className='space-y-2 border px-4 py-2 rounded-md border-gray-400 mb-2'>
					<legend className='text-xs font-bold'>Datos de precios de entrega</legend>
					<ControlText id='pedimentCode' name='pedimentCode' type='text' label='No. Pedimento' />
					<ControlText
						id='landedCostRemarks'
						name='landedCostRemarks'
						type='textarea'
						label='Comentario de precios de entrega'
					/>
					<DateControl id='pedimentDate' name='pedimentDate' label='Fecha de pedimento' />
					<SelectControl id='aduana' name='aduana' label='Aduana'>
						{aduanas?.map((item) => (
							<option key={item.LugarAduana} value={`${item.NumeroAduana} - ${item.LugarAduana}`}>
								{item.NumeroAduana} - {item.LugarAduana}
							</option>
						))}
					</SelectControl>
				</fieldset>
				<fieldset className='space-y-2 border px-4 py-2 rounded-md border-gray-400 mb-2'>
					<legend className='text-xs font-bold'>Datos de surtido a cedis</legend>
					<DateControl
						id='transferAssortmentDocDate'
						name='transferAssortmentDocDate'
						label='Fecha de documento'
					/>
					<DateControl
						id='transferAssortmentTaxDate'
						name='transferAssortmentTaxDate'
						label='Fecha de contabilización'
					/>
					<SelectControl
						id='transferAssortmentMovement'
						name='transferAssortmentMovement'
						label='Razon de movimento'
					>
						{movements?.map((item) => (
							<option key={item.Code} value={item.Code}>
								{item.Code} - {item.Descripcion}
							</option>
						))}
					</SelectControl>
				</fieldset>
				<fieldset className='space-y-2 border px-4 py-2 rounded-md border-gray-400 mb-2'>
					<legend className='text-xs font-bold'>Datos de revalorización</legend>
					<DateControl
						id='revaluationDocDate'
						name='revaluationDocDate'
						label='Fecha de documento'
					/>
					<DateControl
						id='revaluationTaxDate'
						name='revaluationTaxDate'
						label='Fecha de contabilización'
					/>
				</fieldset>
				<fieldset className='space-y-2 border px-4 py-2 rounded-md border-gray-400 mb-2'>
					<legend className='text-xs font-bold'>Datos de transferencia</legend>
					<DateControl id='transferDocDate' name='transferDocDate' label='Fecha de documento' />
					<DateControl
						id='transferTaxDate'
						name='transferTaxDate'
						label='Fecha de contabilización'
					/>
					<SelectControl id='PriceList' name='PriceList' label='Lista de precios'>
						{priceList?.map((item) => (
							<option key={item.ListNum} value={item.ListNum}>
								{item.ListName}
							</option>
						))}
					</SelectControl>
					<SelectControl id='transferMovement' name='transferMovement' label='Razon de movimento'>
						{movements?.map((item) => (
							<option key={item.Code} value={item.Code}>
								{item.Code} - {item.Descripcion}
							</option>
						))}
					</SelectControl>
				</fieldset>
				<fieldset className='space-y-2 border px-4 py-2 rounded-md border-gray-400 mb-4'>
					<legend className='text-xs font-bold'>Usuario de SAP</legend>
					<ControlText type='login' id='UserName' name='UserName' placeholder='Usuario' />
					<ControlText type='password' id='Password' name='Password' placeholder='Contraseña' />
					<ControlText type='text' id='Company' name='CompanyDB' disabled={true} />
				</fieldset>
				<div className='flex justify-end'>
					<Button type='submit' className='btn primary'>
						Procesar seleccionados
					</Button>
				</div>
			</Form>
		</Formik>
	)
}
