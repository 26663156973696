import Portal from '../portal'
import { Overlay } from '../commons'
import { modalProps } from './types'
import { XMarkIcon } from '@heroicons/react/24/solid'

const Modal = ({
	children,
	wrapperId,
	handleClose,
	isOpen,
	width = 200,
	height = 200,
	title
}: modalProps) => {
	return (
		<Portal wrapperId={wrapperId}>
			<aside
				className={`portal__modal ${isOpen ? 'active' : ''}`}
				style={{ width: width, height: height }}
			>
				<section className='portal__modal__head'>
					<h4>{title}</h4>
					<button onClick={handleClose} className='flex items-center'>
						<XMarkIcon width={28} />
					</button>
				</section>
				<section className='portal__modal__body'>{children}</section>
			</aside>
			{isOpen ? <Overlay /> : null}
		</Portal>
	)
}

export default Modal
