import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  SAPShippingListSliceTypes,
  ShipmentsDocs,
  DocumentsCreationTypes
} from '../types/shippingList.types'
import { ShipmentIncidentsTypes } from '@/features/goodsReceipt/types/shippingList.types'

const initialState: SAPShippingListSliceTypes = {
  DocsQuantity: 0,
  DocsToGenerate: [],
  icidents: [],
  documentsCreation: []
}

const SAPShippingListSlice = createSlice({
  name: 'SAPShippingList',
  initialState,
  reducers: {
    setDocsToGenerate: (state, action: PayloadAction<ShipmentsDocs[]>) => ({
      ...state,
      DocsToGenerate: action.payload
    }),
    setClearDocs: (state, action) => ({ ...state, DocsToGenerate: [] }),
    setIncidents: (state, action: PayloadAction<ShipmentIncidentsTypes[]>) => ({
      ...state,
      icidents: action.payload
    }),
    setClearIncidents: (state, action) => ({ ...state, icidents: [] }),
    setDocumentsCreation: (state, action: PayloadAction<DocumentsCreationTypes>) => ({
      ...state,
      documentsCreation: [...state.documentsCreation, action.payload]
    }),
    setClearDocumentsCreation: (state, action: PayloadAction<any>) => ({
      ...state,
      documentsCreation: []
    }),
    setDocumentsQuantity: (state, action: PayloadAction<number>) => ({
      ...state,
      DocsQuantity: action.payload
    }),
    setDocumentsQuintityMinus: (state, action: PayloadAction<number>) => ({
      ...state,
      DocsQuantity: state.DocsQuantity - action.payload
    })
  }
})

export const {
  setDocsToGenerate,
  setClearDocs,
  setIncidents,
  setClearIncidents,
  setDocumentsCreation,
  setClearDocumentsCreation,
  setDocumentsQuantity,
  setDocumentsQuintityMinus
} = SAPShippingListSlice.actions

export default SAPShippingListSlice
