import {
	PreviewPDFReposicionPage,
	PreviewPDFSolTrasladoPage,
	PreviewPDFTrasladoPage
} from '@/features/inventoryAssortment/pages'
import { NotFoundPage } from '@/pages'
import { Routes, Route } from 'react-router-dom'

const PDFRouter = () => {
	return (
		<Routes>
			<Route path='/' element={<NotFoundPage />} />
			<Route path='/reposiciones/:Version/:DocNumTransfer' element={<PreviewPDFReposicionPage />} />
			<Route path='/solTraslado/:DocNumSol' element={<PreviewPDFSolTrasladoPage />} />
			<Route path='/traslado/:DocNumTransfer' element={<PreviewPDFTrasladoPage />} />
		</Routes>
	)
}

export default PDFRouter
