import { useSelector } from 'react-redux'
import { useNotification } from '@/shared/hooks'
import { RootState } from '@/app/store/createStore'
import Portal from '@/shared/components/portal'
import Notifications from '../components/Notifications'

const NotificationContainer = () => {
	const { notifications } = useSelector((state: RootState) => state)
	const { remove } = useNotification()
	return (
		<Portal wrapperId={'NOTIFICATION-DIVS'}>
			<aside className={`notification__container`}>
				{notifications.map((item) => (
					<Notifications key={item.id} {...item} close={remove} />
				))}
			</aside>
		</Portal>
	)
}

export default NotificationContainer
