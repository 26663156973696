import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { ServiceLayerError } from '../types/serviceLayer'

const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => 'data' in error
const isServiceLayerError = (data: any): data is ServiceLayerError => 'error' in data

export const getServiceLayerError = (error: unknown): string => {
	if (isFetchBaseQueryError(error)) {
		if (isServiceLayerError(error.data)) {
			return error.data.error.message
		}
	}
	return 'Error without handler'
}

export default getServiceLayerError
