import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { warehouseSliceTypes } from './warehouse.types'
import { productMarketingTypes, inventoryTypes } from '@/shared/interfaces/shared.interfaces'

const initialState: warehouseSliceTypes = {
  product: null,
  whsTypes: [1, 2, 3, 4],
  docTypes: [1, 2, 3, 4],
  withMovements: 1
}

export const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    setProduct: (state, action: PayloadAction<productMarketingTypes>) => ({
      ...state,
      product: { ...action.payload },
      lastProduct: action.payload.description?.ItemCode
    }),
    clearProduct: (state) => ({ ...state, product: null }),
    setWhsTypes: (state, action: PayloadAction<number[]>) => ({
      ...state,
      whsTypes: [...action.payload]
    }),
    setInventories: (state, action: PayloadAction<inventoryTypes[]>) => {
      if (state.product?.inventory) {
        return { ...state, product: { ...state.product, inventory: [...action.payload] } }
      }
      return state
    },
    setDocTypes: (state, action: PayloadAction<number[]>) => ({
      ...state,
      docTypes: [...action.payload]
    }),
    setWithMovements: (state, action: PayloadAction<number>) => ({
      ...state,
      withMovements: action.payload
    })
  }
})

export const {
  setProduct,
  clearProduct,
  setWhsTypes,
  setInventories,
  setDocTypes,
  setWithMovements
} = warehouseSlice.actions

export default warehouseSlice
