import { RefObject, useRef } from 'react'
import DataGrid, { Column, FilterRow, Item, Toolbar } from 'devextreme-react/data-grid'
import Button from 'devextreme-react/button'
import CheckBox from 'devextreme-react/check-box'
import { TextBox } from 'devextreme-react/text-box'
import { DataRowTemplateData } from 'devextreme/ui/data_grid'
import { HandRaisedIcon, HandThumbUpIcon, ShieldExclamationIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { useOnErrorImage, useToggle } from '@/shared/hooks'
import { ShipmentLines, ShipmentStatus } from '../types/shippingList.types'
import { clearFilterIcon } from '@/assets'

import { RootState } from '@/app/store/createStore'
import { useSelector } from 'react-redux'

type Props = {
	data?: ShipmentLines[]
	shipmentStatus: ShipmentStatus
	confirmedBulks: number[]
	dataGridRef: RefObject<DataGrid>
	inputRef: RefObject<TextBox>
	isOmitCheck: boolean
	onOmitCheck: (value: boolean) => void
	onPressClearFilter: () => void
	onValueChange: (value: number) => void
	onPressConfirm: (line: ShipmentLines) => void
	onPressIncident: (line: ShipmentLines) => void
	bulkHasIncidents: (bulk: number) => boolean
	bulkHasNotClearlyAmount: (bulk: number) => number
}

const ShippingListTable = ({
	data,
	shipmentStatus,
	confirmedBulks,
	dataGridRef,
	inputRef,
	onOmitCheck,
	isOmitCheck,
	onPressClearFilter,
	onValueChange,
	onPressConfirm,
	onPressIncident,
	bulkHasIncidents,
	bulkHasNotClearlyAmount
}: Props) => {
	const { user } = useSelector((state: RootState) => state.auth)
	const [isKeyboardDisabled, setIsKeyboardDisabled] = useToggle(true)
	const [replaceImage] = useOnErrorImage()
	const generateStatusColor = () => {
		switch (shipmentStatus) {
			case 'Confirmado':
				return 'bg-victoria-light'
			case 'Procesado':
				return 'bg-olive-light'
			case 'Recibido':
				return 'bg-grey-light'
			case 'Recibido Parcial':
				return 'bg-green-oliva'
			case 'Ninguno':
				return 'hidden'
			default:
				return ''
		}
	}
	const isbulConfimed = (idBulk: number) => confirmedBulks.includes(idBulk)

	const isScannerUsed = useRef<boolean>(false)

	const RowItem = ({ data }: DataRowTemplateData<ShipmentLines>) => {
		return (
			<>
				<tr>
					<td colSpan={3}>
						<div className={'flex justify-between items-center'}>
							<ul
								className={
									'text-gray-500 font-semibold flex flex-col md:flex-row md:space-x-2 md:items-center'
								}
							>
								<li>
									Caja: <span className={'font-bold text-gray-700'}>{data.Caja}</span>
								</li>
								<li>
									Bulto: <span className={'font-bold text-gray-700'}>{data.Bulto}</span>
								</li>
								<li>
									Articulo: <span className={'font-bold text-gray-700'}>{data.ItemCode}</span>
								</li>
								{data.DocNumGen ? (
									<li className='bg-green-light px-2 py-1 rounded-md text-white'>
										Doc.Generado:{' '}
										<span className={'font-bold text-gray-700'}>{data.DocNumGen}</span>
									</li>
								) : null}
							</ul>
							<span className='flex'>
								{bulkHasIncidents(+data.Bulto) ? (
									<button
										className='flex text-yellow-500 items-center font-semibold mr-4'
										onClick={() => onPressIncident(data)}
									>
										<ShieldExclamationIcon className='w-6 h-6 text-yellow-600 mr-1' />
										<p className='border-b border-yellow-600'>Ver Incidencias</p>
									</button>
								) : null}
								{data.EstatusBulto === 'C' || isbulConfimed(data.Bulto) ? (
									<CheckCircleIcon className={'w-6 h-6 text-green-500'} />
								) : null}
							</span>
						</div>
					</td>
				</tr>

				<tr className={'border-b-2'}>
					<td className={''} colSpan={3}>
						<section
							className={
								'flex flex-col  justify-start  xl:flex-row xl:items-stretch xl:justify-between xl:space-x-4'
							}
						>
							<div
								className={'flex flex-1 flex-col md:flex-row md:items-center lg:justify-between'}
							>
								<div className=' w-full md:w-3/4 flex items-center truncate'>
									<img
										src={data.Imagen}
										alt='Product'
										className='w-32 h-32 object-contain object-center mr-4'
										onError={replaceImage}
									/>
									<ul className={'text-gray-500 font-semibold space-y-1'}>
										<li>
											EAN: <span className={'font-bold text-gray-700'}>{data.EAN}</span>
										</li>
										<li>
											Articulo: <span className={'font-bold text-gray-700'}>{data.ItemCode}</span>
										</li>
										<li>
											Descripcion:{' '}
											<span className={'font-bold text-gray-700'}>{data.Descripcion}</span>
										</li>
										<li>
											Precio público:{' '}
											<span className={'font-semibold text-gray-700'}>${data?.PrecioPublico}</span>
										</li>
										<li
											className={` text-white font-bold w-36 py-1 px-2 rounded-md ${
												data.Calidad === 'PRIMERA' ? 'bg-green-500' : 'bg-red-500'
											}`}
										>
											Calidad: <span className={'font-bold text-gray-700'}>{data.Calidad}</span>
										</li>
									</ul>
								</div>
								<div className='w-full mt-4 pl-4 md:mt-0 md:w-1/4 flex flex-col justify-start md:justify-end lg:justify-center space-y-2'>
									<h5 className={'text-lg font-semibold text-gray-600 uppercase'}>
										Cantidad: <span className={'text-sky-800 font-bold'}>{data.Cantidad}</span>
									</h5>
									<h5 className='text-sm font-semibold text-gray-400'>
										Por aclarar:{' '}
										<span className='font-bold text-gray-500'>
											{bulkHasNotClearlyAmount(data.Bulto)}
										</span>
									</h5>
								</div>
							</div>

							<div className='flex items-center space-x-2 mt-4 xl:mt-0'>
								<button
									className='btn flex-button danger'
									onClick={() => onPressIncident(data)}
									disabled={shipmentStatus === 'Confirmado' || shipmentStatus === 'Recibido'}
								>
									<HandRaisedIcon width={32} className='mr-2' />
									Reportar incidencia
								</button>
								<button
									className='btn flex-button success'
									onClick={() => onPressConfirm(data)}
									disabled={
										shipmentStatus === 'Confirmado' ||
										data.EstatusBulto === 'C' ||
										shipmentStatus === 'Recibido'
									}
								>
									<HandThumbUpIcon width={32} className='mr-2' />
									Confirmar sin incidencias
								</button>
							</div>
						</section>
					</td>
				</tr>
			</>
		)
	}

	return (
		<div className='packing-table-container'>
			<DataGrid
				dataSource={data}
				showBorders={true}
				dataRowRender={RowItem}
				hoverStateEnabled={true}
				columnAutoWidth={true}
				ref={dataGridRef}
				onContentReady={(e) => {
					if (isScannerUsed.current) {
						const row = e.component.getVisibleRows()
						if (row.length === 1 && user?.confirmGlobalShippingList === 'CB') {
							onPressConfirm(row[0].data)
						}
						isScannerUsed.current = false
					}
				}}
			>
				<FilterRow visible={true} />
				<Column width={120} dataField={'Bulto'} visible={true} dataType='number' />
				<Column width={120} dataField={'Caja'} visible={true} />
				<Column dataField={'Articulo'} visible={true} />
				<Toolbar>
					<Item location='before'>
						<span>
							<CheckBox
								text='Omitir confirmados'
								className='mr-2'
								onValueChange={onOmitCheck}
								value={isOmitCheck}
							/>
							<CheckBox
								text='No usar teclado'
								className='mb-1'
								value={isKeyboardDisabled}
								onValueChange={setIsKeyboardDisabled}
							/>
						</span>
						<span className='flex items-center'>
							<TextBox
								placeholder='Buscar Bulto'
								ref={inputRef}
								inputAttr={{ inputMode: isKeyboardDisabled ? 'none' : 'text' }}
								onValueChange={(e) => {
									onValueChange(e)
									isScannerUsed.current = true
								}}
							/>
							<Button icon={clearFilterIcon} className='ml-1' onClick={onPressClearFilter} />
						</span>
					</Item>
					<Item location='after'>
						<span className={`px-4 py-2 rounded-md text-xl ${generateStatusColor()}`}>
							{shipmentStatus}
						</span>
					</Item>
				</Toolbar>
			</DataGrid>
		</div>
	)
}

export default ShippingListTable
