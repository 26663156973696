import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: string[] = []

export const systemMessageSlice = createSlice({
	name: 'systemMessage',
	initialState,
	reducers: {
		newMessage: (state, action: PayloadAction<string>) => [action.payload],
		deleteMessage: () => []
	}
})

export const { newMessage, deleteMessage } = systemMessageSlice.actions

export default systemMessageSlice
