import { ItemTraspasoProps } from '@/features/inventoryAssortment/types/TraspasosTiendas.types'
import { useOnErrorImage } from '@/shared/hooks'
import { useState } from 'react'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { data } from 'autoprefixer'

const ItemTraspaso = ({
	allowEdit,
	DocNum1,
	DocNum2,
	Item,
	handleChangeCant
}: ItemTraspasoProps) => {
	const [replaceImg] = useOnErrorImage()
	const [cantSurtida, setCantidadSurtida] = useState(Item.CantSurtida)
	const [cantConfirmada, setCantidadConfirmada] = useState(Item.CantConfirmada)

	const onChangeCantidad = (value: number, type: string) => {
		if (type == 'S') {
			setCantidadSurtida(Number(value))
			handleChangeCant(Item.ItemCode, Number(value), type)
		}

		setCantidadConfirmada(Number(value))
		handleChangeCant(Item.ItemCode, Number(value), type)
	}

	const lineHasIssues = () => {
		return (Item.CantSurtida && Item.CantConfirmada) !== Item.CantSugerida
	}

	return (
		<div className='relative p-3 my-2 border rounded-lg text-sm flex'>
			<div className='w-1/4 mr-2'>
				<img
					src={Item?.Imagen}
					className='w-28 h-28 object-contain object-center my-auto mr-2'
					onError={replaceImg}
					alt={Item?.Descripcion}
				/>
			</div>
			{lineHasIssues() ? (
				<div className=' absolute top-0 right-0'>
					<ExclamationTriangleIcon width={32} className=' text-yellow-500' />
				</div>
			) : null}

			<div className='w-3/4'>
				<p className='font-bold'>{Item.ItemCode}</p>
				<p>{Item.Descripcion}</p>
				<p>Cant. Sugerida: {Item.CantSugerida}</p>
				<p>
					Cant. Surtida:{' '}
					{allowEdit && DocNum1 == 0 ? (
						<input
							type='number'
							value={cantSurtida}
							className='field-input text-center'
							style={{ width: '70px', height: '25px' }}
							onChange={(e) => onChangeCantidad(Number(e.target.value), 'S')}
							min={0}
						/>
					) : (
						<span>{cantSurtida}</span>
					)}
				</p>
				{DocNum1 != 0 && (
					<p>
						Cant. Confirmada:{' '}
						{cantSurtida != undefined && cantSurtida > 0 && allowEdit && DocNum2 == 0 ? (
							<input
								type='number'
								value={cantConfirmada}
								className='field-input text-center'
								style={{ width: '70px', height: '25px' }}
								onChange={(e) => onChangeCantidad(Number(e.target.value), 'C')}
								min={0}
							/>
						) : (
							<span
								className={`${
									lineHasIssues() ? 'px-2 py-1 bg-yellow-500 font-bold rounded-md' : ''
								} `}
							>
								{cantConfirmada}
							</span>
						)}
					</p>
				)}
			</div>
		</div>
	)
}

export default ItemTraspaso
