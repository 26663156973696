import { AxiosError } from 'axios'
import { axiosErrorTypes } from '../interfaces/helpers.interfaces'

export const getError = (error: unknown): string => {
	if (error instanceof AxiosError) {
		if (error.response?.data) {
			const response: axiosErrorTypes = error.response.data
			return response.message
		}
		return error.message
	}

	if (error instanceof Error) {
		return error.message
	}
	return 'Error sin contexto'
}

export default getError
