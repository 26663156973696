import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { env } from '@/env'

export const serviceLayerBaseApi = createApi({
	reducerPath: 'serviceLayerApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_SERVICE_LAYER ?? env.REACT_APP_SERVICE_LAYER,
		credentials: 'include',
		prepareHeaders: (headers) => headers
	}) as BaseQueryFn<string | FetchArgs>,
	endpoints: () => ({})
})
