import { AxiosError } from 'axios'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { ServiceLayerError } from '../types/Error.types'

const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => 'data' in error
const isServiceLayerError = (data: any): data is ServiceLayerError => 'error' in data

export const getServiceLayerError = (error: unknown): string => {
	if (error instanceof AxiosError) {
		if (error.response?.data) {
			const response: ServiceLayerError = error.response.data
			return response.error.message
		}
		return error.message
	}

	if (error instanceof Error) {
		return error.message
	}

	if (isFetchBaseQueryError(error)) {
		if (isServiceLayerError(error.data)) {
			return error.data.error.message
		}
	}
	return 'Error sin contexto'
}
