import {
	FilterPartidasAbiertasTemplate,
	DataPartidasAbiertasTemplate
} from '@/features/reports/informes/partidasAbiertas/templates'

const PartidasAbiertasPage = () => {
	return (
		<>
			<FilterPartidasAbiertasTemplate />
			<DataPartidasAbiertasTemplate />
		</>
	)
}

export default PartidasAbiertasPage
