import { Routes, Route } from 'react-router-dom'
import { ShippingListPage, GoodsReceiptPage, PackingListPage } from '../pages'

const GoodsReceiptRoutes = () => {
	return (
		<Routes>
			<Route path='/' element={<GoodsReceiptPage />} handle />
			<Route path='embarques' element={<ShippingListPage />} />
			<Route path='packing' element={<PackingListPage />} />
		</Routes>
	)
}

export default GoodsReceiptRoutes
