import { useNotification, useToggle } from '@/shared/hooks'
import {
  IFiltersPartidasAbiertas,
  partidasAbiertasReportTypes
} from '@/features/reports/informes/partidasAbiertas/partidasAbiertas.types'
import {
  resetPartidasAbiertasReportData,
  setPartidasAbiertasReportData
} from '@/features/reports/informes/partidasAbiertas/partidasAbiertas.slice'
import { getError } from '@/shared/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'
import { catalogueClientesFilialAndProv } from '@/features/reports/types/reports.types'
import { RootState } from '@/app/store/createStore'
import { format } from 'date-fns'

const useFilterPartidasAbiertas = () => {
  const { user } = useSelector((state: RootState) => state.auth)
  const useAxios = makeUseAxios({
    axios: axiosInstance(process.env.REACT_APP_API_WMS ?? (env.REACT_APP_API_WMS as string))
  })

  const [{ loading: initialLoadCat, data: catClientes }] = useAxios<
    catalogueClientesFilialAndProv[]
  >({
    url: '/reportes/catalogo/clientesFilialYProveeduria',
    method: 'get'
  })

  const [{ loading }, excuteReport] = useAxios<partidasAbiertasReportTypes[]>(
    { url: '/reportes/partidas-abiertas', method: 'post' },
    { manual: true }
  )

  const [isOpen, setOpen] = useToggle(true)
  const dispatch = useDispatch()
  const notification = useNotification()
  const DocType = [
    { code: 1, name: 'Solicitud de traslado' },
    { code: 2, name: 'Orden de venta' }
  ]
  const DateType = [
    { code: 1, name: 'Fecha de documento' },
    { code: 2, name: 'Fecha de envio' },
    { code: 3, name: 'Fecha de entrega' }
  ]
  const ClienteType = [
    { code: 1, name: 'Filial' },
    { code: 2, name: 'Proveeduria' }
  ]

  const defaultLocationCheck = (code: number) => {
    if (user?.Localidades) {
      const find = user.Localidades.find((item) => item.Codigo === code)
      return find ? true : false
    }
    return false
  }

  const getReportData = async (values: IFiltersPartidasAbiertas) => {
    try {
      const dataSend = {
        TipoDoc: values.DocType,
        TipoFecha: values.DateType,
        FechaInicio: format(values.DateStart, 'yyyy-MM-dd'),
        FechaFin: format(values.DateEnd, 'yyyy-MM-dd'),
        Clientes: values.Clientes.toString(),
        Segmentos: values.Segmentos.toString()
      }

      const { data } = await excuteReport({ data: { ...dataSend } })

      dispatch(
        setPartidasAbiertasReportData({
          data,
          DocType: DocType?.find((list) => list.code == values.DocType)?.name || '',
          DateType: DateType?.find((list) => list.code == values.DateType)?.name || '',
          DateStart: values.DateStart,
          DateEnd: values.DateEnd,
          ClienteType: ClienteType?.find((list) => list.code == values.ClienteType)?.name || '',
          Clientes: values.Clientes,
          Segmentos: values.Segmentos,
          selectedLines: []
        })
      )
      if (isOpen) setOpen()
    } catch (error) {
      notification.error(getError(error))
    }
  }

  const onSubmit = async (values: IFiltersPartidasAbiertas) => {
    try {
      dispatch(resetPartidasAbiertasReportData())
      //console.log(JSON.stringify(values, null, 2))
      if (values.DocType === 0) throw new Error('Necesita seleccionar un tipo de documento valido')
      if (values.DateType === 0) throw new Error('Necesita seleccionar un tipo de fecha valido')
      if (values.DateStart > values.DateEnd)
        throw new Error('La Fecha de inicio debe ser menor o igual a la fecha final')
      if (values.Segmentos.length === 0)
        throw new Error('Necesita seleccionar por lo menos un segmento')
      if (values.Clientes.length === 0)
        throw new Error('Necesita seleccionar por lo menos un cliente')
      await getReportData(values)
    } catch (error) {
      notification.error(getError(error))
    }
  }

  return {
    isOpen,
    setOpen,
    DocType,
    DateType,
    ClienteType,
    initialLoadCat,
    catClientes,
    loading,
    defaultLocationCheck,
    onSubmit
  }
}
export default useFilterPartidasAbiertas
