import SerchProductTemplate from './SearchProductTemplate'
import { warehouseTemplateTypes } from './types'

const WarehouseTemplate = ({ children }: warehouseTemplateTypes) => {
	return (
		<>
			<div className='main-layout-toolbar toolbar'>
				<SerchProductTemplate />
			</div>
			<main className='main-layout-main p-4'>{children}</main>
		</>
	)
}

export default WarehouseTemplate
