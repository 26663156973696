import { Modal } from '@/shared/components/modals'
import { InicioSAPForm } from '@/shared/forms/InicioSAPForm'
import { ISesionSAP } from '@/shared/templates/types'
import { Button } from 'devextreme-react'

interface PropsModal {
	isOpen: boolean
	handleClose: () => void
	onSubmitForm: (e: ISesionSAP) => void
}

export const ModalInicioSAPTemplate = ({ isOpen, handleClose, onSubmitForm }: PropsModal) => {
	return (
		<Modal
			wrapperId='InicioSesionSAP'
			isOpen={isOpen}
			handleClose={handleClose}
			height={350}
			width={350}
			title='Inicio de sesión SAP'
		>
			<InicioSAPForm onSubmit={(e) => onSubmitForm(e)} />
			<Button className='btn secondary w-full mt-2' onClick={handleClose}>
				Cancelar
			</Button>
		</Modal>
	)
}
