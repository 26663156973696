import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { notificationSliceTypes } from './notifications.types'

const initialState: notificationSliceTypes[] = []

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<notificationSliceTypes>) => [
      ...state,
      action.payload
    ],
    removeNotification: (state, action: PayloadAction<string>) =>
      state.filter((item) => action.payload !== item.id),
    clearNotifications: (state, action: PayloadAction) => []
  }
})

export const { addNotification, removeNotification, clearNotifications } = notificationSlice.actions

export default notificationSlice
