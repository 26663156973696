import { useRef, useState } from 'react'
import DataGrid from 'devextreme-react/data-grid'
import { TextBox } from 'devextreme-react/text-box'
import { makeUseAxios } from 'axios-hooks'
import { env } from '@/env'
import { useNotification, useToggle } from '@/shared/hooks'
import { getError } from '@/shared/helpers'
import axiosInstance from '@/app/services/axiosInstance.service'
import type {
  ShipmentTypes,
  ShipmentIncidentsTypes,
  ShipmentLines
} from '../types/shippingList.types'
import { IncidentValues } from '@/features/goodsReceipt/components/types'

import { useDispatch, useSelector } from 'react-redux'
import {
  setShipment,
  clearShipment,
  setConfirmBulk,
  refreshShipmentIncidents
} from '../slices/ShippingReceiptSlice'
import { RootState } from '@/app/store/createStore'

export const useShipping = () => {
  const dataGridRef = useRef<DataGrid>(null)
  const inputRef = useRef<TextBox>(null)
  const searchRef = useRef<HTMLInputElement>(null)
  const useAxios = makeUseAxios({
    axios: axiosInstance(process.env.REACT_APP_API_WMS ?? env.REACT_APP_API_WMS)
  })
  const notification = useNotification()
  const dispatch = useDispatch()
  const { data, incidents, shipmentStatus, confirmedBulks, shipmentDocuments, shipmentId } =
    useSelector((state: RootState) => state.shippingReceipt)

  const [currentLine, setCurrentLine] = useState<ShipmentLines | null>(null)
  const [isScannerOpen, setIsScannerOpen] = useToggle()
  const [isOmitChecked, setIsOmitChecked] = useToggle()
  const [isDialogOpen, setIsDialogOpen] = useToggle()
  const [isDrawerIncidentsOpen, setIsDrawerIncidenstOpen] = useToggle()

  const [{ loading: isSearchingShipment }, executeGetShipment] = useAxios<ShipmentTypes>(
    { method: 'GET' },
    { manual: true }
  )
  const [, executeGetIncidents] = useAxios<ShipmentIncidentsTypes[]>(
    { url: '/listas-embarque/incidencias/', method: 'GET' },
    { manual: true }
  )
  const [{ loading: isPostingIncidents }, executePostIncident] = useAxios(
    { url: '/listas-embarque/incidencias/', method: 'POST' },
    { manual: true }
  )
  const [, executeUpdateBulk] = useAxios({ method: 'PUT' }, { manual: true, autoCancel: false })
  const [{ loading: isUpdatingShipment }, executeUpdateShipment] = useAxios(
    { method: 'PUT' },
    { manual: true }
  )
  const getShipmentListByNumber = async (shipmentNumber: string) => {
    try {
      if (searchRef.current && shipmentNumber.trim() === '') {
        notification.error('Debe ingresar un numero de embarque valido')
        searchRef.current.focus()
        return
      }
      const shipment = await executeGetShipment({ url: `/listas-embarque/${shipmentNumber}` })
      const incidents = await executeGetIncidents({ params: { shippingId: shipmentNumber } })
      if (shipment.data.Embarque && shipment.data.Embarque.length > 0) {
        const shipmentStatus = shipment.data.Embarque[0].EstatusEmbarque
        const shipmentData = shipment.data.Embarque
        const shipmentIncidents = incidents.data
        const shipmentDocuments = shipment.data.Documentos
        dispatch(clearShipment())
        dispatch(
          setShipment({
            data: shipmentData,
            shipmentStatus,
            incidents: shipmentIncidents,
            shipmentDocuments,
            shipmentId: +shipmentNumber
          })
        )
        if (dataGridRef.current && inputRef.current) {
          dataGridRef.current.instance.clearFilter()
          if (isOmitChecked) {
            dataGridRef.current.instance.filter(['EstatusBulto', '<>', 'C'])
          }
          inputRef.current.instance.reset()
          inputRef.current.instance.focus()
        }
        if (isScannerOpen) {
          setIsScannerOpen()
        }
      } else {
        notification.error('El embarque se se encuentra con status recibido parcial')
      }
    } catch (error) {
      notification.error(getError(error))
    }
  }

  const onPressIncident = (line: ShipmentLines) => {
    if (line) {
      setCurrentLine(line)
      setIsDrawerIncidenstOpen()
    }
  }

  const onPressConfirm = async (line: ShipmentLines) => {
    if (data) {
      if (line.EstatusBulto !== 'C' && !confirmedBulks.includes(line.Bulto)) {
        try {
          await executeUpdateBulk({ url: `/listas-embarque/bulto/status/${line.Bulto}` })
          dispatch(setConfirmBulk(line.Bulto))
        } catch (error) {
          notification.error(getError(error))
        }
      }
    }
  }

  const onDecodeShipmentBarcode = async (value: string) => {
    await getShipmentListByNumber(value)
  }

  const onCheckOmit = (value: boolean) => {
    setIsOmitChecked()
    if (dataGridRef.current) {
      if (value) {
        dataGridRef.current.instance.filter(['EstatusBulto', '<>', 'C'])
      } else {
        dataGridRef.current.instance.clearFilter()
      }
    }
  }

  const onFilterBulkChange = (value: number) => {
    if (dataGridRef.current && inputRef.current) {
      if (value) {
        dataGridRef.current.instance.filter(['Bulto', '=', value])
        inputRef.current.instance.focus()
        inputRef.current.instance.resetOption('value')
      }
    }
  }

  const onPressClearFilter = () => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.clearFilter()
      if (isOmitChecked) {
        dataGridRef.current.instance.filter(['EstatusBulto', '<>', 'C'])
      }
    }
  }

  const postIncident = async ({
    type,
    packedQuantity,
    receivedAmount,
    reason,
    images
  }: IncidentValues) => {
    try {
      if (currentLine && data) {
        const formData = new FormData()
        formData.append('shippingId', shipmentId.toString())
        formData.append('type', type.toString())
        formData.append('bulkId', currentLine.Bulto.toString())
        formData.append('packedQuantity', packedQuantity.toString())
        formData.append('receivedAmount', receivedAmount.toString())
        formData.append('reason', reason)
        if (images) {
          formData.append('images', images, images.name)
        }
        await executePostIncident({ data: formData })
        const { data } = await executeGetIncidents({ params: { shippingId: shipmentId } })
        dispatch(refreshShipmentIncidents(data))
      }
    } catch (error) {
      notification.error(getError(error))
    }
  }

  const updateShipmentStatus = async (shipmentId: number, status: string) => {
    if (data && shipmentStatus !== 'Ninguno') {
      try {
        await executeUpdateShipment({
          url: `/listas-embarque/status/${shipmentId}`,
          data: { status }
        })
        await getShipmentListByNumber(shipmentId.toString())
        notification.success('Embarque actualizado')
      } catch (error) {
        notification.error(getError(error))
      }
    }
  }

  const bulkHasIncidents = (bulk: number) => {
    if (incidents) {
      return incidents.some((item) => item.Bulto === bulk)
    }
    return false
  }

  const bulkHasNotClearlyAmount = (bulk: number): number => {
    if (incidents) {
      const bulkIncidents = incidents.filter((item) => item.Bulto === bulk)
      if (bulkIncidents) {
        const lastIncident = bulkIncidents.pop()
        if (lastIncident) {
          return lastIncident.CantidadAclarar ?? 0
        }
      }
    }
    return 0
  }

  return {
    data,
    incidents,
    confirmedBulks,
    shipmentStatus,
    isSearchingShipment,
    dataGridRef,
    inputRef,
    getShipmentListByNumber,
    onPressConfirm,
    onPressIncident,
    shipmentId,
    isScannerOpen,
    setIsScannerOpen,
    onDecodeShipmentBarcode,
    onCheckOmit,
    onPressClearFilter: onPressClearFilter,
    onFilterBulkChange: onFilterBulkChange,
    isOmitChecked,
    shipmentDocuments,
    isDialogOpen,
    setIsDialogOpen,
    updateShipmentStatus,
    isUpdatingShipment,
    setIsDrawerIncidenstOpen,
    isDrawerIncidentsOpen,
    currentLine,
    setCurrentLine,
    postIncident,
    isPostingIncidents,
    searchRef,
    bulkHasIncidents,
    bulkHasNotClearlyAmount
  }
}
