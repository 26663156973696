import { format } from 'date-fns'
import { useState } from 'react'
import { makeUseAxios } from 'axios-hooks'
import { useNotification, useToggle } from '@/shared/hooks'
import { getError } from '@/shared/helpers'
import { ShippingListToReceive } from '@/features/goodsReceipt/types/shippingList.types'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'

export const useShippingToReceive = () => {
  const [isShippingListDrawerOpen, setIsShippingListDrawerOpen] = useToggle()
  const [shippingToReceive, setShippingToReceive] = useState<ShippingListToReceive[]>([])
  const notification = useNotification()
  const useAxios = makeUseAxios({
    axios: axiosInstance(process.env.REACT_APP_API_WMS ?? env.REACT_APP_API_WMS)
  })

  const [{ loading: isSearchingShippingToReceive }, executeShippingListToReceive] = useAxios<
    ShippingListToReceive[]
  >(
    {
      method: 'GET'
    },
    { manual: true }
  )

  const getShipmentToReceive = async () => {
    try {
      const shipmentList = await executeShippingListToReceive({
        url: '/listas-embarque/fechas',
        params: {
          beginDate: format(new Date(), 'yyyy-MM-dd'),
          endDate: format(new Date(), 'yyyy-MM-dd')
        }
      })
      setShippingToReceive(shipmentList.data)
    } catch (error) {
      notification.error(getError(error))
    }
  }

  return {
    shippingToReceive,
    getShipmentToReceive,
    isShippingListDrawerOpen,
    isSearchingShippingToReceive,
    setIsShippingListDrawerOpen
  }
}
