import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { ControlText } from '@/shared/components/fields'
import { Button } from '@/shared/components/commons'
import { IInicioSAPProps, ISesionSAP } from '@/shared/templates/types'
import { env } from '@/env'

export const InicioSAPForm = ({ onSubmit }: IInicioSAPProps) => {
	const initialValues: ISesionSAP = {
		CompanyDB: process.env.REACT_APP_SAP_COMPANY_TEST ?? env.REACT_APP_SAP_COMPANY_TEST,
		Password: '',
		UserName: ''
	}

	const validationSchema = Yup.object().shape({
		CompanyDB: Yup.string().required('Por favor, selecciona una empresa valida'),
		Password: Yup.string().required('Por favor, ingrese su contraseña de SAP'),
		UserName: Yup.string().required('Por favor, ingrese su usuaio de SAP ')
	})

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values: ISesionSAP) => {
				onSubmit(values)
			}}
			validationSchema={validationSchema}
			enableReinitialize
		>
			<Form>
				<fieldset className='space-y-2 border px-4 py-2 rounded-md border-gray-400 mb-4'>
					<legend className='text-xs font-bold'>Usuario de SAP</legend>
					<ControlText type='login' id='UserName' name='UserName' placeholder='Usuario' />
					<ControlText type='password' id='Password' name='Password' placeholder='Contraseña' />
					<ControlText type='text' id='Company' name='CompanyDB' disabled={true} />
				</fieldset>
				<div className='w-full'>
					<Button type='submit' className='btn primary w-full'>
						Aceptar
					</Button>
				</div>
			</Form>
		</Formik>
	)
}
