interface messageProps {
	text: string
	remove: () => void
}

const Message = ({ text, remove }: messageProps) => {
	return (
		<div
			className='bg-white p-5 w-auto h-auto border-t-8 border-black rounded-md'
			style={{ maxWidth: '70%' }}
		>
			<p>{text}</p>
			<div className='mt-5 w-full flex flex-row justify-end'>
				<button
					className='btn text-white bg-black font-bold'
					style={{ padding: '5px 20px' }}
					onClick={remove}
				>
					Aceptar
				</button>
			</div>
		</div>
	)
}

export default Message
