import { AvatarProps } from './types'
import { userDefaultImage } from '@/assets'
import { useOnErrorImage } from '@/shared/hooks'

const Avatar = ({ image, width = 3, height = 3, alt }: AvatarProps) => {
	const [replaceAvatar] = useOnErrorImage(userDefaultImage)

	const styles = {
		width: `${width}rem`,
		height: `${height || width}rem`
	}

	return (
		<figure className='p-1 rounded-full overflow-hidden' style={styles}>
			<img
				src={image}
				alt={alt}
				className=' w-full h-full rounded-full object-fill '
				onError={replaceAvatar}
				crossOrigin='use-credentials'
			/>
		</figure>
	)
}

export default Avatar
