import { useSelector } from 'react-redux'
import { RootState } from '@/app/store/createStore'
import { useToggle } from '@/shared/hooks'
import { ExportingEvent } from 'devextreme/ui/data_grid'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import { topVentasReportTypes } from '@/features/reports/ventas/topVentas/topVentas.types'

const useDataTopVentas = () => {
  const {
    auth: { user }
  } = useSelector((state: RootState) => state)
  const {
    topVentasReport: { data: dataTable, ...filter }
  } = useSelector((state: RootState) => state.reports.ventas)
  const [seeVisionDetails, setSeeVisionDetails] = useToggle()
  const [seeFamiliasDetails, setSeeFamiliasDetails] = useToggle()
  const [seeGenerosDetails, setSeeGenerosDetails] = useToggle()
  const [seeGruposEdadDetails, setSeeGruposEdadDetails] = useToggle()

  const onExport = (evt: ExportingEvent<topVentasReportTypes, any>) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Top Ventas')

    exportDataGrid({
      component: evt.component,
      worksheet,
      autoFilterEnabled: true
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReporteTopVentas.xlsx')
      })
    })
    evt.cancel = true
  }

  return {
    filter,
    dataTable,
    onExport,
    seeVisionDetails,
    setSeeVisionDetails,
    seeFamiliasDetails,
    setSeeFamiliasDetails,
    seeGenerosDetails,
    setSeeGenerosDetails,
    seeGruposEdadDetails,
    setSeeGruposEdadDetails,
    user
  }
}
export default useDataTopVentas
