import { ShipmentIncidentsTypes } from '@/features/goodsReceipt/types/shippingList.types'

export enum DocumentType {
	Nota = 'Nota de Fabricacion',
	Picking = 'Lista de Picking'
}

export enum QualityLine {
	Primera = 'PRIMERA',
	Segunda = 'SEGUNDA'
}

export enum HasOriginLine {
	N = 'N',
	Y = 'Y',
	A = 'A'
}

export interface Movements {
	Code: string
	Descripcion: string
}

export interface ShippingListDocLine {
	TipoDocumento: DocumentType
	Documento: number
	DocBase: number
	Bulto: number
	Caja: number
	Calidad: QualityLine
	Imagen1: string
	Imagen2: string
	Articulo: string
	Descripcion: string
	PrecioPublico: number
	GroupNum: number
	LineNum: number
	JrnlMemo: string
	Comments: string
	SolTrasldoDocEntry: number
	CantidadRequerida: number
	CantidadBultosAcum: number
	CantidadRecibidaBulto: number
	CantidadEmpacadaBulto: number
	CantidadPendienteBultos: number
	CantidadProcesada: number
	RowNum: number
	AlmacenOrigen: string
	AlmacenDestino: string
	BaseCode: string
	IdentificadorArticulos: string
	Stock: number
	ConLinea: HasOriginLine
	Cliente: string
	DocNumGen: number
}

export interface ShippingListDoc {
	Embarque: number
	Estatus: string
	CantidadCajas: number
	TotalPiezas: number
	NombreCliente: string
	Data: ShippingListDocLine[]
}

export interface ShipmentsDocs {
	Shipment: number
	CardCode: string
	PrimaryDocuments: PrimaryDocument[]
	SecondaryDocuments: SecondaryDocument[]
}

export interface PrimaryDocument {
	Document: number
	DocNum: number
	CardCode: string
	Comments: string
	JournalMemo: string
	FromWarehouse: string
	ToWarehouse: string
	PiceList: number
	U_GSP_CodeDocBaseStr: string
	Lines: PrimaryDocumentLine[]
}

export interface PrimaryDocumentLine {
	ItemCode: string
	WarehouseCode: string
	FromWarehouse: string
	Quantity: number
	BaseEntry: number
	BaseLine: number
	BaseType: 'InventoryTransferRequest'
	U_U_BYS_BaseCode: string
	Stock: number
	RequiredQuantity: number
}

export interface SecondaryDocument {
	Document: number
	DocNum: number
	CardCode: string
	Comments: string
	JournalMemo: string
	FromWarehouse: string
	ToWarehouse: string
	PiceList: number
	U_GSP_CodeDocBaseStr: string
	Lines: SecondaryDocumentLine[]
}

export interface SecondaryDocumentLine {
	ItemCode: string
	WarehouseCode: string
	FromWarehouse: string
	Quantity: number
	U_U_BYS_BaseCode: string
	Stock: number
	RequiredQuantity: number
}

export interface DocumentsCreationTypes {
	shipment: number
	document: number
	type: 'success' | 'error'
	message: string
}

export interface UpdateDocTypes {
	TipoDoc: 'Nota de Fabricacion'
	NumDoc: number
	TipoDocBase: 'SolTraslado'
	NumDocBase: number
	TipoDocGenerado: 'Traslado'
	NumDocGenerado: number
	Mensaje: string
	Empresa: string
	Fecha: string
	Hora: string
	Usuario: string
	Embarque: number
	EntryDocGenerado: number
	Lugar: 'RE'
}

export interface SAPShippingListSliceTypes {
	DocsQuantity: number
	DocsToGenerate: ShipmentsDocs[]
	icidents: ShipmentIncidentsTypes[]
	documentsCreation: DocumentsCreationTypes[]
}

export interface RenderArticleStatus {
	productStock: number
	outstandingAmount: number
	amountProcessed: number
}
