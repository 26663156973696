import { useEffect, useState } from 'react'
import { makeUseAxios } from 'axios-hooks'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/app/store/createStore'
import axiosInstance from '@/app/services/axiosInstance.service'
import { productMarketingResponseTypes } from '@/shared/interfaces/shared.interfaces'
import { useNotification } from '@/shared/hooks'
import { getError } from '@/shared/helpers'
import { setProduct } from '../warehouse.slice'
import { env } from '@/env'

const useSearchProductVariant = () => {
  const useAxios = makeUseAxios({
    axios: axiosInstance(process.env.REACT_APP_API_WMS ?? (env.REACT_APP_API_WMS as string))
  })
  const { product, whsTypes } = useSelector((state: RootState) => state.warehouse)
  const notification = useNotification()
  const [selectedSize, setSelectedSize] = useState<number>(0)
  const [selectedColor, setSelectedColor] = useState<number>(0)
  const dispatch = useDispatch()

  const [{ loading: isFetchingDocuments }, executeFetchDocuments] =
    useAxios<productMarketingResponseTypes>(
      {
        url: '/articulo-documentos',
        method: 'post'
      },
      { manual: true }
    )

  const getColorIndex = () => {
    if (product?.description && product.colors) {
      const colorIndex = product.colors?.findIndex(
        (item) => item.CodigoColor === product.description?.CodigoColor
      )
      if (colorIndex === -1) return 0
      return colorIndex
    }
    return 0
  }

  const getSizeIndex = () => {
    if (product?.description && product.sizes) {
      const sizeIndex = product.sizes?.findIndex(
        (item) => item.CodigoTalla === product.description?.CodigoTalla
      )
      if (sizeIndex === -1) return 0
      return sizeIndex
    }
    return 0
  }

  useEffect(() => {
    if (product?.description) {
      const colorIndex = getColorIndex()
      const sizeIndex = getSizeIndex()
      setSelectedColor(colorIndex)
      setSelectedSize(sizeIndex)
    }
  }, [product])

  const onChangeColor = async (colorCode: string) => {
    try {
      if (product?.description) {
        if (product.description.CodigoColor !== colorCode) {
          const model = product.description.Modelo
          const sizeCode = product.description.CodigoTalla
          const { data } = await executeFetchDocuments({
            data: { itemQuery: 2, model, colorCode, sizeCode, whsType: whsTypes }
          })
          dispatch(setProduct(data.product))
        }
      }
    } catch (error) {
      notification.error(getError(error))
    }
  }

  const onChangeSize = async (sizeCode: string) => {
    try {
      if (product?.description) {
        if (product.description.CodigoTalla !== sizeCode) {
          const model = product.description.Modelo
          const colorCode = product.description.CodigoColor
          const { data } = await executeFetchDocuments({
            data: { itemQuery: 2, model, sizeCode, colorCode, whsType: whsTypes }
          })
          dispatch(setProduct(data.product))
        }
      }
    } catch (error) {
      notification.error(getError(error))
    }
  }

  return {
    product,
    onChangeColor,
    onChangeSize,
    selectedColor,
    selectedSize,
    isFetchingDocuments
  }
}

export default useSearchProductVariant
