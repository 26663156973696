import { ChangeEvent } from 'react'
import { checkboxTypes } from './types'

const Checkbox = ({ label, onChange, onChangeCheck, ...rest }: checkboxTypes) => {
	const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = evt.target
		if (onchange) onChange!(value)
		if (onChangeCheck) onChangeCheck!(checked)
	}

	return (
		<label htmlFor={rest.id}>
			<input type='checkbox' {...rest} onChange={handleOnChange} className='mr-1' />
			{label}
		</label>
	)
}

export default Checkbox
