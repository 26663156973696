import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { ControlFileTypes } from './types'
import { useField, ErrorMessage } from 'formik'
import Compressor from 'compressorjs'

const ControlFile = ({ label, useCompress, accept, ...props }: ControlFileTypes) => {
	const [field, meta, helpers] = useField(props)
	const { onChange, value, ...restField } = field
	const { setValue } = helpers
	const textRef = useRef<HTMLInputElement>(null)
	const [fileNames, setFilesNames] = useState('')

	useEffect(() => {
		if (textRef.current) {
			if (!value) {
				textRef.current.files = null
				textRef.current.value = ''
			}
		}
	}, [value])

	const onchange = (evt: ChangeEvent<HTMLInputElement>) => {
		const { files } = evt.target
		if (files) {
			if (useCompress && accept === 'image') {
				new Compressor(files[0], {
					quality: 0.6,
					mimeType: 'jpg',
					convertSize: 0,
					convertTypes: ['image/webp', 'image/png', 'image/jpg', 'image/jpge'],
					success(compressedFile: File) {
						setValue(compressedFile)
					},
					error(error) {
						console.error(error)
					}
				})
			} else {
				setValue(files[0])
			}
			setFilesNames(files[0].name)
		}
	}

	return (
		<label htmlFor={props.id || props.name} className='block'>
			{label}
			<span
				className={`flex items-center field-input ${meta.error && meta.touched ? 'error' : ''}`}
			>
				<i className='mr-2 w-14 text-center bg-blue-500 text-white p-1 rounded-md font-semibold hover:bg-blue-600'>
					Buscar
				</i>
				<p className='w-24 truncate'>{fileNames}</p>
				<input
					type='file'
					{...props}
					{...restField}
					onChange={onchange}
					ref={textRef}
					className='hidden'
				/>
			</span>
			<ErrorMessage
				name={props.name}
				component='span'
				className='text-red-500 font-semibold text-right text-sm'
			/>
		</label>
	)
}

export default ControlFile
