import { useCallback, useRef, useState } from 'react'
import { useToggle } from '@/shared/hooks'
import { useTraspasosTiendas } from '@/features/inventoryAssortment/hooks'
import { format } from 'date-fns'
import { DataGrid, Popover } from 'devextreme-react'
import { Button } from '@/shared/components/commons'
import {
	Column,
	ColumnChooser,
	ColumnFixing,
	Export,
	FilterRow,
	GroupPanel,
	Scrolling,
	SearchPanel
} from 'devextreme-react/data-grid'
import ArrayStore from 'devextreme/data/array_store'
import DataSource from 'devextreme/data/data_source'
import { ColumnCellTemplateData, RowDblClickEvent } from 'devextreme/ui/data_grid'
import { DetailsTraspasoTiendaTemplate } from '@/features/inventoryAssortment/templates'
import { Link } from 'react-router-dom'

const DataTraspasosTiendasTemplate = () => {
	const dataGridRef = useRef<DataGrid>(null)
	const [seeTiendasDetails, setSeeTiendasDetails] = useToggle()
	const [openDetalle, setOpenDetalle] = useToggle()
	const [focusedRowKey, setFocusedRowKey] = useState(0)
	const { user, loading, filters, dataTraspasos, onChangePreviousTab, onSelectTraspaso } =
		useTraspasosTiendas()
	const dataSource = new DataSource({
		store: new ArrayStore({
			data: dataTraspasos,
			key: 'Id'
		})
	})

	const cellEstatusRender = (data: ColumnCellTemplateData) => {
		switch (data.value) {
			case 'T':
				return <div className='status_cicle PROCESADO'></div>
			case 'S':
				return <div className='status_cicle PROCESADO_SIN_DOC'></div>
			case 'A':
				return <div className='status_cicle EN_PROCESO'></div>
			case 'E':
				return <div className='status_cicle ERROR'></div>
			case 'P':
				return <div className='status_cicle PENDIENTE'></div>
			case 'I':
				return <div className='status_cicle INACTIVO'></div>
		}
	}

	const cellDocNumTransferRender = (data: ColumnCellTemplateData) => {
		if (data.value > 0)
			return (
				<Link to={`/view-pdf/traslado/${data.value}`} target='_blank' className='text-blue-600'>
					{data.value}
				</Link>
			)
	}

	const onFocusedRowChanged = useCallback((e: RowDblClickEvent) => {
		onSelectTraspaso(e.data)
		setFocusedRowKey(e.key)
		setOpenDetalle()
	}, [])

	return (
		<section className={`w-full overflow-auto`} style={{ height: 'calc(100% - 5rem)' }}>
			<div className='flex flex-col w-full p-5'>
				<div className='hidden md:mb-4 md:block'>
					<p>
						<span className='font-semibold'> Filtros Seleccionados:</span>
						<span className='ml-2'>
							Del {format(filters.FechaIni, 'yyyy-MM-dd')} Al{' '}
							{format(filters.FechaFin, 'yyyy-MM-dd')} |
						</span>
						<span className='ml-2'>
							Plaza:{' '}
							{user?.Localidades.map((plaza) => {
								return plaza.Codigo == filters.Plaza ? plaza.Nombre : ''
							})}
							{' |'}
						</span>
						<span className='ml-2'>
							Tiendas:
							<button
								id='TiendasDetails'
								className='btn cuartiary'
								onMouseEnter={setSeeTiendasDetails}
								onMouseLeave={setSeeTiendasDetails}
								onClick={setSeeTiendasDetails}
							>
								( {filters.Tiendas.length <= 3 ? filters.Tiendas.toString() : 'Varios'} )
							</button>
						</span>
					</p>

					<Popover
						target='#TiendasDetails'
						position='bottom'
						visible={seeTiendasDetails}
						width={400}
					>
						<p className='text-xs text-justify '>
							{filters.Tiendas.toString().replaceAll(',', ', ') || 'Ninguna seleccionada'}
						</p>
					</Popover>
				</div>

				<DataGrid
					ref={dataGridRef}
					dataSource={dataSource}
					allowColumnResizing={true}
					columnResizingMode={'nextColumn'}
					showBorders={true}
					showRowLines={true}
					columnAutoWidth
					focusedRowEnabled={true}
					focusedRowKey={focusedRowKey}
					onRowDblClick={onFocusedRowChanged}
				>
					<ColumnChooser enabled={false} />
					<GroupPanel visible={true} />
					<FilterRow visible={true} />
					<SearchPanel visible={true} highlightCaseSensitive={true} placeholder='Buscar' />
					<Scrolling mode='virtual' />
					<ColumnFixing enabled={true} />
					<Export enabled={false} />
					<Column
						dataField='Estatus'
						caption='Estatus'
						dataType='string'
						width={50}
						cellRender={cellEstatusRender}
					/>
					<Column dataField='AlmOrigen' caption='Origen' dataType='string' width={90} />
					<Column dataField='AlmPuente' caption='Puente' dataType='string' width={90} />
					<Column dataField='AlmDestino' caption='Destino' dataType='string' width={90} />
					<Column dataField='Fecha' caption='Fecha' dataType='string' width={110} />
					<Column
						dataField='DocNum1'
						caption='DocNum 1'
						dataType='number'
						width={110}
						cellRender={cellDocNumTransferRender}
					/>
					<Column
						dataField='DocNum2'
						caption='DocNum 2'
						dataType='number'
						width={110}
						cellRender={cellDocNumTransferRender}
					/>
					<Column dataField='Mensaje' caption='Mensaje' dataType='string' />
				</DataGrid>
			</div>

			<DetailsTraspasoTiendaTemplate isOpen={openDetalle} handleClose={setOpenDetalle} />

			<div className='p-5 flex justify-end absolute bottom-0 right-0 w-full items-center'>
				<Button
					className='btn secondary w-48 m-1'
					isLoading={loading}
					onClick={onChangePreviousTab}
				>
					Anterior
				</Button>
			</div>
		</section>
	)
}

export default DataTraspasosTiendasTemplate
