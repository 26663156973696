export enum ObjectTypes {
	Invoice = 13,
	CreditNote = 14,
	DeliveryNote = 15,
	Return = 16,
	Order = 17,
	PurchaseInvoice = 18,
	PurchaseCreditNote = 19,
	PurchaseDeliveryNote = 20,
	PurchaseReturn = 21,
	PurchaseOrder = 22,
	Quotation = 23,
	CorrectionAPInvoice = 163,
	CorrectionARInvoice = 165,
	PurchaseQutation = 540000006
}

export enum DocumentBoTypes {
	DeliveryNote = 1,
	LandadCost = 2,
	MaterialRevaluation = 4,
	AssortmentTransfer = 11,
	FirstRequestTransfer = 5,
	SecondRequestTransfer = 7,
	FirstTransferFromRequest = 6,
	SecondTransferFromRequest = 8
}
export enum DocumentBoStatus {
	InProcess = 1,
	Success = 2,
	Error = 3,
	Outstanding = 4,
	NotApply = 5
}
export enum DocumentGeneralBoStatus {
	Generated = 1,
	Error = 2,
	Outstanding = 3
}
