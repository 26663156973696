import { useState, FormEvent, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { placesWarehouseTypes, stockAlmacenTypes } from '../stockAlmacen.types'
import { useToggle, useNotification } from '@/shared/hooks'
import { resetStockAlmacenReportData, setStockAlmacenReportData } from '../stockAlmacen.slice'
import { getError } from '@/shared/helpers'
import { env } from '@/env'

import { RootState } from '@/app/store/createStore'

const useFilterStockAlmacen = () => {
  const { user } = useSelector((state: RootState) => state.auth)
  const useAxios = makeUseAxios({
    axios: axiosInstance(process.env.REACT_APP_API_WMS ?? (env.REACT_APP_API_WMS as string))
  })
  const [{ loading: initialLoad, data: places }] = useAxios<placesWarehouseTypes[]>({
    url: '/reportes/stock/almacenes',
    method: 'get'
  })
  const [{ loading }, excuteReport] = useAxios<stockAlmacenTypes[]>(
    { url: '/reportes/stock', method: 'post' },
    { manual: true }
  )

  const [isOpen, setOpen] = useToggle(true)
  const [warehouse, setWarehouse] = useState<string[]>([])
  const [itemType, setItemType] = useState<number>(2)
  const [placesInReport, setPlacesInReport] = useState<string[]>([])
  const reportHasFething = useRef(false)

  const dispatch = useDispatch()
  const notification = useNotification()

  const onChangePlaceCheck = (values: string[]) => {
    if (values) {
      const placesWarehouse = places?.filter((item) =>
        item.almacenes.find((list) => values.includes(list.codigo))
      )
      const placesName = placesWarehouse?.flatMap((item) => item.plaza)
      if (placesName) {
        setWarehouse(values)
        setPlacesInReport(placesName)
      }
    }
  }

  const onTypeChange = (value: string) => {
    setItemType(Number(value))
  }

  const getReportData = async () => {
    try {
      if (warehouse.length === 0)
        throw new Error('Necesita seleccionar por lo menos un almacen o plaza')
      dispatch(resetStockAlmacenReportData())
      const { data } = await excuteReport({ data: { whsList: [...warehouse], itemType } })
      const type = itemType === 0 ? 'Prenda' : itemType === 1 ? 'Accesorio' : 'Ambos'
      const inPlaces =
        placesInReport.length > 3
          ? 'Varios'
          : placesInReport.length === places?.length
          ? 'Todas'
          : placesInReport.toString()
      dispatch(
        setStockAlmacenReportData({
          data,
          type,
          warehouesIn: { places: inPlaces, warehouses: warehouse.toString() }
        })
      )
      if (isOpen) setOpen()
    } catch (error) {
      notification.error(getError(error))
    }
  }

  const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    try {
      evt.preventDefault()
      await getReportData()
    } catch (error) {
      notification.error(getError(error))
    }
  }

  const defaultCheck = (code: number) => {
    if (user?.Localidades) {
      const find = user.Localidades.find((item) => item.Codigo === code)
      return find ? true : false
    }
    return false
  }

  useEffect(() => {
    if (warehouse.length > 0 && !reportHasFething.current && user?.NombreGrupo === 'Tiendas') {
      getReportData()
      reportHasFething.current = true
    }
  }, [warehouse])

  return {
    isOpen,
    setOpen,
    onSubmit,
    onTypeChange,
    places,
    loading,
    initialLoad,
    defaultCheck,
    onChangePlaceCheck
  }
}

export default useFilterStockAlmacen
