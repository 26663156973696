import { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useFiltersCatalogos, useTraspasosTiendas } from '@/features/inventoryAssortment/hooks'
import { ControlText, DateControl, SelectControl } from '@/shared/components/fields'
import { Button } from '@/shared/components/commons'
import { CheckListSimple } from '@/shared/components/checkList/CheckListSimple'

const FilterTraspasosTiendasTemplate = () => {
	const { user, filters, onSubmitFilters, loading, loadingTraspasos } = useTraspasosTiendas()
	const { loadingCatAlmacenes, catAlmacenes, loadingCatListsPrecios, catListsPrecios } =
		useFiltersCatalogos(['Almacenes', 'ListasPrecios'])
	const [AuxTiendasSel, setAuxTiendasSel] = useState<string[]>([])

	const validationSchema = Yup.object().shape({
		FechaIni: Yup.string().required(),
		FechaFin: Yup.string().required(),
		Plaza: Yup.number().required('Seleccione una plaza'),
		FechaConta: Yup.string().required(),
		FechaDoc: Yup.string().required(),
		ListaPrecios: Yup.number().required('Seleccione una lista de precios'),
		UserSAP: Yup.string().required('Usuario SAP requerido'),
		PwdSAP: Yup.string().required('Contraseña SAP requerida')
	})

	const onChangeAuxTiendasSel = (values: string[]) => {
		setAuxTiendasSel(values)
	}

	const isButtonDisable = () => {
		if (user && (user.tipoTraspasos === 'bodega' || user.tipoTraspasos === 'tienda')) {
			return false
		}
		return true
	}

	return (
		<section className={`w-full overflow-auto`} style={{ height: 'calc(100% - 5rem)' }}>
			<Formik
				enableReinitialize={false}
				initialValues={{
					...filters,
					UserSAP: user ? user.UserSAP : '',
					PwdSAP: user ? (user.PasswordSAP ? user.PasswordSAP : '') : ''
				}}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					//console.log(JSON.stringify({ ...values, Tiendas: AuxVisualSel }, null, 2))
					onSubmitFilters({
						...values,
						Tiendas: AuxTiendasSel
					})
				}}
			>
				{(props) => (
					<Form onSubmit={props.handleSubmit} className='flex flex-col w-full h-full'>
						<div className={`flex flex-row flex-wrap`}>
							<div className={`w-full md:w-1/3 p-5 space-y-1.5 `}>
								<fieldset className='border border-slate-300 w-full rounded-md p-2 grid grid-cols-2 gap-2'>
									<legend className='font-semibold'>Rango de Fechas</legend>
									<DateControl id='FechaIni' name='FechaIni' label='Del' dateFormat='yyyy-MM-dd' />
									<DateControl id='FechaFin' name='FechaFin' label='Al' dateFormat='yyyy-MM-dd' />
								</fieldset>
								<SelectControl id='Plaza' name='Plaza' label='Plaza'>
									{user?.Localidades.map((list) => (
										<option key={list.Codigo} value={list.Codigo}>
											{list.Nombre}
										</option>
									))}
								</SelectControl>
								{props.values.Plaza > 0 && (
									<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col'>
										<legend className='font-semibold'>Tiendas</legend>
										{catAlmacenes?.map((plaza) => {
											return plaza.codigoLocalidad == props.values.Plaza ? (
												<CheckListSimple
													key={`plaza_` + plaza.codigoLocalidad}
													initialItems={plaza.almacenes
														?.map((alm) => ({
															code: alm.codigo,
															description: alm.nombre,
															sup: alm.tipo,
															defaultChecked: true
														}))
														.filter((a) => {
															let selected = false
															if (user?.tipoTraspasos === 'tienda') {
																const p = user?.Tiendas.find(
																	(l) => l.codigoLocalidad == props.values.Plaza
																)
																const tienda = p?.almacenes.find(
																	(t) => t.codigo == a.code && t.selected
																)
																if (tienda != undefined) selected = true
															} else if (user?.tipoTraspasos === 'bodega') {
																/*se mustran todas las tiendas de la plaza */
																selected = true
															}
															if (a.sup === 'Tienda' && selected) return a
														})}
													height={150}
													onChangeCheck={onChangeAuxTiendasSel}
												/>
											) : (
												''
											)
										})}
									</fieldset>
								)}
							</div>

							<div className={`w-full md:w-1/3 p-5 `}>
								<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col p-3 space-y-1.5 '>
									<legend className='font-semibold'>Valores iniciales para Traslados</legend>
									<DateControl
										id='FechaConta'
										name='FechaConta'
										label='Fecha Contabilización'
										dateFormat='yyyy-MM-dd'
									/>
									<DateControl
										id='FechaDoc'
										name='FechaDoc'
										label='Fecha Documento'
										dateFormat='yyyy-MM-dd'
									/>
									<SelectControl id='ListaPrecios' name='ListaPrecios' label='Lista de Precios'>
										{catListsPrecios?.map((list) => (
											<option key={list.ListNum} value={list.ListNum}>
												{list.ListName}
											</option>
										))}
									</SelectControl>
									<ControlText
										id='Comentario'
										name='Comentario'
										label='Comentario'
										type='textarea'
									/>
								</fieldset>
							</div>
							<div className={`w-full md:w-1/3 p-5 `}>
								<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col p-3 space-y-1.5 '>
									<legend className='font-semibold'>Sesión SAP</legend>
									<ControlText
										id='UserSAP'
										name='UserSAP'
										label='Usuario'
										type='login'
										placeholder='Usuario'
									/>
									<ControlText
										id='PwdSAP'
										name='PwdSAP'
										label='Contraseña'
										type='password'
										placeholder='Contraseña'
									/>
								</fieldset>
							</div>
						</div>
						<div className='p-5 flex justify-end absolute bottom-0 right-0 w-full items-center'>
							{loadingTraspasos && (
								<span className='mr-5 font-semibold'>Consultando traspasos</span>
							)}
							<Button
								type='submit'
								className='btn primary w-48'
								isLoading={
									loading || loadingTraspasos || loadingCatAlmacenes || loadingCatListsPrecios
								}
								disabled={isButtonDisable()}
							>
								Siguiente
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</section>
	)
}

export default FilterTraspasosTiendasTemplate
