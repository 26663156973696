import { useNotification, useToggle } from '@/shared/hooks'
import { useState } from 'react'
import { IPackingListToReceive } from '@/features/goodsReceipt/types/packingList.types'
import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'
import { getError } from '@/shared/helpers'

export const usePackingListToReceive = () => {
  const [isPackingListDrawerOpen, setIsPackingListDrawerOpen] = useToggle()
  const [packingListToReceive, setPackingListToReceive] = useState<IPackingListToReceive[]>([])
  const notification = useNotification()
  const useAxios = makeUseAxios({
    axios: axiosInstance(process.env.REACT_APP_API_WMS ?? env.REACT_APP_API_WMS)
  })

  const [{ loading: isSearchingPackingToReceive }, executePackingListToReceive] = useAxios<
    IPackingListToReceive[]
  >(
    {
      method: 'GET'
    },
    { manual: true }
  )

  const getPackingToReceive = async () => {
    try {
      const packingList = await executePackingListToReceive({
        url: 'packing-list/lista-recibir'
      })
      setPackingListToReceive(packingList.data)
    } catch (error) {
      notification.error(getError(error))
    }
  }

  return {
    isPackingListDrawerOpen,
    setIsPackingListDrawerOpen,
    packingListToReceive,
    getPackingToReceive,
    isSearchingPackingToReceive
  }
}
