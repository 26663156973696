import Carousel from '@/shared/components/carousel'
import ButtonGroup from '@/shared/components/buttonGroup'
import { useSearchProductVariant } from '../hooks'
import { RollerLoader } from '@/shared/components/loaders'
import { useOnErrorImage } from '@/shared/hooks'

const ProductDescriptionTemplate = () => {
	const { product, onChangeColor, onChangeSize, selectedSize, selectedColor, isFetchingDocuments } =
		useSearchProductVariant()
	const [replaceColorImage] = useOnErrorImage()

	if (!product) {
		return (
			<div className='w-full h-full text-center'>
				<h2>Sin articulos</h2>
			</div>
		)
	}

	return (
		<section className='flex flex-col sm:flex-row mb-16'>
			<div className='w-full mb-8 h-96 sm:w-2/6  md:w-4/12  lg:w-4/12  lg:landscape:w-4/12 xl:w-4/12 xl:h-[40rem]'>
				<Carousel
					images={product.description?.Imagenes.map((item) => ({
						url: item.url,
						alt: product.description?.NombreModelo
					}))}
				/>
			</div>

			<div className='flex flex-col w-full  sm:w-4/6 sm:pl-8  md:w-8/12 lg:w-8/12  lg:landscape:w-6/12 xl:w-8/12 xl:pl-20'>
				{/*Descripcion del articulo*/}
				<div className='w-full xl:w-6/12 mb-1'>
					<h3 className='font-bold text-xl'>{product?.description?.NombreModelo}</h3>
					<div className='flex w-full mt-2'>
						<table>
							<tbody>
								<tr>
									<td className='text-gray-500'>Codigo</td>
									<td className='font-semibold pl-4'> {product?.description?.ItemCode}</td>
								</tr>
								<tr>
									<td className='text-gray-500'>EAN</td>
									<td className='font-semibold pl-4'>{product?.description?.EAN}</td>
								</tr>
								<tr>
									<td className='text-gray-500'>Nombre</td>
									<td className='font-semibold pl-4'>{product?.description?.ItemName}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				{/*Colores del articulo*/}
				<div className='w-full max-h-60 overflow-auto xl:w-6/12 mb-1 '>
					<h4 className='block sticky z-10 text-gray-500 bg-white top-0'>Colores</h4>
					<div className='flex overflow-y-auto mt-1'>
						<ButtonGroup
							className='button-group__colors'
							selectedIndex={selectedColor}
							selectedClassName='selected'
							button={product?.colors?.map((item) => ({
								value: item.CodigoColor,
								className: 'button-group__color__button',
								element: (
									<span className=' w-full h-full text-center'>
										<img
											src={item.Imagen}
											alt='Color'
											className='shadow-md  mr-3 ml-3 w-10 h-10 object-contain object-center rounded-full'
											onError={replaceColorImage}
										/>
										<sub className=' bottom-1'>{item.Color}</sub>
									</span>
								),
								onClick: onChangeColor
							}))}
						/>
					</div>
				</div>

				{/*Tallas del articulo*/}
				<div className='w-full xl:w-6/12 mb-1'>
					<h4 className='text-gray-500'>Tallas</h4>
					<div className='flex mt-1'>
						<ButtonGroup
							className='button-group__sizes'
							selectedIndex={selectedSize}
							selectedClassName='selected'
							button={product?.sizes?.map((size) => ({
								value: size.CodigoTalla,
								className: 'button-group__sizes__button',
								element: <span>{size.Talla}</span>,
								onClick: onChangeSize
							}))}
						/>
					</div>
				</div>

				{/*Precios del articulo*/}
				<div className='w-full xl:w-6/12 mb-1'>
					<table>
						<tbody>
							{product?.prices?.map((item) => (
								<tr key={item.NumeroLista}>
									<td className='text-gray-500'>{item.NombreLista}</td>
									<td className='pl-4 font-semibold'>${item.Precio.toLocaleString('us-en')}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			{isFetchingDocuments ? <ContainerLoader /> : null}
		</section>
	)
}
const ContainerLoader = () => {
	return (
		<div className='w-full h-full absolute top-0 left-0 grid place-items-center bg-gray-600 bg-opacity-10 z-10'>
			<RollerLoader width={100} height={100} color='gray' />
		</div>
	)
}
export default ProductDescriptionTemplate
