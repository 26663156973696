import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { AuthorizationOutlet, ProtectionOutlet } from '../outlets'
import { useInitalAuth } from '@/features/auth/hooks'
import {
	NotFoundPage,
	DashboardPage,
	WarehousePage,
	UnauthorizedPage,
	ConfigurationPage
} from '@/pages'
import { DefaultLayout } from '@/layouts'

import { GoodsReceiptRoutes } from '@/features/goodsReceipt/routes'
import { SAPDocsRoute } from '@/features/operationStatus/routes'
import { LoginPage } from '@/features/auth/pages'
import { ReportsRoutes } from '@/features/reports/routes'
import { InventoryAssortmentRoutes } from '@/features/inventoryAssortment/routes'
import PDFRouter from './PDFRouter'

const router = createBrowserRouter([
	{
		/*Rutas cion autentificacion requerida*/
		element: <ProtectionOutlet />,
		children: [
			{ path: '/', element: <LoginPage /> },

			/*Toda las rutas que esten dentro de este Outlet comparten el mismo layout */
			{
				element: <DefaultLayout />,
				children: [
					{ path: '/dashboard', element: <DashboardPage /> },
					/*Todas las rutas que esten dentro de este Outlet necesitan autorizacion del usuario */
					{
						element: <AuthorizationOutlet />,
						children: [
							{ path: '/warehouse', element: <WarehousePage /> },
							{ path: '/reports/*', element: <ReportsRoutes /> },
							{ path: '/entrada-de-mercancias/*', element: <GoodsReceiptRoutes /> },
							{ path: '/documentos/*', element: <SAPDocsRoute /> },
							{ path: '/reposicion-de-inventario/*', element: <InventoryAssortmentRoutes /> }
						]
					},

					/**Pagina de 401 */
					{ path: '/unauthorized', element: <UnauthorizedPage /> },
					{ path: '/configuraciones', element: <ConfigurationPage /> }
				]
			},
			{ path: '/view-pdf/*', element: <PDFRouter /> },
			/*Paginas de ayud EJ: pagina de 404 o pagina de pruebas */
			{ path: '/*', element: <NotFoundPage /> }
		]
	}
])

const AppRouter = () => {
	useInitalAuth()
	return <RouterProvider router={router} />
}

export default AppRouter
