import { useField, ErrorMessage } from 'formik'
import { ControlSelectTypes } from './types'

const ControlSelect = ({ label, defaultText, optList, ...props }: ControlSelectTypes) => {
	const [field, meta] = useField(props)
	return (
		<label className='block'>
			<label htmlFor={props.id || props.name} className='block'>
				{label}
				<select
					{...props}
					{...field}
					className={`field-input ${meta.error && meta.touched ? 'error' : ''}`}
				>
					<option value={''}> {defaultText} </option>
					{optList?.map((item) => (
						<option key={item.value} value={item.value}>
							{' '}
							{item.text}
						</option>
					))}
				</select>
				<ErrorMessage
					name={props.name}
					component='span'
					className='text-red-500 font-semibold text-right text-sm'
				/>
			</label>
		</label>
	)
}

export default ControlSelect
