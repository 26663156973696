import { useEffect, useState, ChangeEvent, useRef } from 'react'
import { Html5Qrcode } from 'html5-qrcode'
import { ScannerProps, CameraScanConfig } from './types'
import { CameraDevice } from 'html5-qrcode/camera/core'
import { isMobile } from 'react-device-detect'
import {
	Cog6ToothIcon,
	VideoCameraIcon,
	VideoCameraSlashIcon,
	QrCodeIcon,
	PauseIcon,
	PlayIcon
} from '@heroicons/react/24/outline'

export const Scanner = ({
	initialConfig = { aspectRadio: 1.0, fps: 20, qrbox: { width: 200, height: 100 } },
	initialWidth = 400,
	onDecode,
	onError,
	onCameraOff,
	onCameraOn
}: ScannerProps) => {
	const ID_CONTAINER = 'HTML5_QR_CODE'
	const [cameraList, setCameraList] = useState<CameraDevice[]>([])
	const [idCamera, setIdCamera] = useState<string>('')

	const [config, setConfig] = useState<CameraScanConfig>(initialConfig)
	const [width, setWidth] = useState<number>(initialWidth)

	const [isCameraOn, setIsCameraOn] = useState<boolean>(false)
	const [isCameraPause, setISCameraPause] = useState<boolean>(false)
	const [hasSelectedDevice, setHasSelectedDevice] = useState<boolean>(false)

	const [showConf, setShowConf] = useState<boolean>(false)

	const scanInstance = useRef<Html5Qrcode | null>()
	const scanInstanceIsActive = useRef<boolean>(false)

	const handleOnDecode = (value: string) => {
		if (scanInstance.current) {
			scanInstance.current.pause(true)
			setISCameraPause(true)
			onDecode(value)
		}
	}
	const handleOnError = (error: string) => {
		onError!(error)
	}

	const OnSelectCameraChange = (evt: ChangeEvent<HTMLSelectElement>) => {
		/**
		 * Hace el cambio de seleccion de camara, si no existe una instancia de la camara y el id de la camara es valido
		 * entonces crea una nueva instancia de la camara y pone el estado de camara seleccionada como verdadero
		 *
		 * Si no existe una instancia y el valor de la carama no es valido destruye la instancia y pone el estado de camara seleccionada como falso
		 */
		const { value } = evt.target
		setIdCamera(value)
		if (!scanInstance.current && value !== '') {
			scanInstance.current = new Html5Qrcode(ID_CONTAINER)
			setHasSelectedDevice(true)
		} else {
			scanInstance.current = null
			setHasSelectedDevice(false)
		}
	}

	const onScannerConfigChange = (evt: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = evt.target
		setConfig((prev) => ({ ...prev, qrbox: { ...prev.qrbox, [name]: value } }))
	}

	const onWidthChange = (evt: ChangeEvent<HTMLInputElement>) => {
		const { value } = evt.target
		setWidth(Number(value))
	}

	const onPressStart = () => {
		/**
		 * Si existe una instancia de la camara
		 *      -Si la instancia no esta activa la enciende, si el dispositivo es mobil, usa la camara delantera,
		 *      si es escritorio usa la camara seleccionada por el usuario
		 *      al final si el menu de opciones esta abierto lo cierra y si la propiedad de onCameronOn exite la ejecuta
		 *
		 *      -Si la instancia esta activa, detiene la camara, borra la instancia, pone el indicador de camara activa como falso,
		 *      si el estado de la camara pausada esta como verdadero lo cambia a falso y si la propiedad de onCameraOff existe la ejecuta
		 *
		 * Si el dispositivo es mobil y existe una configuracion previa enciende la camara trasera del mobil
		 * Si el dispositivo es desktop y esta seleccionada una camara y existe una configuracion previa enciende la camara
		 *
		 * al final pone el estado de camara encendida en verdadero o falso
		 */
		if (scanInstance.current) {
			//Enciende la camara
			if (!scanInstanceIsActive.current) {
				//Si es mobile usa la camara trasera
				if (isMobile) {
					scanInstance.current
						.start({ facingMode: { exact: 'environment' } }, config, handleOnDecode, handleOnError)
						.catch((error) => console)
				}

				//Si es desktop usa la camara seleccionada
				else {
					scanInstance.current
						.start(idCamera, config, handleOnDecode, handleOnError)
						.catch((error) => console)
				}

				scanInstanceIsActive.current = true
				if (showConf) setShowConf(false)
				if (onCameraOn) onCameraOn()

				//Apaga la camara
			} else if (scanInstanceIsActive.current) {
				scanInstance.current?.stop().then(() => {
					scanInstance.current?.clear()
					scanInstance.current = null
					scanInstanceIsActive.current = false
					if (isCameraPause) setISCameraPause(false)
					if (onCameraOff) onCameraOff()
				})
			}

			//Si es mobil y exite una configuracion previa enciende la camara trasera
		} else if (isMobile && config) {
			scanInstance.current = new Html5Qrcode(ID_CONTAINER)
			scanInstance.current
				.start({ facingMode: { exact: 'environment' } }, config, handleOnDecode, handleOnError)
				.catch((error) => console)
			scanInstanceIsActive.current = true
			if (showConf) setShowConf(false)
			if (onCameraOn) onCameraOn()

			//Si es desktop enceinde la camara seleccionada
		} else if (idCamera !== '' && config) {
			scanInstance.current = new Html5Qrcode(ID_CONTAINER)
			scanInstance.current
				.start(idCamera, config, handleOnDecode, handleOnError)
				.catch((error) => console)
			scanInstanceIsActive.current = true
			if (showConf) setShowConf(false)
			if (onCameraOn) onCameraOn()
		}

		setIsCameraOn(!isCameraOn)
	}

	const onPressContinue = () => {
		/**
		 * Esta funcion solo funciona si exite una instancia de la camara y esta se esta ejecutando
		 * Si la camara esta en pausa, reanuda el scaner y pone el estado de pausa como falso
		 * si la camara esra escaneando, hace el proceso contrario
		 */
		if (scanInstance.current && scanInstanceIsActive.current) {
			if (scanInstance.current.getState() === 3) {
				scanInstance.current.resume()
				setISCameraPause(false)
			} else if (scanInstance.current.getState() === 2) {
				scanInstance.current.pause(true)
				setISCameraPause(true)
			}
		}
	}

	useEffect(() => {
		/**
		 * Si el dispositivo donde se ejecuta es un mobile pone el indicador de camara seleccioanda en verdadero,
		 * si el dispositivo es una computadora obtiene las camaras que esten conectadas al equipo y por defecto
		 * selecciona la primera y marca el indicador de camara seleccionada como verdadero
		 */

		if (isMobile) {
			setHasSelectedDevice(true)
		} else {
			Html5Qrcode.getCameras()
				.then((devices: CameraDevice[]) => {
					setCameraList(devices)
					if (devices.length > 0) {
						setIdCamera(devices[0].id)
						setHasSelectedDevice(true)
					}
				})
				.catch((error) => {
					/*Ignore this line*/
				})
		}

		/**
		 * Este return limpia la instancia de la camara
		 * Si existe una instancia de la camara y esta scaneando o en pausa la detiene y la elimina
		 * si existe la instacia pero esta detenida la elimina
		 * al final elimina la referencia de la instancia y pone el estado de camara seleccionada en falso
		 */
		return () => {
			if (scanInstance.current) {
				//Si la instancia esta escaneando o en pausa
				if (scanInstance.current.getState() !== 0) {
					scanInstance.current.stop().then(() => {
						scanInstance.current?.clear()
					})

					//si la instacia existe pero esta detenida
				} else {
					scanInstance.current?.clear()
				}
				scanInstance.current = null
				setHasSelectedDevice(false)
			}
		}
	}, [])

	const Configuration = () => {
		return (
			<>
				<button
					className='bg-gray-50 rounded-md  h-8  w-12 flex items-center justify-center'
					onClick={() => setShowConf(!showConf)}
				>
					<Cog6ToothIcon width={24} />
				</button>

				<div
					className={`absolute h-fit top-9 shadow-md right-0  w-56 bg-white rounded-md z-10 transform scale-0 ${
						showConf ? 'transform scale-100' : ''
					}`}
				>
					<div className='flex flex-col space-y-4 p-4'>
						{!isMobile ? (
							<div>
								<label htmlFor='devices' className='block mb-1'>
									Dispositivos
									<select
										id='devices'
										onChange={OnSelectCameraChange}
										className='field-input disabled:bg-gray-50'
										disabled={isCameraOn}
										value={idCamera}
									>
										<option value={''}>Camaras</option>
										{cameraList?.map((item) => (
											<option key={item.id} value={item.id}>
												{item.label}
											</option>
										))}
									</select>
								</label>
								{!hasSelectedDevice ? (
									<p className='font-semibold text-sm text-red-500'>Sin dispositivo seleccionado</p>
								) : null}
							</div>
						) : null}

						<fieldset className='border rounded-md p-2'>
							<legend>Area de deteccion</legend>
							<label htmlFor='width' className='block mb-2'>
								Ancho
								<input
									id='width'
									name='width'
									type='number'
									className='field-input disabled:bg-gray-50'
									onChange={onScannerConfigChange}
									min={50}
									value={config.qrbox.width}
									disabled={isCameraOn}
								/>
							</label>
							<label htmlFor='height' className='block'>
								Alto
								<input
									id='height'
									name='height'
									type='number'
									className='field-input disabled:bg-gray-50'
									onChange={onScannerConfigChange}
									min={50}
									value={config.qrbox.height}
									disabled={isCameraOn}
								/>
							</label>
						</fieldset>

						<label>
							Tamaño de imagen
							<input
								id='height'
								name='height'
								type='number'
								className='field-input disabled:bg-gray-50'
								onChange={onWidthChange}
								min={50}
								value={width}
								disabled={isCameraOn}
							/>
						</label>
					</div>
				</div>
			</>
		)
	}

	return (
		<>
			<aside className='block'>
				<div className='h-8 relative flex justify-end space-x-2'>
					<button
						className='bg-gray-50 rounded-md  h-8  w-12 flex items-center justify-center disabled:bg-gray-300'
						onClick={onPressContinue}
						disabled={!hasSelectedDevice || !isCameraOn}
					>
						{isCameraPause ? <PlayIcon width={24} /> : <PauseIcon width={24} />}
					</button>

					<button
						className='relative bg-gray-50 rounded-md  h-8  w-12 flex items-center justify-center disabled:bg-gray-300'
						disabled={!hasSelectedDevice}
						onClick={onPressStart}
					>
						{!isCameraOn ? <VideoCameraIcon width={24} /> : <VideoCameraSlashIcon width={24} />}
						<span
							className={`h-2 w-2 absolute top-1 right-1 rounded-full animate-ping opacity-75 ${
								isCameraOn ? ' bg-green-400' : 'bg-red-400'
							}`}
						/>
						<span
							className={`h-2 w-2 absolute top-1 right-1 rounded-full z-10 ${
								isCameraOn ? ' bg-green-400' : 'bg-red-400'
							}`}
						></span>
					</button>
					<Configuration />
				</div>

				<div className='mt-2 overflow-auto bg-slate-500 rounded-md  text-center'>
					<div id={ID_CONTAINER} style={{ width }} />
					{!isCameraOn ? (
						<span className='flex justify-center text-gray-800'>
							<QrCodeIcon width={120} />
						</span>
					) : null}
				</div>
			</aside>
		</>
	)
}
