import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ShippingListSlice,
  ShippingListSetTypes,
  ShipmentIncidentsTypes,
  DoesScannAction,
  ShipmentLines
} from '../types/shippingList.types'

const initialState: ShippingListSlice = {
  shippingListId: 0,
  EstatusEmbarque: 'Procesado',
  data: [],
  currentLine: null,
  filters: [
    { value: 'Bulto', text: 'Filtrar por bulto' },
    { value: 'Cajas', text: 'Filtrar por caja' }
  ],
  filtering: 'Bulto',
  filteringValue: '',
  shipmentIncidents: [],
  doesScann: 'table',
  documents: []
}

export const shippingListSlice = createSlice({
  name: 'shippingList',
  initialState,
  reducers: {
    setShippingList: (state, action: PayloadAction<ShippingListSetTypes>) => ({
      ...state,
      shippingListId: action.payload.shippingListId,
      data: action.payload.data,
      documents: action.payload.documents,
      currentLine: null,
      filteringValue: '',
      shipmentIncidents: action.payload.shipmentIncidents,
      EstatusEmbarque: action.payload.EstatusEmbarque
    }),
    setCurrentLine: (state, action: PayloadAction<ShipmentLines>) => ({
      ...state,
      currentLine: action.payload
    }),
    setFiltering: (state, action: PayloadAction<string>) => ({
      ...state,
      filtering: action.payload
    }),
    setFilteringValue: (state, action: PayloadAction<string>) => ({
      ...state,
      filteringValue: action.payload
    }),
    setShipmentIncidents: (state, action: PayloadAction<ShipmentIncidentsTypes[]>) => ({
      ...state,
      shipmentIncidents: action.payload
    }),
    setClear: (state, action) => ({ ...initialState }),
    setClearCurrentLine: (state, action: PayloadAction) => ({ ...state, currentLine: null }),
    setDoesScann: (state, action: PayloadAction<DoesScannAction>) => ({
      ...state,
      doesScann: action.payload
    }),
    setConfirmBulk: (state, action: PayloadAction<number>) => {
      const restOfBulks = state.data?.filter((item) => item.Bulto !== action.payload)
      const bulktToConfirm = state.data?.find((item) => item.Bulto === action.payload, {})
      if (restOfBulks && bulktToConfirm) {
        return { ...state, data: [...restOfBulks, { ...bulktToConfirm, EstatusBulto: 'C' }] }
      } else {
        return state
      }
    }
  }
})

export const {
  setShippingList,
  setCurrentLine,
  setFiltering,
  setFilteringValue,
  setShipmentIncidents,
  setClear,
  setClearCurrentLine,
  setDoesScann,
  setConfirmBulk
} = shippingListSlice.actions
export default shippingListSlice
