import { Children, useContext } from 'react'
import { spotlightBodyTypes } from './interfaces'
import { spotlightContext } from './Spotlight'

const SpotlightBody = ({ children }: spotlightBodyTypes) => {
	const { isLoading, loaderComponent } = useContext(spotlightContext)

	if (isLoading) {
		return <div className='portal__spotlight__body-loader'>{loaderComponent}</div>
	}

	if (Children.count(children) === 0) {
		return null
	}

	return (
		<section className='portal__spotlight__body'>
			<div className='portal__spotlight__body-content'>
				<ul className='w-full'>{children}</ul>
			</div>
		</section>
	)
}

export default SpotlightBody
