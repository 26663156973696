declare global {
	interface Window {
		env: any
	}
}

type EnvType = {
	REACT_APP_API_CONFIGURATOR: string

	REACT_APP_API_WMS: string

	REACT_APP_API_WMS_ONE_BEAT: string

	REACT_APP_SAP_COMPANY: string

	REACT_APP_SERVICE_LAYER: string

	REACT_APP_SERVICE_LAYER_PING: string

	REACT_APP_SERVICE_LAYER_PING_INTERVAL: number

	REACT_APP_SAP_COMPANY_TEST: string

	REACT_APP_DOMAIN_APP: string
}

export const env: EnvType = { ...process.env, ...window.env }
