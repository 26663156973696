import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'
import { useNotification } from '@/shared/hooks'
import {
	placesWarehouseTypes,
	catalogueListasPrecios,
	catalogueFormatsPDF
} from '@/features/reports/types/reports.types'
import { useEffect } from 'react'

const useFiltersCatalogos = (catalogos: string[]) => {
	const notification = useNotification()

	const useAxios = makeUseAxios({
		axios: axiosInstance(process.env.REACT_APP_API_WMS ?? (env.REACT_APP_API_WMS as string))
	})

	const useAxiosConfig = makeUseAxios({
		axios: axiosInstance(
			process.env.REACT_APP_API_CONFIGURATOR ?? (env.REACT_APP_API_CONFIGURATOR as string)
		)
	})

	const [{ loading: loadingCatAlmacenes, data: catAlmacenes }, executeAlmacenesCat] = useAxios<
		placesWarehouseTypes[]
	>({ url: '/reportes/stock/almacenes', method: 'get' }, { manual: true })

	const [{ loading: loadingCatListsPrecios, data: catListsPrecios }, executeListsPreciosCat] =
		useAxios<catalogueListasPrecios[]>(
			{ url: '/packing-list/documentos/lista-precios', method: 'get' },
			{ manual: true }
		)

	const [{ loading: loadingFormatsPDF, data: ListsFormatsPDF }, executeListsFormatsPDFRepo] =
		useAxiosConfig<catalogueFormatsPDF[]>(
			{ url: '/reportes/catalogo', method: 'post', data: { Tipo: 'Reposicion' } },
			{ manual: true }
		)

	useEffect(() => {
		try {
			if (catalogos.includes('Almacenes')) executeAlmacenesCat()
			if (catalogos.includes('ListasPrecios')) executeListsPreciosCat()
			if (catalogos.includes('FormatosPDFRepo')) executeListsFormatsPDFRepo()
		} catch (error) {
			console.log(error)
			notification.error('Error al cargar los filtros')
		}
	}, [])

	return {
		loadingCatAlmacenes,
		catAlmacenes,
		loadingCatListsPrecios,
		catListsPrecios,
		loadingFormatsPDF,
		ListsFormatsPDF
	}
}
export default useFiltersCatalogos
