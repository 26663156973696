import { RollerLoader } from '@/shared/components/loaders'

export const LoaderTemplate = () => {
	return (
		<div
			id='loader'
			className='fixed top-0 right-0 bottom-0 left-0 bg-black opacity-50 z-50 flex cursor-progress '
		>
			<RollerLoader width={100} height={100} />
		</div>
	)
}
