import { buttonTypes } from './types'

const Button = ({
	index,
	onClick,
	element,
	className,
	value,
	onSelectButton,
	isButtonSelect,
	selectedClassName
}: buttonTypes) => {
	return (
		<button
			className={`${className} ${isButtonSelect(index) ? selectedClassName : ''}`}
			onClick={async () => {
				onSelectButton(index)
				if (onClick) {
					onClick(value)
				}
			}}
		>
			{element}
		</button>
	)
}
export default Button
