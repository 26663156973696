import { Bars3Icon, XMarkIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid'
import { useToggle } from '../../hooks'
import SubLinks from './SubLinks'
import Portal from '../portal'
import { useAuth } from '@/features/auth/hooks'
import SubMenu from './SubMenu'
import { reportList } from '@/features/reports/routes'

const AsideMenu = () => {
	const [hidden, setHidden] = useToggle(true)
	const { userLogout } = useAuth()
	return (
		<>
			<section className='main-layout-menu menu'>
				<button onClick={setHidden}>
					<Bars3Icon width={32} className='text-blue-500' />
				</button>
			</section>

			<Portal wrapperId='MENU_SECTION'>
				<aside className={`aside-menu ${hidden && 'hide'}`}>
					<section className='flex justify-between items-center mb-4'>
						<h5 className='text-xl font-semibold'>Menu</h5>
						<button onClick={setHidden}>
							<XMarkIcon width={26} />
						</button>
					</section>

					<section className=' flex-1 h-full overflow-auto'>
						<nav className='navigation__nav'>
							<ul>
								{/*only menu option*/}
								<SubLinks
									title='Inventarios'
									close={setHidden}
									items={[
										{
											name: 'Articulos',
											path: '/warehouse'
										}
									]}
								/>

								{/*multiple menu options*/}
								<SubMenu title='Reportes' close={setHidden} items={reportList} />

								<SubLinks
									title='Entrada de mercancias'
									close={setHidden}
									items={[
										{
											name: 'Embarques',
											path: '/entrada-de-mercancias/embarques'
										},
										{
											name: 'Packing list',
											path: '/entrada-de-mercancias/packing'
										}
									]}
								/>

								<SubLinks
									title='Reposición de inventario'
									close={setHidden}
									items={[
										{
											name: 'Surtido de tiendas',
											path: '/reposicion-de-inventario/tiendas'
										},
										{
											name: 'Traspasos entre tiendas',
											path: '/reposicion-de-inventario/traspasos-entre-tiendas'
										}
									]}
								/>

								<SubLinks
									title='Estatus de operacion'
									close={setHidden}
									items={[
										{
											name: 'Embarques',
											path: '/documentos/embarques'
										},
										{
											name: 'Packing list',
											path: '/documentos/packing'
										}
									]}
								/>
							</ul>
						</nav>
					</section>

					<section className=''>
						<button
							className='flex w-full items-center h-10 rounded-md hover:bg-blue-500'
							onClick={userLogout}
						>
							<ArrowRightOnRectangleIcon width={26} className='mr-2' />
							<span>Cerrar sesion</span>
						</button>
					</section>
				</aside>
			</Portal>
		</>
	)
}

export default AsideMenu
