import { DefaultTemplate } from '@/shared/templates'
import { Title } from '@/shared/components/commons'
import { ConfigurationIconBlack } from '@/assets'
import { LoginSAP } from '@/features/operationStatus/components'
import { useNotification } from '@/shared/hooks'

const ConfigurationPage = () => {
	const { success } = useNotification()

	const successServiceLayerTest = (sapUser: string) => {
		success(`Prueba exitosa de conexion, usuario ${sapUser}`)
	}

	return (
		<DefaultTemplate>
			<Title
				backPage='Dashboard'
				backTo='/Dashboard'
				title='Configuracion y manuales'
				pageName='Configuracion'
				icon={ConfigurationIconBlack}
			/>

			<section className='mt-8'>
				<div className={'mb-4'}>
					<h4 className={'font-semibold text-lg'}>Manuales</h4>
					<ul>
						<li className={'pl-4'}>
							<a className={'text-blue-600'} href='/docs/manual_scanner.pdf' target='_blank'>
								Configuracion de escaner
							</a>
						</li>
					</ul>
				</div>

				<div>
					<h4 className={'font-semibold text-lg mb-2'}>Prueba de conexion a service layer</h4>
					<div className={'w-full md:w-64'}>
						<LoginSAP action={successServiceLayerTest} />
					</div>
				</div>
			</section>
		</DefaultTemplate>
	)
}

export default ConfigurationPage
