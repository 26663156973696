import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Button } from '@/shared/components/commons'
import { DateControl } from '@/shared/components/fields'
import { DataGrid } from 'devextreme-react'
import {
	Column,
	ColumnChooser,
	Export,
	FilterRow,
	GroupPanel,
	Scrolling,
	SearchPanel
} from 'devextreme-react/data-grid'
import { useRef, useState } from 'react'
import { useFiltersCatalogos, useSurtidoTiendas } from '@/features/inventoryAssortment/hooks'
import { ColumnCellTemplateData } from 'devextreme/ui/data_grid'
import { Link } from 'react-router-dom'

const initialVlues = {
	FechaIni: new Date(),
	FechaFin: new Date()
}

const SearchSurtidoTiendasTemplate = () => {
	const dataGridRef = useRef<DataGrid>(null)

	const { user, dataSurtidosByUser, searching, getSurtidosByUser } = useSurtidoTiendas()
	const { loadingFormatsPDF, ListsFormatsPDF } = useFiltersCatalogos(['FormatosPDFRepo'])
	const validationSchema = Yup.object().shape({
		FechaIni: Yup.string().required(),
		FechaFin: Yup.string().required()
	})

	const [formatoPDF, setFormatoPDF] = useState(
		user?.DefaultReposicion ? user.DefaultReposicion.toString() : '1'
	)

	const cellDocNumTransferRender = (data: ColumnCellTemplateData) => {
		const formato = ListsFormatsPDF?.find((pdf) => pdf.Id === Number(formatoPDF))
		if (formato === undefined) {
			return <span>{data.value}</span>
		} else {
			return (
				<Link to={`/view-pdf${formato.UrlBase}/${formato.Version}/${data.value}`} target='_blank'>
					<span className='text-blue-600'>{data.value}</span>
				</Link>
			)
		}
	}

	const cellDocNumSolicitudRender = (data: ColumnCellTemplateData) => {
		return (
			<Link to={`/view-pdf/SolTraslado/${data.value}`} target='_blank'>
				<span className='text-blue-600'>{data.value}</span>
			</Link>
		)
	}

	return (
		<>
			<Formik
				enableReinitialize={false}
				initialValues={initialVlues}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					//console.log(JSON.stringify({ ...values }, null, 2))
					getSurtidosByUser(values.FechaIni, values.FechaFin)
				}}
			>
				{(props) => (
					<Form onSubmit={props.handleSubmit} className=''>
						<div className={`flex flex-row flex-wrap`}>
							<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-row flex-wrap space-x-3'>
								<legend>Rango de fechas</legend>
								<DateControl id='FechaIni' name='FechaIni' label='Del' dateFormat='yyyy-MM-dd' />
								<DateControl id='FechaFin' name='FechaFin' label='Al' dateFormat='yyyy-MM-dd' />
							</fieldset>
						</div>
						<div className='mt-2 flex justify-end w-full items-center'>
							<Button
								type='submit'
								className='btn primary w-20'
								isLoading={loadingFormatsPDF || searching}
							>
								Buscar
							</Button>
						</div>
					</Form>
				)}
			</Formik>

			{ListsFormatsPDF != undefined && (
				<div className='pt-5 px-5'>
					<label htmlFor='formatoPDF' className='block'>
						Formato Doc. Reposición:
						<select
							id='formatoPDF'
							onChange={(evt) => setFormatoPDF(evt.target.value)}
							className={`field-input`}
							style={{ width: 'auto', height: 'auto', margin: '0 5px' }}
							defaultValue={formatoPDF}
						>
							{ListsFormatsPDF.map((list) => (
								<option key={list.Id} value={list.Id}>
									{list.Nombre}
								</option>
							))}
						</select>
					</label>
				</div>
			)}

			<div className='flex flex-col w-full px-5 mt-2'>
				<DataGrid
					ref={dataGridRef}
					dataSource={dataSurtidosByUser}
					allowColumnResizing={true}
					columnResizingMode={'nextColumn'}
					showBorders={true}
					showRowLines={true}
					columnAutoWidth
				>
					<ColumnChooser enabled={false} />
					<GroupPanel visible={true} />
					<FilterRow visible={true} />
					<SearchPanel visible={true} highlightCaseSensitive={true} placeholder='Buscar' />
					<Scrolling mode='virtual' />
					<Export enabled={false} />
					<Column
						dataField='Plaza'
						caption='Plaza'
						dataType='string'
						width={120}
						groupIndex={0}
						sortOrder='asc'
					/>
					<Column
						dataField='Fecha'
						caption='Fecha'
						dataType='string'
						width={120}
						sortOrder='desc'
					/>
					<Column dataField='AlmOrigen' caption='Alm. Origen' dataType='string' width={100} />
					<Column dataField='Puente' caption='Alm. Puente' dataType='string' width={100} />
					<Column dataField='AlmDestino' caption='Alm. Destino' dataType='string' width={100} />
					<Column
						dataField='FechaContabilidad'
						caption='F. Contabilidad'
						dataType='string'
						width={120}
					/>
					<Column dataField='FechaDocumento' caption='F. Documento' dataType='string' width={120} />
					<Column
						dataField='TransferDocNum'
						caption='Doc. Traslado'
						width={150}
						cellRender={cellDocNumTransferRender}
					/>
					<Column
						dataField='SolTrasladoDocNum'
						caption='Sol. Traslado'
						width={150}
						cellRender={cellDocNumSolicitudRender}
					/>
				</DataGrid>
			</div>
		</>
	)
}

export default SearchSurtidoTiendasTemplate
