import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Drawer } from '@/shared/components/modals'
import { useToggle } from '@/shared/hooks'
import { usePackingList } from '../hooks/usePackingList'
import { PackingListDocumentsForm, PackingListTable } from '../components'
import { Button } from '@/shared/components/commons'
import { ArrowPathIcon } from '@heroicons/react/20/solid'

const PackingListDocumentsPage = () => {
	const {
		priceList,
		packingId,
		setPackingId,
		getPackingList,
		isFetchingPacking,
		data,
		createDocuments,
		stockMovements,
		isCreatingDocuments,
		aduanas
	} = usePackingList()
	const [isDocumentDrawerOpen, setIsDocumentDrawerOpen] = useToggle()

	return (
		<>
			<div className='main-layout-toolbar toolbar' />
			<main className='main-layout-main p-4'>
				<section className='mb-4'>
					<div className='w-full flex items-center space-x-2  md:w-2/4 xl:w-1/4'>
						<input
							type='text'
							className='field-input'
							placeholder='No.Packing'
							value={packingId}
							onChange={(e) => setPackingId(e.target.value)}
							onKeyDown={(e) => {
								if (e.code.includes('Enter' || 'NumpadEnter')) {
									getPackingList(packingId)
								}
							}}
						/>
						<Button
							className='btn primary flex-button'
							onClick={() => getPackingList(packingId)}
							isLoading={isFetchingPacking}
						>
							<MagnifyingGlassIcon width={22} className='mr-1' />
							Buscar
						</Button>
					</div>
				</section>

				<div className='mb-2'>
					{isCreatingDocuments ? (
						<span className='flex'>
							<ArrowPathIcon width={18} className='mr-1 text-yellow-600 animate-spin' />
							Generando documentos
						</span>
					) : null}
				</div>
				<div className={'packing-table-container'}>
					<PackingListTable data={data} isCreatingDocuments={isCreatingDocuments} />
				</div>
				<div className='flex justify-end mt-4'>
					<Button
						className='btn primary'
						onClick={setIsDocumentDrawerOpen}
						isLoading={isCreatingDocuments}
					>
						Procesar listas
					</Button>
				</div>
			</main>

			<Drawer
				wrapperId='DOCUMENT_DRAWER'
				isOpen={isDocumentDrawerOpen}
				handleClose={setIsDocumentDrawerOpen}
				title='Procesar packing list'
			>
				<PackingListDocumentsForm
					priceList={priceList}
					movements={stockMovements}
					onSubmit={createDocuments}
					closeDrawer={setIsDocumentDrawerOpen}
					aduanas={aduanas}
					FechaPedimento={data?.FechaPedimento}
					AduanaPedimento={data?.Aduana}
					Pedimento={data?.Pedimento}
				/>
			</Drawer>
		</>
	)
}

export default PackingListDocumentsPage
