import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useDocumentsProducts } from '../hooks'
import {
	InventoriesTable,
	PackingListTable,
	ShippingListTable,
	DocumentsTable,
	ProductDetailsTable
} from '../components/tables'
import ButtonGroup from '@/shared/components/buttonGroup'
import { RollerLoader } from '@/shared/components/loaders'
import {
	reserveInvoiceIcon,
	purchaseOrderIcon,
	shippingListIcon,
	pakingListIcon,
	storeIcon,
	warehouseIcon,
	bridgeIcon,
	warehouseOthersIcon
} from '@/assets'
import { Checkbox } from '@/shared/components/fields'

const TableDocumentsTemplate = () => {
	const {
		selectedIndexTab,
		setSelectedIndexTab,
		product,
		onWhsTypesChange,
		whsTypes,
		docTypes,
		onDocTypesChange,
		isFetchingInventory,
		onExportInventory,
		onWithMovementsChange,
		withMovements,
		onExportPurcharseOrder,
		onExportReserveInvoice
	} = useDocumentsProducts()

	if (!product) {
		return null
	}

	return (
		<section className='w-full mt-8'>
			<Tabs
				onSelect={(index) => setSelectedIndexTab(index)}
				selectedIndex={selectedIndexTab}
				selectedTabClassName='border-b-2 border-blue-600 outline-none'
			>
				<TabList className='flex space-x-4  bg-gray-100 rounded-md'>
					<Tab className='cursor-pointer p-2 truncate'>Detalles</Tab>
					<Tab className='cursor-pointer p-2'>Doc.Marketing</Tab>
					<Tab className='cursor-pointer p-2'>Inventarios</Tab>
				</TabList>

				<TabPanel>
					<section className='p-2 md:p-4 space-y-8'>
						<ProductDetailsTable rows={product.description?.Detalles} />
					</section>
				</TabPanel>

				<TabPanel>
					<section className='p-2 md:p-4 space-y-8'>
						<div className='mb-4'>
							<ButtonGroup
								className='button-group__filters'
								indexPositive={true}
								multi={true}
								selectedIndex={docTypes}
								selectedClassName='selected'
								button={[
									{
										value: '1',
										className: 'button-group__filters__button',
										element: (
											<img
												src={purchaseOrderIcon}
												className='w-full h-full object-cover object-center'
												alt='icon'
											/>
										),
										onClick: onDocTypesChange
									},
									{
										value: '2',
										className: 'button-group__filters__button',
										element: (
											<img
												src={reserveInvoiceIcon}
												className='w-full h-full object-cover object-center'
												alt='icon'
											/>
										),
										onClick: onDocTypesChange
									},
									{
										value: '3',
										className: 'button-group__filters__button',
										element: (
											<img
												src={shippingListIcon}
												className='w-full h-full object-cover object-center'
												alt='icon'
											/>
										),
										onClick: onDocTypesChange
									},
									{
										value: '4',
										className: 'button-group__filters__button',
										element: (
											<img
												src={pakingListIcon}
												className='w-full h-full object-cover object-center'
												alt='icon'
											/>
										),
										onClick: onDocTypesChange
									}
								]}
							/>
						</div>
						{docTypes.includes(1) ? (
							<DocumentsTable
								rows={product?.purchaseOrders?.data}
								title='Ordenes de compra'
								onExport={onExportPurcharseOrder}
							/>
						) : null}
						{docTypes.includes(2) ? (
							<DocumentsTable
								rows={product?.reserveInvoices?.data}
								title='Facturas de reserva'
								onExport={onExportReserveInvoice}
							/>
						) : null}
						{docTypes.includes(3) ? <ShippingListTable title='Listas de embarque' /> : null}
						{docTypes.includes(4) ? <PackingListTable title='Packing list abiertos' /> : null}
					</section>
				</TabPanel>

				<TabPanel>
					<section className='p-2 md:p-4 space-y-8'>
						<div className='mb-4 flex flex-col space-y-2 md:flex-row md:justify-between md:items-center md:space-y-0'>
							<ButtonGroup
								className='button-group__filters'
								indexPositive={true}
								selectedIndex={whsTypes}
								selectedClassName='selected'
								multi={true}
								button={[
									{
										value: '1',
										className: 'button-group__filters__button',
										element: (
											<img
												src={warehouseIcon}
												alt='Icono bodega'
												className='w-full h-full object-cover object-center'
											/>
										),
										onClick: onWhsTypesChange
									},
									{
										value: '2',
										className: 'button-group__filters__button',
										element: (
											<img
												src={storeIcon}
												alt='Icono tienda'
												className='w-full h-full object-cover object-center'
											/>
										),
										onClick: onWhsTypesChange
									},
									{
										value: '3',
										className: 'button-group__filters__button',
										element: (
											<img
												src={bridgeIcon}
												alt='Icono puente'
												className='w-full h-full object-cover object-center'
											/>
										),
										onClick: onWhsTypesChange
									},
									{
										value: '4',
										className: 'button-group__filters__button',
										element: (
											<img
												src={warehouseOthersIcon}
												alt='Icono otro'
												className='w-full h-full object-cover object-center'
											/>
										),
										onClick: onWhsTypesChange
									}
								]}
							/>

							<Checkbox
								id='inventories_filter'
								label='Solo con movimiento'
								onChangeCheck={onWithMovementsChange}
								defaultChecked={withMovements === 1}
							/>
						</div>
						<InventoriesTable rows={product?.inventory} onExport={onExportInventory} />
					</section>
				</TabPanel>
			</Tabs>
			{isFetchingInventory ? <ContainerLoader /> : null}
		</section>
	)
}

const ContainerLoader = () => {
	return (
		<div className='w-full h-full absolute top-0 left-0 grid place-items-center bg-gray-600 bg-opacity-10 z-10'>
			<RollerLoader width={100} height={100} color='gray' />
		</div>
	)
}

export default TableDocumentsTemplate
