import DataGrid, {
	Column,
	Paging,
	Pager,
	Selection,
	SearchPanel,
	GroupPanel,
	FilterRow,
	Export
} from 'devextreme-react/data-grid'
import { shippingTableTypes } from './interfaces'

const ShippingListTable = ({ title, rows }: shippingTableTypes) => {
	return (
		<div className='shadow-lg rounded-b-md'>
			<span className='block w-full px-2 py-1 mb-2 bg-blue-300 rounded-t-md'>
				<h4 className='text-xl font-semibold text-gray-700'>{title}</h4>
			</span>
			<div className='p-2'>
				<DataGrid dataSource={rows} columnHidingEnabled={true} showBorders={true}>
					<GroupPanel visible={true} />
					<FilterRow visible={true} />
					<SearchPanel visible={true} highlightCaseSensitive={true} />
					<Paging defaultPageSize={10} />
					<Pager allowedPageSizes={[5, 10, 30, 50]} showPageSizeSelector={true} />
					<Selection mode='multiple' />
					<Export enabled={true} allowExportSelectedData={true} />
					<Column dataField='IdEmbarque' caption='Embarque' format=',##0.###' dataType='number' />
					<Column dataField='DocBase' caption='No.Documento' dataType='number' />
					<Column dataField='Cliente' caption='Cliente' dataType='string' />
					<Column dataField='ItemCode' caption='Articulo' dataType='number' />
					<Column dataField='ItemName' caption='Descripcion' dataType='number' />
					<Column dataField='Cantidad' caption='Cantidad' format=',##0.###' dataType='number' />
					<Column dataField='Calidad' caption='Calidad' dataType='string' />
				</DataGrid>
			</div>
		</div>
	)
}

export default ShippingListTable
