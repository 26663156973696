import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react'
import { v4 } from 'uuid'
import { useToggle } from '@/shared/hooks'
import {
	ClipboardDocumentListIcon,
	MagnifyingGlassIcon,
	DocumentDuplicateIcon
} from '@heroicons/react/24/outline'
import { Dialog, Drawer, Modal } from '@/shared/components/modals'
import { GoodsReceiptTable, IncidentItem, IncidentForm } from '../components'
import { cameraIcon, basketICon } from '@/assets'
import { Button, Title } from '@/shared/components/commons'
import { useShipping } from '../hooks/useShipping'
import Scanner from '@/shared/components/scanner'
import { useShippingToReceive } from '@/features/goodsReceipt/hooks/useShippingToReceive'

const ShippingListPage = () => {
	const {
		shipmentId,
		getShipmentListByNumber,
		data,
		incidents,
		isScannerOpen,
		setIsScannerOpen,
		onDecodeShipmentBarcode,
		isSearchingShipment,
		dataGridRef,
		inputRef,
		onPressClearFilter,
		onCheckOmit,
		onPressConfirm,
		onPressIncident,
		shipmentStatus,
		isOmitChecked,
		onFilterBulkChange,
		shipmentDocuments,
		confirmedBulks,
		isDialogOpen,
		setIsDialogOpen,
		updateShipmentStatus,
		isUpdatingShipment,
		isDrawerIncidentsOpen,
		setIsDrawerIncidenstOpen,
		currentLine,
		setCurrentLine,
		postIncident,
		isPostingIncidents,
		searchRef,
		bulkHasIncidents,
		bulkHasNotClearlyAmount
	} = useShipping()
	const [isDrawerDocumentsOpen, setIsDrawerDocumentsOpen] = useToggle()
	const [inputValue, setInputValue] = useState<string>('')
	const {
		shippingToReceive,
		getShipmentToReceive,
		isSearchingShippingToReceive,
		isShippingListDrawerOpen,
		setIsShippingListDrawerOpen
	} = useShippingToReceive()
	const buttonClickRef = useRef<HTMLButtonElement>(null)
	const idShippingRef = useRef('')

	const onInputValueChange = (evt: ChangeEvent<HTMLInputElement>) => {
		setInputValue(evt.target.value)
	}

	const onInputKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
		if (evt.code.includes('Enter' || 'NumpadEnter')) {
			getShipmentListByNumber(inputValue)
		}
	}

	return (
		<>
			<div className='main-layout-toolbar toolbar'>
				<div className='space-x-2 md:space-y-2 md:space-x-0'>
					<button
						onClick={() => {
							setCurrentLine(null)
							setIsDrawerIncidenstOpen()
						}}
						className='text-white'
					>
						<ClipboardDocumentListIcon width={28} />
					</button>
					<button onClick={setIsDrawerDocumentsOpen} className=' text-white'>
						<DocumentDuplicateIcon width={28} />
					</button>
				</div>
			</div>

			<main className='main-layout-main p-4'>
				<Title
					title='Entrada de mercancias'
					pageName='Lista de embarques'
					backTo='/entrada-de-mercancias'
					backPage='Menu de entradas'
					icon={basketICon}
				/>

				<section className='mb-4'>
					<div className='flex items-center space-x-2 md:w-2/4 xl:w-1/4'>
						<button className='btn flex-button border w-16' onClick={setIsScannerOpen}>
							<img src={cameraIcon} alt='use Camera' />
						</button>
						<input
							type='search'
							className='field-input'
							placeholder='No.Lista de Embarque'
							value={inputValue}
							onChange={onInputValueChange}
							onKeyDown={onInputKeyDown}
							ref={searchRef}
						/>
						<Button
							className='btn primary flex-button'
							onClick={() => {
								if (idShippingRef.current !== '') getShipmentListByNumber(idShippingRef.current)
								else getShipmentListByNumber(inputValue)
							}}
							isLoading={isSearchingShipment}
							innerRef={buttonClickRef}
						>
							<MagnifyingGlassIcon width={22} className='mr-1' />
							Buscar
						</Button>

						<Button
							className='btn primary flex-button'
							onClick={() => {
								getShipmentToReceive().then(() => setIsShippingListDrawerOpen())
							}}
							isLoading={isSearchingShippingToReceive}
						>
							<MagnifyingGlassIcon width={22} className='mr-1' />
							Embarques a Recibir
						</Button>
					</div>
				</section>
				<section className='flex flex-col bg-white p-2'>
					<GoodsReceiptTable
						data={data}
						dataGridRef={dataGridRef}
						inputRef={inputRef}
						onPressIncident={onPressIncident}
						onPressConfirm={onPressConfirm}
						confirmedBulks={confirmedBulks}
						isOmitCheck={isOmitChecked}
						onOmitCheck={onCheckOmit}
						onPressClearFilter={onPressClearFilter}
						onValueChange={onFilterBulkChange}
						shipmentStatus={shipmentStatus}
						bulkHasIncidents={bulkHasIncidents}
						bulkHasNotClearlyAmount={bulkHasNotClearlyAmount}
					/>

					<div className={'flex items-center space-x-2 mt-4 self-end'}>
						{data && data.length ? (
							<Button
								className='btn secondary'
								onClick={setIsDialogOpen}
								disabled={shipmentStatus === 'Procesado' || shipmentStatus === 'Recibido'}
							>
								Abrir embarque
							</Button>
						) : null}

						{data && data.length > 0 ? (
							<Button
								className={'btn primary'}
								onClick={setIsDialogOpen}
								disabled={shipmentStatus === 'Confirmado' || shipmentStatus === 'Recibido'}
							>
								Confirmar Embarque
							</Button>
						) : null}
					</div>
				</section>
			</main>

			<Dialog
				wrapperId='DIALOG_CONFIRM_SHIPPING'
				isOpen={isDialogOpen}
				handleClose={setIsDialogOpen}
				width={300}
				height={200}
				confirm={() => updateShipmentStatus(shipmentId, shipmentStatus === 'Procesado' ? 'O' : 'T')}
				title={`${shipmentStatus === 'Procesado' ? 'Cerrar' : 'Abrir'} el embarque ${shipmentId}`}
				text={`${shipmentStatus === 'Procesado' ? '¿Cerrar el embarque?' : '¿Abrir el embarque?'}`}
				isLoading={isUpdatingShipment || isSearchingShipment}
			/>

			<Modal
				wrapperId='GOODRECEIPT_SHIPPINGLIST_SCANNER_MODAL'
				isOpen={isScannerOpen}
				handleClose={setIsScannerOpen}
				width={350}
				height={500}
				title='Escanear codigo'
			>
				{isScannerOpen ? <Scanner onDecode={onDecodeShipmentBarcode} initialWidth={400} /> : null}
			</Modal>

			<Drawer
				wrapperId={'GOODRECEIPT_DOCUMENTS'}
				isOpen={isDrawerDocumentsOpen}
				handleClose={setIsDrawerDocumentsOpen}
				title='Documentos generados'
			>
				<div className='space-y-4'>
					{shipmentDocuments && shipmentDocuments.length > 0 ? (
						shipmentDocuments?.map((item) => (
							<ul key={v4()} className='px-4 py-2 bg-gray-200 rounded-md text-md text-gray-600'>
								<li>
									Documento: <span className=' font-semibold text-black'>{item.NumDoc}</span>
								</li>
								<li>
									Tipo de documento:{' '}
									<span className=' font-semibold text-black'>{item.TipoDoc}</span>
								</li>
								<li>
									Documento base:{' '}
									<span className=' font-semibold text-black'>{item.NumDocBase}</span>
								</li>
								<li>
									Tipo de documento base:{' '}
									<span className=' font-semibold text-black'>{item.TipoDocBase}</span>
								</li>
								<li>
									Documento generado:{' '}
									<span className=' font-semibold text-black'>{item.NumDocGenerado}</span>
								</li>
								<li>
									Tipo de documento generado:{' '}
									<span className=' font-semibold text-black'>{item.TipoDocGenerado}</span>
								</li>
								<li>
									Fecha: <span className=' font-semibold text-black'>{item.Fecha}</span>
								</li>
								<li>
									Hora: <span className=' font-semibold text-black'>{item.Hora}</span>
								</li>
								<li>
									Usuario: <span className=' font-semibold text-black'>{item.Usuario}</span>
								</li>
							</ul>
						))
					) : (
						<div className=' bg-white rounded-md shadow-lg h-12 flex items-center justify-center '>
							<h3 className=''>Embarque sin documentos</h3>
						</div>
					)}
				</div>
			</Drawer>

			<Drawer
				wrapperId={'GOODRECEIPT_INCIDENTS'}
				isOpen={isDrawerIncidentsOpen}
				handleClose={setIsDrawerIncidenstOpen}
				title={
					currentLine
						? `Incidencias del bulto ${currentLine.Bulto}`
						: `Incidencia del embarque ${shipmentId ?? ''}`
				}
			>
				<div>
					{currentLine && shipmentStatus !== 'Recibido' ? (
						<div className='shadow-lg  p-4 rounded-md bg-white mt-2'>
							<IncidentForm
								hideForm={setIsDrawerIncidenstOpen}
								onSubmit={postIncident}
								isPostIncident={isPostingIncidents || isSearchingShipment}
								receivedAmount={currentLine?.Cantidad}
							/>
						</div>
					) : null}

					<div className='space-y-4 mt-8'>
						{currentLine ? (
							incidents && incidents.length > 0 ? (
								incidents
									.filter((item) => item.Bulto === +currentLine.Bulto)
									.map((incident) => <IncidentItem key={v4()} item={incident} />)
							) : (
								<div className=' bg-white rounded-md shadow-lg h-12 flex items-center justify-center '>
									<h3>Sin Bulto sin incidencias</h3>
								</div>
							)
						) : incidents && incidents.length > 0 ? (
							incidents.map((item) => <IncidentItem key={v4()} item={item} />)
						) : (
							<div className=' bg-white rounded-md shadow-lg h-12 flex items-center justify-center '>
								<h3 className=''>Sin incidencias</h3>
							</div>
						)}
					</div>
				</div>
			</Drawer>

			<Drawer
				wrapperId={'SHPPINGLIST_RECEIVE'}
				isOpen={isShippingListDrawerOpen}
				handleClose={setIsShippingListDrawerOpen}
				title={'Embarques a Recibir'}
			>
				<div className='space-y-4'>
					{shippingToReceive.length > 0 ? (
						shippingToReceive.map((item) => (
							<ul
								key={v4()}
								onClick={() => {
									idShippingRef.current = item.Embarque.toString(10)
									// Para que se muestre en el input
									setInputValue(item.Embarque.toString(10))
									setIsShippingListDrawerOpen()
									buttonClickRef?.current?.click()
								}}
								className='px-4 py-2 mt-3 bg-gray-200 rounded-md text-md text-gray-600 cursor-pointer'
							>
								<li>
									Embarque: <span className='font-semibold text-black'>{item.Embarque}</span>
								</li>
								<li>
									Cliente: <span className='font-semibold text-black'>{item.NombreCliente}</span>
								</li>
							</ul>
						))
					) : (
						<div className='bg-white rounded-md shadow-lg h-12 flex items-center justify-center '>
							<h3>No hay embarques a recibir</h3>
						</div>
					)}
				</div>
			</Drawer>
		</>
	)
}

export default ShippingListPage
