import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	logResultDocument,
	surtidoTiendasSliceTypes,
	surtidoTiendasPayloadAction
} from '@/features/inventoryAssortment/types/SurtidoTiendas.types'
import { format } from 'date-fns'

const date1 = new Date()
date1.setDate(date1.getDate() - 1)
const date2 = new Date()

const initialState: surtidoTiendasSliceTypes = {
	data: [],
	logDocs: [],
	step: 0,
	Base: 'Normal',
	Segmentos: ['ACCESORIOS'],
	FechaVenta: date1,
	Plaza: 0,
	Tiendas: [],
	FechaConta: date2,
	FechaDoc: date2,
	ListaPrecios: 1,
	Comentario: `Reposición de Mercancia Corr. venta del dia ${format(date1, 'dd/MM/yyyy')}`,
	UserSAP: '',
	PwdSAP: '',
	penetracionBuffer: false
}

export const surtidoTiendasSlice = createSlice({
	name: 'surtidoTiendas',
	initialState,
	reducers: {
		setSurtidoTiendasData: (state, action: PayloadAction<surtidoTiendasPayloadAction>) => ({
			...state,
			...action.payload
		}),
		resetSurtidoTiendasData: () => initialState,
		changeStep: (state, action: PayloadAction<number>) => ({
			...state,
			step: action.payload
		}),
		addDocLog: (state, action: PayloadAction<logResultDocument>) => ({
			...state,
			logDocs: [...state.logDocs, action.payload]
		}),
		setPenetracionBuffer: (state, action: PayloadAction<boolean>) => ({
			...state,
			penetracionBuffer: action.payload
		})
	}
})

export const {
	setSurtidoTiendasData,
	resetSurtidoTiendasData,
	changeStep,
	addDocLog,
	setPenetracionBuffer
} = surtidoTiendasSlice.actions
export default surtidoTiendasSlice
