const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
const meses = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre'
]

export const formatDateComplete = (fecha: Date) => {
	return (
		diasSemana[fecha.getDay()] +
		', ' +
		fecha.getDate() +
		' de ' +
		meses[fecha.getMonth()] +
		' de ' +
		fecha.getFullYear()
	)
}

export default formatDateComplete
