import { IFiltersPartidasAbiertas } from '@/features/reports/informes/partidasAbiertas/partidasAbiertas.types'
import * as Yup from 'yup'
import { AsideModal } from '@/shared/components/modals'
import { FunnelIcon } from '@heroicons/react/24/outline'
import { Formik } from 'formik'
import {
	ControlCheckbox,
	ControlRadio,
	DateControl,
	SelectControl
} from '@/shared/components/fields'
import { Button } from '@/shared/components/commons'
import useFilterPartidasAbiertas from '@/features/reports/informes/partidasAbiertas/hooks/useFilterPartidasAbiertas'
import { CheckListSimple } from '@/shared/components/checkList/CheckListSimple'
import { useState } from 'react'

export const FilterPartidasAbiertasTemplate = () => {
	const {
		isOpen,
		setOpen,
		DocType,
		DateType,
		ClienteType,
		initialLoadCat,
		catClientes,
		loading,
		defaultLocationCheck,
		onSubmit
	} = useFilterPartidasAbiertas()

	const [ClientesSel, setClientesSel] = useState<string[]>([])

	const onChangeClientesSel = (values: string[]) => {
		setClientesSel(values)
	}

	const date1 = new Date()
	date1.setDate(1)
	const date2 = new Date()

	const initialState: IFiltersPartidasAbiertas = {
		DocType: 0,
		DateType: 0,
		DateStart: date1,
		DateEnd: date2,
		ClienteType: 1,
		Clientes: [],
		Segmentos: ['LINEA', 'MODA', 'ACCESORIOS']
	}

	const validationSchema = Yup.object().shape({
		DocType: Yup.string().required('Selecciona un tipo de documento valido'),
		DateType: Yup.string().required('Selecciona un tipo de fecha valido'),
		DateStart: Yup.string().required('Ingrese una fecha valida'),
		DateEnd: Yup.string().required('Ingrese una fecha valida')
	})

	return (
		<>
			<div className='main-layout-toolbar toolbar'>
				<AsideModal
					title='Filtros'
					className='w-full md:w-[30%] xl:w-[20%]'
					closeButtonElement={<FunnelIcon width={28} className='text-white' />}
					classNameButton='toolbar-button'
					isOpen={isOpen}
					handleClose={setOpen}
				>
					<Formik
						enableReinitialize={false}
						initialValues={initialState}
						validationSchema={validationSchema}
						onSubmit={(values) => {
							//console.log(JSON.stringify({...values, Clientes:ClientesSel}, null, 2))
							onSubmit({
								...values,
								Clientes: ClientesSel
							})
						}}
					>
						{(props) => (
							<form onSubmit={props.handleSubmit} className='h-full flex flex-col'>
								<div className={`absolute top-14 bottom-14 left-2 right-2 overflow-auto`}>
									<SelectControl id='DocType' name='DocType' label='Tipo de Documento'>
										{DocType.map((list) => (
											<option key={list.code} value={list.code}>
												{list.name}{' '}
											</option>
										))}
									</SelectControl>

									<SelectControl id='DateType' name='DateType' label='Tipo de Fecha'>
										{DateType.map((list) => (
											<option key={list.code} value={list.code}>
												{list.name}{' '}
											</option>
										))}
									</SelectControl>

									<fieldset className='border border-slate-300 w-full rounded-md p-2 grid grid-cols-2 gap-2'>
										<legend className='font-semibold'>Rango de Fechas</legend>
										<DateControl
											id='DateStart'
											name='DateStart'
											label='Del'
											dateFormat='yyyy-MM-dd'
										/>
										<DateControl id='DateEnd' name='DateEnd' label='Al' dateFormat='yyyy-MM-dd' />
									</fieldset>

									<fieldset className='border border-slate-300 w-full rounded-md p-2 grid grid-cols-2'>
										<legend className='font-semibold'>Segmento</legend>
										<ControlCheckbox
											id='ckLinea'
											name='Segmentos'
											value='LINEA'
											label='Linea'
											checked={props.values.Segmentos.includes('LINEA')}
										/>
										<ControlCheckbox
											id='ckModa'
											name='Segmentos'
											value='MODA'
											label='Moda'
											checked={props.values.Segmentos.includes('MODA')}
										/>
										<ControlCheckbox
											id='ckAccesorios'
											name='Segmentos'
											value='ACCESORIOS'
											label='Accesorios'
											checked={props.values.Segmentos.includes('ACCESORIOS')}
										/>
										<ControlCheckbox
											id='ckSuministros'
											name='Segmentos'
											value='SUMINISTROS'
											label='Suministros'
											checked={props.values.Segmentos.includes('SUMINISTROS')}
										/>
										<ControlCheckbox
											id='ckUniformes'
											name='Segmentos'
											value='UNIFORMES'
											label='Uniformes'
											checked={props.values.Segmentos.includes('UNIFORMES')}
										/>
										<ControlCheckbox
											id='ckOtros'
											name='Segmentos'
											value='OTROS'
											label='Otros'
											checked={props.values.Segmentos.includes('OTROS')}
										/>
									</fieldset>

									<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col'>
										<legend className='font-semibold'>Tipo de Cliente</legend>
										<div className='w-full grid grid-cols-2 col-span-1'>
											{ClienteType.map((list) => (
												<ControlRadio
													key={`c${list.name}`}
													id={`c${list.name}`}
													name='ClienteType'
													value={list.code.toString()}
													label={list.name}
													checked={props.values.ClienteType == list.code}
												/>
											))}
										</div>
									</fieldset>

									<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col'>
										<legend className='font-semibold'>Clientes</legend>
										{props.values.ClienteType == 1 && (
											<CheckListSimple
												initialItems={catClientes
													?.filter((item) => item.EsPlaza === 'Y')
													.map((item) => ({
														code: item.CardCode,
														description: `${item.CardCode} ${item.CardName}`,
														defaultChecked: defaultLocationCheck(Number(item.Localidad)),
														isChecked: false
													}))
													.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0))}
												height={250}
												onChangeCheck={onChangeClientesSel}
												showSearchBox={true}
											/>
										)}
										{props.values.ClienteType == 2 && (
											<CheckListSimple
												initialItems={catClientes
													?.filter((item) => item.EsProveeduria === 'Y')
													.map((item) => ({
														code: item.CardCode,
														description: `${item.CardCode} ${item.CardName}`,
														defaultChecked: defaultLocationCheck(Number(item.Localidad)),
														isChecked: false
													}))
													.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0))}
												height={250}
												onChangeCheck={onChangeClientesSel}
												showSearchBox={true}
											/>
										)}
									</fieldset>
								</div>
								<div className='flex-1 mt-4 absolute bottom-2 left-2 right-2'>
									<Button
										type='submit'
										className='btn primary w-full'
										isLoading={initialLoadCat || loading}
									>
										Consultar
									</Button>
								</div>
							</form>
						)}
					</Formik>
				</AsideModal>
			</div>
		</>
	)
}
