import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { ControlSelect, ControlText, ControlFile } from '@/shared/components/fields'
import { Button } from '@/shared/components/commons'
import { IPackingListIncidentValues, IPackingListDetail } from '../types/packingList.types'

type Props = {
	packingId?: number
	item?: IPackingListDetail
	hide: () => void
	isLoading?: boolean
	postIncident: (Args: IPackingListIncidentValues) => void
}

const IncidentFormPackingList = ({
	item,
	hide,
	packingId,
	isLoading = false,
	postIncident
}: Props) => {
	const initialValues: IPackingListIncidentValues = {
		packingList: packingId ?? 0,
		dispercion: item?.IdDispersion ?? 0,
		type: 1,
		receivedAmount: item?.UnidadesRecibidas ?? 0,
		motive: '',
		images: null
	}

	const validationSchema = Yup.object()
		.shape({
			type: Yup.string().required('Por favor seleccione un tipo de incidencia'),
			receivedAmount: Yup.number()
				.required('La cantidad confirmada es requerida')
				.min(0, 'La cantidad confirmada no puede ser negativa'),
			motive: Yup.string().required('El campo razon es requerido'),
			images: Yup.mixed().nullable()
		})
		.required()

	const handleSubmit = async (
		{ type, receivedAmount, motive, images, packingList, dispercion }: IPackingListIncidentValues,
		{ resetForm }: FormikHelpers<IPackingListIncidentValues>
	) => {
		await postIncident({ type, receivedAmount, motive, images, packingList, dispercion })
		resetForm()
		hide()
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			enableReinitialize
		>
			<Form>
				<fieldset className='space-y-2'>
					<ControlSelect
						id='type'
						name='type'
						label='Tipo de incidencia'
						defaultText='Seleccione una incidencia'
						optList={[
							{ value: '1', text: 'Mercancia dañada' },
							{ value: '2', text: 'Mercancia abierta' },
							{ value: '3', text: 'Faltante de Mercancia' },
							{ value: '4', text: 'Sobrante de Mercancia' },
							{ value: '5', text: 'Error en informacion de articulo' }
						]}
					/>
					<ControlText type='number' id='receivedAmount' name='receivedAmount' label='Confirmada' />
					<ControlText
						type='textarea'
						id='reason'
						name='motive'
						label='Comentarios'
						rows={14}
						cols={14}
					/>
					<ControlFile id='images' name='images' label='Imagen' accept='image' useCompress />
				</fieldset>
				<div className='flex items-center space-x-2 mt-4'>
					<Button type='reset' className='btn secondary w-full' onClick={hide}>
						Cancelar
					</Button>
					<Button type='submit' className='btn primary w-full' isLoading={isLoading}>
						Guardar
					</Button>
				</div>
			</Form>
		</Formik>
	)
}

export default IncidentFormPackingList
