import DataGrid, {
	Column,
	MasterDetail,
	FilterRow,
	Selection,
	Scrolling
} from 'devextreme-react/data-grid'
import {
	CheckCircleIcon,
	ExclamationTriangleIcon,
	ArrowPathIcon,
	MinusIcon,
	InformationCircleIcon
} from '@heroicons/react/20/solid'
import { useDispatch } from 'react-redux'
import { setSelectedDocuments } from '../slices/PackingListDocumentsSlice'
import { ColumnCellTemplateData } from 'devextreme/ui/data_grid'
import type {
	IPackingData,
	IPackingDataDetail,
	SelectedDocument,
	DocumentStatus
} from '../types/packingList'
import { DocumentGeneralBoStatus } from '../types/objectTypes'

type Props = {
	data?: IPackingData
	isCreatingDocuments: boolean
}

const StatusCell = ({ value }: ColumnCellTemplateData<DocumentStatus>) => {
	switch (value as DocumentStatus) {
		case 'En Proceso':
			return (
				<span className='flex'>
					<InformationCircleIcon width={18} className='mr-1 text-blue-600' />
					{value}
				</span>
			)
		case 'Error':
			return (
				<span className='flex'>
					<ExclamationTriangleIcon width={18} className='mr-1 text-red-600' />
					{value}
				</span>
			)
		case 'Generado':
			return (
				<span className='flex'>
					<CheckCircleIcon width={18} className='mr-1 text-green-600' />
					{value}
				</span>
			)
		case 'No Aplica':
			return (
				<span className='flex'>
					<MinusIcon width={18} className='mr-1 text-gray-400' />
					{value}
				</span>
			)
		case 'Pendiente':
			return (
				<span className='flex'>
					<InformationCircleIcon width={18} className='mr-1 text-blue-600' />
					{value}
				</span>
			)
		case 'En Generacion':
			return (
				<span className='flex'>
					<ArrowPathIcon width={18} className='mr-1 text-yellow-600 animate-spin' />
					{value}
				</span>
			)
		default:
			return <span className='flex'>{value}</span>
	}
}

const StatusPacking = ({ value }: ColumnCellTemplateData<DocumentGeneralBoStatus>) => {
	switch (value as DocumentGeneralBoStatus) {
		case DocumentGeneralBoStatus.Generated:
			return (
				<span className='flex items-center'>
					<CheckCircleIcon width={18} className='mr-1 text-green-600' />
					Generado
				</span>
			)
		case DocumentGeneralBoStatus.Error:
			return (
				<span className='flex items-center'>
					<ExclamationTriangleIcon width={18} className='mr-1 text-red-600' />
					Error
				</span>
			)
		default:
			return (
				<span className='flex items-center'>
					<InformationCircleIcon width={18} className='mr-1 text-blue-600' />
					Pendiente
				</span>
			)
	}
}

const Detail = ({ data }: ColumnCellTemplateData<IPackingDataDetail>) => {
	return (
		<DataGrid dataSource={data?.Documentos} showRowLines={false} columnHidingEnabled={true}>
			<Scrolling columnRenderingMode='virtual' />
			<Column dataField='DocEntry' caption='DocEntry' width={100} />
			<Column dataField='TipoDocumento' caption='Tipo de documento' width={190} />
			<Column dataField='Estatus' caption='Estatus' cellRender={StatusCell} width={130} />
			<Column dataField='ErrorMessage' caption={'Mensaje'} />
		</DataGrid>
	)
}

export const PackingListTable = ({ data }: Props) => {
	const dispatch = useDispatch()
	return (
		<DataGrid
			dataSource={data?.Detalles}
			columnHidingEnabled={true}
			columnAutoWidth={true}
			showRowLines={true}
			showBorders={true}
			onSelectionChanged={({ selectedRowsData }) => {
				const allDocsInRow: SelectedDocument[] = []
				selectedRowsData.forEach((row) => {
					const currentDoc = row.Documentos.map(
						(item): SelectedDocument => ({
							id: item.Id,
							type: item.IdTipoDocumento
						})
					)
					allDocsInRow.push(...currentDoc)
				})
				dispatch(setSelectedDocuments(allDocsInRow))
			}}
			onRowClick={({ rowIndex, component }) => {
				const selectedKey = component.getKeyByRowIndex(rowIndex)
				if (!component.isRowExpanded(selectedKey)) {
					component.expandRow(selectedKey)
				} else {
					component.collapseRow(selectedKey)
				}
			}}
			columnMinWidth={30}
		>
			<FilterRow visible={true} />
			<Selection mode={'multiple'} showCheckBoxesMode='always' />
			<Column dataField='OC' caption='OC/FR' width={70} />
			<Column dataField='Cliente' caption='Cliente' width={100} groupIndex={2} />
			<Column
				dataField='EstatusDocumentos'
				caption='Estatus'
				cellRender={StatusPacking}
				width={120}
			/>
			<Column dataField='ItemCode' caption='Articulo' />
			<Column dataField='Cantidad' caption='Cantidad' width={90} dataType='number' />
			<Column dataField='Localidad' caption='Localidad' groupIndex={1} />
			<Column dataField='CostoUnitTotal' caption='Costos' dataType='number' width={70} />
			<Column dataField='AlmCosteo' caption='Alm.Costeo' dataType='string' width={90} />
			<Column dataField='AlmSurtido' caption='Alm.Surtido' width={90} />
			<Column dataField='AlmBodega' caption='Alm.Bodega' width={90} />
			<Column dataField='AlmPuente' caption='Alm.Puente' width={90} />
			<MasterDetail render={Detail} enabled={true} />
		</DataGrid>
	)
}
