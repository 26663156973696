import { buttonTypes } from './types'
import { RollerLoader } from '@/shared/components/loaders'

const Button = ({ isLoading, innerRef, children, ...props }: buttonTypes) => {
	return (
		<button {...props} disabled={isLoading || props.disabled} ref={innerRef}>
			{isLoading ? <RollerLoader width={29} height={29} /> : children}
		</button>
	)
}
export default Button
