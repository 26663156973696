import { useSelector } from 'react-redux'
import { RootState } from '@/app/store/createStore'
import { ExportingEvent } from 'devextreme/ui/data_grid'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import { stockAlmacenTypes, InventoryAudit } from '../stockAlmacen.types'
import { useToggle, useNotification } from '@/shared/hooks'
import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'
import { format } from 'date-fns'
import { getError } from '@/shared/helpers'

const useDataStockAlmacen = () => {
  const {
    auth: { user }
  } = useSelector((state: RootState) => state)
  const {
    stockAlmacen: { data, type, warehouesIn }
  } = useSelector((state: RootState) => state.reports.inventario)
  const [seeWarehouseDetails, setSeeWarehouseDetails] = useToggle()

  const notification = useNotification()

  const useAxios = makeUseAxios({
    axios: axiosInstance(process.env.REACT_APP_API_WMS ?? (env.REACT_APP_API_WMS as string))
  })

  const [{ loading: loadInventoryData, data: inventoryData }, execute] = useAxios<InventoryAudit[]>(
    {
      url: '/reportes/stock/auditoria',
      method: 'post'
    },
    { manual: true }
  )

  const onExport = (evt: ExportingEvent<stockAlmacenTypes, any>) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Inventario')

    exportDataGrid({
      component: evt.component,
      worksheet,
      autoFilterEnabled: true
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'StockPorAlmacenes.xlsx')
      })
    })
    evt.cancel = true
  }

  const getItemCodeAuditory = async (itemCode: string, warehouse: string) => {
    try {
      await execute({
        data: {
          beginDate: format(new Date(), 'yyyy-MM-dd').toString(),
          endDate: format(new Date(), 'yyyy-MM-dd').toString(),
          beginItemCode: itemCode,
          endItemCode: itemCode,
          warehouses: [warehouse]
        }
      })
    } catch (error) {
      notification.error(getError(error))
    }
  }

  return {
    data,
    onExport,
    seeWarehouseDetails,
    setSeeWarehouseDetails,
    type,
    warehouesIn,
    user,
    getItemCodeAuditory,
    inventoryData,
    loadInventoryData
  }
}
export default useDataStockAlmacen
