import { useState, useEffect } from 'react'
import { buttonGroupTypes } from './types'
import Button from './Button'

const ButtonGroup = ({
	className,
	multi,
	button,
	selectedClassName,
	selectedIndex,
	indexPositive = false
}: buttonGroupTypes) => {
	const [itemSelected, setItemSelected] = useState<number[]>([])

	useEffect(() => {
		if (selectedIndex !== undefined && selectedIndex !== itemSelected) {
			if (Array.isArray(selectedIndex)) {
				setItemSelected(selectedIndex)
			} else {
				setItemSelected([selectedIndex])
			}
		}
	}, [selectedIndex])

	const onSelectButton = (index: number) => {
		if (multi) {
			if (itemSelected?.includes(index))
				setItemSelected((prev) => prev?.filter((item) => item !== index))
			else setItemSelected((prev) => [...prev, index])
		} else {
			setItemSelected([index])
		}
	}
	const isButtonSelect = (index: number) => itemSelected.includes(index)

	return (
		<div className={className}>
			{button?.map((item, index) => (
				<Button
					key={index}
					index={indexPositive ? index + 1 : index}
					onSelectButton={onSelectButton}
					isButtonSelect={isButtonSelect}
					selectedClassName={selectedClassName}
					{...item}
				/>
			))}
		</div>
	)
}

export default ButtonGroup
