import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/app/store/createStore'
import { useNotification, useToggle, useSystemMessage } from '@/shared/hooks'
import { useState } from 'react'
import {
	useCreateStockTransferMutation,
	useLoginMutation
} from '@/features/operationStatus/service/serviceLayerService'
import {
	changeStep,
	resetTraspasosTiendasData,
	setDataTraspaso,
	setSelectedDataTraspaso,
	setTraspasosTiendasData
} from '@/features/inventoryAssortment/slices/TraspasosTiendas.slice'
import { getError } from '@/shared/helpers'
import {
	IFiltersTraspasosTiendas,
	dataTraspasoTienda,
	lineTraspasoTienda
} from '@/features/inventoryAssortment/types/TraspasosTiendas.types'
import { getServiceLayerError } from '@/app/service_layer/helpers/getServiceLayerError'
import { format } from 'date-fns'
import { StockTransfer, StockTransferLines } from '@/features/operationStatus/types/stockTransfer'
import {
	IParameterSGI,
	resultDocument,
	resultExec
} from '@/features/inventoryAssortment/types/SurtidoTiendas.types'

const useTraspasosTiendas = () => {
	const { user } = useSelector((state: RootState) => state.auth)
	const CompanyDB = process.env.REACT_APP_SAP_COMPANY_TEST ?? env.REACT_APP_SAP_COMPANY_TEST
	const [loading, setLoading] = useToggle(false)
	const [generatingDocs, setGeneratingDocs] = useState(false)

	const [executeLogin] = useLoginMutation()
	const [executeCreateStockTransfer] = useCreateStockTransferMutation()

	const {
		traspasosTiendas: { data: dataTraspasos, step: selectedTab, selectedTraspaso, ...filters }
	} = useSelector((state: RootState) => state.repoInventory)

	const useAxios = makeUseAxios({
		axios: axiosInstance(process.env.REACT_APP_API_WMS ?? (env.REACT_APP_API_WMS as string))
	})

	const [{}, excuteGetParametro] = useAxios<IParameterSGI>(
		{ url: '/asistente-reposicion/parametros', method: 'post' },
		{ manual: true }
	)

	const useAxiosOneBeat = makeUseAxios({
		axios: axiosInstance(
			process.env.REACT_APP_API_WMS_ONE_BEAT ?? (env.REACT_APP_API_WMS_ONE_BEAT as string)
		)
	})

	const [{ loading: loadingTraspasos }, excuteGetTraspasos] = useAxiosOneBeat<dataTraspasoTienda[]>(
		{ url: '/traspasos', method: 'post' },
		{ manual: true }
	)

	const urlUpdateEnc = (id: string) => `/traspasos/encabezado/${id}`
	const [{}, executeUpdateEncTraslado] = useAxiosOneBeat<resultExec[]>(
		{ method: 'put' },
		{ manual: true }
	)

	const urlUpdateCantDet = (id: string, type: 'surtida' | 'confirmada') =>
		`/traspasos/detalle/cantidad-${type}/${id}`
	const [{}, executeUpdateCantDetTraslado] = useAxiosOneBeat<resultExec[]>(
		{ method: 'put' },
		{ manual: true }
	)

	const [{}, executeAddDocTraslado] = useAxiosOneBeat<resultExec[]>(
		{ url: '/traspasos/documentos', method: 'post' },
		{ manual: true }
	)

	const dispatch = useDispatch()
	const notification = useNotification()
	const systemMessage = useSystemMessage()

	const onChangePreviousTab = async () => {
		dispatch(changeStep(selectedTab - 1))
	}

	const onChangeNextTab = async () => {
		dispatch(changeStep(selectedTab + 1))
	}

	const onReset = async () => {
		dispatch(resetTraspasosTiendasData())
	}

	const getTrasladosData = async (values: IFiltersTraspasosTiendas) => {
		try {
			const dataSend = {
				fechaInicio: format(values.FechaIni, 'yyyy-MM-dd'),
				fechaFin: format(values.FechaFin, 'yyyy-MM-dd'),
				tiendas: values.Tiendas,
				empresa: CompanyDB
			}
			const data: dataTraspasoTienda[] = await excuteGetTraspasos({
				data: { ...dataSend }
			})
				.then(({ data }) => {
					if (data.length === 0) systemMessage.add('No se encontraron artículos para traspasar')
					return data
				})
				.catch(({ response }) => {
					notification.error(response.data.message)
					return []
				})

			if (data.length > 0) {
				dispatch(
					setTraspasosTiendasData({
						data,
						step: selectedTab,
						selectedTraspaso: undefined,
						...values
					})
				)
				onChangeNextTab()
			}
		} catch (error) {
			notification.error(getError(error))
		}
	}

	const onSubmitFilters = async (values: IFiltersTraspasosTiendas) => {
		setLoading()
		try {
			dispatch(resetTraspasosTiendasData())

			if (values.FechaIni > values.FechaFin)
				throw new Error('La Fecha de inicio debe ser menor o igual a la fecha final')
			if (values.Plaza === 0) throw new Error('Seleccione una plaza')
			if (values.Tiendas.length === 0) throw new Error('Seleccione por lo menos una tienda')
			if (values.ListaPrecios === 0) throw new Error('Seleccione una lista de precios')

			/*INICIO DE SESION SAP*/
			await executeLogin({
				UserName: values.UserSAP,
				Password: values.PwdSAP,
				CompanyDB: CompanyDB
			})
				.unwrap()
				.catch((error) => {
					throw new Error('Inicio de sesión SAP no válida.\nError: ' + getServiceLayerError(error))
				})

			await getTrasladosData(values)
			setLoading()
		} catch (error) {
			notification.error(getError(error))
			setLoading()
		}
	}

	const onSelectTraspaso = (data: dataTraspasoTienda) => {
		dispatch(setSelectedDataTraspaso(data))
	}

	const guardarDetalleTraslado = async (
		idTraspaso: number,
		detalle: lineTraspasoTienda[],
		tipo: number
	) => {
		for await (const line of detalle) {
			await executeUpdateCantDetTraslado({
				url: urlUpdateCantDet(line.IdDetalle.toString(), tipo == 1 ? 'surtida' : 'confirmada'),
				data:
					tipo == 1 ? { cantSurtida: line.CantSurtida } : { cantConfirmada: line.CantConfirmada }
			}).catch(() => {
				throw new Error('Error al actualizar el detalle, intenta de nuevo')
			})
		}
		dispatch(
			setDataTraspaso(
				dataTraspasos.map((t) => {
					if (t.Id == idTraspaso) return { ...t, Lineas: detalle }
					else return t
				})
			)
		)
	}

	const onGenerateTraspaso = async () => {
		setGeneratingDocs(true)
		try {
			let LinesTraslado: StockTransferLines[] = []
			let Tipo: number
			let FromWarehouse: string
			let ToWarehouse: string
			let CodeDocBaseStr: string

			if (selectedTraspaso == undefined) {
				throw new Error('No se encontro traspaso seleccionado')
			}

			if (!user?.tipoTraspasos)
				throw new Error(
					'Usuario no valido para realizar los traspasos, no se ha definido un tipo de perfil.'
				)

			if (selectedTraspaso.DocNum1 == 0) {
				/*GENERAR 1ER DOCUMENTO DE TRANSFERENCIA (TIENDA ORIGEN - BODEGA PUENTE) - CANTIDAD SURTIDA*/
				Tipo = 1
				FromWarehouse = selectedTraspaso.AlmOrigen
				ToWarehouse = selectedTraspaso.AlmPuente
				CodeDocBaseStr = 'TT-WMS-' + format(new Date(), 'yyyyMMddHmmssSSS')

				LinesTraslado = selectedTraspaso.Lineas.filter(
					(l) => l.CantSurtida != undefined && l.CantSurtida > 0
				).map((item) => ({
					ItemCode: item.ItemCode,
					Quantity: item.CantSurtida
				}))
			} else if (selectedTraspaso.DocNum2 == 0) {
				/*GENERAR 2DO DOCUMENTO DE TRANSFERENCIA (BODEGA PUENTE - TIENDA DESTINO) - CANTIDAD CONFIRMADA*/
				if (user.tipoTraspasos == 'tienda')
					throw new Error('Usuario no autorizado para la generación del documento 2')

				Tipo = 2
				FromWarehouse = selectedTraspaso.AlmPuente
				ToWarehouse = selectedTraspaso.AlmDestino
				CodeDocBaseStr = selectedTraspaso.BaseCode + '_2'

				LinesTraslado = selectedTraspaso.Lineas.filter(
					(l) => l.CantConfirmada != undefined && l.CantConfirmada > 0
				).map((item) => ({
					ItemCode: item.ItemCode,
					Quantity: item.CantConfirmada
				}))
			} else throw new Error('No se encontro documento pendiente por generar')

			/*GUARDAR DETALLE - LINEAS*/
			await guardarDetalleTraslado(selectedTraspaso.Id, selectedTraspaso.Lineas, Tipo).catch(
				(error) => {
					throw new Error(error)
				}
			)

			/*INICIO DE GENERACION DE DOCUMENTO*/
			let Estatus: string = selectedTraspaso.Estatus
			let Mensaje: string = selectedTraspaso.Mensaje
			let DocNumGenerado: number | undefined

			if (LinesTraslado.length > 0) {
				/*obtener razones de movimiento desde parametros GPS */
				const { data: MOVRAZON } = await excuteGetParametro({
					data: {
						nombre: 'RazMovTraspasosTiendas',
						aplicacion: 'BYS_Administracion',
						empresa: CompanyDB
					}
				})

				const dataTraslado: StockTransfer = {
					CardCode: selectedTraspaso.Cliente,
					DocDate: format(filters.FechaConta, 'yyyy-MM-dd'),
					TaxDate: format(filters.FechaDoc, 'yyyy-MM-dd'),
					PriceList: filters.ListaPrecios,
					FromWarehouse: FromWarehouse,
					ToWarehouse: ToWarehouse,
					U_GSP_MOVESTOCK: MOVRAZON.Value.toString(),
					Comments: filters.Comentario,
					U_BYS_CodeDocBaseStr: CodeDocBaseStr,
					StockTransferLines: LinesTraslado
				}
				const resultTransfer: resultDocument = await createStockTransfer(dataTraslado)

				if (resultTransfer.DocNum != 0) {
					/*DOCUMENTO GENERADO CORRECTAMENTE*/
					Estatus = Tipo == 1 ? 'A' : 'T'
					Mensaje = 'Ok'
					DocNumGenerado = resultTransfer.DocNum
					notification.success('Documento generado correctamente')
					await executeAddDocTraslado({
						data: {
							idTraspaso: selectedTraspaso.Id,
							objType: 67,
							docNum: resultTransfer.DocNum,
							docDate: format(filters.FechaConta, 'yyyy-MM-dd'),
							razonMov: MOVRAZON.Value.toString(),
							listaPrecio: filters.ListaPrecios,
							baseCode: CodeDocBaseStr,
							orden: Tipo
						}
					}).catch((error) => {
						notification.error(getServiceLayerError(error))
					})
				} else {
					/*ACTUALIZAR ESTATUS ERROR Y MENSAJE */
					Estatus = 'E'
					Mensaje = resultTransfer.Error
						? resultTransfer.Error
						: 'Error al generar el documento ' + Tipo
					DocNumGenerado = 0
					notification.error(
						'Error al generar el documento. Error: ' + resultTransfer.Error
							? resultTransfer.Error
							: 'desconocido',
						6000
					)
				}
			} else {
				Estatus = 'S'
				Mensaje =
					'No fueron confirmadas cantidades (Mayor a 0) para la generación del documento ' +
					Tipo.toString()
				DocNumGenerado = 0
			}

			const newData: dataTraspasoTienda = {
				...selectedTraspaso,
				Estatus: Estatus,
				Mensaje: Mensaje,
				DocNum1: Tipo == 1 ? DocNumGenerado : selectedTraspaso.DocNum1,
				DocNum2: Tipo == 2 ? DocNumGenerado : selectedTraspaso.DocNum2,
				BaseCode: CodeDocBaseStr
			}

			dispatch(setSelectedDataTraspaso(newData))
			dispatch(
				setDataTraspaso(
					dataTraspasos.map((t) => {
						if (t.Id == selectedTraspaso.Id) return newData
						else return t
					})
				)
			)

			await executeUpdateEncTraslado({
				url: urlUpdateEnc(selectedTraspaso.Id.toString()),
				data: { estatus: Estatus, mensaje: Mensaje }
			})

			setGeneratingDocs(false)
		} catch (error) {
			notification.error(getError(error))
			setGeneratingDocs(false)
		}
	}

	const createStockTransfer = async (dataDoc: StockTransfer) => {
		return await executeCreateStockTransfer({ ...dataDoc })
			.unwrap()
			.then((data) => {
				return { DocNum: data.DocNum, Error: '' }
			})
			.catch((error) => {
				return { DocNum: 0, Error: error.data.error.message }
			})
	}

	return {
		user,
		filters,
		dataTraspasos,
		selectedTab,
		loading,
		loadingTraspasos,
		generatingDocs,
		onChangePreviousTab,
		onChangeNextTab,
		onReset,
		onSubmitFilters,
		selectedTraspaso,
		onSelectTraspaso,
		onGenerateTraspaso
	}
}

export default useTraspasosTiendas
