import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'
import { authorizationTypes } from '../auth.types'
import { useLocation } from 'react-router-dom'

const useAuthorization = () => {
  const { pathname } = useLocation()
  const useAxios = makeUseAxios({
    axios: axiosInstance(process.env.REACT_APP_API_CONFIGURATOR ?? env.REACT_APP_API_CONFIGURATOR)
  })
  const [{ loading, data }] = useAxios<authorizationTypes>({
    url: '/user/dashboards/acceso',
    method: 'post',
    data: { point: pathname }
  })

  return { data, loading }
}

export default useAuthorization
