import { useOnErrorImage } from '@/shared/hooks'
import type { IPackingListIncident } from '../types/packingList.types'

const PackingListIncident = ({
	Dispersion,
	Motivo,
	UnidadesRecibidas,
	Imagenes
}: IPackingListIncident) => {
	const [replaceImage] = useOnErrorImage()
	return (
		<div className='flex flex-col p-4 shadow-lg rounded-md bg-blue-100'>
			<section className='w-full h-52 '>
				<figure className='w-full h-full rounded-md'>
					<img
						src={Imagenes[0]?.Ruta ?? ''}
						onError={replaceImage}
						className='w-full h-full object-cover  rounded-md object-center'
						alt='product damage'
						crossOrigin='anonymous'
					/>
				</figure>
			</section>

			<section className='mt-4  space-y-2 text-sm'>
				<div className='flex '>
					<h4 className=' text-gray-600'>Linea de dispersion</h4>
					<p className='ml-2 font-semibold'>{Dispersion}</p>
				</div>
				<div className='flex'>
					<h4 className=' text-gray-600'>Cantidad</h4>
					<p className='ml-2 font-semibold'>{UnidadesRecibidas}</p>
				</div>
				<div>
					<h4 className=' text-gray-600'>Motivo</h4>
					<div className=' bg-neutral-100 rounded-md p-2'>
						<p className=' text-gray-700 text-justify'>{Motivo}</p>
					</div>
				</div>
			</section>
		</div>
	)
}

export default PackingListIncident
