import {
	WarehouseTemplate,
	ProductDescriptionTemplate,
	TableDocumentsTemplate
} from '@/features/warehouse/templates'
const WarehousePage = () => {
	return (
		<WarehouseTemplate>
			<ProductDescriptionTemplate />
			<TableDocumentsTemplate />
		</WarehouseTemplate>
	)
}

export default WarehousePage
