import { useCallback, useEffect, useRef, useState } from 'react'
import { useSurtidoTiendas } from '@/features/inventoryAssortment/hooks'
import { DataGrid, Popover } from 'devextreme-react'
import { Button } from '@/shared/components/commons'
import {
	Column,
	ColumnChooser,
	ColumnFixing,
	Export,
	FilterRow,
	Format,
	GroupPanel,
	Scrolling,
	SearchPanel,
	Selection
} from 'devextreme-react/data-grid'
import { ConfirmModalTemplate } from '@/shared/templates'
import ArrayStore from 'devextreme/data/array_store'
import DataSource from 'devextreme/data/data_source'
import { SelectionChangedEvent } from 'devextreme/ui/data_grid'
import { useToggle } from '@/shared/hooks'

const DataSurtidoTiendasTemplate = () => {
	const dataGridRef = useRef<DataGrid>(null)
	const [seeTiendasDetails, setSeeTiendasDetails] = useToggle()
	const {
		user,
		loading,
		generatingDocs,
		filters,
		dataSurtido,
		onChangePreviousTab,
		onGenerateSurtido,
		openConfirm,
		isSelectableRowDataSurtido,
		setOpenConfirm,
		whsBufferPercents,
		validateWhsBufferPercent
	} = useSurtidoTiendas()

	const dataSource = new DataSource({
		store: new ArrayStore({
			data: dataSurtido,
			key: 'ID'
		})
	})

	const [selectedRowKeys, setSelectedRowKeys] = useState<(string | null)[]>([])

	useEffect(() => {
		if (filters.penetracionBuffer) {
			const assormentLines = dataSurtido.filter((item) => Number(item.Entregado) > 0)
			const linesWithBuffer = assormentLines
				.filter((item) => validateWhsBufferPercent(item, whsBufferPercents))
				.map(({ ID }) => ID)
			setSelectedRowKeys(linesWithBuffer)
		} else {
			setSelectedRowKeys(
				dataSurtido.filter((item) => Number(item.Entregado) > 0).map((item) => item.ID)
			)
		}
	}, [dataSurtido, whsBufferPercents])

	const onSelectionChanged = useCallback((e: SelectionChangedEvent) => {
		const deselectRowKeys: number[] = []
		const selectRowKeys: string[] = []
		e.selectedRowsData.forEach((item) => {
			if (isSelectableRowDataSurtido(item)) selectRowKeys.push(item.ID)
			else deselectRowKeys.push(e.component.keyOf(item))
		})
		if (deselectRowKeys.length) {
			e.component.deselectRows(deselectRowKeys)
		}
		setSelectedRowKeys(selectRowKeys)
	}, [])

	return (
		<section
			className={`w-full overflow-auto`}
			style={{
				height: 'calc(100% - 5rem)'
			}}
		>
			<div className='flex flex-col w-full p-5'>
				<div className='hidden md:mb-4 md:block'>
					<p>
						<span className='font-semibold'> Filtros Seleccionados:</span>
						<span className='ml-2'>Base: {filters.Base} |</span>
						<span className='ml-2'>Segmentos: {filters.Segmentos.toString()} |</span>
						<span className='ml-2'>
							Plaza:{' '}
							{user?.Localidades.map((plaza) => {
								return plaza.Codigo == filters.Plaza ? plaza.Nombre : ''
							})}
							{' |'}
						</span>
						<span className='ml-2'>
							Tiendas:
							<button
								id='TiendasDetails'
								className='btn cuartiary'
								onMouseEnter={setSeeTiendasDetails}
								onMouseLeave={setSeeTiendasDetails}
								onClick={setSeeTiendasDetails}
							>
								( {filters.Tiendas.length <= 3 ? filters.Tiendas.toString() : 'Varios'} )
							</button>
						</span>
					</p>

					<Popover
						target='#TiendasDetails'
						position='bottom'
						visible={seeTiendasDetails}
						width={400}
					>
						<p className='text-xs text-justify '>
							{filters.Tiendas.toString().replaceAll(',', ', ') || 'Ninguna seleccionada'}
						</p>
					</Popover>
				</div>

				<DataGrid
					ref={dataGridRef}
					dataSource={dataSource}
					selectedRowKeys={selectedRowKeys}
					allowColumnResizing={true}
					columnResizingMode={'nextColumn'}
					showBorders={true}
					showRowLines={true}
					columnAutoWidth
					onSelectionChanged={onSelectionChanged}
				>
					<ColumnChooser enabled={false} />
					<GroupPanel visible={true} />
					<FilterRow visible={true} />
					<SearchPanel visible={true} highlightCaseSensitive={true} placeholder='Buscar' />
					<Scrolling mode='virtual' />
					<Selection mode='multiple' allowSelectAll={false} />
					<ColumnFixing enabled={true} />
					<Export enabled={false} />
					<Column dataField='WhsCode' caption='Tienda' dataType='string' width={70} />
					<Column dataField='ItemCode' caption='Articulo' dataType='string' width={170} />
					<Column dataField='ItemName' caption='Descripcion' dataType='string' width={300} />
					<Column dataField='TipoArticulo' caption='Segmento' dataType='string' width={80} />
					<Column dataField='Vta' caption='Tipo Repo' dataType='string' width={80} />
					<Column dataField='WhsFrom' caption='Bodega' dataType='string' width={70} />
					<Column dataField='UBysAlmPuente' caption='AlmPuente' dataType='string' width={85} />
					<Column dataField='Cantidad' caption='Cantidad' dataType='number' width={75} />
					<Column
						dataField='Buffer'
						caption='Buffer'
						dataType='string'
						width={60}
						fixed={true}
						fixedPosition='right'
					/>
					<Column
						dataField='WhsOnHand'
						caption='Exi Tienda'
						dataType='number'
						width={90}
						fixed={true}
						fixedPosition='right'
					/>
					<Column
						dataField='PenetracionBuffer'
						caption='Penetración'
						dataType='number'
						width={90}
						fixed={true}
						fixedPosition='right'
					/>

					<Column
						dataField='WhsOnHandTransito'
						caption='En Transito'
						dataType='number'
						width={90}
						fixed={true}
						fixedPosition='right'
					/>
					<Column dataField='WhsFromOnHand' caption='Exi Bodega' dataType='number' width={90} />
					<Column dataField='WhsOnHandPuente' caption='Exi Puente' dataType='number' width={90} />
					<Column dataField='RotaCalc' caption='Rot Calc' dataType='number' width={70} />
					<Column dataField='SalesAvg' caption='Prom Vta' dataType='number' width={80} />
					<Column dataField='MinStock' caption='Rot Cli' dataType='number' width={60} />
					<Column dataField='UUBysRotacion' caption='Rot Alm' dataType='number' width={70} />
					<Column dataField='RotaReal' caption='Rot Real' dataType='number' width={70} />
					<Column dataField='SalesDays' caption='Días' dataType='number' width={50} />
					<Column dataField='MinOrder' caption='Mínimo' dataType='number' width={70} />
					<Column dataField='Day1Qty' caption='Vta1' dataType='number' width={50} />
					<Column dataField='Day2Qty' caption='Vta2' dataType='number' width={50} />
					<Column dataField='Day3Qty' caption='Vta3' dataType='number' width={50} />
					<Column dataField='Day4Qty' caption='Vta4' dataType='number' width={50} />
					<Column dataField='Day5Qty' caption='Vta5' dataType='number' width={50} />
					<Column dataField='Day6Qty' caption='Vta6' dataType='number' width={50} />
					<Column dataField='Day7Qty' caption='Vta7' dataType='number' width={50} />
					<Column dataField='SalesSum' caption='Tot Vtas' dataType='number' width={70} />
					<Column
						dataField='TipoRep'
						caption='TipRep'
						dataType='number'
						width={60}
						visible={false}
					/>
					<Column dataField='Reponer' caption='Reponer' dataType='number' width={70} />
					<Column dataField='Negado' caption='Negado' dataType='number' width={70} />
					<Column
						dataField='Entregado'
						caption='Entregado'
						dataType='number'
						width={80}
						fixed={true}
						fixedPosition='right'
					/>
					<Column
						dataField='Surtir'
						caption='Surtir'
						dataType='boolean'
						width={50}
						visible={false}
					/>
				</DataGrid>
			</div>

			<div className='p-5 flex justify-end absolute bottom-0 right-0 w-full items-center'>
				{generatingDocs && <span className='mr-5 font-semibold'>Generando documentos</span>}
				<Button
					className='btn secondary w-48 m-1'
					isLoading={loading || generatingDocs}
					onClick={onChangePreviousTab}
				>
					Anterior
				</Button>
				<Button
					className='btn primary w-48 m-1'
					isLoading={loading || generatingDocs}
					onClick={setOpenConfirm}
				>
					Siguiente
				</Button>
			</div>

			<ConfirmModalTemplate
				message='Este proceso realizará los traslados de stock para las reposiciones y generará los objetos de negado si existe, ¿Desea continuar?'
				isOpen={openConfirm}
				handleClose={setOpenConfirm}
				onConfirm={() => onGenerateSurtido(selectedRowKeys)}
			/>
		</section>
	)
}

export default DataSurtidoTiendasTemplate
