import { useRef, useState } from 'react'
import { useFiltersCatalogos, useSurtidoTiendas } from '@/features/inventoryAssortment/hooks'
import { Button } from '@/shared/components/commons'
import { DataGrid } from 'devextreme-react'
import {
	Column,
	ColumnChooser,
	Export,
	FilterRow,
	GroupPanel,
	Scrolling,
	SearchPanel
} from 'devextreme-react/data-grid'
import { ColumnCellTemplateData } from 'devextreme/ui/data_grid'
import { Link } from 'react-router-dom'
import { LoaderTemplate } from '@/shared/templates'

const ResultSurtidoTiendasTemplate = () => {
	const dataGridRef = useRef<DataGrid>(null)
	const { user, onReset, logDocs, onExport } = useSurtidoTiendas()
	const [formatoPDF, setFormatoPDF] = useState(
		user?.DefaultReposicion ? user.DefaultReposicion.toString() : '1'
	)

	const { loadingFormatsPDF, ListsFormatsPDF } = useFiltersCatalogos(['FormatosPDFRepo'])

	const cellDocNumTransferRender = (data: ColumnCellTemplateData) => {
		const formato = ListsFormatsPDF?.find((pdf) => pdf.Id === Number(formatoPDF))
		if (formato === undefined) {
			return <span>{data.value}</span>
		} else {
			return (
				<Link to={`/view-pdf${formato.UrlBase}/${formato.Version}/${data.value}`} target='_blank'>
					<span className='text-blue-600'>{data.value}</span>
				</Link>
			)
		}
	}

	const cellDocNumSolicitudRender = (data: ColumnCellTemplateData) => {
		return (
			<Link to={`/view-pdf/SolTraslado/${data.value}`} target='_blank'>
				<span className='text-blue-600'>{data.value}</span>
			</Link>
		)
	}

	return (
		<>
			{loadingFormatsPDF && <LoaderTemplate />}
			<section
				className={`w-full overflow-auto`}
				style={{
					height: 'calc(100% - 5rem)'
				}}
			>
				{ListsFormatsPDF != undefined && (
					<div className='pt-5 px-5'>
						<label htmlFor='formatoPDF' className='block'>
							Formato Doc. Reposición:
							<select
								id='formatoPDF'
								onChange={(evt) => setFormatoPDF(evt.target.value)}
								className={`field-input`}
								style={{ width: 'auto', height: 'auto', margin: '0 5px' }}
								defaultValue={formatoPDF}
							>
								{ListsFormatsPDF.map((list) => (
									<option key={list.Id} value={list.Id}>
										{list.Nombre}
									</option>
								))}
							</select>
						</label>
					</div>
				)}

				<div className='flex flex-col w-full px-5 mt-2'>
					<DataGrid
						ref={dataGridRef}
						dataSource={logDocs}
						allowColumnResizing={true}
						columnResizingMode={'nextColumn'}
						showBorders={true}
						showRowLines={true}
						columnAutoWidth
						onExporting={onExport}
					>
						<ColumnChooser enabled={false} />
						<GroupPanel visible={false} />
						<FilterRow visible={true} />
						<SearchPanel visible={true} highlightCaseSensitive={true} placeholder='Buscar' />
						<Scrolling mode='virtual' />
						<Export enabled={true} />
						<Column dataField='WhsCodeFrom' caption='Alm. Origen' dataType='string' width={150} />
						<Column dataField='AlmPuenteVal' caption='Alm. Puente' dataType='string' width={150} />
						<Column dataField='WhsCode' caption='Alm. Destino' dataType='string' width={150} />
						<Column
							dataField='DocNum'
							caption='Doc. Traslado'
							width={150}
							cellRender={cellDocNumTransferRender}
						/>
						<Column
							dataField='SolDocNum'
							caption='Sol. Traslado'
							width={150}
							cellRender={cellDocNumSolicitudRender}
						/>
						<Column dataField='Error' caption='Error' dataType='string' />
					</DataGrid>
				</div>
				<div className='p-5 flex justify-end absolute bottom-0 right-0 w-full'>
					<Button className='btn primary w-48 m-1' isLoading={false} onClick={onReset}>
						Finalizar
					</Button>
				</div>
			</section>
		</>
	)
}

export default ResultSurtidoTiendasTemplate
