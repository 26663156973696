import { useEffect, useRef } from 'react'
import { isDesktop, isMobileOnly } from 'react-device-detect'
import { Popover } from 'devextreme-react/popover'
import DataGrid, {
	Column,
	ColumnChooser,
	Paging,
	Export,
	FilterRow,
	GroupPanel,
	SearchPanel,
	Selection,
	Grouping,
	Pager
} from 'devextreme-react/data-grid'
import { CellPreparedEvent } from 'devextreme/ui/data_grid'
import { Title } from '@/shared/components/commons'
import { format } from 'date-fns'
import useDataPartidasAbiertas from '@/features/reports/informes/partidasAbiertas/hooks/useDataPartidasAbiertas'
import { DataResultCloseLines } from '@/features/reports/informes/partidasAbiertas/templates/DataResultCloseLines'
import { ModalInicioSAPTemplate, LoaderTemplate } from '@/shared/templates'

const cellAvailables = {
	mobile: ['TipoDoc', 'CardCode', 'ItemCode', 'DocNum_1', 'OpenQty_1', 'DocNum_2', 'OpenQty_2'],
	tablet: [
		'TipoDoc',
		'FechaDoc',
		'CardCode',
		'ItemCode',
		'LineNum_1',
		'OpenQty_1',
		'DocNum_2',
		'LineNum_2',
		'OpenQty_2'
	],
	shopUsers: [
		'TipoDoc',
		'FechaDoc',
		'FechaEnvio',
		'FechaEntrega',
		'CardCode',
		'CardName',
		'Modelo',
		'ItemCode',
		'ItemName',
		'Segmento',
		'DocNum_1',
		'LineNum_1',
		'OpenQty_1',
		'DocNum_2',
		'LineNum_2',
		'OpenQty_2'
	]
}
export const DataPartidasAbiertasTemplate = () => {
	const dataGridRef = useRef<DataGrid>(null)

	const {
		filter,
		dataSourceReport,
		onExport,
		seeClientesDetails,
		setSeeClientesDetails,
		seeSegmentosDetails,
		setSeeSegmentosDetails,
		onChangeSelectionLines,
		openInicioSAP,
		setOpenInicioSAP,
		isClosedLines,
		onCloseLineDocs,
		confirmCloseLineDocs,
		openResult,
		setOpenResult,
		dataCloseResult
	} = useDataPartidasAbiertas()

	const isCellAvailable = (cellName: string) => {
		if (isMobileOnly) {
			return cellAvailables.mobile.includes(cellName)
		} else if (isDesktop) {
			return cellAvailables.shopUsers.includes(cellName)
		} else {
			return cellAvailables.tablet.includes(cellName)
		}
	}

	useEffect(() => {
		if (dataGridRef.current) {
			const instance = dataGridRef.current.instance
			instance.clearFilter()
			instance.clearSelection()
		}
	}, [dataSourceReport])

	const onCellDraw = (e: CellPreparedEvent) => {
		switch (e.column.dataField) {
			case 'OpenQty_1':
				if (e.value == 0) {
					e.cellElement.style.color = 'white'
					e.cellElement.style.backgroundColor = 'gray'
				}
				break
			case 'OpenQty_2':
				if (e.value == 0) {
					e.cellElement.style.color = 'white'
					e.cellElement.style.backgroundColor = 'gray'
				}
				break
		}
	}

	return (
		<main className='main-layout-main p-4'>
			<Title
				backPage='Reportes'
				backTo='/reports'
				title='Lista de Partidas abiertas'
				pageName='Informes'
			/>
			<div className='flex flex-col w-full hidden md:mb-4 md:block'>
				<p>
					<span className='font-semibold'> Filtros Seleccionados:</span>
					{filter.DocType !== '' && (
						<>
							<span className='ml-2'>Tipo de Documento: {filter.DocType} |</span>
							<span className='ml-2'>Tipo de Fecha: {filter.DateType} |</span>
							<span className='ml-2'>
								Del {format(filter.DateStart, 'yyyy-MM-dd')} Al{' '}
								{format(filter.DateEnd, 'yyyy-MM-dd')} |
							</span>
							<span className='ml-2'>
								Segmentos:
								<button
									id='SegmentosDetails'
									className='btn cuartiary'
									onMouseEnter={setSeeSegmentosDetails}
									onMouseLeave={setSeeSegmentosDetails}
									onClick={setSeeSegmentosDetails}
								>
									({' '}
									{filter.Segmentos.length <= 3
										? filter.Segmentos.toString().replaceAll(',', ', ')
										: 'Varios'}{' '}
									)
								</button>{' '}
								|
							</span>
							<span className='ml-2'>
								Clientes: {filter.ClienteType}
								<button
									id='ClientesDetails'
									className='btn cuartiary'
									onMouseEnter={setSeeClientesDetails}
									onMouseLeave={setSeeClientesDetails}
									onClick={setSeeClientesDetails}
								>
									({' '}
									{filter.Clientes.length <= 3
										? filter.Clientes.toString().replaceAll(',', ', ')
										: 'Varios'}{' '}
									)
								</button>{' '}
								|
							</span>
						</>
					)}
				</p>

				<Popover
					target='#SegmentosDetails'
					position='bottom'
					visible={seeSegmentosDetails}
					width={400}
				>
					<p className='text-xs text-justify '>
						{filter.Segmentos.toString().replaceAll(',', ', ') || 'Ninguna seleccionada'}
					</p>
				</Popover>

				<Popover
					target='#ClientesDetails'
					position='bottom'
					visible={seeClientesDetails}
					width={400}
				>
					<p className='text-xs text-justify '>
						{filter.Clientes.toString().replaceAll(',', ', ') || 'Ninguna seleccionada'}
					</p>
				</Popover>
			</div>

			<div className='flex flex-col w-full h-3/4 overflow-auto'>
				<DataGrid
					ref={dataGridRef}
					dataSource={dataSourceReport}
					allowColumnResizing={true}
					columnResizingMode={'nextColumn'}
					onExporting={onExport}
					showBorders={true}
					columnAutoWidth
					onSelectionChanged={({ selectedRowsData }) => onChangeSelectionLines(selectedRowsData)}
					onCellPrepared={(e) => onCellDraw(e)}
				>
					<Paging enabled={true} defaultPageSize={isDesktop ? 30 : 10} />
					<Pager allowedPageSizes={[5, 10, 30, 50]} showPageSizeSelector={true} />
					<ColumnChooser enabled={true} />
					<GroupPanel visible={true} />
					<Grouping autoExpandAll={true} />
					<FilterRow visible={true} />
					<SearchPanel visible={true} highlightCaseSensitive={true} placeholder='Buscar' />
					<Selection mode='multiple' allowSelectAll={true} showCheckBoxesMode='always' />
					<Export enabled={true} allowExportSelectedData={true} />
					<Column
						dataField='TipoDoc'
						caption='Tipo Doc.'
						dataType='string'
						width={100}
						visible={false}
					/>
					<Column
						dataField='FechaDoc'
						caption='Fecha Doc.'
						dataType='string'
						width={100}
						visible={isCellAvailable('FechaDoc')}
					/>
					<Column
						dataField='FechaEnvio'
						caption='Fecha Envio'
						dataType='string'
						width={100}
						visible={isCellAvailable('FechaEnvio') && filter.DocType != 'Solicitud de traslado'}
					/>
					<Column
						dataField='FechaEntrega'
						caption='Fecha Entrega'
						dataType='string'
						width={100}
						visible={isCellAvailable('FechaEntrega') && filter.DocType != 'Solicitud de traslado'}
					/>
					<Column
						dataField='CardCode'
						caption='Cliente'
						dataType='string'
						width={100}
						visible={isCellAvailable('CardCode')}
					/>
					<Column
						dataField='CardName'
						caption='Nombre'
						dataType='string'
						width={320}
						visible={isCellAvailable('CardName')}
					/>
					<Column
						dataField='EsPlaza'
						caption='EsPlaza'
						dataType='string'
						visible={false}
						showInColumnChooser={false}
					/>
					<Column
						dataField='Localidad'
						caption='Localidad'
						dataType='string'
						visible={false}
						showInColumnChooser={false}
					/>
					<Column
						dataField='Plaza'
						caption='Plaza'
						dataType='string'
						visible={false}
						showInColumnChooser={false}
					/>
					<Column
						dataField='EsProveeduria'
						caption='EsProveeduria'
						dataType='string'
						visible={false}
						showInColumnChooser={false}
					/>
					<Column
						dataField='Modelo'
						caption='Modelo'
						dataType='string'
						width={120}
						visible={isCellAvailable('Modelo')}
					/>
					<Column
						dataField='ItemCode'
						caption='Artículo'
						dataType='string'
						width={180}
						visible={isCellAvailable('ItemCode')}
					/>
					<Column
						dataField='ItemName'
						caption='Descripción'
						dataType='string'
						width={350}
						visible={isCellAvailable('ItemName')}
					/>
					<Column
						dataField='Segmento'
						caption='Segmento'
						dataType='string'
						width={100}
						visible={isCellAvailable('Segmento')}
					/>
					<Column
						dataField='OpenQty_1'
						caption={`OpenQty ${filter.DocType === 'Solicitud de traslado' ? '1' : ''}`}
						dataType='number'
						width={100}
						visible={isCellAvailable('OpenQty_1')}
					/>
					<Column
						dataField='OpenQty_2'
						caption='OpenQty 2'
						dataType='number'
						width={100}
						visible={isCellAvailable('OpenQty_2') && filter.DocType === 'Solicitud de traslado'}
					/>
					<Column
						dataField='DocNum_1'
						caption={`DocNum ${filter.DocType === 'Solicitud de traslado' ? '1' : ''}`}
						dataType='number'
						width={100}
						groupIndex={0}
						visible={isCellAvailable('DocNum_1')}
					/>
					<Column
						dataField='DocEntry_1'
						caption={`DocEntry ${filter.DocType === 'Solicitud de traslado' ? '1' : ''}`}
						dataType='number'
						width={100}
						visible={isCellAvailable('DocEntry_1')}
					/>
					<Column
						dataField='LineNum_1'
						caption={`LineNum ${filter.DocType === 'Solicitud de traslado' ? '1' : ''}`}
						dataType='number'
						width={100}
						visible={isCellAvailable('LineNum_1')}
					/>
					<Column
						dataField='DocNum_2'
						caption='DocNum 2'
						dataType='number'
						width={100}
						visible={isCellAvailable('DocNum_2') && filter.DocType === 'Solicitud de traslado'}
					/>
					<Column
						dataField='DocEntry_2'
						caption='DocEntry 2'
						dataType='number'
						width={100}
						visible={isCellAvailable('DocEntry_2') && filter.DocType === 'Solicitud de traslado'}
					/>
					<Column
						dataField='LineNum_2'
						caption='LineNum 2'
						dataType='number'
						width={100}
						visible={isCellAvailable('LineNum_2') && filter.DocType === 'Solicitud de traslado'}
					/>
				</DataGrid>
			</div>

			<div className='flex p-5 fixed bottom-0 right-0'>
				{filter.DocType === 'Solicitud de traslado' && (
					<p className='m-2 flex items-center'>
						<section className='w-3 h-3 bg-gray-600 mr-2'></section> No aplica cierre (cantidad 0)
					</p>
				)}
				<button id='SegmentosDetails' className='ml-2 btn primary' onClick={onCloseLineDocs}>
					<span className='pl-3 pr-3'>Cerrar Lineas</span>
				</button>
			</div>

			<ModalInicioSAPTemplate
				isOpen={openInicioSAP}
				handleClose={setOpenInicioSAP}
				onSubmitForm={(e) => confirmCloseLineDocs(e)}
			/>

			{isClosedLines && <LoaderTemplate />}

			<DataResultCloseLines
				isOpen={openResult}
				handleClose={setOpenResult}
				dataResult={dataCloseResult}
			/>
		</main>
	)
}
