import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
	dataTraspasoTienda,
	traspasosTiendasSliceTypes
} from '@/features/inventoryAssortment/types/TraspasosTiendas.types'

const initialState: traspasosTiendasSliceTypes = {
	data: [],
	step: 0,
	FechaIni: new Date(),
	FechaFin: new Date(),
	Plaza: 0,
	Tiendas: [],
	FechaConta: new Date(),
	FechaDoc: new Date(),
	ListaPrecios: 1,
	Comentario: `Traspaso entre tiendas OneBeat`,
	UserSAP: '',
	PwdSAP: '',
	selectedTraspaso: undefined
}

export const traspasosTiendasSlice = createSlice({
	name: 'traspasosTiendas',
	initialState,
	reducers: {
		setTraspasosTiendasData: (state, action: PayloadAction<traspasosTiendasSliceTypes>) => ({
			...state,
			...action.payload
		}),
		resetTraspasosTiendasData: () => initialState,
		changeStep: (state, action: PayloadAction<number>) => ({
			...state,
			step: action.payload
		}),
		setDataTraspaso: (state, action: PayloadAction<dataTraspasoTienda[]>) => ({
			...state,
			data: action.payload
		}),
		setSelectedDataTraspaso: (state, action: PayloadAction<dataTraspasoTienda>) => ({
			...state,
			selectedTraspaso: action.payload
		})
	}
})

export const {
	setTraspasosTiendasData,
	resetTraspasosTiendasData,
	changeStep,
	setDataTraspaso,
	setSelectedDataTraspaso
} = traspasosTiendasSlice.actions
export default traspasosTiendasSlice
