import DataGrid, {
	Column,
	Paging,
	Pager,
	Export,
	Selection,
	SearchPanel,
	GroupPanel,
	FilterRow,
	Summary,
	TotalItem,
	ColumnChooser,
	MasterDetail,
	LoadPanel
} from 'devextreme-react/data-grid'
import { isMobileOnly, isDesktop } from 'react-device-detect'
import { Popover } from 'devextreme-react/popover'
import { useReportData } from '../hooks'
import { Title } from '@/shared/components/commons'

import type { InventoryAudit } from '../stockAlmacen.types'

const cellAvailables = {
	mobile: ['Articulo', 'Stock', 'CodigoAlmacen'],
	tablet: ['Articulo', 'Stock', 'CodigoAlmacen', 'Plaza'],
	shopUsers: ['Articulo', 'Nombre', 'Tipo', 'Stock', 'Modelo', 'CodigoAlmacen', 'Plaza']
}

export const DataStockAlmacenTemplate = () => {
	const {
		data,
		onExport,
		seeWarehouseDetails,
		setSeeWarehouseDetails,
		type,
		warehouesIn,
		user,
		getItemCodeAuditory,
		inventoryData,
		loadInventoryData
	} = useReportData()

	const isCellAvailable = (cellName: string) => {
		if (isMobileOnly) {
			return cellAvailables.mobile.includes(cellName)
		} else if (isDesktop) {
			if (user?.NombreGrupo === 'Tiendas') {
				return cellAvailables.shopUsers.includes(cellName)
			} else {
				return true
			}
		} else {
			return cellAvailables.tablet.includes(cellName)
		}
	}

	const calculateValue = (e: InventoryAudit) => {
		return e.InQty !== 0 ? e.InQty : e.OutQty
	}

	const ItemAuditory = () => {
		return (
			<DataGrid dataSource={inventoryData} showRowLines={false} columnHidingEnabled={true}>
				<Column dataField='CreatedBy' caption='No.documento' dataType='number' />
				<Column dataField='DocType' caption='Tipo' dataType='string' />
				<Column dataField='FromWarehouse' caption='De almacen' />
				<Column dataField='ToWarehouse' caption='Al almacen' />
				<Column
					caption='Cantidad'
					calculateCellValue={calculateValue}
					dataType='number'
					width={100}
				/>
				<Column dataField='DocDate' caption='Fecha' dataType='date' />
				<LoadPanel enabled={loadInventoryData} />
			</DataGrid>
		)
	}

	return (
		<main className='main-layout-main p-4'>
			<Title
				backPage='Reportes'
				backTo='/reports'
				title='Stock por almacén'
				pageName='Inventario'
			/>
			<div className='flex flex-col w-full'>
				<div className='hidden md:mb-4 md:block'>
					<p>
						<span className='font-semibold'> Filtros Seleccionados:</span>
						{type !== '' && (
							<>
								<span className='ml-2'>Tipo: {type} |</span>
								<span className='ml-2'>
									Plazas:
									<button
										id='WarehouseDetails'
										className='btn cuartiary'
										onMouseEnter={setSeeWarehouseDetails}
										onMouseLeave={setSeeWarehouseDetails}
										onClick={setSeeWarehouseDetails}
									>
										{warehouesIn.places}
									</button>
								</span>
							</>
						)}
					</p>

					<Popover
						target='#WarehouseDetails'
						position='bottom'
						visible={seeWarehouseDetails}
						width={400}
					>
						<p>{warehouesIn.warehouses || 'Ninguna seleccionada'}</p>
					</Popover>
				</div>

				<DataGrid
					dataSource={data}
					allowColumnResizing={true}
					showRowLines={false}
					columnHidingEnabled={true}
					columnResizingMode={'nextColumn'}
					onExporting={onExport}
					showBorders={true}
					columnAutoWidth
					//onContentReady={focusSearchPanelOnContentReady}
					onSelectionChanged={(e) => {
						e.component.collapseAll(-1)
						e.component.expandRow(e.currentSelectedRowKeys[0])
						if (e.selectedRowsData) {
							getItemCodeAuditory(
								e.selectedRowsData[0].Articulo,
								e.selectedRowsData[0].CodigoAlmacen
							)
						}
					}}
				>
					<ColumnChooser enabled={true} />
					<GroupPanel visible={true} />
					<FilterRow visible={true} />
					<SearchPanel
						visible={true}
						highlightCaseSensitive={true}
						placeholder='Buscar EAN/Modelo'
					/>
					<Paging defaultPageSize={isDesktop ? 30 : 10} />
					<Pager allowedPageSizes={[5, 10, 30, 50]} showPageSizeSelector={true} />
					<Selection mode='single' />
					<Export enabled={true} allowExportSelectedData={true} />
					<Column dataField='EAN' caption='EAN' visible={false} />
					<Column
						dataField='Articulo'
						caption='Articulo'
						dataType='string'
						visible={isCellAvailable('Articulo')}
					/>
					<Column
						dataField='Nombre'
						caption='Descripcion'
						dataType='string'
						visible={isCellAvailable('Nombre')}
						buttons={{}}
					/>
					<Column
						dataField='Tipo'
						caption='Tipo'
						dataType='string'
						width={80}
						visible={isCellAvailable('Tipo')}
					/>
					<Column
						dataField='Stock'
						caption='Stock'
						dataType='number'
						width={60}
						visible={isCellAvailable('Stock')}
					/>
					<Column
						dataField='Solicitado'
						caption='Solicitado'
						dataType='number'
						width={74}
						visible={isCellAvailable('Solicitado')}
					/>
					<Column
						dataField='Comprometido'
						caption='Comprometido'
						dataType='number'
						width={108}
						visible={isCellAvailable('Comprometido')}
					/>
					<Column
						dataField='Disponible'
						caption='Disponible'
						dataType='number'
						width={80}
						visible={isCellAvailable('Disponible')}
					/>
					<Column
						dataField='Modelo'
						caption='Modelo'
						dataType='string'
						width={120}
						visible={isCellAvailable('Modelo')}
					/>
					<Column
						dataField='CodigoAlmacen'
						caption='Almacen'
						dataType='string'
						defaultSortOrder='asc'
						width={72}
						visible={isCellAvailable('CodigoAlmacen')}
					/>
					<Column
						dataField='Plaza'
						caption='Plaza'
						dataType='string'
						width={100}
						groupIndex={isDesktop ? 0 : -1}
						visible={isCellAvailable('Plaza')}
					/>
					<Summary>
						<TotalItem column='Articulo' summaryType='count' />
					</Summary>
					<MasterDetail component={ItemAuditory} enabled={false} />
				</DataGrid>
			</div>
		</main>
	)
}
