import { useState, useCallback } from 'react'

const useToggle = (initialState: boolean = false) => {
  const [toggle, setToggle] = useState(initialState)
  const handle = useCallback(() => {
    setToggle((prev) => !prev)
  }, [])
  return [toggle, handle] as const
}

export default useToggle
