import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
	IPropsConfigDiasSurtido,
	IConfigDiasSurtidoValues
} from '@/features/inventoryAssortment/types/SurtidoTiendas.types'

const ConfigDiasTienda = ({ data, onChangeConfig }: IPropsConfigDiasSurtido) => {
	const validationSchema = Yup.object({
		Lunes: Yup.boolean(),
		Martes: Yup.boolean(),
		Miercoles: Yup.boolean(),
		Jueves: Yup.boolean(),
		Viernes: Yup.boolean(),
		Sabado: Yup.boolean(),
		Domingo: Yup.boolean(),
		PorcLinea: Yup.number().required().min(0),
		PorcModa: Yup.number().required().min(0),
		PorcAccesorios: Yup.number().required().min(0),
		PorcOtros: Yup.number().required().min(0)
	})

	const { handleSubmit, setFieldValue, values, errors, touched } =
		useFormik<IConfigDiasSurtidoValues>({
			initialValues: {
				Lunes: data.Lunes === 'Y',
				Martes: data.Martes === 'Y',
				Miercoles: data.Miercoles === 'Y',
				Jueves: data.Jueves === 'Y',
				Viernes: data.Viernes === 'Y',
				Sabado: data.Sabado === 'Y',
				Domingo: data.Domingo === 'Y',
				PorcLinea: data.PorcLinea ?? 0,
				PorcModa: data.PorcModa ?? 0,
				PorcAccesorios: data.PorcAccesorios ?? 0,
				PorcOtros: data.PorcOtros ?? 0
			},
			onSubmit: (values) => {
				onChangeConfig({
					...data,
					Lunes: values.Lunes ? 'Y' : 'N',
					Martes: values.Martes ? 'Y' : 'N',
					Miercoles: values.Miercoles ? 'Y' : 'N',
					Jueves: values.Jueves ? 'Y' : 'N',
					Viernes: values.Viernes ? 'Y' : 'N',
					Sabado: values.Sabado ? 'Y' : 'N',
					Domingo: values.Domingo ? 'Y' : 'N',
					PorcLinea: values.PorcLinea ?? 0,
					PorcModa: values.PorcModa ?? 0,
					PorcAccesorios: values.PorcAccesorios ?? 0,
					PorcOtros: values.PorcOtros ?? 0
				})
			},
			validationSchema,
			enableReinitialize: true
		})

	const onchange = async (field: keyof IConfigDiasSurtidoValues, value: boolean | string) => {
		setFieldValue(field, value).then(() => {
			handleSubmit()
		})
	}

	return (
		<form onSubmit={handleSubmit} className='flex flex-row flex-nowrap border-b-2'>
			<div className='diaSurtido tienda'>{`${data.Almacen} ${data.Descripcion}`}</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={values.Lunes}
					onChange={(e) => onchange('Lunes', e.target.checked)}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={values.Martes}
					onChange={(e) => onchange('Martes', e.target.checked)}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={values.Miercoles}
					onChange={(e) => onchange('Miercoles', e.target.checked)}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={values.Jueves}
					onChange={(e) => onchange('Jueves', e.target.checked)}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={values.Viernes}
					onChange={(e) => onchange('Viernes', e.target.checked)}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={values.Sabado}
					onChange={(e) => onchange('Sabado', e.target.checked)}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={values.Domingo}
					onChange={(e) => onchange('Domingo', e.target.checked)}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='number'
					value={values.PorcLinea}
					className={`border w-24 ${
						errors.PorcLinea && touched.PorcLinea ? 'border border-red-500' : ''
					}`}
					onChange={(e) => onchange('PorcLinea', e.target.value)}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='number'
					className={`border w-24 ${
						errors.PorcModa && touched.PorcModa ? 'border border-red-500' : ''
					}`}
					value={values.PorcModa}
					onChange={(e) => onchange('PorcModa', e.target.value)}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='number'
					className={`border w-24 ${
						errors.PorcAccesorios && touched.PorcAccesorios ? 'border border-red-500' : ''
					}`}
					value={values.PorcAccesorios}
					onChange={(e) => onchange('PorcAccesorios', e.target.value)}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='number'
					className={`border w-24 ${
						errors.PorcOtros && touched.PorcOtros ? 'border border-red-500' : ''
					}`}
					value={values.PorcOtros}
					onChange={(e) => onchange('PorcOtros', e.target.value)}
				/>
			</div>
		</form>
	)
	/*
	const handleOnChange = (name: keyof IConfigDiasSurtido, value: string | number) => {
		let newValue = value

		if (
			name === 'PorcAccesorios' ||
			name === 'PorcLinea' ||
			name === 'PorcModa' ||
			name === 'PorcOtros'
		) {
			if (!value) {
				newValue = 0
			} else if (value.toString().startsWith('0') && value.toString().length > 0) {
				console.log('aqui ando')
				newValue = value.toString().padStart(1)
			} else {
				newValue = value
			}
		}

		const newCurrent: IConfigDiasSurtido = { ...config, [name]: newValue }
		setConfig(newCurrent)
		onChangeConfig(newCurrent)
	}

	return (
		<div className='flex flex-row flex-nowrap border-b-2'>
			<div className='diaSurtido tienda'>{`${config.Almacen} ${config.Descripcion}`}</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={config.Lunes == 'Y' ? true : false}
					onChange={(e) => handleOnChange('Lunes', e.target.checked ? 'Y' : 'N')}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={config.Martes == 'Y' ? true : false}
					onChange={(e) => handleOnChange('Martes', e.target.checked ? 'Y' : 'N')}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={config.Miercoles == 'Y' ? true : false}
					onChange={(e) => handleOnChange('Miercoles', e.target.checked ? 'Y' : 'N')}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={config.Jueves == 'Y' ? true : false}
					onChange={(e) => handleOnChange('Jueves', e.target.checked ? 'Y' : 'N')}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={config.Viernes == 'Y' ? true : false}
					onChange={(e) => handleOnChange('Viernes', e.target.checked ? 'Y' : 'N')}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={config.Sabado == 'Y' ? true : false}
					onChange={(e) => handleOnChange('Sabado', e.target.checked ? 'Y' : 'N')}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='checkbox'
					checked={config.Domingo == 'Y' ? true : false}
					onChange={(e) => handleOnChange('Domingo', e.target.checked ? 'Y' : 'N')}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='number'
					value={config.PorcLinea}
					className='border w-24'
					onChange={(e) => handleOnChange('PorcLinea', e.target.value)}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='number'
					className='border w-24'
					value={config.PorcModa}
					onChange={(e) => handleOnChange('PorcModa', e.target.value)}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='number'
					className='border w-24'
					value={config.PorcAccesorios}
					onChange={(e) => handleOnChange('PorcAccesorios', e.target.value)}
				/>
			</div>
			<div className='diaSurtido'>
				<input
					type='number'
					className='border w-24'
					value={config.PorcOtros}
					onChange={(e) => handleOnChange('PorcOtros', e.target.value)}
				/>
			</div>
		</div>
	)
	*/
}

export default ConfigDiasTienda
