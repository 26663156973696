import { useToggle } from '@/shared/hooks'
import { Drawer } from '@/shared/components/modals'
import { ItemTraspaso } from '@/features/inventoryAssortment/components'
import { ConfirmModalTemplate } from '@/shared/templates'
import { Button } from '@/shared/components/commons'
import { formatStrToDateComplete } from '@/shared/helpers'
import { useTraspasosTiendas } from '../hooks'
import { detailsTraspasoDrawerProps } from '@/features/inventoryAssortment/types/TraspasosTiendas.types'
import { Link } from 'react-router-dom'

const DetailsTraspasoTiendaTemplate = ({ isOpen, handleClose }: detailsTraspasoDrawerProps) => {
	const [openConfirm, setOpenConfirm] = useToggle()
	const {
		selectedTraspaso: data,
		onSelectTraspaso: setData,
		generatingDocs,
		onGenerateTraspaso,
		user
	} = useTraspasosTiendas()

	const onChangeCant = (ItemCode: string, cantidad: number, type: string) => {
		if (data != undefined) {
			setData({
				...data,
				Lineas: data.Lineas.map((l) => {
					if (l.ItemCode == ItemCode)
						return {
							...l,
							CantSurtida: type == 'S' ? cantidad : l.CantSurtida,
							CantConfirmada: cantidad
						}
					else return l
				})
			})
		}
	}

	const isButtonDisabled = () => {
		if (!user || !data) return true
		if (user.tipoTraspasos == 'tienda' && data.DocNum1 !== 0) return true
		else if (user.tipoTraspasos == 'bodega') {
			if (data.DocNum1 === 0 || data.DocNum2 !== 0) return true
		}
		return false
	}

	const isAllowEdit = () => {
		if (!user || !data) return false
		if (user.tipoTraspasos == 'tienda' && data.DocNum1 !== 0) return false
		else if (user.tipoTraspasos == 'bodega') {
			if (data.DocNum1 === 0 || data.DocNum2 !== 0) return false
		}
		return true
	}

	const getDescEstatus = (estatus: string) => {
		switch (estatus) {
			case 'P':
				return <span className='status_desc PENDIENTE'>PENDIENTE</span>
			case 'A':
				return <span className='status_desc EN_PROCESO'>EN PROCESO</span>
			case 'E':
				return <span className='status_desc ERROR'>ERROR</span>
			case 'T':
				return <span className='status_desc PROCESADO'>PROCESADO</span>
			case 'S':
				return <span className='status_desc PROCESADO_SIN_DOC'>PROCESADO SIN DOCUMENTO</span>
			case 'I':
				return <span className='status_desc INACTIVO'>INACTIVO</span>
		}
	}

	return (
		<Drawer
			wrapperId='DETALLE_TRASLADO_DRAWER'
			isOpen={isOpen}
			handleClose={() => handleClose()}
			title='Detalle del traspaso'
			size='fitContent'
		>
			<h4 className='font-bold'>
				Traspaso de tienda {data?.AlmOrigen} a {data?.AlmDestino}
			</h4>
			<p className='text-sm'>Bodega Puente: {data?.AlmPuente}</p>
			<p className='text-sm'>Fecha: {data?.Fecha ? formatStrToDateComplete(data.Fecha) : ''}</p>
			<br />
			{data != undefined && (
				<>
					<p>{getDescEstatus(data.Estatus)}</p>
					<p className='text-sm'>
						No. Documento:{' '}
						{data.DocNum1 > 0 && (
							<Link to={`/view-pdf/traslado/${data.DocNum1}`} target='_blank' title='Ver documento'>
								<span className='font-bold text-blue-600 cursor-pointer'>{data.DocNum1}</span>
							</Link>
						)}{' '}
						|{' '}
						{data.DocNum2 > 0 && (
							<Link to={`/view-pdf/traslado/${data.DocNum2}`} target='_blank' title='Ver documento'>
								<span className='font-bold text-blue-600 cursor-pointer'>{data.DocNum2}</span>
							</Link>
						)}
					</p>
					<br />
					<div className='overflow-auto' style={{ height: 'calc(100% - 14rem)' }}>
						{data.Lineas.map((line) => (
							<ItemTraspaso
								key={line.ItemCode}
								Item={line}
								allowEdit={isAllowEdit()}
								DocNum1={data.DocNum1}
								DocNum2={data.DocNum2}
								handleChangeCant={onChangeCant}
							/>
						))}
					</div>
					<div className='flex flex-row justify-end py-5'>
						{generatingDocs && <span className='mr-5 font-semibold'>Generando documento</span>}
						<Button
							className='btn primary'
							style={{ width: '110px' }}
							isLoading={generatingDocs}
							disabled={isButtonDisabled()}
							onClick={setOpenConfirm}
						>
							Procesar
						</Button>
					</div>

					<ConfirmModalTemplate
						message='Este proceso realizará los traslados de stock, ¿Desea continuar?'
						isOpen={openConfirm}
						handleClose={setOpenConfirm}
						onConfirm={() => {
							setOpenConfirm()
							onGenerateTraspaso()
						}}
					/>
				</>
			)}
		</Drawer>
	)
}

export default DetailsTraspasoTiendaTemplate
