import { useCallback, useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/app/store/createStore'
import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { inventoryTypes, marketingDocumentTypes } from '@/shared/interfaces/shared.interfaces'
import {
  setInventories,
  setDocTypes,
  setWhsTypes,
  setWithMovements
} from '@/features/warehouse/warehouse.slice'
import { useNotification, useOnExportToExcel } from '@/shared/hooks'
import { getError } from '@/shared/helpers'
import { env } from '@/env'
import { ExportingEvent } from 'devextreme/ui/data_grid'

const useDocumentsProducts = () => {
  const [selectedIndexTab, setSelectedIndexTab] = useState<number>(0)
  const { product, whsTypes, docTypes, withMovements } = useSelector(
    (state: RootState) => state.warehouse
  )

  const useAxios = makeUseAxios({
    axios: axiosInstance(process.env.REACT_APP_API_WMS ?? env.REACT_APP_API_WMS)
  })
  const dispatch = useDispatch()
  const notification = useNotification()
  const { devExpressExport } = useOnExportToExcel()

  const urlInventory = (itemCode: string) => `/articulos/${itemCode}/inventarios`
  const [{ loading: isFetchingInventory }, executeFetchInventory] = useAxios<inventoryTypes[]>(
    { method: 'post' },
    { manual: true }
  )
  const isMount = useRef<boolean>(false)

  const onWhsTypesChange = useCallback(
    (whsType: string) => {
      if (whsTypes.includes(Number(whsType))) {
        dispatch(setWhsTypes(whsTypes.filter((item) => item !== Number(whsType))))
      } else {
        dispatch(setWhsTypes(whsTypes.concat(Number(whsType))))
      }
    },
    [whsTypes, dispatch]
  )

  const onDocTypesChange = useCallback(
    (docType: string) => {
      if (docTypes.includes(Number(docType))) {
        dispatch(setDocTypes(docTypes.filter((item) => item !== Number(docType))))
      } else {
        dispatch(setDocTypes(docTypes.concat(Number(docType))))
      }
    },
    [docTypes, dispatch]
  )

  const onWithMovementsChange = useCallback(
    (value: boolean) => {
      dispatch(setWithMovements(value ? 1 : 0))
    },
    [dispatch]
  )

  useEffect(() => {
    if (isMount.current) {
      if (product?.description) {
        const { ItemCode } = product.description
        executeFetchInventory({
          url: urlInventory(ItemCode),
          data: { withMovements },
          params: { whsType: whsTypes.toString() }
        })
          .then(({ data }) => {
            dispatch(setInventories(data))
          })
          .catch((error) => {
            notification.error(getError(error))
          })
      }
    } else {
      isMount.current = true
    }
  }, [whsTypes, withMovements])

  const onExportInventory = (evt: ExportingEvent<inventoryTypes, any>) => {
    if (product?.description) {
      devExpressExport(evt, `Informe_Inventario_${product.description?.ItemCode}`, 'Inventario')
    }
  }

  const onExportPurcharseOrder = (evt: ExportingEvent<marketingDocumentTypes, any>) => {
    if (product?.description) {
      devExpressExport(evt, `Ordenes_Compra_${product.description?.ItemCode}`, 'Ordenes')
    }
  }

  const onExportReserveInvoice = (evt: ExportingEvent<marketingDocumentTypes, any>) => {
    if (product?.description) {
      devExpressExport(evt, `Facturas_Reserva_${product.description?.ItemCode}`, 'Facturas')
    }
  }

  return {
    selectedIndexTab,
    setSelectedIndexTab,
    product,
    onWhsTypesChange,
    whsTypes,
    docTypes,
    onDocTypesChange,
    isFetchingInventory,
    onExportInventory,
    onWithMovementsChange,
    withMovements,
    onExportPurcharseOrder,
    onExportReserveInvoice
  }
}

export default useDocumentsProducts
