import { useEffect, useRef } from 'react'
import { useDevexpressActions } from '@/shared/hooks'
import { isDesktop, isMobileOnly } from 'react-device-detect'
import { Popover } from 'devextreme-react/popover'
import DataGrid, {
	Column,
	ColumnChooser,
	Export,
	FilterRow,
	GroupPanel,
	Pager,
	Paging,
	SearchPanel,
	Selection
} from 'devextreme-react/data-grid'
import useDataTopVentas from '@/features/reports/ventas/topVentas/hooks/useDataTopVentas'
import { Title } from '@/shared/components/commons'
import { format } from 'date-fns'

const cellAvailables = {
	mobile: ['TopNo', 'Ubicacion', 'ModeloSKU', 'Segmento'],
	tablet: [
		'TopNo',
		'Ubicacion',
		'ModeloSKU',
		'Segmento',
		'PPublico',
		'VentaPiezas',
		'VentaImporte',
		'Costo',
		'Margen',
		'Inventario'
	],
	shopUsers: [
		'TopNo',
		'Ubicacion',
		'ModeloSKU',
		'Descripcion',
		'Segmento',
		'Familia',
		'Genero',
		'GrupoEdad',
		'PPublico',
		'VentaPiezas',
		'VentaImporte',
		'Inventario',
		'FechaPrimerMovEntrada',
		'FechaUltimoMovEntrada',
		'FechaPrimerMovSalida',
		'FechaUltimoMovSalida'
	]
}
export const DataTopVentasTemplate = () => {
	const dataGridRef = useRef<DataGrid>(null)

	const {
		filter,
		dataTable,
		onExport,
		seeVisionDetails,
		setSeeVisionDetails,
		seeFamiliasDetails,
		setSeeFamiliasDetails,
		seeGenerosDetails,
		setSeeGenerosDetails,
		seeGruposEdadDetails,
		setSeeGruposEdadDetails,
		user
	} = useDataTopVentas()

	const { focusSearchPanelOnContentReady } = useDevexpressActions()

	const isCellAvailable = (cellName: string) => {
		if (isMobileOnly) {
			return cellAvailables.mobile.includes(cellName)
		} else if (isDesktop) {
			if (user?.NombreGrupo === 'Tiendas') {
				return cellAvailables.shopUsers.includes(cellName)
			} else {
				return true
			}
		} else {
			return cellAvailables.tablet.includes(cellName)
		}
	}

	useEffect(() => {
		if (dataGridRef.current) {
			const instance = dataGridRef.current.instance
			instance.clearFilter()
		}
	}, [dataTable])

	return (
		<main className='main-layout-main p-4'>
			<Title backPage='Reportes' backTo='/reports' title='Top de ventas' pageName='Ventas' />
			<div className='flex flex-col w-full'>
				<div className='hidden md:mb-4 md:block'>
					<p>
						<span className='font-semibold'> Filtros Seleccionados:</span>
						{filter.ReportType !== '' && (
							<>
								<span className='ml-2'>Tipo de reporte: {filter.ReportType} |</span>
								<span className='ml-2'>Top {filter.ShowTop} |</span>
								<span className='ml-2'>
									Del {format(filter.DateStart, 'yyyy-MM-dd')} Al{' '}
									{format(filter.DateEnd, 'yyyy-MM-dd')} |
								</span>
								<span className='ml-2'>Segmentos: {filter.Segmentos.toString()} |</span>
								<span className='ml-2'>
									Vision: {filter.Vision}{' '}
									{filter.Vision !== 'Grupo' && (
										<button
											id='VisionDetails'
											className='btn cuartiary'
											onMouseEnter={setSeeVisionDetails}
											onMouseLeave={setSeeVisionDetails}
											onClick={setSeeVisionDetails}
										>
											( {filter.AuxVision.length <= 3 ? filter.AuxVision.toString() : 'Varios'} )
										</button>
									)}{' '}
									|
								</span>
								<span className='ml-2'>Tipo de Venta : {filter.InventoryType.toString()} |</span>
								<span className='ml-2'>
									Familias:{' '}
									<button
										id='FamiliasDetails'
										className='btn cuartiary'
										onMouseEnter={setSeeFamiliasDetails}
										onMouseLeave={setSeeFamiliasDetails}
										onClick={setSeeFamiliasDetails}
									>
										{filter.Familias.length <= 3 ? filter.Familias.toString() : 'Varios'}
									</button>{' '}
									|
								</span>
								<span className='ml-2'>
									Generos:{' '}
									<button
										id='GenerosDetails'
										className='btn cuartiary'
										onMouseEnter={setSeeGenerosDetails}
										onMouseLeave={setSeeGenerosDetails}
										onClick={setSeeGenerosDetails}
									>
										{filter.Generos.length <= 3 ? filter.Generos.toString() : 'Varios'}
									</button>{' '}
									|
								</span>
								<span className='ml-2'>
									Grupos de Edad:{' '}
									<button
										id='GruposEdadDetails'
										className='btn cuartiary'
										onMouseEnter={setSeeGruposEdadDetails}
										onMouseLeave={setSeeGruposEdadDetails}
										onClick={setSeeGruposEdadDetails}
									>
										{filter.GruposEdad.length <= 3 ? filter.GruposEdad.toString() : 'Varios'}
									</button>{' '}
									|
								</span>
								<span className='ml-2'>
									Omitir descuentos :{' '}
									{filter.OmitDesc ? `Si, A partir del ${filter.DescNoValid}%` : 'No'} |
								</span>
								<span className='ml-2'>Ordenar por : {filter.OrdenBy} |</span>
								<span className='ml-2'>
									Agrupar por : {filter.GroupByItem}, {filter.GroupByLocation} |
								</span>
								{filter.ReportType === '3' && (
									<span className='ml-2'>
										Dias valido de movimiento : {filter.DiasValidoMov.toString()} |
									</span>
								)}
							</>
						)}
					</p>

					<Popover target='#VisionDetails' position='bottom' visible={seeVisionDetails} width={400}>
						<p className='text-xs text-justify '>
							{filter.AuxVision.toString().replaceAll(',', ', ') || 'Ninguna seleccionada'}
						</p>
					</Popover>

					<Popover
						target='#FamiliasDetails'
						position='bottom'
						visible={seeFamiliasDetails}
						width={400}
					>
						<p className='text-xs text-justify '>
							{filter.Familias.toString().replaceAll(',', ', ') || 'Ninguna seleccionada'}
						</p>
					</Popover>

					<Popover
						target='#GenerosDetails'
						position='bottom'
						visible={seeGenerosDetails}
						width={400}
					>
						<p className='text-xs text-justify '>
							{filter.Generos.toString().replaceAll(',', ', ') || 'Ninguna seleccionada'}
						</p>
					</Popover>

					<Popover
						target='#GruposEdadDetails'
						position='bottom'
						visible={seeGruposEdadDetails}
						width={400}
					>
						<p className='text-xs text-justify '>
							{filter.GruposEdad.toString().replaceAll(',', ', ') || 'Ninguna seleccionada'}
						</p>
					</Popover>
				</div>

				<DataGrid
					ref={dataGridRef}
					dataSource={dataTable}
					allowColumnResizing={true}
					columnResizingMode={'nextColumn'}
					onExporting={onExport}
					showBorders={true}
					columnAutoWidth
					onContentReady={(e) => {
						focusSearchPanelOnContentReady(e)
					}}
				>
					<ColumnChooser enabled={true} />
					<GroupPanel visible={true} />
					<FilterRow visible={true} />
					<SearchPanel
						visible={true}
						highlightCaseSensitive={true}
						placeholder='Buscar Modelo/SKU'
					/>
					<Paging defaultPageSize={isDesktop ? 30 : 10} />
					<Pager allowedPageSizes={[5, 10, 30, 50]} showPageSizeSelector={true} />
					<Selection mode='multiple' />
					<Export enabled={true} allowExportSelectedData={true} />
					<Column
						dataField='TopNo'
						caption='No'
						dataType='number'
						width={35}
						visible={isCellAvailable('TopNo')}
					/>
					<Column
						dataField='Ubicacion'
						caption='Ubicacion'
						dataType='string'
						width={140}
						groupIndex={0}
						visible={isCellAvailable('Ubicacion')}
					/>
					<Column
						dataField='ModeloSKU'
						caption='Modelo / SKU'
						dataType='string'
						width={110}
						visible={isCellAvailable('ModeloSKU')}
					/>
					<Column
						dataField='Descripcion'
						caption='Descripcion'
						dataType='string'
						visible={isCellAvailable('Descripcion')}
					/>
					<Column
						dataField='Segmento'
						caption='Segmento'
						dataType='string'
						width={110}
						visible={isCellAvailable('Segmento')}
					/>
					<Column
						dataField='Familia'
						caption='Familia'
						dataType='string'
						width={185}
						visible={isCellAvailable('Familia')}
					/>
					<Column
						dataField='Genero'
						caption='Genero'
						dataType='string'
						width={170}
						visible={isCellAvailable('Genero')}
					/>
					<Column
						dataField='GrupoEdad'
						caption='Grupo de Edad'
						dataType='string'
						width={180}
						visible={isCellAvailable('GrupoEdad')}
					/>
					<Column
						dataField='PPublico'
						caption='PPublico'
						dataType='number'
						width={80}
						visible={isCellAvailable('PPublico')}
					/>
					<Column
						dataField='VentaPiezas'
						caption='Venta Piezas'
						dataType='number'
						width={100}
						visible={isCellAvailable('VentaPiezas')}
					/>
					<Column
						dataField='Inventario'
						caption='Inventario'
						dataType='number'
						width={80}
						visible={isCellAvailable('Inventario')}
					/>
					<Column
						dataField='FechaPrimerMovEntrada'
						caption='FechaPrimerMovEntrada'
						dataType='date'
						visible={
							isCellAvailable('FechaPrimerMovEntrada') && filter.ReportType === 'Menos Venta'
						}
					/>
					<Column
						dataField='FechaUltimoMovEntrada'
						caption='FechaUltimoMovEntrada'
						dataType='date'
						visible={isCellAvailable('FechaUltimoMovEntrada') && filter.ReportType === 'Sin Venta'}
					/>
					<Column
						dataField='FechaPrimerMovSalida'
						caption='FechaPrimerMovSalida'
						dataType='date'
						visible={isCellAvailable('FechaPrimerMovSalida') && false}
					/>
					<Column
						dataField='FechaUltimoMovSalida'
						caption='FechaUltimoMovSalida'
						dataType='date'
						visible={
							isCellAvailable('FechaUltimoMovSalida') &&
							(filter.ReportType === 'Menos Venta' || filter.ReportType === 'Sin Venta')
						}
					/>
				</DataGrid>
			</div>
		</main>
	)
}
