import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  partidaAbiertaLine,
  partidasAbiertasSliceTypes
} from '@/features/reports/informes/partidasAbiertas/partidasAbiertas.types'

const date1 = new Date()
date1.setDate(1)
const date2 = new Date()

const initialState: partidasAbiertasSliceTypes = {
  data: [],
  DocType: '',
  DateType: '',
  DateStart: date1,
  DateEnd: date2,
  ClienteType: '',
  Clientes: [],
  Segmentos: [],
  selectedLines: []
}

export const partidasAbiertasSlice = createSlice({
  name: 'partidasAbiertasReport',
  initialState,
  reducers: {
    setPartidasAbiertasReportData: (state, action: PayloadAction<partidasAbiertasSliceTypes>) => ({
      ...state,
      ...action.payload
    }),
    resetPartidasAbiertasReportData: () => initialState,
    setPartidasAbiertasSelectedLines: (state, action: PayloadAction<partidaAbiertaLine[]>) => ({
      ...state,
      selectedLines: action.payload
    })
  }
})

export const {
  setPartidasAbiertasReportData,
  resetPartidasAbiertasReportData,
  setPartidasAbiertasSelectedLines
} = partidasAbiertasSlice.actions
export default partidasAbiertasSlice
