import { ConfigurationIcon, moveInStockAltIcon, UploadWhiteIcon } from '@/assets'
import { Title } from '@/shared/components/commons'
import { TabPanel } from 'devextreme-react'
import { Item } from 'devextreme-react/tab-panel'
import {
	DataSurtidoTiendasTemplate,
	FilterSurtidoTiendasTemplate,
	ResultSurtidoTiendasTemplate,
	SearchSurtidoTiendasTemplate
} from '../templates'
import { NavLink } from 'react-router-dom'
import { useSurtidoTiendas } from '@/features/inventoryAssortment/hooks'
import { useEffect } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { AsideModal } from '@/shared/components/modals'
import { useToggle } from '@/shared/hooks'

const SurtidoTiendasPage = () => {
	const { selectedTab, onReset } = useSurtidoTiendas()
	const [openConsulta, setOpenConsulta] = useToggle()

	useEffect(() => {
		onReset()
	}, [])

	return (
		<>
			<div className='main-layout-toolbar toolbar'>
				<MagnifyingGlassIcon
					className='w-6 h-6 cursor-pointer text-white font-extrabold md:mt-0 max-md:ml-0 m-5'
					title='Consultar documentos previos'
					onClick={setOpenConsulta}
				/>
				<NavLink
					to={`/reposicion-de-inventario/tiendas/configuracion`}
					title='Configuración de días para surtido de tiendas'
					className='mb-0 mr-4 md:mb-4 md:mr-0'
				>
					<img src={ConfigurationIcon} className='w-6 h-6 cursor-pointer' />
				</NavLink>
				<NavLink
					to={`/reposicion-de-inventario/tiendas/carga-archivo-minimos`}
					title='Carga de archivos para minimo'
					className='m-0'
				>
					<img src={UploadWhiteIcon} className='w-7 h-7 cursor-pointer' />
				</NavLink>
			</div>

			<main className='main-layout-main p-4'>
				<Title
					title='Surtido de Tiendas'
					pageName='Tiendas'
					backTo='/reposicion-de-inventario'
					backPage='Reposición de inventario'
					icon={moveInStockAltIcon}
				/>
				<AsideModal
					title='Consultar surtidos'
					isOpen={openConsulta}
					handleClose={setOpenConsulta}
					closeButtonElement={undefined}
					className='bg-white w-full md:w-fit'
				>
					<SearchSurtidoTiendasTemplate />
				</AsideModal>

				<TabPanel
					className='h-[calc(_100%_-_6rem)]'
					swipeEnabled={false}
					selectedIndex={selectedTab}
					onTitleClick={() => {
						return false
					}}
					onOptionChanged={() => {
						return false
					}}
					onSelectionChanged={() => {
						return false
					}}
					onItemClick={() => {
						return false
					}}
				>
					<Item title='Selección de filtro'>
						<FilterSurtidoTiendasTemplate />
					</Item>
					<Item title='Detalle'>
						<DataSurtidoTiendasTemplate />
					</Item>
					<Item title='Resultado'>
						<ResultSurtidoTiendasTemplate />
					</Item>
				</TabPanel>
			</main>
		</>
	)
}

export default SurtidoTiendasPage
