import React, { createContext } from 'react'
import Portal from '@/shared/components/portal'
import { spotlightContextTypes, spotlightTypes } from './interfaces'
import { Overlay } from '@/shared/components/commons'

export const spotlightContext = createContext({} as spotlightContextTypes)
const { Provider } = spotlightContext

export const Spotlight = ({ children, ...props }: spotlightTypes) => {
	return (
		<Portal wrapperId='soptlight-warehouse'>
			<Provider value={props}>
				<React.Fragment>
					<aside className={`portal__spotlight ${props.isOpen ? 'active' : ''}`}>{children}</aside>
					{props.isOpen ? <Overlay /> : null}
				</React.Fragment>
			</Provider>
		</Portal>
	)
}
