import { IncidentIntemProps } from './types'
import { noImage } from '@/assets'
import { useOnErrorImage } from '@/shared/hooks'

const IncidentItem = ({ item }: IncidentIntemProps) => {
	const getImageUrl = () => {
		if (item && item.Imagenes.length > 0) return item.Imagenes[0].Ruta
		else return noImage
	}

	const [replaceImage] = useOnErrorImage()

	return (
		<div className='flex flex-col p-4 shadow-lg rounded-md bg-white'>
			<section className='w-full h-52 '>
				<figure className='w-full h-full rounded-md'>
					<img
						src={getImageUrl()}
						onError={replaceImage}
						className='w-full h-full object-cover  rounded-md object-center'
						alt='product damage'
						crossOrigin='anonymous'
					/>
				</figure>
			</section>

			<section className='mt-4  space-y-2 text-sm'>
				<div className='flex '>
					<h4 className=' text-gray-600'>Bulto</h4>
					<p className='ml-2 font-semibold'>{item?.Bulto}</p>
				</div>
				<div className='flex'>
					<h4 className=' text-gray-600'>Motivo</h4>
					<p className='ml-2 font-semibold'>Dañada</p>
				</div>
				<div className='flex'>
					<h4 className=' text-gray-600'>Cantidad</h4>
					<p className='ml-2 font-semibold'>{item?.CantidadEmpacada}</p>
				</div>
				<div className='flex'>
					<h4 className=' text-gray-600'>Cantidad confirmada</h4>
					<p className='ml-2 font-semibold'>{item?.CantidadRecibida}</p>
				</div>
				<div>
					<h4 className=' text-gray-600'>Comentarios</h4>
					<div className=' bg-neutral-100 rounded-md p-2'>
						<p className=' text-gray-700 text-justify'>{item?.Motivo}</p>
					</div>
				</div>
			</section>
		</div>
	)
}

export default IncidentItem
