import { ListItemProp } from '@/shared/components/checkList/types'

export const CheckListSimpleItem = ({
	code,
	description,
	isChecked,
	onChangeCheck
}: ListItemProp) => {
	return (
		<label className='text-sm mt-1'>
			<input
				id={code}
				type='checkbox'
				className='mr-1'
				value={code}
				checked={isChecked}
				onChange={onChangeCheck}
			/>
			{description}
		</label>
	)
}
