import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/app/store/createStore'
import { ISesionSAP } from '@/shared/templates/types'
import {
  useCloseStockTransferRequestMutation,
  useLazyGetOrderQuery,
  useLazyGetStockTransferRequestQuery,
  useLazyGetWarehouseQuery,
  useLoginMutation,
  useUpdateOrderMutation
} from '@/features/operationStatus/service/serviceLayerService'
import { useNotification, useToggle } from '@/shared/hooks'
import { getServiceLayerError } from '@/app/service_layer/helpers/getServiceLayerError'
import { ExportingEvent } from 'devextreme/ui/data_grid'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import {
  lineCloseResult,
  partidaAbiertaLine,
  partidasAbiertasReportTypes,
  resultValues
} from '@/features/reports/informes/partidasAbiertas/partidasAbiertas.types'
import {
  setPartidasAbiertasReportData,
  setPartidasAbiertasSelectedLines
} from '@/features/reports/informes/partidasAbiertas/partidasAbiertas.slice'
import { IStockTransferDocument } from '@/features/operationStatus/types/stockTransfer'
import { IOrderDocument } from '@/features/operationStatus/types/order'
import { useState } from 'react'

const useDataPartidasAbiertas = () => {
  const {
    auth: { user }
  } = useSelector((state: RootState) => state)
  const {
    partidasAbiertasReport: { data: dataSourceReport, selectedLines: selectedLinesClose, ...filter }
  } = useSelector((state: RootState) => state.reports.informes)
  const [seeClientesDetails, setSeeClientesDetails] = useToggle()
  const [seeSegmentosDetails, setSeeSegmentosDetails] = useToggle()
  const [openInicioSAP, setOpenInicioSAP] = useToggle(false)
  const [isClosedLines, setIsClosedLines] = useToggle()
  const [openResult, setOpenResult] = useToggle(false)
  const dispatch = useDispatch()
  const notification = useNotification()
  const [dataCloseResult, setDataCloseResult] = useState<lineCloseResult[]>([])

  const [executeLogin] = useLoginMutation()
  const [executeGetStockTransferRequest] = useLazyGetStockTransferRequestQuery()
  const [executeCloseStockTransferRequest] = useCloseStockTransferRequestMutation()
  const [executeGetOrder] = useLazyGetOrderQuery()
  const [executeUpdateOrder] = useUpdateOrderMutation()
  const [executeGetWarehouse] = useLazyGetWarehouseQuery()

  const onChangeSelectionLines = (selectedRowsData: partidasAbiertasReportTypes[]) => {
    const closeLinesDoc: partidaAbiertaLine[] = []
    for (const line of selectedRowsData) {
      if (
        line.DocNum_1 != null &&
        line.DocEntry_1 != null &&
        line.LineNum_1 != null &&
        line.OpenQty_1 != null &&
        line.OpenQty_1 > 0
      ) {
        closeLinesDoc.push({
          TipoDoc: line.TipoDoc,
          DocNum: line.DocNum_1,
          DocEntry: line.DocEntry_1,
          LineNum: line.LineNum_1,
          OpenQty: line.OpenQty_1,
          NumIdent: 1
        })
      }

      if (
        line.DocNum_2 != null &&
        line.DocEntry_2 != null &&
        line.LineNum_2 != null &&
        line.OpenQty_2 != null &&
        line.OpenQty_2 > 0
      ) {
        closeLinesDoc.push({
          TipoDoc: line.TipoDoc,
          DocNum: line.DocNum_2,
          DocEntry: line.DocEntry_2,
          LineNum: line.LineNum_2,
          OpenQty: line.OpenQty_2,
          NumIdent: 2
        })
      }
    }
    dispatch(setPartidasAbiertasSelectedLines(closeLinesDoc))
  }

  const onCloseLineDocs = () => {
    if (selectedLinesClose.length > 0) setOpenInicioSAP()
    else notification.error('No se seleccionaron lineas para procesar')
  }

  const confirmCloseLineDocs = async (values: ISesionSAP) => {
    setOpenInicioSAP()
    setIsClosedLines()
    executeLogin({
      UserName: values.UserName,
      Password: values.Password,
      CompanyDB: values.CompanyDB
    })
      .unwrap()
      .then(async () => {
        const closeLinesResult: lineCloseResult[] = []
        for await (const line of selectedLinesClose) {
          const findResult = closeLinesResult.filter(
            (a) =>
              a.TipoDoc === line.TipoDoc &&
              a.DocNum === line.DocNum &&
              ((line.TipoDoc === 'Orden de venta' && a.LineNum === line.LineNum) ||
                (line.TipoDoc === 'Solicitud de traslado' && a.DocEntry === line.DocEntry))
          )
          /*VALIDA QUE ESTE PENDIENTE Y NO EXISTA REGISTRO DE RESULTADO*/
          if (findResult.length === 0) {
            const lineValid: resultValues = await validateDocClose(line)
            if (lineValid.success) {
              const closeResult: resultValues = await executeDocClose(line)
              if (closeResult.success)
                closeLinesResult.push({ ...line, Estatus: 'T', Mensaje: 'OK' })
              else {
                closeLinesResult.push({
                  ...line,
                  Estatus: 'E',
                  Mensaje: lineValid.errorMsj
                    ? lineValid.errorMsj.toString()
                    : 'No ha podido realizarse el cierre de la linea'
                })
              }
            } else {
              closeLinesResult.push({
                ...line,
                Estatus: 'E',
                Mensaje: lineValid.errorMsj
                  ? lineValid.errorMsj.toString()
                  : 'No ha podido realizarse el cierre de la linea'
              })
            }
          }
        }
        refreshDataDocLinesCloseResult(closeLinesResult)
      })
      .catch((error) => {
        notification.error(getServiceLayerError(error))
      })
      .finally(() => {
        setIsClosedLines()
        setOpenResult()
      })
  }
  const validateDocClose = async (line: partidaAbiertaLine): Promise<resultValues> => {
    try {
      switch (line.TipoDoc) {
        case 'Solicitud de traslado':
          /*OBTIENE LA INFORMACIÓN DEL LA SOLICITUD DE TRASLADO*/
          const dataStockTransferRequest: IStockTransferDocument =
            await executeGetStockTransferRequest({
              DocNum: line.DocNum.toString()
            })
              .unwrap()
              .then((data) => {
                return data
              })
              .catch((error) => {
                const errorMessage = getServiceLayerError(error)
                throw Error(errorMessage)
              })

          /*VALIDA QUE EL DOCUEMENTO ESTE ABIERTO*/
          if (dataStockTransferRequest.DocumentStatus != 'bost_Open')
            return {
              success: false,
              errorMsj: `El documento se encuentra cerrado (Sol. ${line.NumIdent})`
            }

          /*VALIDA SI LA LOCALIDAD DEL ALMACEN ORIGEN ES VALIDO PARA EL USUARIO*/
          const locationOrigen = await executeGetWarehouse({
            Code: dataStockTransferRequest.FromWarehouse
          })
            .unwrap()
            .then((data) => {
              return data.Location ? data.Location : 0
            })
            .catch(() => {
              return 0
            })
          if (user?.Localidades) {
            const validLoc = user.Localidades.find((item) => item.Codigo === locationOrigen)
            if (!validLoc)
              return { success: false, errorMsj: `Usuario no autorizado (Sol. ${line.NumIdent})` }
          } else
            return { success: false, errorMsj: `Usuario no autorizado (Sol. ${line.NumIdent})` }

          /*OBTIENE TODAS LAS LINEAS ABIERTAS*/
          const linesOpen_Sol = dataStockTransferRequest.StockTransferLines.filter(
            (item) => item.LineStatus === 'bost_Open'
          )
          /*VALIDA QUE LA LINEA SE ENCUENTRE EN EL LISTADO */
          const lineFind_Sol = linesOpen_Sol.find(
            (item) => item.DocEntry === line.DocEntry && item.LineNum === line.LineNum
          )
          if (!lineFind_Sol)
            return {
              success: false,
              errorMsj: `La linea se encuentra cerrada (Sol. ${line.NumIdent})`
            }

          /*VALIDA QUE TODAS LAS LINEAS DEL DOCUMENTO HAYAN SIDO SELECCIONADAS*/
          const lineSelected = selectedLinesClose.filter(
            (item) =>
              item.TipoDoc === line.TipoDoc &&
              item.DocNum === line.DocNum &&
              item.DocEntry === line.DocEntry
          )
          if (lineSelected.length != linesOpen_Sol.length)
            return {
              success: false,
              errorMsj: `Es necesario seleccionar todas las lineas abiertas para cerrar el documento (Sol. ${line.NumIdent})`
            }

          return { success: true, errorMsj: '' }
        case 'Orden de venta':
          /*OBTIENE LA INFORMACIÓN DEL LA ORDEN DE VENTA*/
          const dataOrder: IOrderDocument = await executeGetOrder({
            DocNum: line.DocNum.toString()
          })
            .unwrap()
            .then((data) => {
              return data
            })
            .catch((error) => {
              const errorMessage = getServiceLayerError(error)
              throw Error(errorMessage)
            })

          /*VALIDA QUE EL DOCUEMENTO ESTE ABIERTO*/
          if (dataOrder.DocumentStatus != 'bost_Open')
            return { success: false, errorMsj: 'El documento se encuentra cerrado' }
          /*VALIDA QUE LA LINEA ESTE ABIERTA*/
          const lineFind_Ord = dataOrder.DocumentLines.filter(
            (a) => a.LineStatus === 'bost_Open'
          ).find((b) => b.DocEntry === line.DocEntry && b.LineNum === line.LineNum)
          if (!lineFind_Ord) return { success: false, errorMsj: 'La linea se encuentra cerrada' }

          return { success: true, errorMsj: '' }
        default:
          return { success: false, errorMsj: 'No se encontro información del documento' }
      }
    } catch (error) {
      return { success: false, errorMsj: error ? error.toString() : 'Error no definido' }
    }
  }

  const executeDocClose = async (line: partidaAbiertaLine): Promise<resultValues> => {
    try {
      switch (line.TipoDoc) {
        case 'Solicitud de traslado':
          return await executeCloseStockTransferRequest({
            DocNum: line.DocNum.toString()
          })
            .then(() => {
              return { success: true, errorMsj: '' }
            })
            .catch((error) => {
              return { success: false, errorMsj: getServiceLayerError(error) }
            })
        case 'Orden de venta':
          return await executeUpdateOrder({
            id: line.DocNum,
            data: {
              DocumentLines: [{ LineNum: line.LineNum, LineStatus: 'bost_Close' }]
            }
          })
            .then(() => {
              return { success: true, errorMsj: '' }
            })
            .catch((error) => {
              return { success: false, errorMsj: getServiceLayerError(error) }
            })
        default:
          return { success: false, errorMsj: 'No se encontro información del documento' }
      }
    } catch (error) {
      return {
        success: false,
        errorMsj: error ? error.toString() : 'Error no definido: no se ha podido realizar el cierre'
      }
    }
  }

  const refreshDataDocLinesCloseResult = (closeLinesResult: lineCloseResult[]) => {
    const newDataCloseLine: lineCloseResult[] = selectedLinesClose.map((a) => {
      const findResult = closeLinesResult.find(
        (b) =>
          b.TipoDoc === a.TipoDoc &&
          b.DocNum === a.DocNum &&
          ((a.TipoDoc === 'Orden de venta' && b.LineNum === a.LineNum) ||
            (a.TipoDoc === 'Solicitud de traslado' && b.DocEntry === a.DocEntry))
      )

      if (findResult) return { ...a, Estatus: findResult.Estatus, Mensaje: findResult.Mensaje }
      else return { ...a, Estatus: 'P', Mensaje: '' }
    })

    /*ACTURALIZAR ESTADO REPORTE SOURCE*/
    const dataCloseLineTeminada = newDataCloseLine.filter((item) => item.Estatus === 'T')
    const newDataSourse = dataSourceReport.map((a) => {
      /*resultado de documento 1*/
      const lineResult1 = dataCloseLineTeminada.find(
        (b) =>
          b.NumIdent === 1 &&
          b.DocNum === a.DocNum_1 &&
          b.DocEntry === a.DocEntry_1 &&
          b.LineNum === a.LineNum_1
      )
      /*resultado de documento 2*/
      const lineResult2 = dataCloseLineTeminada.find(
        (b) =>
          b.NumIdent === 2 &&
          b.DocNum === a.DocNum_2 &&
          b.DocEntry === a.DocEntry_2 &&
          b.LineNum === a.LineNum_2
      )

      console.log(lineResult1, lineResult2)
      const newDataLine: partidasAbiertasReportTypes = {
        ...a,
        OpenQty_1: lineResult1 ? 0 : a.OpenQty_1,
        OpenQty_2: lineResult2 ? 0 : a.OpenQty_2
      }

      return newDataLine
    })

    console.log(newDataSourse)
    dispatch(
      setPartidasAbiertasReportData({
        data: newDataSourse.filter(
          (a) => (a.OpenQty_1 && a.OpenQty_1 > 0) || (a.OpenQty_2 && a.OpenQty_2 > 0)
        ),
        selectedLines: [],
        ...filter
      })
    )
    setDataCloseResult(newDataCloseLine)
  }

  const onExport = (evt: ExportingEvent<partidasAbiertasReportTypes, any>) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet(filter.DocType)

    exportDataGrid({
      component: evt.component,
      worksheet,
      autoFilterEnabled: true
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'ReportePartidasAbiertas.xlsx'
        )
      })
    })
    evt.cancel = true
  }

  return {
    filter,
    dataSourceReport,
    onExport,
    seeClientesDetails,
    setSeeClientesDetails,
    seeSegmentosDetails,
    setSeeSegmentosDetails,
    user,
    onChangeSelectionLines,
    selectedLinesClose,
    openInicioSAP,
    setOpenInicioSAP,
    isClosedLines,
    onCloseLineDocs,
    confirmCloseLineDocs,
    openResult,
    setOpenResult,
    dataCloseResult
  }
}
export default useDataPartidasAbiertas
