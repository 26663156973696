import {
	FilterTopVentasTemplate,
	DataTopVentasTemplate
} from '@/features/reports/ventas/topVentas/templates'

const TopVentasPage = () => {
	return (
		<>
			<FilterTopVentasTemplate />
			<DataTopVentasTemplate />
		</>
	)
}

export default TopVentasPage
