import axios from 'axios'
import {
  StockTransferTypes,
  StockTransferFromRequestTypes,
  CreatedTransferResponse
} from '../types/StockTransfer.types'
import { env } from '@/env'

export const useInventoryTransfer = () => {
  const baseUrl = process.env.REACT_APP_SERVICE_LAYER ?? env.REACT_APP_SERVICE_LAYER

  const createStockTransfer = async (args: StockTransferTypes) => {
    try {
      const { data } = await axios.post<CreatedTransferResponse>(
        baseUrl + '/StockTransfers',
        args,
        { withCredentials: true }
      )
      return data
    } catch (error) {
      throw error
    }
  }

  const createStockTransferFromRequest = async (args: StockTransferFromRequestTypes) => {
    try {
      const { data } = await axios.post<CreatedTransferResponse>(
        baseUrl + '/StockTransfers',
        args,
        { withCredentials: true }
      )
      return data
    } catch (error) {
      throw error
    }
  }

  return {
    createStockTransfer,
    createStockTransferFromRequest
  }
}
