import { makeUseAxios } from 'axios-hooks'
import axiosInstance from '@/app/services/axiosInstance.service'
import { env } from '@/env'
import { useState } from 'react'
import { IConfigDiasSurtido, IPlaza } from '../types/SurtidoTiendas.types'
import { useNotification } from '@/shared/hooks'
import { getError } from '@/shared/helpers'

const useSurtidoTiendasConfig = () => {
	const notification = useNotification()
	const [dataConfig, setDataConfig] = useState<IConfigDiasSurtido[]>([])
	const [lstPlaza, setLstPlaza] = useState<IPlaza[]>([])

	const useAxios = makeUseAxios({
		axios: axiosInstance(process.env.REACT_APP_API_WMS ?? (env.REACT_APP_API_WMS as string))
	})
	const [{ loading }, executeGetConfig] = useAxios<IConfigDiasSurtido[]>(
		{ url: '/asistente-reposicion/dias', method: 'get' },
		{ manual: true }
	)
	const [{ loading: saving }, executeSaveConfig] = useAxios<IConfigDiasSurtido[]>(
		{ url: '/asistente-reposicion/dias', method: 'put' },
		{ manual: true }
	)

	const getConfiguration = async () => {
		await executeGetConfig().then(({ data }) => {
			const _grupoPlaza: IPlaza[] = []
			const _plazas = data.map((item) => ({
				code: item.Location,
				name: item.Plaza
			}))

			_plazas.forEach((a) => {
				if (_grupoPlaza.filter((b) => b.code === a.code && b.name === a.name).length === 0) {
					_grupoPlaza.push(a)
				}
			})
			setLstPlaza(_grupoPlaza)
			setDataConfig(data)
		})
	}

	const onChangeConfig = (newConfigTienda: IConfigDiasSurtido) => {
		const newData = dataConfig.map((i) => {
			if (i.Almacen == newConfigTienda.Almacen) return newConfigTienda
			else return i
		})
		setDataConfig(newData)
	}

	const onSaveConfig = async () => {
		try {
			for (const config of dataConfig) {
				await executeSaveConfig({
					data: {
						almacen: config.Almacen,
						lunes: config.Lunes,
						martes: config.Martes,
						miercoles: config.Miercoles,
						jueves: config.Jueves,
						viernes: config.Viernes,
						sabado: config.Sabado,
						domingo: config.Domingo,
						porcLinea: config.PorcLinea,
						porcModa: config.PorcModa,
						porcAccesorios: config.PorcAccesorios,
						porcOtros: config.PorcOtros
					}
				})
			}
			notification.success('Configuración guardada correctamente')
		} catch (error) {
			notification.error('No fue posible guardar los cambios. Error: ' + getError(error))
		}
	}

	return {
		dataConfig,
		loading,
		lstPlaza,
		getConfiguration,
		onChangeConfig,
		saving,
		onSaveConfig
	}
}

export default useSurtidoTiendasConfig
