import { NavLink } from 'react-router-dom'
import { DashLinkTypes } from './types'
const DashLink = ({
	title,
	description,
	icon,
	primaryColor,
	secondaryColor,
	to
}: DashLinkTypes) => {
	return (
		<li className='w-fit transition-all duration-100 ease-in-out hover:ml-4'>
			<NavLink
				to={to}
				className='flex w-[30rem] max-[500px]:w-full h-28 rounded-l-3xl rounded-r-2xl border-2 p-1 border-blue-500 shadow-md  overflow-hidden '
			>
				<div className={`${primaryColor} w-44 h-full p-2 rounded-3xl`}>
					<div className='bg-white h-full w-full rounded-3xl grid place-items-center'>
						<figure className={`${secondaryColor} w-20 h-20 grid  place-items-center rounded-full`}>
							<img
								src={icon}
								alt='Icon'
								className=' w-12 h-12 object-center object-contain transition-transform ease-in-out duration-300 hover:rotate-45'
							/>
						</figure>
					</div>
				</div>
				<div className='flex-1  flex flex-col justify-center pl-4'>
					<h3 className='uppercase text-lg text-blue-gray font-semibold'>{title}</h3>
					<p className='block text-sm text-gray-500 '>{description}</p>
				</div>
			</NavLink>
		</li>
	)
}

export default DashLink
