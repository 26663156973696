import { Outlet } from 'react-router-dom'
import { Header, AsideMenu } from '@/shared/components/commons'

const DefaultLayout = () => {
	return (
		<div className='main-layout'>
			<Header />
			<AsideMenu />
			<Outlet />
		</div>
	)
}

export default DefaultLayout
