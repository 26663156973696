import { productDetailsTableTypes } from './interfaces'

const ProductDetailsTable = ({ rows }: productDetailsTableTypes) => {
	return (
		<div className='shadow-lg rounded-b-md'>
			<span className='block w-full px-2 py-1 mb-2 bg-blue-300  rounded-t-md'>
				<h4 className='text-xl text-gray-700 font-semibold'>Detalles del artículo</h4>
			</span>
			<div className='p-2'>
				<table className='simple-table'>
					<tbody>
						{rows?.Composicion ? (
							<tr>
								<td>Composición</td>
								<td>{rows?.Composicion}</td>
							</tr>
						) : null}

						{rows?.Material ? (
							<tr>
								<td>Material</td>
								<td>{rows?.Material}</td>
							</tr>
						) : null}

						{rows?.Capacidad ? (
							<tr>
								<td>Capacidad</td>
								<td>{rows?.Capacidad}</td>
							</tr>
						) : null}

						{rows?.Marca ? (
							<tr>
								<td>Marca</td>
								<td>{rows?.Marca}</td>
							</tr>
						) : null}

						{rows?.Uso ? (
							<tr>
								<td>Uso</td>
								<td>{rows?.Uso}</td>
							</tr>
						) : null}

						{rows?.Tejido ? (
							<tr>
								<td>Tejido</td>
								<td>{rows?.Tejido}</td>
							</tr>
						) : null}

						{rows?.PaisOrigen ? (
							<tr>
								<td>País</td>
								<td>{rows?.PaisOrigen}</td>
							</tr>
						) : null}

						{rows?.Tecnica ? (
							<tr>
								<td>Tecnica</td>
								<td>{rows?.Tecnica}</td>
							</tr>
						) : null}

						{rows?.Cuidado ? (
							<tr>
								<td>Cuidados</td>
								<td>{rows?.Cuidado}</td>
							</tr>
						) : null}

						{rows?.CuidadoLavado ? (
							<tr>
								<td>Cuidado lavado</td>
								<td>{rows?.CuidadoLavado}</td>
							</tr>
						) : null}

						{rows?.CuidadoSecado ? (
							<tr>
								<td>Cuidado secado</td>
								<td>{rows?.CuidadoSecado}</td>
							</tr>
						) : null}

						{rows?.CuidadoPlanchado ? (
							<tr>
								<td>Cuidado planchado</td>
								<td>{rows?.CuidadoPlanchado}</td>
							</tr>
						) : null}

						{rows?.AtributosComerciales ? (
							<tr>
								<td>Atributos comerciales</td>
								<td>{rows?.AtributosComerciales}</td>
							</tr>
						) : null}

						{rows?.InfAdicional ? (
							<tr>
								<td>Informacion adicional</td>
								<td>{rows?.InfAdicional}</td>
							</tr>
						) : null}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default ProductDetailsTable
