import { useContext } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { SearchText } from '@/shared/components/fields'
import { spotlightHeaderTypes } from './interfaces'
import { spotlightContext } from './Spotlight'

const SpotlightHeader = ({ children }: spotlightHeaderTypes) => {
	const { toUpperCase, onChange, searchRef, handleClose, onPressEnter } =
		useContext(spotlightContext)
	const decorateHandleClose = () => {
		handleClose()
		if (searchRef.current) {
			searchRef.current.clear()
		}
	}

	return (
		<section className='portal__spotlight__head'>
			<div className='portal__spotlight__head-content'>
				{children}
				<SearchText
					id='search-spotlight'
					name='search-spotlight'
					toUpperCase={toUpperCase}
					onChange={onChange}
					ref={searchRef}
					onPressEnter={onPressEnter}
				/>
				<button onClick={decorateHandleClose}>
					<XMarkIcon width={28} />
				</button>
			</div>
		</section>
	)
}

export default SpotlightHeader
