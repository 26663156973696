import { Drawer } from '@/shared/components/modals'
import { IncidentItem } from '@/features/goodsReceipt/components'
import { ShipmentIncidentsTypes } from '@/features/goodsReceipt/types/shippingList.types'

interface Props {
	isDrawerOpen: boolean
	setIsDrawerOpen: () => void
	shipmentIncidents?: ShipmentIncidentsTypes[]
}

export const ShippingIncidents = ({ isDrawerOpen, setIsDrawerOpen, shipmentIncidents }: Props) => {
	return (
		<Drawer
			position='right'
			wrapperId='DRAWER_INCIDENT_LIST_DOCS'
			isOpen={isDrawerOpen}
			handleClose={setIsDrawerOpen}
		>
			<section className='space-y-8'>
				{shipmentIncidents && shipmentIncidents.length > 0 ? (
					shipmentIncidents.map((item) => <IncidentItem key={item.Bulto + item.Hora} item={item} />)
				) : (
					<div className=' bg-white rounded-md shadow-lg h-12 flex items-center justify-center '>
						<h3 className=''>Sin incidencias</h3>
					</div>
				)}
			</section>
		</Drawer>
	)
}
