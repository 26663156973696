import { DialogTypes } from './types'
import Portal from '../portal'
import { Overlay, Button } from '../commons'
import { XMarkIcon } from '@heroicons/react/24/solid'

const Dialog = ({
	wrapperId,
	isOpen,
	handleClose,
	width = 200,
	height = 200,
	title,
	text,
	cancel,
	confirm,
	isLoading,
	children
}: DialogTypes) => {
	const onPressCancel = () => {
		if (cancel) {
			cancel()
		}

		if (isOpen) {
			handleClose()
		}
	}

	const onPressConfirm = async () => {
		await confirm()
		if (isOpen) {
			handleClose()
		}
	}

	return (
		<Portal wrapperId={wrapperId}>
			<aside
				className={`portal__modal ${isOpen ? 'active' : ''}`}
				style={{ width: width, height: height }}
			>
				<section className='portal__modal__head'>
					<h4>{title}</h4>
					<button onClick={handleClose} className='flex items-center'>
						<XMarkIcon width={28} />
					</button>
				</section>
				<section className='portal__modal__body--no-rounded'>
					{children ? children : <p>{text}</p>}
				</section>
				<section className='portal_modal_foot'>
					<Button className='btn secondary w-full' onClick={onPressCancel} disabled={isLoading}>
						Cancelar
					</Button>
					<Button className='btn primary w-full' isLoading={isLoading} onClick={onPressConfirm}>
						Aceptar
					</Button>
				</section>
			</aside>
			{isOpen ? <Overlay /> : null}
		</Portal>
	)
}

export default Dialog
