import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { ControlText } from '@/shared/components/fields'
import { userEmailTypes, RecoveryFormProp } from '@/features/auth/auth.types'
import { Button } from '@/shared/components/commons'

const RecoveryPasswordForm = ({ onSubmit, isLoading }: RecoveryFormProp) => {
	const initialValues: userEmailTypes = {
		email: ''
	}

	const validationSchema = Yup.object().shape({
		email: Yup.string().required('El correo es requerido')
	})

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
			<Form>
				<div className='mb-4'>
					<ControlText
						id='email'
						name='email'
						type='login'
						placeholder='example@greencompany.biz'
					/>
				</div>
				<Button className='btn primary w-full' isLoading={isLoading}>
					Solicitar
				</Button>
			</Form>
		</Formik>
	)
}

export default RecoveryPasswordForm
