import createStore from './app/store/createStore'
import { Provider } from 'react-redux'
import { AppRouter } from './app/router'
import NotificationContainer from './features/notifications/containers'
import './styles/main.css'
import 'devextreme/dist/css/dx.light.css'
import 'react-datepicker/dist/react-datepicker.css'
import SystemMessageContainer from './features/systemMessage/containers/SystemMessageContainer'

function App() {
	return (
		<Provider store={createStore}>
			<NotificationContainer />
			<SystemMessageContainer />
			<AppRouter />
		</Provider>
	)
}

export default App
