import { NavLink } from 'react-router-dom'
import { LinkImageProps } from './types'

const LinkImage = ({ image, width, heigth, path }: LinkImageProps) => {
	const styles = {
		width: `${width}rem`,
		heigth: `${heigth || width}rem`
	}

	return (
		<NavLink to={path}>
			<figure className='p-1 rounded-full overflow-hidden' style={styles}>
				<img
					src={image}
					alt='Avatar'
					className='w-full h-full object-cover object-center rounded-full'
				/>
			</figure>
		</NavLink>
	)
}

export default LinkImage
