import { FunnelIcon } from '@heroicons/react/24/outline'
import { AsideModal } from '@/shared/components/modals'
import {
	DateControl,
	SelectControl,
	ControlText,
	ControlCheckbox,
	ControlRadio
} from '@/shared/components/fields'
import { Button } from '@/shared/components/commons'
import { Formik } from 'formik'
import { IFiltersTopVentas } from '@/features/reports/ventas/topVentas/topVentas.types'
import * as Yup from 'yup'
import useFilterTopVentas from '@/features/reports/ventas/topVentas/hooks/useFilterTopVentas'
import { CheckListSimple } from '@/shared/components/checkList/CheckListSimple'
import { useState } from 'react'
import CheckList from '@/shared/components/checkList'

export const FilterTopVentasTemplate = () => {
	const {
		isOpen,
		setOpen,
		ReportType,
		initialLoad,
		initialLoadCat1,
		initialLoadCat2,
		initialLoadCat3,
		loading,
		placesCatalogue,
		defaulRegionCheck,
		defaultLocationCheck,
		catFamilias,
		catGeneros,
		catGruposEdad,
		onSubmit,
		getUserDefaultShops,
		userHasAShop
	} = useFilterTopVentas()

	const [FamiliasSel, setFamiliasSel] = useState<string[]>([])
	const [GenerosSel, setGenerosSel] = useState<string[]>([])
	const [GruposEdadSel, setGruposEdadSel] = useState<string[]>([])
	const [AuxVisualSel, setAuxVisualSel] = useState<string[]>([])
	const onChangeFamiliasSel = (values: string[]) => {
		setFamiliasSel(values)
	}

	const onChangeGenerosSel = (values: string[]) => {
		setGenerosSel(values)
	}

	const onChangeGruposEdadSel = (values: string[]) => {
		setGruposEdadSel(values)
	}

	const onChangeAuxVisualSel = (values: string[]) => {
		setAuxVisualSel(values)
	}

	const date1 = new Date()
	date1.setMonth(date1.getMonth() - 1)
	const date2 = new Date()

	const initialState: IFiltersTopVentas = {
		ReportType: '1',
		ShowTop: '20',
		DateStart: date1,
		DateEnd: date2,
		Segmento: ['Linea', 'Moda', 'Accesorios'],
		Vision: userHasAShop() ? '4' : '1',
		AuxVision: AuxVisualSel,
		InventoryType: ['Primera', 'Segunda'],
		OmitDesc: false,
		DescNoValid: 20,
		OrdenBy: '1',
		GroupByLocation: '1',
		GroupByItem: '1',
		Familias: FamiliasSel,
		Generos: GenerosSel,
		GruposEdad: GruposEdadSel,
		DiasValidoMov: 15
	}

	const validationSchema = Yup.object().shape({
		ReportType: Yup.string().required('Selecciona un reporte valido'),
		ShowTop: Yup.string().required('Selecciona un valor valido'),
		DateStart: Yup.string().required('Ingrese una fecha valida'),
		DateEnd: Yup.string().required('Ingrese una fecha valida')
	})

	return (
		<>
			<div className='main-layout-toolbar toolbar'>
				<AsideModal
					title='Filtros'
					className='w-full md:w-[30%] xl:w-[20%]'
					closeButtonElement={<FunnelIcon width={28} className='text-white' />}
					classNameButton='toolbar-button'
					isOpen={isOpen}
					handleClose={setOpen}
				>
					<Formik
						enableReinitialize={false}
						initialValues={initialState}
						validationSchema={validationSchema}
						onSubmit={(values) => {
							//console.log(JSON.stringify({...values, Familias:FamiliasSel, Generos: GenerosSel, GruposEdad: GruposEdadSel  }, null, 2))
							onSubmit({
								...values,
								AuxVision: AuxVisualSel,
								Familias: FamiliasSel,
								Generos: GenerosSel,
								GruposEdad: GruposEdadSel
							})
						}}
					>
						{(props) => (
							<form onSubmit={props.handleSubmit} className='h-full flex flex-col'>
								<div className={`absolute top-14 bottom-14 left-2 right-2 overflow-auto`}>
									<SelectControl id='ReportType' name='ReportType' label='Tipo de Reporte'>
										{ReportType.map((list) => (
											<option key={list.code} value={list.code}>
												{list.name}
											</option>
										))}
									</SelectControl>

									<SelectControl id='ShowTop' name='ShowTop' label='Mostrar Top'>
										<option value='10'>10</option>
										<option value='20'>20</option>
										<option value='30'>30</option>
									</SelectControl>

									<fieldset className='border border-slate-300 w-full rounded-md p-2 grid grid-cols-2 gap-2'>
										<legend className='font-semibold'>Rango de Fechas</legend>
										<DateControl
											id='DateStart'
											name='DateStart'
											label='Del'
											dateFormat='yyyy-MM-dd'
										/>
										<DateControl id='DateEnd' name='DateEnd' label='Al' dateFormat='yyyy-MM-dd' />
									</fieldset>

									<fieldset className='border border-slate-300 w-full rounded-md p-2 grid grid-cols-3 col-span-1'>
										<legend className='font-semibold'>Segmento</legend>
										<ControlCheckbox
											id='ckLinea'
											name='Segmento'
											value='Linea'
											label='Linea'
											checked={props.values.Segmento.includes('Linea')}
										/>
										<ControlCheckbox
											id='ckModa'
											name='Segmento'
											value='Moda'
											label='Moda'
											checked={props.values.Segmento.includes('Moda')}
										/>
										<ControlCheckbox
											id='ckAccesorios'
											name='Segmento'
											value='Accesorios'
											label='Accesorios'
											checked={props.values.Segmento.includes('Accesorios')}
										/>
									</fieldset>

									<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col'>
										<legend className='font-semibold'>Opciones de Visión</legend>
										<div className='w-full grid grid-cols-4 col-span-1'>
											<ControlRadio
												id='vision1'
												name='Vision'
												value='1'
												label='Grupo'
												checked={props.values.Vision === '1'}
											/>
											<ControlRadio
												id='vision2'
												name='Vision'
												value='2'
												label='Region'
												checked={props.values.Vision === '2'}
											/>
											<ControlRadio
												id='vision3'
												name='Vision'
												value='3'
												label='Plaza'
												checked={props.values.Vision === '3'}
											/>
											<ControlRadio
												id='vision4'
												name='Vision'
												value='4'
												label='Tienda'
												checked={props.values.Vision === '4'}
											/>
										</div>

										<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col mt-2'>
											<legend className='font-semibold'>Selección de Visión</legend>
											{props.values.Vision === '1' && (
												<p className='text-sm'>
													La opción Grupo no requiere filtros en la sección de visión.
												</p>
											)}
											{props.values.Vision === '2' && (
												<CheckListSimple
													initialItems={placesCatalogue
														?.map((item) => ({
															code: item.region,
															description: item.nombreRegion,
															defaultChecked: defaulRegionCheck(item.region)
														}))
														.filter(
															(a, i) =>
																placesCatalogue.findIndex(
																	(s) => a.code === s.region && a.code !== null
																) === i
														)
														.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0))}
													height={120}
													onChangeCheck={onChangeAuxVisualSel}
												/>
											)}
											{props.values.Vision === '3' && (
												<CheckListSimple
													initialItems={placesCatalogue
														?.map((item) => ({
															code: item.codigoLocalidad.toString(),
															description: item.plaza,
															defaultChecked: defaultLocationCheck(item.codigoLocalidad)
														}))
														.sort((a, b) =>
															a.description > b.description
																? 1
																: b.description > a.description
																? -1
																: 0
														)}
													height={300}
													onChangeCheck={onChangeAuxVisualSel}
												/>
											)}
											{props.values.Vision === '4' && (
												<CheckList
													initialItems={placesCatalogue?.map((item) => ({
														id: item.plaza,
														label: item.plaza,
														defaultChecked: defaultLocationCheck(item.codigoLocalidad),
														itemList: item.almacenes
															.map((list) => ({
																id: list.codigo,
																value: list.codigo,
																label: list.nombre,
																sup: list.tipo,
																defaultChecked: getUserDefaultShops(list.codigo)
															}))
															.filter((a) => a.sup === 'Tienda')
													}))}
													height={300}
													onCheckChange={onChangeAuxVisualSel}
												/>
											)}
										</fieldset>
									</fieldset>
									<fieldset className='border border-slate-300 w-full rounded-md p-2 grid grid-cols-2 col-span-1'>
										<legend className='font-semibold'>Tipo de venta</legend>
										<ControlCheckbox
											id='ckPrimera'
											name='InventoryType'
											value='Primera'
											label='Primera'
											checked={props.values.InventoryType.includes('Primera')}
										/>
										<ControlCheckbox
											id='ckSegunda'
											name='InventoryType'
											value='Segunda'
											label='Segunda'
											checked={props.values.InventoryType.includes('Segunda')}
										/>
									</fieldset>

									<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col'>
										<legend className='font-semibold'>Familia</legend>
										<CheckListSimple
											initialItems={catFamilias
												?.map((item) => ({
													code: item.Code,
													description: item.Name,
													defaultChecked: true,
													isChecked: true
												}))
												.sort((a, b) =>
													a.description > b.description ? 1 : b.description > a.description ? -1 : 0
												)}
											height={200}
											onChangeCheck={onChangeFamiliasSel}
											showSearchBox={true}
										/>
									</fieldset>

									<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col'>
										<legend className='font-semibold'>Genero</legend>
										<CheckListSimple
											initialItems={catGeneros
												?.map((item) => ({
													code: item.Code,
													description: item.Name,
													defaultChecked: true,
													isChecked: true
												}))
												.sort((a, b) =>
													a.description > b.description ? 1 : b.description > a.description ? -1 : 0
												)}
											height={200}
											onChangeCheck={onChangeGenerosSel}
											showSearchBox={true}
										/>
									</fieldset>

									<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col'>
										<legend className='font-semibold'>Grupo de Edad</legend>
										<CheckListSimple
											initialItems={catGruposEdad
												?.map((item) => ({
													code: item.Code,
													description: item.Name,
													defaultChecked: true,
													isChecked: true
												}))
												.sort((a, b) =>
													a.description > b.description ? 1 : b.description > a.description ? -1 : 0
												)}
											height={200}
											onChangeCheck={onChangeGruposEdadSel}
											showSearchBox={true}
										/>
									</fieldset>

									<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col'>
										<legend className='font-semibold'>Descuento</legend>
										<ControlCheckbox
											id='ckOmitDesc'
											name='OmitDesc'
											value='OmitDesc'
											label='Omitir descuentos'
											checked={props.values.OmitDesc}
										/>
										<ControlText
											id='DescNoValid'
											label='Descuento No Valido a partir del (%)'
											name='DescNoValid'
											type='number'
											disabled={!props.values.OmitDesc}
										/>
									</fieldset>

									<fieldset className='border border-slate-300 w-full rounded-md p-2 grid grid-cols-3 col-span-1'>
										<legend className='font-semibold'>Ordenamiento</legend>
										<ControlRadio
											id='ordenBy1'
											name='OrdenBy'
											value='1'
											label={props.values.ReportType === '3' ? 'Tiempo' : 'Piezas'}
											checked={props.values.OrdenBy === '1'}
										/>
										<ControlRadio
											id='ordenBy2'
											name='OrdenBy'
											value='2'
											label={props.values.ReportType === '3' ? 'Stock' : 'Importe'}
											checked={props.values.OrdenBy === '2'}
										/>
										<ControlRadio
											id='ordenBy3'
											name='OrdenBy'
											value='3'
											label='Margen'
											checked={props.values.OrdenBy === '3'}
										/>
									</fieldset>

									<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col'>
										<legend className='font-semibold'>Agrupacion</legend>
										<section className='mt-0'>
											<label>Código</label>
											<section className='w-full grid grid-cols-3 col-span-1'>
												<ControlRadio
													id='groupByItem1'
													name='GroupByItem'
													value='1'
													label='Modelo'
													checked={props.values.GroupByItem === '1'}
												/>
												<ControlRadio
													id='groupByItem2'
													name='GroupByItem'
													value='2'
													label='SKU'
													checked={props.values.GroupByItem === '2'}
												/>
											</section>
										</section>
										<section className='mt-2'>
											<label>Ubicación</label>
											<section className='w-full grid grid-cols-4 col-span-1'>
												<ControlRadio
													id='groupByLocation1'
													name='GroupByLocation'
													value='1'
													label='Grupo'
													checked={props.values.GroupByLocation === '1'}
												/>
												<ControlRadio
													id='groupByLocation2'
													name='GroupByLocation'
													value='2'
													label='Region'
													checked={props.values.GroupByLocation === '2'}
												/>
												<ControlRadio
													id='groupByLocation3'
													name='GroupByLocation'
													value='3'
													label='Plaza'
													checked={props.values.GroupByLocation === '3'}
												/>
												<ControlRadio
													id='groupByLocation4'
													name='GroupByLocation'
													value='4'
													label='Tienda'
													checked={props.values.GroupByLocation === '4'}
												/>
											</section>
										</section>
									</fieldset>
									{props.values.ReportType === '3' && (
										<fieldset className='border border-slate-300 w-full rounded-md p-2 flex flex-col'>
											<legend className='font-semibold'>Dias valido de movimiento</legend>
											<ControlText id='DiasValidoMov' label='' name='DiasValidoMov' type='number' />
										</fieldset>
									)}
								</div>

								<div className='flex-1 mt-4 absolute bottom-2 left-2 right-2'>
									<Button
										type='submit'
										className='btn primary w-full'
										isLoading={
											initialLoad ||
											initialLoadCat1 ||
											initialLoadCat2 ||
											initialLoadCat3 ||
											loading
										}
									>
										Consultar
									</Button>
								</div>
							</form>
						)}
					</Formik>
				</AsideModal>
			</div>
		</>
	)
}
