import { ChangeEvent } from 'react'
import { radioTypes } from './types'

const Radio = ({ label, onChange, ...rest }: radioTypes) => {
	const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
		const { value } = evt.target
		onChange!(value)
	}

	return (
		<label htmlFor={rest.id}>
			<input type='radio' {...rest} onChange={handleOnChange} className='mr-1' />
			{label}
		</label>
	)
}

export default Radio
