import { createSlice } from '@reduxjs/toolkit'
import authSlice from '@/features/auth/auth.slice'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { IPackingReceiptSlice, IPackingList } from '../types/packingList.types'

type ConfirmPacking = { id: number; onlyReceived: 'Y' | 'N' }

export const packingReceiptSlice = createSlice({
  name: 'packingSlice',
  initialState: {
    includProv: true
  } as IPackingReceiptSlice,
  reducers: {
    setPacking: (state, action: PayloadAction<IPackingList>) => ({
      ...state,
      Id: action.payload.Id,
      IdContenedor: action.payload.IdContenedor,
      CodigoProveedor: action.payload.CodigoProveedor,
      Estatus: action.payload.Estatus,
      FechaLlegada: action.payload.FechaLlegada,
      NombreProveedor: action.payload.NombreProveedor,
      data: state.excludeLocalities
        ? action.payload.Detalles.filter(
            (item) => !state.excludeLocalities.includes(item.Localidad)
          ).filter((item) =>
            state.includProv
              ? item.Proveeduria === 'Y' || item.Proveeduria === 'N'
              : item.Proveeduria !== 'Y'
          )
        : action.payload.Detalles.filter((item) =>
            state.includProv
              ? item.Proveeduria === 'Y' || item.Proveeduria === 'N'
              : item.Proveeduria !== 'Y'
          ),
      notModifiedData: action.payload.Detalles,
      incidents: action.payload.Incidencias
    }),
    setConfirmLine: (state, action: PayloadAction<ConfirmPacking>) => ({
      ...state,
      confirmLines: [...state.confirmLines, action.payload.id],
      data: state.data
        ? state.data.map((item) => {
            if (item.IdDispersion !== action.payload.id) {
              return item
            } else if (action.payload.onlyReceived === 'Y') {
              return { ...item, Confirmado1: 'C' }
            } else {
              return item.Confirmado1 === 'U'
                ? { ...item, Confirmado1: 'C' }
                : { ...item, Confirmado2: 'C' }
            }
          })
        : undefined
    }),
    clearPacking: (state) => ({
      ...state,
      CodigoProveedor: '',
      confirmLines: [],
      data: undefined,
      notModifiedData: undefined,
      Estatus: '',
      FechaLlegada: '',
      Id: 0,
      IdContenedor: 0,
      incidents: undefined,
      NombreProveedor: ''
    }),
    changeOmitProv: (state, action: PayloadAction<boolean>) => ({
      ...state,
      includProv: action.payload,
      data: state.notModifiedData
        ? state.excludeLocalities
          ? state.notModifiedData
              .filter((item) => !state.excludeLocalities.includes(item.Localidad))
              .filter((item) =>
                action.payload
                  ? item.Proveeduria === 'Y' || item.Proveeduria === 'N'
                  : item.Proveeduria !== 'Y'
              )
          : state.notModifiedData.filter((item) =>
              action.payload
                ? item.Proveeduria === 'Y' || item.Proveeduria === 'N'
                : item.Proveeduria !== 'Y'
            )
        : undefined
    })
  },
  extraReducers: (builder) => {
    builder.addCase(authSlice.actions.setCheckLocation, (state, action) => {
      const current = state.excludeLocalities
        ? action.payload.value
          ? state.excludeLocalities.filter((item) => item !== action.payload.code)
          : [...state.excludeLocalities, action.payload.code]
        : [action.payload.code]
      const filterData = state.notModifiedData
        ? state.notModifiedData
            .filter((item) => !current.includes(item.Localidad))
            .filter((item) =>
              state.includProv
                ? item.Proveeduria === 'Y' || item.Proveeduria === 'N'
                : item.Proveeduria !== 'Y'
            )
        : undefined
      return { ...state, excludeLocalities: current, data: filterData }
    })
  }
})

export const { setPacking, clearPacking, setConfirmLine, changeOmitProv } =
  packingReceiptSlice.actions
