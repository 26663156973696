import { ListItem } from './types'

const CheckItem = ({ id, sub, sup, value, isChecked, onListItemCheck }: ListItem) => {
	return (
		<span key={id} className={`block text-sm text-gray-500 ${sup ? 'mb-6 mt-6' : 'mb-2 mt-2'}`}>
			{sup ? <sup className='block'>{sup}</sup> : null}
			<label htmlFor={id} className='text-xs font-bold '>
				<input
					id={id}
					type='checkbox'
					className='mr-1'
					value={value}
					checked={isChecked}
					onChange={onListItemCheck}
				/>
				{value}
			</label>
		</span>
	)
}

export default CheckItem
