import { useSelector } from 'react-redux'
import { RootState } from '@/app/store/createStore'
import Menu from './Menu'
import Avatar from './Avatar'
import LinkImage from './LinkImage'
import { logoImage, homeImage } from '@/assets'
import { ArrowPathIcon } from '@heroicons/react/20/solid'

const Header = () => {
	const { user } = useSelector((state: RootState) => state.auth)
	return (
		<header className='main-layout-header bg-black-shape'>
			<div className={'w-full h-full flex items-center justify-between'}>
				<LinkImage path={'/dashboard'} image={logoImage} width={3} />
				<div className={'flex items-center space-x-2'}>
					<button
						className='xl:hidden cursor-pointer text-white'
						onClick={() => window.location.reload()}
					>
						<ArrowPathIcon width={36} />
					</button>
					<LinkImage path={'/dashboard'} image={homeImage} width={3} />
					<Avatar width={3} image={user?.ImgPerfil} alt={user?.Nombre} />
					<Menu />
				</div>
			</div>
		</header>
	)
}

export default Header
