import { MagnifyingGlassIcon, CameraIcon } from '@heroicons/react/24/outline'
import Spotlight from '@/shared/components/spotlight'
import { useSearchProduct } from '../hooks'
import Scanner from '@/shared/components/scanner'
import { RollerLoader } from '@/shared/components/loaders'
import { Button } from '@/shared/components/commons'

const SearchProductTemplate = () => {
	const {
		spotlightOpen,
		searchTextRef,
		closeSpotLight,
		onSearchTextChange,
		models,
		onClickOnModel,
		onScannerDecode,
		setfindByScanner,
		findByScanner,
		isFetchingModels,
		isFetchigDocuments,
		onPressEnter
	} = useSearchProduct()
	return (
		<>
			<button onClick={closeSpotLight} className='toolbar-button '>
				<MagnifyingGlassIcon width={28} className='text-white' />
			</button>

			<Spotlight
				isOpen={spotlightOpen}
				handleClose={closeSpotLight}
				searchRef={searchTextRef}
				onChange={onSearchTextChange}
				toUpperCase={true}
				loaderComponent={<RollerLoader width={34} height={34} />}
				isLoading={isFetchingModels || isFetchigDocuments}
				onPressEnter={onPressEnter}
			>
				<Spotlight.Header>
					<button
						onClick={setfindByScanner}
						className={`${findByScanner ? 'text-blue-500' : 'text-black'}`}
					>
						<CameraIcon width={28} />
					</button>
				</Spotlight.Header>
				<Spotlight.Body>
					{models?.map((item) => (
						<li key={item.CodigoModelo} className=' h-12 border-b flex items-center mt-2'>
							<Button
								onClick={() => onClickOnModel(item.CodigoModelo)}
								className='w-full h-full text-start'
							>
								{item.CodigoModelo}
							</Button>
						</li>
					))}
				</Spotlight.Body>
				{findByScanner ? <Scanner onDecode={onScannerDecode} /> : <></>}
			</Spotlight>
		</>
	)
}

export default SearchProductTemplate
