import { DataGrid, Column, GroupPanel, SearchPanel } from 'devextreme-react/data-grid'
import { useUploadFileMin } from '../hooks'
import { Title, Button } from '@/shared/components/commons'
import { UploadBlackIcon } from '@/assets'

const UploadFileMinPage = () => {
	const {
		data,
		loadingUpdateDate,
		loadingUpdateMaximums,
		intputRef,
		isMaximumsCheck,
		onChange,
		uploadFileData,
		onChangeChecked
	} = useUploadFileMin()
	return (
		<>
			<div className='main-layout-toolbar toolbar'></div>
			<main className='main-layout-main p-4'>
				<Title
					title='Carga de minimos'
					pageName='Carga'
					backTo='/reposicion-de-inventario/tiendas'
					backPage='Surtido de tiendas'
					icon={UploadBlackIcon}
				/>

				<div className=' w-96 mb-4 flex flex-col'>
					<label htmlFor='upload-file-input' className='block mb-1'>
						Archivo de carga (.xls)
						<input
							id='upload-file-input'
							type='file'
							className='field-input'
							onChange={onChange}
							ref={intputRef}
						/>
					</label>
					<label htmlFor='chkMaximums' className='flex items-center text-sm'>
						Cargar maximos
						<input
							id='chkMaximums'
							type='checkbox'
							className='ml-1'
							checked={isMaximumsCheck}
							onChange={onChangeChecked}
						/>
					</label>
				</div>

				<DataGrid
					width={'100%'}
					columnResizingMode={'nextColumn'}
					showBorders={true}
					showRowLines={true}
					dataSource={data}
				>
					<GroupPanel visible={true} />
					<SearchPanel visible={true} highlightCaseSensitive={true} placeholder='Buscar' />
					<Column dataField='whscode' caption='Tienda' width={100} groupIndex={0} sortOrder='asc' />
					<Column dataField='itemcode' caption='Articulo' />
					<Column dataField='valor' caption='Valor' width={90} dataType='number' />
					<Column dataField='finicio' caption='Fecha inicial' width={120} dataType='date' />
					<Column dataField='ffin' caption='Fecha final' width={120} dataType='date' />
				</DataGrid>

				<div className='mt-4'>
					<Button
						className='btn primary w-32'
						isLoading={loadingUpdateDate || loadingUpdateMaximums}
						disabled={data.length === 0}
						onClick={uploadFileData}
					>
						Procesar
					</Button>
				</div>
			</main>
		</>
	)
}

export default UploadFileMinPage
