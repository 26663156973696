import DatePicker from 'react-datepicker'
import { ShippingListTable, LoginSAP, ShippingIncidents } from '../components'
import { useToggle } from '@/shared/hooks'
import { useShippingListPage } from '../hooks/useShippingListPage'
import { Button } from '@/shared/components/commons'
import { Modal } from '@/shared/components/modals'
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline'
import { SpinnerLoader } from '@/shared/components/loaders'

const ShippingListPage = () => {
	const [showModal, setShowModal] = useToggle()
	const {
		shipmets,
		dates,
		onChangeDates,
		onSubmit,
		isLoadingShipmets,
		movements,
		createDocuments,
		razonMov,
		onRazonChange,
		showResults,
		setShowResults,
		documentsCreation,
		DocsQuantity,
		isDrawerOpen,
		setIsDrawerOpen,
		bulkHasIncident,
		bulkIncidents,
		getbulkIncidents,
		onCheckSecondsChange,
		isCreatingDocs
	} = useShippingListPage()

	return (
		<>
			<div className='main-layout-toolbar toolbar'>
				<button className='text-white' onClick={setShowResults}>
					<ClipboardDocumentCheckIcon width={28} />
				</button>
			</div>

			<main className='main-layout-main p-4'>
				<section className='mb-4 p-2'>
					<form onSubmit={onSubmit} className='w-full flex items-end md:w-2/4 xl:w-1/4'>
						<fieldset className='flex flex-row justify-between space-x-2 mr-2'>
							<label htmlFor='beginDate' className='block flex-1 text-sm'>
								Fecha de inicial
								<DatePicker
									id='beginDate'
									selected={dates.beginDate}
									onChange={(date) => {
										onChangeDates(date, 'beginDate')
									}}
									className='field-input'
								/>
							</label>
							<label htmlFor='endDate' className='block flex-1 text-sm'>
								Fecha final
								<DatePicker
									id='enDate'
									selected={dates.endDate}
									onChange={(date) => {
										onChangeDates(date, 'endDate')
									}}
									className='field-input'
								/>
							</label>
						</fieldset>
						<Button type='submit' className='btn primary w-28' isLoading={isLoadingShipmets}>
							Consultar
						</Button>
					</form>
				</section>

				<section className='p-2'>
					<ShippingListTable
						data={shipmets}
						bulkHasIncident={bulkHasIncident}
						getbulkIncidents={getbulkIncidents}
					/>
				</section>

				<div className=' w-full mt-4 flex flex-col items-end space-y-2'>
					<label id={'chkIncluir'}>
						<input
							type={'checkbox'}
							className='mr-1'
							id={'chkIncluir'}
							onChange={onCheckSecondsChange}
						/>
						Incluir segundas
					</label>
					<Button disabled={DocsQuantity === 0} className='btn primary w-42' onClick={setShowModal}>
						Procesar Embarques
					</Button>
				</div>
			</main>

			<Modal
				wrapperId='SAP_MODAL_DOCS'
				isOpen={showModal}
				handleClose={setShowModal}
				title='Procesar documentos'
				width={300}
				height={380}
			>
				<div className=' p-2'>
					<label htmlFor='razon-mov' className='block text-sm mb-6'>
						Razon de movimiento
						<select
							className='field-input'
							id='razon-mov'
							value={razonMov}
							onChange={onRazonChange}
						>
							<option value={''}>Seleccione una opcion</option>
							{movements?.map((item) => (
								<option
									key={item.Code}
									value={item.Code}
								>{`${item.Code} - ${item.Descripcion}`}</option>
							))}
						</select>
					</label>
					<LoginSAP action={createDocuments} />
				</div>
			</Modal>

			<Modal
				wrapperId='SAP_MODAL_RESULT'
				isOpen={showResults}
				handleClose={setShowResults}
				title='Documentos procesados'
				width={300}
				height={380}
			>
				<div className='relative'>
					<div className='mb-2 p-1 bg-gray-50 rounded-md'>
						<span>Documentos por generar: {DocsQuantity}</span>
					</div>

					{isCreatingDocs ? (
						<div className='flex items-center justify-center w-full'>
							<SpinnerLoader />
						</div>
					) : (
						<ul className='space-y-2'>
							{documentsCreation.map((item) => (
								<li
									key={item.document}
									className={`p-1 rounded-md text-sm ${
										item.type === 'error' ? 'bg-red-100' : 'bg-green-100'
									}`}
								>
									<p className='mb-1'>
										{' '}
										<span className='font-semibold'>Documento:</span> {item.document}
									</p>
									<p className='mb-1'>
										{' '}
										<span className='font-semibold'>Estado:</span> {item.type}
									</p>
									<p>
										{' '}
										<span className='font-semibold'>Mensaje:</span> {item.message}
									</p>
								</li>
							))}
						</ul>
					)}
				</div>
			</Modal>

			<ShippingIncidents
				isDrawerOpen={isDrawerOpen}
				setIsDrawerOpen={setIsDrawerOpen}
				shipmentIncidents={bulkIncidents}
			/>
		</>
	)
}

export default ShippingListPage
