import { useField, ErrorMessage } from 'formik'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { useToggle } from '@/shared/hooks'
import { ControlTextTypes } from './types'

const ControlText = ({ type, label, cols, rows, height = 150, ...props }: ControlTextTypes) => {
	const [field, meta] = useField(props)
	const [visibleText, setVisibleText] = useToggle(false)

	if (type === 'password') {
		return (
			<label htmlFor={props.id || props.name} className='block'>
				<span className={`field-input flex ${meta.error && meta.touched ? 'error' : ''}`}>
					<input
						type={visibleText ? 'text' : 'password'}
						{...props}
						{...field}
						className='w-full outline-none text-center'
					/>
					{visibleText ? (
						<EyeSlashIcon width={28} onClick={setVisibleText} className='text-blue-500' />
					) : (
						<EyeIcon width={28} onClick={setVisibleText} className='text-gray-600' />
					)}
				</span>
				<ErrorMessage
					name={props.name}
					component='span'
					className='text-red-500 font-semibold text-right text-sm'
				/>
			</label>
		)
	} else if (type === 'login') {
		return (
			<label htmlFor={props.id || props.name} className='block'>
				<input
					type={type}
					{...props}
					{...field}
					className={`field-input text-center ${meta.error && meta.touched ? 'error' : ''}`}
				/>
				<ErrorMessage
					name={props.name}
					component='span'
					className='text-red-500 font-semibold text-right text-sm'
				/>
			</label>
		)
	} else if (type === 'textarea') {
		return (
			<label htmlFor={props.id || props.name} className='block'>
				{label}
				<textarea
					cols={cols}
					rows={rows}
					{...props}
					{...field}
					style={{ height }}
					className={`field-input ${meta.error && meta.touched ? 'error' : ''}`}
				/>
				<ErrorMessage
					name={props.name}
					component='span'
					className='text-red-500 font-semibold text-right text-sm'
				/>
			</label>
		)
	} else {
		return (
			<label htmlFor={props.id || props.name} className='block'>
				{label}
				<input
					type={type}
					{...props}
					{...field}
					className={`field-input ${meta.error && meta.touched ? 'error' : ''}`}
				/>
				<ErrorMessage
					name={props.name}
					component='span'
					className='text-red-500 font-semibold text-right text-sm'
				/>
			</label>
		)
	}
}

export default ControlText
