import DataGrid, {
	Column,
	Paging,
	Pager,
	Selection,
	SearchPanel,
	GroupPanel,
	FilterRow,
	Export
} from 'devextreme-react/data-grid'
import { packingTableTypes } from './interfaces'
import { shippedIcon, deliveredIcon } from '@/assets'

export const PackingListTable = ({ title }: packingTableTypes) => {
	//Recibe la data de esa columa
	const CellRender = (isDelivered: boolean = false) => {
		if (isDelivered) {
			return <img src={deliveredIcon} alt='delivery icon' />
		} else {
			;<img src={shippedIcon} alt='shipped icon' />
		}
	}

	return (
		<div className='shadow-lg rounded-b-md'>
			<span className='block w-full px-2 py-1 mb-2 bg-blue-300 rounded-t-md'>
				<h4 className='text-xl text-gray-700 font-semibold'>{title}</h4>
			</span>
			<div className='p-2'>
				<DataGrid columnHidingEnabled={true} showBorders={true}>
					<GroupPanel visible={true} />
					<FilterRow visible={true} />
					<SearchPanel visible={true} highlightCaseSensitive={true} />
					<Paging defaultPageSize={10} />
					<Pager allowedPageSizes={[5, 10, 30, 50]} showPageSizeSelector={true} />
					<Selection mode='multiple' />
					<Export enabled={true} allowExportSelectedData={true} />
					<Column dataField='Proveedor' caption='Documento' dataType='string' />
					<Column dataField='ItemCode' caption='Unidades' dataType='string' />
					<Column dataField='ItemName' caption='Cajas' dataType='string' />
					<Column dataField='Cantidad' caption='Precio' dataType='number' />
					<Column dataField='CantidadAbierta' caption='Fecha de llegada' dataType='number' />
					<Column dataField='FechaLlegada' caption='Fecha de salida' dataType='string' />
					<Column dataField='Almacen' caption='Destino' dataType='string' />
					<Column dataField='Almacen' caption='Guia' dataType='string' />
					<Column dataField='Almacen' caption='Estatus' dataType='string' />
					<Column allowSorting={false} cellRender={CellRender} />
				</DataGrid>
			</div>
		</div>
	)
}

export default PackingListTable
